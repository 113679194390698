import { Checkbox, Typography, Box, Dialog, Button } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { AddOnItem } from '../pages/addOns/addOnsStyle'
import { type FC } from 'react'
import { type IAddOns } from '@shared/typings'
import { convertToDecimal } from '@shared/helper'

interface IAddAddOnsProps {
  hide: () => void
  addOns: IAddOns[]
  selectedAddOns: IAddOns[]
  setSelectedAddOns: (selectedAddOns: IAddOns[]) => void
}

const messages = {
  button: {
    save: 'Save',
    wait: 'Wait...',
    cancel: 'Cancel'
  }
}

export const AddAddOns: FC<IAddAddOnsProps> = ({
  hide,
  addOns,
  selectedAddOns,
  setSelectedAddOns
}) => {
  const theme = useTheme()

  const onSelect = (addonId: number, isSelect: boolean): void => {
    const isExist = selectedAddOns.find((addon) => addon.id === addonId)
    if ((isExist == null) && isSelect) {
      const tempAddOns = [...selectedAddOns]
      const selected = addOns.find((addon) => addon.id === addonId)
      if (selected !== undefined) {
        tempAddOns.push(selected)
      }
      setSelectedAddOns(tempAddOns)
    } else {
      setSelectedAddOns(selectedAddOns.filter((addon) => addon.id !== addonId))
    }
  }

  return (
    <Dialog
      open={true}
      theme={theme}
      title="Add-Ons"
      onCloseDialog={hide}
      size="sm"
      customFooter={
        <>
          <Button
            theme={theme}
            secondary
            text={messages.button.cancel}
            onClick={hide}
          ></Button>
          <Button
            theme={theme}
            primary
            text={messages.button.save}
            onClick={hide}
          ></Button>
        </>
      }
    >
      {addOns?.map((addon) => (
        <AddOnItem
          key={addon.id}
          theme={theme}
          className={`addon-item ${
            (selectedAddOns.find((addOn) => addOn.id === addon.id) != null) ? 'selected' : ''}`}
        >
          <Box theme={theme} className="label-area">
            <Checkbox
              theme={theme}
              label={addon.name}
              checkBoxChecked={
                (selectedAddOns.find((addOn) => addOn.id === addon.id) != null) && true
              }
              onChange={(e) => { onSelect(addon.id, e.target.checked) }}
            ></Checkbox>
          </Box>
          <Typography
            theme={theme}
            className="text-primary addon-price"
            variant="h4"
            component="h4"
          >
            ${convertToDecimal(addon.value / 100)}
          </Typography>
        </AddOnItem>
      ))}
    </Dialog>
  )
}
