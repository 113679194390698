import { Header } from '../../../../layout'
import { useTheme } from '@mui/material'
import { MainContent, PageHeader } from './userListStyle'
import { HttpStatus, NotificationType } from 'shared'
import { Box, Typography, Button, Icon } from '@ntpkunity/controls'
import { type ReactElement, useEffect, useState } from 'react'
import { useStoreContext } from 'store/storeContext'
import { UserListingComponent } from '@ntpkunity/controls-ums'
import { USER_ID, TENANT_ID } from '@shared/constants/userProfile'
import { APP_ROUTES } from '@router/path'

export const UserList = (): ReactElement => {
  const theme = useTheme()
  const [response, setResponse] = useState<any>(undefined)

  const [createUserResponse] = useState<any>(undefined)
  const [openPopUp, setOpenPopUp] = useState(false)

  const {
    states: { userProfile },
    actions: { setToaster }
  } = useStoreContext()

  useEffect(() => {
    if (response?.status === HttpStatus.OK) {
      setToaster({
        isDisplay: true,
        message: response?.data?.message,
        type: ''
      })
    } else if (response?.status !== undefined) {
      setToaster({
        isDisplay: true,
        message: response?.message,
        type: NotificationType.ERROR
      })
    }
  }, [response])

  useEffect(() => {
    if (createUserResponse?.status === HttpStatus.OK) {
      setToaster({
        isDisplay: true,
        message: createUserResponse?.data.message,
        type: ''
      })
    } else if (response?.status !== undefined) {
      setToaster({
        isDisplay: true,
        message: createUserResponse?.message,
        type: NotificationType.ERROR
      })
    }
  }, [createUserResponse])

  return (
    <>
      <Header theme={theme} />
      <MainContent theme={theme} className="main-content" mt={{ xs: 0, md: 5 }}>
        <PageHeader
          theme={theme}
          className="page-header"
          display={'flex'}
          alignItems={'center'}
          flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
        >
          <Box theme={theme} className="title" flexGrow={'1'}>
            <Typography
              data-testid="manageUsers"
              theme={theme}
              variant="h3"
              component="h3"
              className="text-h3"
            >
              Manage Users
            </Typography>
          </Box>
          <Box
            theme={theme}
            className="action-area"
            flexShrink={0}
            display={'flex'}
            alignItems={'center'}
            mt={{ xs: 2, sm: 0 }}
            width={{ xs: '100%', sm: 'auto' }}
          >
            <Button
              fullWidth
              theme={theme}
              secondary
              text="Add New User"
              startIcon={
                <>
                  <Icon name="AddIcon" />
                </>
              }
              onClick={() => {
                setOpenPopUp(true)
              }}
            />
          </Box>
        </PageHeader>
        <Box theme={theme} className="user-mgmt-table">
          {userProfile?.[TENANT_ID] !== undefined
            ? (
            <UserListingComponent
              theme={theme}
              setResponse={setResponse}
              tenantId={userProfile?.[TENANT_ID]}
              userId={userProfile?.[USER_ID]}
              value={''}
              componentBehavior={'default'}
              layoutText={{
                nameLabel: 'First/Last Name',
                emailAddressLabel: 'Email Address',
                productsLabel: 'Product(s)',
                rolesLabel: 'Role(s)',
                contactNumLabel: 'Contact Number',
                statusLabel: 'Status'
              }}
              nextScreenUrl={`${APP_ROUTES.APP_USER_PROFILE}`}
              openAddComponent={false}
              openPopup={openPopUp}
              setPopUpOpen={setOpenPopUp}
              actionType={'add'}
              close={() => { setOpenPopUp(false) }}
            />
              )
            : null}
        </Box>
      </MainContent>
      {/* <Footer /> */}
    </>
  )
}
