import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'
import BannerImage from '../../../../shared/assets/images/appex-now-home-banner.svg'
import BannerImageMobile from '../../../../shared/assets/images/appex-now-home-banner-mobile.svg'

export const BannerWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.banner-wrap': {
    backgroundImage: `url(${BannerImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    width: '100%',
    height: 680,
    borderRadius: 40,
    '.custom-container': {
      height: '100%'
    },
    '.banner-content': {
      '.badge': {
        backgroundColor: 'rgba(255, 255, 255, 0.20)',
        padding: '8px 16px',
        borderRadius: 24,
        [theme.breakpoints.down('md')]: {
          borderRadius: 16
        }
      },
      [theme.breakpoints.up('sm')]: {
        '.text-h2': {
          fontSize: theme.typography.h1.fontSize,
          lineHeight: theme.typography.h1.lineHeight
        },
        '.text-body2': {
          fontSize: theme.typography.body1.fontSize,
          lineHeight: theme.typography.body1.lineHeight
        }
      },
      '@media(min-width: 650px) and (max-width: 960px)': {
        paddingTop: 70,
        maxWidth: 440,
        width: '100%'
      }
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: 24,
      backgroundImage: `url(${BannerImageMobile})`,
      height: 600,
      padding: '24px 0'
    }
  }
}))
