import { type FC } from 'react'
import { useTheme } from '@mui/material'
import { Box, Grid, Typography } from '@ntpkunity/controls'
import { FadeAnimation } from '@shared/components'
import WhyChooseImage01 from '../../../../shared/assets/images/why-choose-flex-01.png'
import WhyChooseImage02 from '../../../../shared/assets/images/why-choose-flex-02.png'
import WhyChooseImage03 from '../../../../shared/assets/images/why-choose-flex-03.png'
import WhyChooseImage04 from '../../../../shared/assets/images/why-choose-flex-04.png'
import { ProductFeaturesWrap } from './whyChooseProductStyle'

interface ICol6TemplateProps {
  features: Array<{
    className?: string
    name?: string
    description?: string
    image?: string
  }>
}

export const Col6Template: FC<ICol6TemplateProps> = ({ features }) => {
  const theme = useTheme()

  return (
    <>
    {features.map((feature, index) => (
        <Grid theme={theme} item xs={12} md={6} key={index}>
            <Box theme={theme} className={`feat-item-box ${feature.className}`}>
                <Box theme={theme} className='content-area'>
                    <FadeAnimation>
                        <Typography
                            theme={theme}
                            variant="h3"
                            component="h3"
                            className="text-h3"
                        >
                            {feature.name}
                        </Typography>
                        <Typography
                            theme={theme}
                            className="text-muted text-body2"
                            variant="body1"
                            component="p"
                            mt={1}
                        >
                            {feature.description}
                        </Typography>
                    </FadeAnimation>
                </Box>
                <Box theme={theme} className='img-area'>
                    <FadeAnimation>
                        <img src={feature.image} alt='' />
                    </FadeAnimation>
                </Box>
            </Box>
        </Grid>
    ))}
        </>
  )
}

export const WhyChooseProductComponent: FC = () => {
  const theme = useTheme()
  return (
    <ProductFeaturesWrap theme={theme} className='product-features-wrap'>
      <Box
        theme={theme}
        className="section-head"
        textAlign={'center'}
      >
        <FadeAnimation direction='up'>
        <Typography
          display={'inline-block'}
          mb={{ xs: 2, md: 3 }}
          theme={theme}
          className="badge text-primary text-uppercase"
          variant="caption"
          component="small"
        >
          Capabilities
        </Typography>
        <Typography
          theme={theme}
          variant="h2"
          component="h2"
          className="text-h2"
        >
          Why Choose Flex?
        </Typography>
        <Typography
          theme={theme}
          className="text-muted text-body2"
          variant="body1"
          component="p"
          mt={{ xs: 2, md: 1 }}
        >
          What sets Flex apart!
        </Typography>
        </FadeAnimation>
      </Box>

      <Box theme={theme} className='feature-items-wrap' mt={{ xs: 5, md: 8 }}>
        <Grid theme={theme} item container spacing={3}>
          <Col6Template
            features={[
              {
                className: 'text-first',
                name: 'Scalability Without Added Costs',
                description: 'Say goodbye to the limitations of manual processes, calculators and prehistoric software. Flex allows you to scale your operations efficiently, flexibly and with ease, all out of the box without need for large investment.',
                image: WhyChooseImage01
              },
              {
                className: 'text-last',
                name: 'Modernize Your Operations',
                description: 'Transition from outdated spreadsheets to a modern, efficient solution. Flex streamlines your workflow, saving time, improve transparency and compliance while improving accuracy.',
                image: WhyChooseImage02
              },
              {
                className: 'text-first',
                name: 'Elevate Regulatory Compliance',
                description: 'Via a highly configurable solutions, provide elevated transparency and traceability in how you and your partners are pricing and presenting offers to borrowers.',
                image: WhyChooseImage03
              },
              {
                className: 'text-last',
                name: 'Minimal Disruption',
                description: 'Integrating Flex into your existing operations is seamless and non-disruptive. Our team ensures a smooth transition, allowing you to focus on your business priorities. ',
                image: WhyChooseImage04
              }
            ]}
          />
        </Grid>
      </Box>
    </ProductFeaturesWrap>
  )
}
