import { useTheme } from '@mui/material'
import { type FC, useEffect, useState } from 'react'
import { Authentication } from '@modules/authentication'
import { ResetPasswordLinkComponent, type IResponse } from '@ntpkunity/controls-ums'

import { APP_ROUTES } from '@router/path'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStoreContext } from 'store/storeContext'
import { Box, Button, Icon } from '@ntpkunity/controls'
import { resetPasswordEmailSent } from '@shared/constants'

export const ResetPasswordLink: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation() as any
  const [response, setResponse] = useState<IResponse>()
  const email = location?.state?.email

  const {
    actions: { setToaster }
  } = useStoreContext()

  useEffect(() => {
    setToaster({
      isDisplay: true,
      message: resetPasswordEmailSent,
      type: ''
    })
  }, [response])

  return (
    <Authentication>
      <Box theme={theme} mb={4} className="back-btn">
        <Button
          defaultBtn
          theme={theme}
          startIcon={<Icon name="IconLeftArrow" />}
          text="Go Back"
          onClick={() => { navigate(APP_ROUTES.FORGOT_PASSWORD) }}
        />
      </Box>
      <ResetPasswordLinkComponent
        setResponse={setResponse}
        email={email}
        theme={theme}
        nextScreenUrl={`${APP_ROUTES.RESET_PASSWORD}`}
      />
    </Authentication>
  )
}
