import { type IProductPriceDetails, type IPrice, type IProduct, type IProductDetailsPayload, type IProductPricing } from '@shared/typings'
import Http from '@shared/helper/http-api'
import { useQuery } from 'react-query'
import { getItem } from '@shared/helper'
import { IS_LOGGED_IN } from '@shared/constants'
import { type AppError } from 'single-spa'

const BASE_URL = process.env.BASE_URL

export const PRODUCT_PRICING = 'signup/prices'
export const PRODUCT_PRICING_BY_ID = 'signup/product-prices'
export const PRODUCT = 'signup/products'
export const CUSTOM_PACKAGE = 'marketplace/products/get-custom-package'
export const PRODUCT_DETAIL = 'signup/product-details'

export const useGetProductPricing = (): {
  data: IPrice[]
  error: AppError | unknown
  isLoading: boolean
} => {
  const { data, error, isLoading } = useQuery(
    PRODUCT_PRICING,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(PRODUCT_PRICING)
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  )
  return { data, error, isLoading }
}

export const useGetProductPricingById = (pricingProductId: number | string): {
  data: IProductPriceDetails | undefined
  error: AppError | unknown
} => {
  const url = `${PRODUCT_PRICING}/${pricingProductId}`
  const isLoggedIn = getItem(IS_LOGGED_IN)
  const { data, error } = useQuery<IProductPriceDetails>(
    [PRODUCT_PRICING, pricingProductId],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(url)
    },
    { refetchOnWindowFocus: false, enabled: Boolean(isLoggedIn) }
  )
  return { data, error }
}

export const useGetProductPricingByProductId = (productId: number | undefined): {
  data: IProductPriceDetails[] | undefined
  error: AppError | unknown
  isLoading: boolean
} => {
  const url = `${PRODUCT_PRICING_BY_ID}/${productId}`
  const { data, error, isLoading } = useQuery<IProductPriceDetails[]>(
    [PRODUCT_PRICING_BY_ID, productId],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(url)
    },
    { refetchOnWindowFocus: false, enabled: Boolean(productId) }
  )
  return { data, error, isLoading }
}

export const useGetProductById = (productId: number): {
  data: IProduct | undefined
  error: AppError | unknown
} => {
  const url = `${PRODUCT}/${productId}`
  const { data, error } = useQuery<IProduct>(
    [PRODUCT, productId],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(url)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error }
}

export const useGetProductByName = (productName: string | undefined): {
  data: IProduct | undefined
  error: AppError | unknown
} => {
  const url = `${PRODUCT}/${productName}`
  const { data, error } = useQuery<IProduct>(
    [PRODUCT, productName],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(url)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error }
}

export const useGetCustomPackage = (productId: number | undefined): any => {
  const isLoggedIn = getItem(IS_LOGGED_IN)
  const { data, error, isLoading } = useQuery<IProductPricing[]>(
    [CUSTOM_PACKAGE, productId],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(CUSTOM_PACKAGE)
    },
    { refetchOnWindowFocus: false, enabled: Boolean(isLoggedIn) && Boolean(productId) }
  )
  return { data, error, isLoading }
}

export const useGetCompleteProductById = (productId: number): {
  data: IProductDetailsPayload | undefined
  error: AppError | unknown
} => {
  const url = `${PRODUCT_DETAIL}/${productId}`
  const { data, error } = useQuery<IProductDetailsPayload>(
    [PRODUCT_DETAIL, productId],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(url)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error }
}
