import { type FC, useEffect, useState, type ReactElement } from 'react'
import { useTheme } from '@mui/material'
import {
  Typography,
  Dialog,
  Box,
  Button,
  Radio,
  Textarea
} from '@ntpkunity/controls'
import { DialogActionArea, UnsubPopupWrap } from './feedbackPopupStyle'
import { Controller, useForm } from 'react-hook-form'
import { formatedDate, scrollToBottom, validation } from '@shared/helper'
import { Validation } from '@shared/constants'
import { type IProductResponse } from '@shared/typings'
import DisableLoader from '@shared/assets/images/loader-disabled-btn.gif'

interface UnsubscribeFeedbackPopupProps {
  hide: () => void
  unsubscribe: (feedback: string) => void
  isLoading: boolean
  selectedPackage: IProductResponse | undefined
}

export const UnsubscribeFeedbackPopup: FC<UnsubscribeFeedbackPopupProps> = ({
  hide,
  unsubscribe,
  isLoading,
  selectedPackage
}): ReactElement => {
  const theme = useTheme()
  const [isOther, setIsOther] = useState<boolean>(false)
  const [feedback, setFeedback] = useState<string>('')

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm()

  const feedbackOptions = [
    { label: 'The subscription is too expensive', value: 1 },
    { label: "I don't think the product is useful", value: 2 },
    { label: 'The product is hard to use', value: 3 },
    { label: 'I am facing technical difficulties', value: 4 },
    { label: "I don't find the product relevant to my requirements", value: 5 },
    { label: "I don't want to be auto-billed", value: 6 },
    { label: 'Other', value: 7 }
  ]

  const submit = (): void => { unsubscribe(feedback) }

  useEffect(() => {
    if (isOther) {
      const element = document.getElementById('feedback-popup')
      scrollToBottom(element)
    }
  }, [isOther])

  return (
    <>
      <UnsubPopupWrap theme={theme} className="unsub-popup-wrap">
        <Dialog
          theme={theme}
          variant="confirmation"
          size="sm"
          open={true}
          disablePortal={true}
          customFooter={
            <>
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                display={'block'}
                className="text-muted"
                mb={4}
              >
                The un-subscription will be effective at the end of your current
                billing period on{' '}
                <span className="text-dark fw-medium">{formatedDate((selectedPackage?.end_date ?? ''), 'MMM DD, YYYY')}</span>.
              </Typography>
              <DialogActionArea theme={theme} className="dialog-action-area">
              {// eslint-disable-next-line @typescript-eslint/no-misused-promises
              }<form className="buttons" onSubmit={handleSubmit(submit)}>
                  <Button
                    data-testid="feedback-popup-cancel-btn"
                    theme={theme}
                    secondary
                    text="Cancel"
                    onClick={hide}
                  />
                  <Button
                    data-testid="feedback-popup-unsubscribe-btn"
                    theme={theme}
                    primary
                    type="submit"
                    text={isLoading ? 'Wait...' : 'Unsubscribe'}
                    disabled={
                      feedback === 'Other' ? watch('feedback') === '' : feedback === ''
                    }
                    startIcon={
                      isLoading && (
                        <img src={DisableLoader} alt="Loader" />
                      )
                    }
                  />
                </form>
              </DialogActionArea>
            </>
          }
        >
          {// eslint-disable-next-line @typescript-eslint/no-misused-promises
          }<form onSubmit={handleSubmit(submit)} >
            <Box theme={theme}>
              <Typography
                theme={theme}
                className="content-title"
                variant="h2"
                component="h2"
                mb={{ xs: 2, md: 3 }}
              >
                We are sorry to see you go!
              </Typography>
              <Typography
                theme={theme}
                className="text-muted"
                variant="body2"
                component="span"
              >
                Thanks for using {selectedPackage?.status} package of{' '}
                {selectedPackage?.product_name}. Please let us know why you are
                cancelling your subscription, maybe we can help.
              </Typography>
            </Box>
            <Box theme={theme} className="dialog-body" id="feedback-popup">
              <Box theme={theme} className="feedback-form-wrap" mt={3}>
                <Radio
                  theme={theme}
                  defaultValue={feedback}
                  options={feedbackOptions}
                  onChange={(e) => {
                    setFeedback(
                      feedbackOptions?.find((feedback) => feedback.value === +e)
                        ?.label ?? ''
                    )
                    setIsOther(+e === 7)
                  }}
                />
                <Box theme={theme} className="other-field">
                  {isOther && (
                    <Controller
                      name={'feedback'}
                      control={control}
                      rules={validation('feedback', Validation.REQUIRED, Validation.LIMIT_LENGTH, '300')}
                      render={({ field }) => (
                        <Textarea
                          theme={theme}
                          fullWidth={true}
                          type={'text'}
                          id={''}
                          label={'Please specify'}
                          rows={2}
                          placeholder={'Type here...'}
                          {...field}
                          value={field.value}
                          error={errors?.feedback?.message?.toString()}
                          onChange={(e) => {
                            field.onChange(e)
                            setFeedback(watch('feedback'))
                          }}
                        />
                      )}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </form>
        </Dialog>
      </UnsubPopupWrap>
    </>
  )
}
