import { Box, type IBoxProps } from '@ntpkunity/controls'
import { styled } from '@mui/material/styles'

export const PaymentBoxWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.card-area-wrap': {
    fontFamily: theme.typography.fontFamily,
    '.btn': {
      padding: '12px 24px',
      minWidth: 'auto',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.customVariables.fontWeightSemiBold,
      lineHeight: theme.typography.subtitle1.lineHeight,
      border: '1px solid transparent',
      borderRadius: theme.shape.borderRadius,
      textTransform: 'capitalize',
      outline: '0 none',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
      cursor: 'pointer',
      marginTop: 24,
      '&.btn-primary': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
          color: theme.palette.primary.main,
          backgroundColor: 'transparent'
        },
        '&:focus-visible': {
          color: theme.palette.primary.main,
          backgroundColor: 'transparent'
        },
        '&:focus': {
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white
        },
        '&:disabled': {
          borderColor: theme.palette.grey[100],
          backgroundColor: theme.palette.grey[100],
          color: theme.palette.grey[300]
        },
        '&.disabled': {
          borderColor: theme.palette.grey[100],
          backgroundColor: theme.palette.grey[100],
          color: theme.palette.grey[300]
        }
      }
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.link': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }
}))

export const CardFieldsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.card-fields-wrap': {
    '.relative-grid': {
      position: 'relative'
    },
    '.card-element': {
      border: '1px solid rgba(0,0,0,0.2)',
      padding: '12px 16px',
      borderRadius: theme.shape.borderRadius,
      width: '100%',
      minHeight: 48,
      '&:hover': {
        borderColor: theme.palette.primary.main
      },
      '&.StripeElement--focus': {
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        '+': {
          '.stripe-input-label': {
            color: 'rgba(0, 0, 0, 0.6)',
            transform: 'translate(35px, 15px) scale(0.85)',
            maxWidth: '100%'
          }
        }
      },
      '&.StripeElement--complete': {
        '+': {
          '.stripe-input-label': {
            color: 'rgba(0, 0, 0, 0.6)',
            transform: 'translate(35px, 15px) scale(0.85)',
            maxWidth: '100%'
          }
        }
      },
      '&.StripeElement--invalid': {
        borderColor: theme.palette.error.main,
        '+': {
          '.stripe-input-label': {
            color: theme.palette.error.main,
            transform: 'translate(35px, 15px) scale(0.85)',
            maxWidth: '100%'
          }
        },
        '&.StripeElement--empty': {
          '+': {
            '.stripe-input-label': {
              transform: 'translate(35px, 37px) scale(1)'
            }
          },
          '&.StripeElement--focus': {
            '+': {
              '.stripe-input-label': {
                transform: 'translate(35px, 15px) scale(0.85)'
              }
            }
          }
        }
      }
    },
    '.stripe-input-label': {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0,0,0,0.87)',
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: '1rem',
      lineHeight: '1.4375em',
      letterSpacing: '0.00938em',
      padding: '0 5px 0 5px',
      display: 'block',
      transformOrigin: 'top left',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 'calc(100% - 24px)',
      position: 'absolute',
      left: 0,
      top: 0,
      transform: 'translate(35px, 37px) scale(1)',
      transition:
        'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      zIndex: 1,
      pointerEvents: 'none',
      userSelect: 'none'
    }
  }
}))

export const PaymentCards = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.payment-cards-wrap': {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      top: -74,
      marginLeft: 30,
      '&.no-card': {
        top: 74
      }
    },
    '&.no-card': {
      textAlign: 'center'
    },

    '.card-item': {
      border: '1px solid' + theme.palette.grey[50],
      borderRadius: theme.shape.borderRadius,
      padding: 24,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 8,
      '.custom-checkbox-wrap': {
        '.u-custom-control-label': {
          margin: 0,
          '.MuiTypography-root': {
            fontSize: theme.typography.body1.fontSize,
            marginLeft: 8
          }
        }
      },
      '.card-icon': {
        flexShrink: 0
      },
      '&.selected': {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
        borderColor: theme.palette.grey[100]
      }
    }
  }
}))

export const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.main-content': {
    paddingTop: 112,
    paddingBottom: 58,
    [theme.breakpoints.up('md')]: {
      paddingTop: 160
    },
    '.custom-container': {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 32,
        paddingRight: 32
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: 24,
        paddingRight: 24
      }
    },
    '.payment-btns': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      '.u-custom-control-label': {
        marginBottom: 0,
        marginRight: 24,
        '.custom-checkbox': {
          display: 'none',
          '&.Mui-checked': {
            '~': {
              '.MuiFormControlLabel-label': {
                borderColor: theme.palette.primary.main,
                '&:before': {
                  opacity: 1
                }
              }
            }
          }
        },
        '.MuiFormControlLabel-label': {
          border: '1px solid' + theme.palette.grey[50],
          width: 88,
          height: 49,
          textAlign: 'center',
          paddingTop: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: theme.shape.borderRadius,
          position: 'relative',
          '&:before': {
            display: 'block',
            content: "''",
            width: 12,
            height: 12,
            backgroundColor: theme.palette.primary.main,
            borderRadius: '50%',
            position: 'absolute',
            top: -5,
            right: -5,
            opacity: 0
          }
        }
      }
    },
    '.payment-fields': {
      '.u-date-picker': {
        '.u-form-group': {
          '.MuiInputBase-formControl': {
            borderRadius: theme.shape.borderRadius
          }
        }
      }
    }
  }
}))
