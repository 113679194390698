import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.main-content': {
    paddingTop: 112,
    paddingBottom: 80,
    [theme.breakpoints.up('md')]: {
      paddingTop: 160
    },
    '.custom-container': {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 32,
        paddingRight: 32
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: 24,
        paddingRight: 24
      }
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-danger': {
      color: theme.palette.error.main
    },
    '.list': {
      paddingLeft: 15
    },
    '.custom-accordion': {
      '.u-accordion-header': {
        '&.filter-accordion': {
          justifyContent: 'start',
          '.MuiAccordionSummary-content': {
            flexGrow: 0
          }
        },
        '.MuiAccordionSummary-expandIconWrapper': {
          svg: {
            path: {
              stroke: theme.palette.grey[900]
            }
          }
        }
      },
      '.u-accordian-body': {
        maxHeight: '100%',
        overflowY: 'visible',
        '&.filter-accordion': {
          borderBottom: 'none'
        }
      }
    }
  }
}))
