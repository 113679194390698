import { type IToaster } from '../configs'
import type IActions from '../configs'
import { type Dispatch, type ReducerAction } from 'react'

export const DISPLAY_TOASTER = 'DISPLAY_TOASTER'

export const ToasterinitialState: IToaster = {
  isDisplay: false,
  type: '',
  message: '',
  showIcon: false,
  duration: 5000
}

const reducer = (state: IToaster, action: IActions): IToaster => {
  switch (action.type) {
    case DISPLAY_TOASTER:
      return {
        ...state,
        isDisplay: (action.payload as IToaster).isDisplay,
        type: (action.payload as IToaster).type,
        message: (action.payload as IToaster).message,
        showIcon: (action.payload as IToaster)?.showIcon ?? false,
        duration: (action.payload as IToaster)?.duration ?? 5000
      }
  }
  return state
}

export const setToaster = (
  data: IToaster,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  dispatch({ type: DISPLAY_TOASTER, payload: data })
}

export default reducer
