import { useTheme } from '@mui/material'
import { DataTable, Typography, Container } from '@ntpkunity/controls'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { MainContent, TableWrap } from './marketingLinksStyle'
import { flexData, hubexData } from '@shared/constants'
import { Header } from '@layout/components'
import { type ReactElement } from 'react'

export const MarketingLinks = (): ReactElement => {
  const theme = useTheme()

  return (
    <>
      <Header theme={theme} />
      <MainContent theme={theme} className="main-content" mt={{ xs: 0, md: 5 }}>
        <Container theme={theme} maxWidth="lg" className="custom-container">
          <TableWrap theme={theme} mt={4}>
            <Typography
              theme={theme}
              variant="h3"
              component="h3"
              className="text-h3"
            >
              Flex
            </Typography>
            <DataTable
              theme={theme}
              variant="basic"
              theadChildren={
                <>
                  <TableRow>
                    <TableCell>Packages</TableCell>
                    <TableCell>Links</TableCell>
                  </TableRow>
                </>
              }
              tbodyChildren={
                <>
                  {flexData?.map((data, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{data.title}</TableCell>
                        <TableCell>
                          <a className="text-primary" href={data.link}>
                            {data.link}
                          </a>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </>
              }
            />
          </TableWrap>

          <TableWrap theme={theme} mt={4}>
            <Typography
              theme={theme}
              variant="h3"
              component="h3"
              className="text-h3"
            >
              Hubex
            </Typography>
            <DataTable
              theme={theme}
              variant="basic"
              theadChildren={
                <>
                  <TableRow>
                    <TableCell>Packages</TableCell>
                    <TableCell>Links</TableCell>
                  </TableRow>
                </>
              }
              tbodyChildren={
                <>
                  {hubexData?.map((data, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{data.title}</TableCell>
                        <TableCell>
                          <a className="text-primary" href={data.link}>
                            {data.link}
                          </a>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </>
              }
            />
          </TableWrap>
        </Container>
      </MainContent>
    </>
  )
}
