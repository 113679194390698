import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'
import IcAdd from '../../../../shared/assets/images/ic-add.svg'
import IcMinus from '../../../../shared/assets/images/ic-minus.svg'

export const FaqsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.faqs-wrap': {
    '.faqs-accordion': {
      '.u-accordian-wrap': {
        '&.u-accordion-group': {
          marginBottom: 16,
          borderRadius: 16,
          '&:not(:last-child)': {
            borderBottom: '1px solid' + theme.palette.grey[100],
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16
          },
          '&:not(:first-of-type)': {
            borderRadius: 16
          },
          '&:last-child': {
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16,
            marginBottom: 0
          },
          [theme.breakpoints.down('md')]: {
            marginBottom: 8
          }
        },
        '.u-accordion-header': {
          minHeight: 'auto',
          '&.u-accordion-group-header': {
            alignItems: 'flex-start',
            padding: 24,
            '.MuiAccordionSummary-content': {
              order: 2
            },
            '.main-title': {
              flex: '0 0 auto',
              maxWidth: '100%',
              whiteSpace: 'normal'
            },
            [theme.breakpoints.down('md')]: {
              padding: 16
            }
          },
          '.MuiAccordionSummary-expandIconWrapper': {
            order: 1,
            marginRight: 24,
            marginLeft: 0,
            backgroundImage: `url(${IcAdd})`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            width: 24,
            height: 24,
            flexShrink: 0,
            svg: {
              display: 'none'
            },
            '&.Mui-expanded': {
              backgroundImage: `url(${IcMinus})`,
              transform: 'rotate(0)'
            },
            [theme.breakpoints.down('md')]: {
              marginRight: 16
            }
          }
        }
      },
      '.u-accordian-body': {
        '&.u-accordion-group-body': {
          padding: '0px 24px 24px 24px',
          borderTop: 'none',
          [theme.breakpoints.down('md')]: {
            padding: '0px 16px 16px 16px'
          }
        },
        '.content-area': {
          position: 'relative',
          marginLeft: 48,
          paddingLeft: 24,
          '&:before': {
            display: 'block',
            content: "''",
            backgroundColor: theme.palette.primary.main,
            width: 4,
            height: '100%',
            position: 'absolute',
            left: 0,
            borderRadius: 2,
            top: 0
          },
          [theme.breakpoints.down('md')]: {
            marginLeft: 40,
            paddingLeft: 16
          }
        }
      }
    }
  }
}))
