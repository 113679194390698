import { Typography, Select, Icon, Button, Tooltip } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { useState, type ReactElement, useEffect, type FC } from 'react'
import { InfoCaption } from './paymentMethodStyle'
import { useUpdatePaymentMethod } from '@modules/billingDetails/services/bankTransfer/paymentMethodService'
import { ButtonType, DialogHeading, PaymentMethodStatus, Validation, bankTransferConfirmation, sentRequestApproval, validation, PaymentFrequency } from 'shared'
import { Controller, useForm } from 'react-hook-form'
import { useStoreContext } from 'store/storeContext'
import { useModal } from 'react-modal-hook'
import { DecisionPrompt } from '@shared/components/DecisionPrompt/decisionPrompt'
import { Link, useLocation } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'

const messages = {
  button: {
    updatePaymentMethod: 'Update Payment Method'
  },
  name: {
    paymentFrequency: 'payment_frequency'
  },
  label: {
    paymentFrequency: 'Payment Frequency'
  }
}

interface IBankTransfer {
  id: number
  payment_frequency: 'Monthly' | 'Quarterly' | 'Half Yearly' | 'Yearly'
  status: 'Declined' | 'Pending' | 'Approved'
}

interface IBankTransferProps {
  bankTransfer: IBankTransfer
  setPaymentFrequency?: React.Dispatch<React.SetStateAction<string | null>>
}

export const BankTransfer: FC<IBankTransferProps> = ({ bankTransfer, setPaymentFrequency }): ReactElement => {
  const theme = useTheme()
  const { pathname } = useLocation()
  const onCheckoutPage = pathname === APP_ROUTES.CHECKOUT
  const { status, payment_frequency: paymentFrequency } = bankTransfer ?? {}
  const { mutate: updatePaymentMethod, isLoading } = useUpdatePaymentMethod()
  const [isDisplay, setIsDisplay] = useState(true)
  const [isDisable, setIsDisable] = useState(false)
  const {
    getValues,
    control,
    formState: { errors }
  } = useForm()

  const {
    actions: { setToaster }
  } = useStoreContext()

  const paymentFrequencyOptions = [
    { text: PaymentFrequency.MONTHLY, value: PaymentFrequency.MONTHLY },
    { text: PaymentFrequency.QUARTERLY, value: PaymentFrequency.QUARTERLY },
    { text: 'Half-Yearly', value: PaymentFrequency.HALF_YEARLY },
    { text: PaymentFrequency.YEARLY, value: PaymentFrequency.YEARLY }
  ]

  const handleUpdatePaymentMethod = (): void => {
    hide()
    updatePaymentMethod({ payment_frequency: getValues('payment_frequency') } as any, {
      onSuccess () {
        setToaster({
          isDisplay: true,
          message: sentRequestApproval,
          type: ''
        })
      }
    })
  }

  const [show, hide] = useModal(
    () => (
          <DecisionPrompt
            hide={hide}
            onSave={handleUpdatePaymentMethod}
            title={DialogHeading.UPDATE}
            message={bankTransferConfirmation}
            saveBtnName={ButtonType.SAVE}
          />
    ),
    []
  )

  useEffect(() => {
    if (status === PaymentMethodStatus.APPROVED) {
      setIsDisplay(false)
    }
    if (status === PaymentMethodStatus.PENDING || isLoading) {
      setIsDisable(true)
    }
  }, [status])

  return (
            <form>
                <Controller
                    name={messages.name.paymentFrequency}
                    control={control}
                    defaultValue={paymentFrequency ?? PaymentFrequency.MONTHLY}
                    rules={validation(messages.label.paymentFrequency, Validation.REQUIRED)}
                    render={({ field: { value, onChange } }) => (
                    <Select
                        theme={theme}
                        label={messages.label.paymentFrequency}
                        items={paymentFrequencyOptions}
                        sxProps={false}
                        placeholder="Payment Frequency"
                        disablePortal={false}
                        onChange={(e) => {
                          onChange(e.target.value)
                          setIsDisplay(true)
                          setPaymentFrequency?.(e.target.value as string)
                        }}
                        value={value}
                        selectError={errors?.payment_frequency?.message?.toString()}
                      ></Select>
                    )}
                  />
                <InfoCaption theme={theme} sx={{ mb: 3 }}>
                    <Icon name='InfoIcon' />
                    {
                      onCheckoutPage
                        ? (
                          <Typography theme={theme} variant="body2" component="span">
                            <strong>First 90 days will be free of charge!</strong> After that, an invoice will be emailed. Once you have made the bank transfer, you have to upload the proof of payment in <Link to={APP_ROUTES.BILLING_DETAILS} className="text-primary link">billing details</Link> of your profile.
                          </Typography>
                          )
                        : (
                          <Typography theme={theme} variant="body2" component="span">
                            For bank transfer, we will email you the invoice. You have to pay the due amount within 15 days. Meanwhile, you can use the product(s). Our representative will contact you regarding the change of payment method.
                          </Typography>
                          )
                    }
                </InfoCaption>
                {
                  !onCheckoutPage
                    ? (
                      <>
                        {isDisable && <Tooltip
                          theme={theme}
                          title={sentRequestApproval}
                        >
                          <Button theme={theme} primary fullWidth text={messages.button.updatePaymentMethod} disabled={true} />
                        </Tooltip>
                        }
                        {
                          !isDisable && isDisplay && <Button theme={theme} primary fullWidth text={messages.button.updatePaymentMethod} onClick={show} disabled={isLoading} />
                        }
                      </>
                      )
                    : null
                }
            </form>
  )
}
