import { type SnackbarCloseReason, useTheme } from '@mui/material'
import { Snackbar } from '@ntpkunity/controls'
import { type INotificationType } from '@shared/typings'
import { type FC, type ReactNode, useEffect, useState } from 'react'
import { useStoreContext } from 'store/storeContext'

export interface BaseLayoutProps {
  children: ReactNode
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  const theme = useTheme()
  const {
    states: { toaster },
    actions: { setToaster }
  } = useStoreContext()
  const [open, setOpen] = useState(toaster?.isDisplay ?? false)

  useEffect(() => {
    if (Object.keys((toaster ?? {})).length > 0 && (toaster?.isDisplay ?? false)) {
      setOpen(toaster?.isDisplay ?? false)
    }
  }, [toaster])

  return (
    <>
      <Snackbar
        theme={theme}
        action={(toaster?.showIcon ?? false) ? <></> : null}
        autoHideDuration={(toaster?.showIcon ?? false) ? undefined : toaster?.duration}
        message={toaster?.message ?? ''}
        open={open}
        onClose={(
          event?: React.SyntheticEvent<any> | Event,
          reason?: SnackbarCloseReason
        ) => {
          if (reason !== 'clickaway') {
            setOpen(false)
            setToaster({
              isDisplay: false,
              type: toaster?.type ?? '',
              message: toaster?.message ?? '',
              showIcon: toaster?.showIcon ?? false,
              duration: toaster?.duration ?? 5000
            })
          }
        }}
        variant={toaster?.type as INotificationType}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      />
      {children}
    </>
  )
}

export default BaseLayout
