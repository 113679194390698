import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.main-content': {
    paddingTop: 112,
    paddingBottom: 82,
    [theme.breakpoints.up('md')]: {
      paddingTop: 160
    },
    '.custom-container': {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 32,
        paddingRight: 32
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: 24,
        paddingRight: 24
      }
    },
    '.text-danger': {
      color: theme.palette.error.main
    },
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.text-white': {
      color: theme.palette.common.white
    },
    '.text-ellipses': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      '&.line-clamp': {
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '&.line-clamp-2': {
          '-webkit-line-clamp': '2'
        },
        '&.line-clamp-3': {
          '-webkit-line-clamp': '3'
        }
      }
    },
    '.link': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '.text-capitalize': {
      textTransform: 'capitalize'
    },
    '.text-uppercase': {
      textTransform: 'uppercase'
    },
    '.fw-sbold': {
      fontWeight: theme.customVariables.fontWeightSemiBold
    },
    '.text-h1': {
      fontSize: '48px',
      lineHeight: '56px',
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h2.fontSize,
        lineHeight: theme.typography.h2.lineHeight
      }
    },
    '.text-h2': {
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight
      }
    },
    '.text-h3': {
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h4.fontSize,
        lineHeight: theme.typography.h4.lineHeight
      }
    },
    '.text-h4': {
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.body1.fontSize,
        lineHeight: theme.typography.body1.lineHeight
      }
    },
    '.text-body2': {
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.caption.fontSize,
        lineHeight: theme.typography.caption.lineHeight
      }
    },
    '.text-small': {
      [theme.breakpoints.down('md')]: {
        fontSize: '10px',
        lineHeight: '16px'
      }
    },
    '.text-body1': {
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight
      }
    }
  }
}))
