import { HomePageLayout } from '@layout/index'
import { useGetProducts } from '@modules/homePage/services'
import { useTheme } from '@mui/material'
import {
  Autocomplete,
  Box,
  Container,
  Grid,
  Icon,
  Skeleton,
  Typography
} from '@ntpkunity/controls'
import { type FC, useEffect, useMemo, useState } from 'react'
import {
  ProductsWrap,
  ProductBox,
  SearchArea,
  FeaturedProducts,
  ProductSlider
} from './listingPageStyle'
import { APP_ROUTES } from '@router/path'
import { useNavigate, useParams } from 'react-router-dom'
import { className, Product as UnityProduct } from '@shared/constants'
import ImagePlaceholder from '../../../../shared/assets/images/img-placeholder.svg'
import { ResponsiveCarousel, type ResponsiveType } from '@shared/components/ResponsiveCarousel/ResponsiveCarousel'
import { scrollOnTop } from '@shared/helper/methods'
import { type IProduct } from '@shared/typings'

const responsive = ({ itemXl, itemLg, itemMd, itemSm, itemXs }): ResponsiveType => ({
  xl: {
    breakpoint: { max: 4000, min: 1920 },
    items: itemXl
  },
  lg: {
    breakpoint: { max: 1920, min: 1201 },
    items: itemLg
  },
  md: {
    breakpoint: { max: 1200, min: 992 },
    items: itemMd,
    partialVisibilityGutter: 16
  },
  sm: {
    breakpoint: { max: 991, min: 576 },
    items: itemSm,
    partialVisibilityGutter: 16
  },
  xs: {
    breakpoint: { max: 575, min: 0 },
    items: itemXs,
    partialVisibilityGutter: 16
  }
})

export const ListingPage: FC = (props) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { category } = useParams()
  const [search, setSearch] = useState<string>('')
  const { data: products, isLoading } = useGetProducts(category)

  const [filterredProducts, setFilterredProducts] = useState <IProduct[]>([])

  useEffect(() => {
    if (products !== undefined) {
      setFilterredProducts(products)
    }
  }, [products])

  useEffect(() => {
    scrollOnTop()
  }, [])

  const searchItems = useMemo(
    () =>
      products
        ?.filter((product) => product.name !== UnityProduct.SUPPORT_SERVICES)
        ?.map((product) => ({ ...product, label: product.name })),
    [products]
  )

  const getFilteredData = (value: string): void => {
    let tempCategory = [...(products ?? [])]
    if (value?.trim() === '') {
      setFilterredProducts(products ?? [])
    } else {
      tempCategory = filterByName(products, value)
      setFilterredProducts(
        tempCategory?.length > 0 ? tempCategory : value?.length > 0 ? [] : (products ?? [])
      )
    }
  }

  const filterByName = (target: any, value: string): IProduct[] =>
    target.filter((obj) =>
      obj.name?.toLowerCase().includes(value?.toLowerCase())
    )

  const NoResultFound = (): JSX.Element => (
    <Box theme={theme} className="no-data">
      <Typography
        className="text-muted"
        theme={theme}
        variant="body2"
        component="span"
        display={'block'}
        textAlign={'center'}
      >
        No results found
      </Typography>
    </Box>
  )

  return (
    <HomePageLayout>
      {
        <Container theme={theme} maxWidth="lg" className="custom-container">
          <Box theme={theme} className="page-head" textAlign={'center'} mb={5}>
            <Grid
              theme={theme}
              container
              item
              justifyContent={'center'}
              alignItems={'stretch'}
            >
              <Grid theme={theme} item xs={12} md={8}>
                <Typography
                  theme={theme}
                  variant="h2"
                  component="h2"
                  mb={2}
                  className="text-h2"
                >
                  Explore Our Products
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                >
                  All the solutions to your problems under one marketplace. Get
                  financial apps, business apps and much more!
                  {/* <Typography
                    className="text-muted"
                    theme={theme}
                    variant="body2"
                    component="span"
                    display={"block"}
                  >
                    Don’t know about our products? Lets{" "}
                    <a href="#" className="text-primary link">
                      take a quiz
                    </a>{" "}
                    to get you what you need.
                  </Typography> */}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <SearchArea theme={theme} className="search-area">
              {isLoading
                ? <Skeleton width={'100%'} height={72} theme={theme} sx={{
                  borderRadius: 48
                }} />
                : <Autocomplete
                    theme={theme}
                    items={searchItems ?? []}
                    disablePortal={true}
                    endAdornment={<Icon name="SearchIcon" />}
                    value={(search ?? '') as any}
                    onChange={(event, product: any) => {
                      setSearch(product?.name)
                      getFilteredData(product?.name)
                    }}
                    onInputChange={(event, searchProductName) => {
                      setSearch(searchProductName)
                      getFilteredData(searchProductName)
                    }}
                    placeholder="Search by keyword..."
                />
              }
          </SearchArea>
          <ProductsWrap theme={theme} className="product-wrap">
            <Box theme={theme} className="product-section" mt={8}>
              {!isLoading && filterredProducts?.length === 0
                ? (
                  <NoResultFound />
                  )
                : (
                <Grid theme={theme} container item spacing={3}>
                    {(isLoading
                      ? Array.from(new Array(4))
                      : filterredProducts
                        ?.filter(
                          (product) =>
                            product.name !== UnityProduct.SUPPORT_SERVICES
                        ))
                      ?.map((product: IProduct | undefined, index) => (
                      <Grid
                        key={product?.id ?? index}
                        theme={theme}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                      >
                        <ProductBox
                          theme={theme}
                          className={`product-box gt-${product?.name ?? ''}-${className.productListing}`}
                          onClick={() => {
                            navigate(
                              `${APP_ROUTES.DETAIL_PAGE}/${product?.name ?? ''}`
                            )
                          }
                          }
                        >
                          <a className={`prd-link gt-${product?.name ?? ''}-${className.productListing}`}>
                            <Box theme={theme} className={`prd-img gt-${product?.name ?? ''}-${className.productListing}`}>
                                {(product?.image_url ?? '').length > 0
                                  ? <img
                                  src={product?.image_url}
                                  alt="Product Image"
                                />
                                  : <Skeleton width={'100%'} height={120} sx={{ mt: -0.45 }} theme={theme} />}
                            </Box>
                            {(product?.name ?? '').length > 0
                              ? <>
                                  <Typography
                                    className="prd-name"
                                    theme={theme}
                                    variant="body1"
                                    component="p"
                                  >
                                    {product?.name}
                                    </Typography>
                                    <Typography
                                      className="prd-desc text-muted text-ellipses line-clamp line-clamp-3"
                                      theme={theme}
                                      variant="caption"
                                      component="small"
                                      display={'block'}
                                      mt={1}
                                    >
                                      {product?.product_thumbnail}
                                    </Typography>
                                  </>
                              : <Skeleton width={'100%'} height={20} theme={theme} />}
                          </a>
                        </ProductBox>
                      </Grid>
                      ))}
                </Grid>
                  )
                }
            </Box>
          </ProductsWrap>
          <Box theme={theme} mt={10} pt={10}>
            <Typography textAlign={'center'} mb={5} theme={theme} className='text-h2' component='h2' variant='h2'>Products on the way!</Typography>
            <Grid theme={theme} container item spacing={3}>
                <Grid theme={theme} item xs={12} sm={6} md={4} lg={3}>
                  <ProductBox
                    theme={theme}
                    className='product-box text-only'
                  >
                    <Box theme={theme} className="prd-link-item" textAlign={'center'}>
                      <Typography
                        className="prd-name"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                       Decision Engine
                      </Typography>
                    </Box>
                  </ProductBox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={6} md={4} lg={3}>
                  <ProductBox
                    theme={theme}
                    className='product-box text-only'
                  >
                    <Box theme={theme} className="prd-link-item" textAlign={'center'}>
                      <Typography
                        className="prd-name"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                        Scoring Engine
                      </Typography>
                    </Box>
                  </ProductBox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={6} md={4} lg={3}>
                  <ProductBox
                    theme={theme}
                    className='product-box text-only'
                  >
                    <Box theme={theme} className="prd-link-item" textAlign={'center'}>
                      <Typography
                        className="prd-name"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                        Contract Management
                      </Typography>
                    </Box>
                  </ProductBox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={6} md={4} lg={3}>
                  <ProductBox
                    theme={theme}
                    className='product-box text-only'
                  >
                    <Box theme={theme} className="prd-link-item" textAlign={'center'}>
                      <Typography
                        className="prd-name"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                        Underwriting
                      </Typography>
                    </Box>
                  </ProductBox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={6} md={4} lg={3}>
                  <ProductBox
                    theme={theme}
                    className='product-box text-only'
                  >
                    <Box theme={theme} className="prd-link-item" textAlign={'center'}>
                      <Typography
                        className="prd-name"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                        Asset Register
                      </Typography>
                    </Box>
                  </ProductBox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={6} md={4} lg={3}>
                  <ProductBox
                    theme={theme}
                    className='product-box text-only'
                  >
                    <Box theme={theme} className="prd-link-item" textAlign={'center'}>
                      <Typography
                        className="prd-name"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                        Default Handling
                      </Typography>
                    </Box>
                  </ProductBox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={6} md={4} lg={3}>
                  <ProductBox
                    theme={theme}
                    className='product-box text-only'
                  >
                    <Box theme={theme} className="prd-link-item" textAlign={'center'}>
                      <Typography
                        className="prd-name"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                        Asset Remarketing
                      </Typography>
                    </Box>
                  </ProductBox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={6} md={4} lg={3}>
                  <ProductBox
                    theme={theme}
                    className='product-box text-only'
                  >
                    <Box theme={theme} className="prd-link-item" textAlign={'center'}>
                      <Typography
                        className="prd-name"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                        Asset Repossession
                      </Typography>
                    </Box>
                  </ProductBox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={6} md={4} lg={3}>
                  <ProductBox
                    theme={theme}
                    className='product-box text-only'
                  >
                    <Box theme={theme} className="prd-link-item" textAlign={'center'}>
                      <Typography
                        className="prd-name"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                       Audit Trail
                      </Typography>
                    </Box>
                  </ProductBox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={6} md={4} lg={3}>
                  <ProductBox
                    theme={theme}
                    className='product-box text-only'
                  >
                    <Box theme={theme} className="prd-link-item" textAlign={'center'}>
                      <Typography
                        className="prd-name"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                        User Management
                      </Typography>
                    </Box>
                  </ProductBox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={6} md={4} lg={3}>
                  <ProductBox
                    theme={theme}
                    className='product-box text-only'
                  >
                    <Box theme={theme} className="prd-link-item" textAlign={'center'}>
                      <Typography
                        className="prd-name"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                        Localize Now
                      </Typography>
                    </Box>
                  </ProductBox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={6} md={4} lg={3}>
                  <ProductBox
                    theme={theme}
                    className='product-box text-only'
                  >
                    <Box theme={theme} className="prd-link-item" textAlign={'center'}>
                      <Typography
                        className="prd-name"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                       Notify Now
                      </Typography>
                    </Box>
                  </ProductBox>
                </Grid>
            </Grid>
          </Box>
          <FeaturedProducts
            theme={theme}
            className="feature-products"
            mt={{ xs: 10, md: 10 }}
            display={'none'}
          >
            <Box theme={theme} className="section-head" mb={2}>
              <Typography
                theme={theme}
                variant="h3"
                component="h3"
                flexGrow={1}
                className="text-h3"
              >
                Get the Best of Appex Now
              </Typography>
            </Box>
            <ProductSlider theme={theme} className="product-slider">
              <ResponsiveCarousel
                responsive={responsive({
                  itemXl: 4,
                  itemLg: 4,
                  itemMd: 3,
                  itemSm: 2.04,
                  itemXs: 1.03
                })}
              >
                <Box theme={theme} className="feat-prod-item">
                  <a className="prd-link">
                    <Box theme={theme} className="prd-img" mb={2}>
                      <img src={ImagePlaceholder} alt="Product Image" />
                    </Box>
                    <Box theme={theme} className="prd-content">
                      <Typography
                        display={'block'}
                        mb={1}
                        className="text-muted text-uppercase fw-sbold"
                        component="small"
                        variant="caption"
                        theme={theme}
                      >
                        Learn something new
                      </Typography>
                      <Typography
                        className="text-h4"
                        mb={1}
                        theme={theme}
                        component="h4"
                        variant="h4"
                      >
                        Dive into your data with Flex
                      </Typography>
                      <Typography
                        className="text-muted text-body2"
                        component="span"
                        variant="body2"
                        theme={theme}
                      >
                        Sed aenean ipsum, non dictum elementum mauris sit.
                        Scelerisque ultrices posuere leo metus.
                      </Typography>
                    </Box>
                  </a>
                </Box>
                <Box theme={theme} className="feat-prod-item">
                  <a className="prd-link">
                    <Box theme={theme} className="prd-img" mb={2}>
                      <img src={ImagePlaceholder} alt="Product Image" />
                    </Box>
                    <Box theme={theme} className="prd-content">
                      <Typography
                        display={'block'}
                        mb={1}
                        className="text-muted text-uppercase fw-sbold"
                        component="small"
                        variant="caption"
                        theme={theme}
                      >
                        how to
                      </Typography>
                      <Typography
                        className="text-h4"
                        mb={1}
                        theme={theme}
                        component="h4"
                        variant="h4"
                      >
                        Solve customer needs with Hubex
                      </Typography>
                      <Typography
                        className="text-muted text-body2"
                        component="span"
                        variant="body2"
                        theme={theme}
                      >
                        Sed aenean ipsum, non dictum elementum mauris sit.
                        Scelerisque ultrices posuere leo metus.
                      </Typography>
                    </Box>
                  </a>
                </Box>
                <Box theme={theme} className="feat-prod-item">
                  <a className="prd-link">
                    <Box theme={theme} className="prd-img" mb={2}>
                      <img src={ImagePlaceholder} alt="Product Image" />
                    </Box>
                    <Box theme={theme} className="prd-content">
                      <Typography
                        display={'block'}
                        mb={1}
                        className="text-muted text-uppercase fw-sbold"
                        component="small"
                        variant="caption"
                        theme={theme}
                      >
                        get started
                      </Typography>
                      <Typography
                        className="text-h4"
                        mb={1}
                        theme={theme}
                        component="h4"
                        variant="h4"
                      >
                        Never write code again
                      </Typography>
                      <Typography
                        className="text-muted text-body2"
                        component="span"
                        variant="body2"
                        theme={theme}
                      >
                        Sed aenean ipsum, non dictum elementum mauris sit.
                        Scelerisque ultrices posuere leo metus.
                      </Typography>
                    </Box>
                  </a>
                </Box>
                <Box theme={theme} className="feat-prod-item">
                  <a className="prd-link">
                    <Box theme={theme} className="prd-img" mb={2}>
                      <img src={ImagePlaceholder} alt="Product Image" />
                    </Box>
                    <Box theme={theme} className="prd-content">
                      <Typography
                        display={'block'}
                        mb={1}
                        className="text-muted text-uppercase fw-sbold"
                        component="small"
                        variant="caption"
                        theme={theme}
                      >
                        pro tip
                      </Typography>
                      <Typography
                        className="text-h4"
                        mb={1}
                        theme={theme}
                        component="h4"
                        variant="h4"
                      >
                        Get insight in Index
                      </Typography>
                      <Typography
                        className="text-muted text-body2"
                        component="span"
                        variant="body2"
                        theme={theme}
                      >
                        Sed aenean ipsum, non dictum elementum mauris sit.
                        Scelerisque ultrices posuere leo metus.
                      </Typography>
                    </Box>
                  </a>
                </Box>
              </ResponsiveCarousel>
            </ProductSlider>
          </FeaturedProducts>
        </Container>
      }
    </HomePageLayout>
  )
}
