import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const UnsubPopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.unsub-popup-wrap': {
    '.u-dialog': {
      '.dialog-icon': {
        svg: {
          path: {
            stroke: theme.palette.primary.main
          }
        }
      },
      '&.u-dialog-sm': {
        '.MuiDialog-paper': {
          maxWidth: 520
        }
      },
      '.u-dialog-content': {
        '&.has-no-footer': {
          [theme.breakpoints.up('md')]: {
            padding: 60
          }
        }
      }
    }
  }
}))

export const DialogContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.dialog-content-wrap': {
    '.text-dark': {
      color: theme.palette.grey[900]
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.link': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline !important'
      }
    },
    '.fw-medium': {
      fontWeight: theme.typography.fontWeightMedium
    }
  }
}))
