import { useTheme } from '@mui/material'
import { Box, Grid, Typography } from '@ntpkunity/controls'
import { type IFeatureDetails } from '@shared/typings'
import { type FC } from 'react'
import { FadeAnimation } from '@shared/components'

interface ICol6TemplateProps {
  gridLayout: number
  className: string
  feature: IFeatureDetails
}

export const Col6Template: FC<ICol6TemplateProps> = ({ className, gridLayout, feature }) => {
  const theme = useTheme()

  return (
        <Grid theme={theme} item xs={12} md={gridLayout}>
            <Box theme={theme} className={`feat-item-box ${className}`}>
                <Box theme={theme} className='content-area'>
                    <FadeAnimation>
                        <Typography
                            theme={theme}
                            variant="h3"
                            component="h3"
                            className="text-h3"
                        >
                            {feature?.name}
                        </Typography>
                        <Typography
                            theme={theme}
                            className="text-muted text-body2"
                            variant="body1"
                            component="p"
                            mt={1}
                        >
                            {feature?.description}
                        </Typography>
                    </FadeAnimation>
                </Box>
                <Box theme={theme} className='img-area'>
                    <FadeAnimation>
                        <img src={feature?.image_url} alt='Initiation' />
                    </FadeAnimation>
                </Box>
            </Box>
        </Grid>
  )
}
