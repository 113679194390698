import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const BillingAddressWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.billing-address': {
    '.billing-address-area': {
      '.card-item': {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: '1px solid' + theme.palette.grey[100],
        padding: 24,
        borderRadius: 8,
        flexShrink: 0,
        '&.item-add-new': {
          border: '2px dashed' + theme.palette.grey[100],
          width: '100%',
          flex: '0 1 auto',
          minWidth: 150,
          '.btn-default': {
            width: '100%',
            height: '100%',
            color: theme.palette.grey[600],
            flexDirection: 'column',
            '.MuiButton-startIcon': {
              marginRight: 0,
              marginBottom: 4
            }
          }
        },
        '.card-info': {
          flexGrow: 1
        },
        '.card-cta': {
          flexShrink: 0,
          [theme.breakpoints.down('md')]: {
            '.btn': {
              '&.btn-secondary': {
                width: '100%'
              }
            }
          }
        },
        '.loader-wrap': {
          '.loader': {
            left: 24,
            right: 'auto'
          }
        }
      }
    }
  }
}))
