import Http from '@shared/helper/http-api'
import { useQuery } from 'react-query'
import { type AppError } from 'single-spa'
import { type IFeatureDetails } from '@shared/typings'

const BASE_URL = process.env.BASE_URL

export const PRODUCTS_FEATURES = 'signup/product-features'
export const useGetProductFeatures = (productId: number | undefined): {
  data: IFeatureDetails[] | undefined
  error: AppError | unknown
  isLoading: boolean
} => {
  const url = `${PRODUCTS_FEATURES}/${productId}`

  const { data, error, isLoading } = useQuery<IFeatureDetails[]>(
    url,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(url)
    },
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    { refetchOnWindowFocus: false, enabled: !!productId }
  )
  return { data, error, isLoading }
}
