import { useTheme } from '@mui/material'
import { type FC, useEffect, useState } from 'react'
import { Authentication } from '@modules/authentication'
import { ResetPasswordComponent, type IResponse } from '@ntpkunity/controls-ums'

import { APP_ROUTES } from '@router/path'
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  changePassword,
  HttpStatus,
  PRODUCT_PRICING_ID,
  errorTitle,
  expiredLinkTypes,
  expiredResetLinkButtonText,
  expiredResetLinkSubTitle,
  PRODUCT_ID
} from '@shared/constants'
import { useStoreContext } from 'store/storeContext'
import { getItem } from '@shared/helper'

export const ResetPassword: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const [response, setResponse] = useState<IResponse>()
  const { tab: token } = queryString.parse(location?.search)

  const {
    actions: { setToaster }
  } = useStoreContext()

  useEffect(() => {
    if (response?.status === HttpStatus.Bad_Request) {
      navigate(APP_ROUTES.EMAIL_LINK_EXPIRED, {
        replace: true,
        state: {
          token,
          verificationScreenUrl: `${APP_ROUTES.RESET_PASSWORD}`,
          type: expiredLinkTypes.password,
          layoutText: {
            title: errorTitle,
            subTitle: expiredResetLinkSubTitle,
            buttonText: expiredResetLinkButtonText
          }
        }
      })
    }
    if (response?.status === HttpStatus.OK) {
      setToaster({
        isDisplay: true,
        message: changePassword,
        type: ''
      })
      navigate(
        `${APP_ROUTES.SIGNUP}?productPricingId=${getItem(
          PRODUCT_PRICING_ID
        )}&productId=${getItem(PRODUCT_ID)}`
      )
    }
  }, [response])

  return (
    <Authentication>
      {(token ?? '').length > 0 && (
        <ResetPasswordComponent
          setResponse={setResponse}
          validateUrl={token as string}
          nextScreenUrl={`${APP_ROUTES.SIGNUP}`}
          theme={theme}
        />
      )}
    </Authentication>
  )
}
