import { type ICreateFreeSubscription, type ICustomOnDemand, type IProductResponse } from '@shared/typings'
import Http from '@shared/helper/http-api'
import { type UseMutateFunction, useMutation, useQuery, useQueryClient } from 'react-query'
import { getItem } from '@shared/helper'
import { IS_LOGGED_IN, PRODUCT_PRICING_ID } from '@shared/constants'
import { type AppError } from 'single-spa'

const BASE_URL = process.env.BASE_URL

export const SUBSCRIPTIONS = 'marketplace/subscription'
export const UNSUBSCRIBE_PRODUCT = 'marketplace/subscription/end-trial'
export const SUBSCRIBE_PRODUCT = 'marketplace/subscription/subscribe-product'
export const FREE_SUBSCRIPTION =
  'marketplace/subscription/create-new-subscription' // FOR OLD SUBSCRIPTION FLOW
export const CUSTOM_ON_DEMAND = 'signup/subscription/custom-package-request'
export const SUBSCRIBE_FREE_TRIAL = 'marketplace/order/create-trial' // FOR NEW SUBSCRIPTION FLOW

export const useGetSubscriptions = (
  productId?: number
): {
  data: IProductResponse[] | undefined
  error: AppError | unknown
  isLoading: boolean
} => {
  const isLoggedIn = getItem(IS_LOGGED_IN)

  const { data, error, isLoading } = useQuery<IProductResponse[]>(
    [SUBSCRIPTIONS, productId],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(SUBSCRIPTIONS)
    },
    { refetchOnWindowFocus: false, enabled: Boolean(isLoggedIn) }
  )
  return { data, error, isLoading }
}

export const useEndTrial = (userId: number): any => {
  const url = `${UNSUBSCRIBE_PRODUCT}/${userId}`
  const { data, isLoading, mutate, error } = useMutation<any>(async () => {
    const apiService = Http.createConnection({
      baseUrl: BASE_URL,
      withAuthentication: true
    })
    return await apiService.put<any>(url)
  })
  return { data, isLoading, mutate, error }
}

export const useUnsubscribePaidProduct = (): {
  data: IProductResponse[] | undefined
  isLoading: boolean
  error: AppError | unknown
  mutate: UseMutateFunction<IProductResponse[], any, string, unknown>
} => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate, error } = useMutation<
  IProductResponse[],
  any,
  string
  >(
    async (subscriptionId) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.put<any>(`${SUBSCRIPTIONS}/${subscriptionId}`)
    },
    {
      onSuccess () {
        void queryClient.invalidateQueries(SUBSCRIPTIONS)
      }
    }
  )
  return { data, isLoading, mutate, error }
}

export const useCreateFreeTrialSubscription = (): {
  data: IProductResponse[] | undefined
  isLoading: boolean
  mutate: UseMutateFunction
  error: string
} => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate, error } = useMutation<any, any>(
    async (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(SUBSCRIBE_FREE_TRIAL, body)
    },
    {
      onSuccess () {
        void queryClient.invalidateQueries(SUBSCRIPTIONS)
      }
    }
  )
  return { data, isLoading, mutate, error: error?.message }
}

export const useCreateFreeSubscription = (): {
  data: ICreateFreeSubscription | undefined
  isLoading: boolean
  mutate: UseMutateFunction<ICreateFreeSubscription, any, ICreateFreeSubscription, unknown>
  error: string
} => {
  const { data, isLoading, mutate, error } = useMutation<
  ICreateFreeSubscription,
  any,
  ICreateFreeSubscription
  >(async (body) => {
    const url = `${FREE_SUBSCRIPTION}/${body.productPricingId}`
    const apiService = Http.createConnection({
      baseUrl: BASE_URL,
      withAuthentication: true
    })
    return await apiService.post<any>(url, {})
  })
  return { data, isLoading, mutate, error: error?.message }
}

export const useCreateCustomOnDemand = (): any => {
  const { data, isLoading, mutate, error } = useMutation<ICustomOnDemand, any>(
    async (body) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL
      })
      return await apiService.post<any>(CUSTOM_ON_DEMAND, body)
    }
  )
  return { data, isLoading, mutate, error: error?.message }
}
