import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const CalendlyPopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.calendly-popup': {
    '.u-dialog': {
      '.u-dialog-content': {
        overflow: 'hidden',
        '&.has-no-footer': {
          padding: 0
        }
      }
    }
  }
}))
