import Http from '@shared/helper/http-api'
import { type IFeedback } from '@shared/typings'
import { type UseMutateFunction, useMutation } from 'react-query'
import { type AppError } from '@shared/helper'

const BASE_URL = process.env.BASE_URL

const CREATE_FEEDBACK = 'signup/create-feedback'

interface IUseFeedback {
  data: IFeedback | undefined
  isLoading: boolean
  isError: boolean
  error: AppError | unknown
  mutate: UseMutateFunction<IFeedback, unknown, IFeedback, unknown>
}

export const useFeedback = (): IUseFeedback => {
  const { data, isLoading, isError, error, mutate } = useMutation<IFeedback, AppError, IFeedback>(
    async (payload) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post(CREATE_FEEDBACK, payload)
    }
  )
  return { data, isLoading, isError, error, mutate }
}
