import Http from '@shared/helper/http-api'
import { type UseMutateFunction, useMutation } from 'react-query'
import { type AppError } from 'single-spa'

const BASE_URL = process.env.BASE_URL

const UPDATE_PAYMENT_METHOD = 'marketplace/payment/request-bank-transfer'

export const useUpdatePaymentMethod = (): {
  data: any
  isLoading: boolean
  isError: boolean
  error: AppError | unknown
  mutate: UseMutateFunction
} => {
  const { data, isLoading, isError, error, mutate } = useMutation<any>(
    async (body) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(UPDATE_PAYMENT_METHOD, body)
    }
  )
  return { data, isLoading, isError, error, mutate }
}
