export const paidConfirmationMessage =
  'Your payment has been successful. Thank you for subscribing to our product. We have sent you a welcome email to access the product documentation and related details.'
export const paidConfirmationMessageForBankTransfer =
  'Thank you for subscribing to our product. We have sent you a welcome email to access the product documentation and related details. Your payment is still pending.'
export const paidConfirmationForMultipleProducts =
  'Your payment has been successful. Thank you for subscribing to our product(s). We have sent you a welcome email to access the product documentation and related details.'
export const paidConfirmationForMultipleProductsForBankTransfer =
  'Thank you for subscribing to our product(s). We have sent you a welcome email to access the product documentation and related details. Your payment is still pending.'
export const freeConfirmationMessage =
  'Thank you for subscribing to our product. We have sent you a welcome email to access the product documentation and related details.'
export const paymentDeclined =
  'Sorry, your payment has been declined. Please try again. If the problem continues, please contact your bank or try using another card.'
export const contactUsMsg = "Thanks, we'll get back to you soon!"
export const networkError = "User doesn't exist."
export const invalidCredentials = 'Username or password is not valid'
export const userDisabled =
  'Your account has been blocked. Please contact your administrator!'
export const packageAlreadyAvailed = 'You have already availed this Package.'
export const cartsErrorResponse = 'Something went wrong adding your carts!'
export const verificationEmailSent = 'Verification email has been sent'
export const otpEmailSent = 'Otp email has been sent'
export const sendingOtpEmail = 'Sending OTP ...'
export const verifyingOtpValue = 'Verifying OTP ...'
export const resetPasswordEmailSent = 'Reset password link has been sent'
export const changePassword = 'Your password has been changed successfully.'
export const editUserSuccessfully =
  'User updated successfully and verification link has been sent'
export const incorrectPassword = 'Incorrect current password.'
export const congratulations = 'Congratulations'
export const defaultCardMsg = 'Default card cannot be deleted.'
export const deleteCardMessage = 'Once deleted, you cannot undo this action.'
export const cardLimitMessage =
  'You can save up to 3 cards in your profile. If you want to add a new card, delete a saved one to proceed.'
export const errorTitle = 'Link Expired!'
export const expiredResetLinkSubTitle =
  'This reset password link has been expired. Please click the button below to regenerate the link.'
export const expiredResetLinkButtonText = 'Send Reset Link'
export const expiredEmailLinkSubTitle =
  'This link has been expired. Please click the button below to regenerate the link.'
export const expiredEmailLinkButtonText = 'Resend Verification Link'
export const successfulPayment = 'Successful Payment'
export const alreadyAvailedPkg = 'You cannot update your package!'
export const productAdded = 'Product added to cart successfully!'
export const productRemoved = 'Product removed from your cart successfully!'
export const updateProfileErrorMessage =
  'Something went wrong updating your profile!'
export const updateProfileMessage = 'Information updated successfully!'
export const updateBillingMessage = 'Billing address updated successfully!'
export const addBillingMessage = 'You have added your billing details successfully!'
export const updateBusinessMessage =
  'Business information updated successfully! '
export const invalidCardDetailsMessage =
  'Invalid Card Details! Please try again.'
export const invalidLink = 'Invalid Link'
export const invalidOtp = 'Invalid OTP'
export const invalidPassword = 'Invalid Password'
export const feedbackSuccessMessage = 'Your response has been submitted successfully!'
export const bankTransferConfirmation = 'Once the payment method is updated, all of your subscriptions will be updated accordingly.'
export const unsubscribeConfirmation = 'If you proceed with the un-subscription, you can still use the product until your next billing date.'
export const sentRequestApproval = 'Request has been sent for approval!'
export const retryPaymentSuccessMsg = 'Your payment been successful'
export const homepageBlogDesc = 'Learn about the industry news, interviews, new product features, the latest in technology, solutions, updates and resources.'
