import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const WebinarDetailItem = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.webinar-detail-item': {
    '.fw-medium': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '.webinar-detail-page-head': {
      margin: 'auto auto 40px',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 792,
        width: '100%',
        margin: 'auto auto 64px'
      }
    },
    '.webinar-img': {
      maxHeight: 675,
      borderRadius: '16px',
      overflow: 'hidden',
      position: 'relative',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        borderRadius: theme.shape.borderRadius
      },
      img: {
        maxWidth: '100%',
        maxHeight: 675,
        objectFit: 'cover',
        objectPosition: 'center center',
        width: '100%',
        height: '100%'
      },
      '.play-icon': {
        position: 'absolute',
        left: '50%',
        top: '50%',
        zIndex: 1,
        transform: 'translate(-50%, -50%)',
        width: 48,
        height: 48,
        img: {
          maxHeight: 48,
          objectFit: 'contain',
          [theme.breakpoints.up('md')]: {
            maxHeight: 149
          }
        },
        [theme.breakpoints.up('md')]: {
          width: 149,
          height: 149
        }
      }
    },
    '.webinar-meta': {
      '.seperator': {
        display: 'inline-block',
        width: 4,
        height: 4,
        borderRadius: '50%',
        backgroundColor: theme.palette.grey[300]
      }
    },
    '.webinar-content': {
      '.webinar-details-wrap': {
        position: 'relative',
        [theme.breakpoints.down('md')]: {
          display: 'flex',
          flexDirection: 'column'
        }
      },
      '.webinar-details': {
        fontFamily: theme.typography.fontFamily,
        maxWidth: 792,
        width: '100%',
        margin: 'auto',
        '.webinar-content-img': {
          maxHeight: 445,
          borderRadius: '16px',
          overflow: 'hidden',
          margin: '40px 0',
          [theme.breakpoints.down('md')]: {
            borderRadius: theme.shape.borderRadius,
            margin: '24px 0'
          },
          img: {
            maxWidth: '100%',
            maxHeight: 445,
            objectFit: 'cover',
            objectPosition: 'center center',
            width: '100%',
            height: '100%'
          }
        },
        p: {
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.body1.fontSize,
          lineHeight: theme.typography.body1.lineHeight,
          color: theme.palette.grey[600],
          marginBottom: 0,
          fontWeight: 'normal',
          [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight
          }
        },
        h3: {
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.h3.fontSize,
          lineHeight: theme.typography.h3.lineHeight,
          fontWeight: theme.typography.fontWeightBold,
          marginTop: 40,
          marginBottom: 40,
          [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.h4.fontSize,
            lineHeight: theme.typography.h4.lineHeight,
            marginTop: 24,
            marginBottom: 24
          }
        },
        ul: {
          marginBottom: 40,
          li: {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.body1.fontSize,
            lineHeight: theme.typography.body1.lineHeight,
            color: theme.palette.grey[600],
            [theme.breakpoints.down('md')]: {
              fontSize: theme.typography.body2.fontSize,
              lineHeight: theme.typography.body2.lineHeight
            }
          },
          [theme.breakpoints.down('md')]: {
            marginTop: 24,
            marginBottom: 24
          },
          '&.num-list': {
            listStyle: 'decimal outside none'
          }
        },
        a: {
          color: theme.palette.primary.main,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline'
          }
        },
        '.list': {
          paddingLeft: 15

        },
        '.quote': {
          fontStyle: 'italic'
        },
        '.blockquote': {
          fontWeight: theme.typography.fontWeightMedium,
          backgroundColor: '#F5F5F7',
          borderLeft: '4px solid #F3B140',
          padding: 40,
          margin: '40px 0px',
          color: theme.palette.grey[900],
          borderRadius: '0px 16px 16px 0px',
          [theme.breakpoints.down('md')]: {
            borderRadius: '0px 8px 8px 0px',
            margin: '24px 0px'
          }
        },
        '.img-caption': {
          textAlign: 'center',
          fontSize: theme.typography.caption.fontSize,
          lineHeight: theme.typography.caption.lineHeight
        },
        '.webinar-content-img-wrap': {
          '&.with-caption': {
            '.webinar-content-img': {
              marginBottom: 16
            }
          }
        }
      }
    }
  }
}))
