import Http from '@shared/helper/http-api'
import { type UseMutateFunction, useMutation, useQuery, useQueryClient } from 'react-query'
import { IS_LOGGED_IN } from '@shared/constants'
import { getItem, isLoggedIn } from '@shared/helper'
import { type IUserProfile, type IStorageForm, type ITenantForm, type IUser, type IStorageBackendResponse } from '@shared/typings'
import { type AppError } from 'single-spa'

const BASE_URL = process.env.BASE_URL
const UPDATE_PROFILE = 'ums/um/update-user-profile'
const UPDATE_TENANT_PROFILE = 'ums/update-tenant'
const UPDATE_S3_STORAGE = 'ums/update-s3-storage'
const GET_PROFILE = 'ums/customer-profile'
export const GET_USER_PROFILE = 'ums/user-profile'
const GET_ROLES = 'ums/user-roles'
const GET_BILLING_EMAILS = 'marketplace/order/get-billing-emails'

export const useUpdateProfile = (): {
  data: IUser
  isLoading: boolean
  mutate: UseMutateFunction
  error: AppError | unknown
} => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate, error } = useMutation(
    async (body) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.patch<any>(UPDATE_PROFILE, body)
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(GET_USER_PROFILE)
      }
    }
  )
  return { data, isLoading, mutate, error }
}

export const useGetUserProfile = (): {
  data: IUser
  error: AppError | unknown
  isLoading: boolean
  isFetching: boolean
  isError: boolean
  isFetched: boolean
} => {
  const isLoggedIn = getItem(IS_LOGGED_IN)

  const { data, error, isLoading, isFetching, isError, isFetched } = useQuery(
    GET_PROFILE,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(GET_PROFILE)
    },
    { refetchOnWindowFocus: false, enabled: Boolean(isLoggedIn) }
  )
  return { data, error, isLoading, isFetching, isError, isFetched }
}

export const useGetUserRoles = (): any => {
  const isLoggedIn = getItem(IS_LOGGED_IN)

  const { data, error, isLoading, isFetching, isError, isFetched } = useQuery(
    GET_ROLES,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(GET_ROLES)
    },
    { refetchOnWindowFocus: false, enabled: Boolean(isLoggedIn) }
  )
  return { data, error, isLoading, isFetching, isError, isFetched }
}

export const useGetProfile = (shouldFetch = true): {
  data: IUserProfile
  error: AppError | unknown
  isLoading: boolean
  isError: boolean
} => {
  const _isLoggedIn = (isLoggedIn() ?? '').length > 0
  const { data, error, isLoading, isError } = useQuery(
    GET_USER_PROFILE,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(GET_USER_PROFILE)
    },
    { refetchOnWindowFocus: false, enabled: Boolean(_isLoggedIn) && shouldFetch }
  )
  return { data, error, isLoading, isError }
}

export const useUpdateTenant = (): {
  data: ITenantForm
  isLoading: boolean
  error: AppError | unknown
  mutate: UseMutateFunction
} => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate, error } = useMutation(
    async (body) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.patch<any>(UPDATE_TENANT_PROFILE, body)
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(GET_USER_PROFILE)
      }
    }
  )
  return { data, isLoading, mutate, error }
}

export const useS3Storage = (): {
  data: IStorageBackendResponse | undefined
  isLoading: boolean
  error: string
  mutate: UseMutateFunction<IStorageBackendResponse, AppError, IStorageForm, unknown>
} => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate, error } = useMutation<IStorageBackendResponse, AppError, IStorageForm>(
    async (body) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.patch<any>(UPDATE_S3_STORAGE, body)
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(GET_USER_PROFILE)
      }
    }
  )
  return { data, isLoading, mutate, error: error?.message ?? '' }
}

export const useGetBillingEmails = (): {
  data: ITenantForm
  isLoading: boolean
  error: AppError | unknown
  isError: boolean
} => {
  const { data, error, isLoading, isError } = useQuery(
    GET_BILLING_EMAILS,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(GET_BILLING_EMAILS)
    }
  )
  return { data, error, isLoading, isError }
}
