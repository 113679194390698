import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const DialogContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.dilaog-content': {
    '.u-custom-file-upload': {
      marginBottom: 8,
      '.root-drag-file': {
        height: 180
      }
    }
  }
}))

export const DialogActionButtons = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.dialog-action-buttons-wrap': {
    width: '100%',
    '.buttons': {
      width: '100%',
      display: 'flex',
      gap: '16px',
      '.MuiButton-root': {
        '&:not(:first-of-type)': {
          flexGrow: 1
        },
        '&:last-of-type': {
          width: '100%'
        }
      }
    }
  }
}))
