import { isLoggedIn } from '@shared/helper'
import Http from '@shared/helper/http-api'
import { useQuery } from 'react-query'

const BASE_URL = process.env.BASE_URL
const GET_USER_PRODUCTS = 'ums/user-products'

const fallback = []
export const useGetUserProducts = (): any => {
  const {
    data = fallback,
    error,
    isLoading,
    isFetching,
    isError,
    isFetched
  } = useQuery(
    GET_USER_PRODUCTS,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(GET_USER_PRODUCTS)
    },
    { refetchOnWindowFocus: false, enabled: (isLoggedIn() ?? '').length > 0 }
  )
  return { data, error, isLoading, isFetching, isError, isFetched }
}
