import { useTheme } from '@mui/material'
import { type FC, useState, useEffect } from 'react'
import { Authentication } from '@modules/authentication'
import { useStoreContext } from 'store/storeContext'
import {
  Box,
  Button,
  Icon
} from '@ntpkunity/controls'
import { OtpComponent, type IResponse } from '@ntpkunity/controls-ums'

import { APP_ROUTES } from '@router/path'
import {
  ACCESS_TOKEN_KEY,
  cartsErrorResponse,
  CART_DATA,
  COMPANY_NAME,
  CURRENT_ROUTE,
  FREE_TRIAL_PRODUCT,
  HttpStatus,
  invalidOtp,
  invalidPassword,
  IS_CHECKOUT,
  IS_LOGGED_IN,
  otpEmailSent,
  packageAlreadyAvailed,
  PRODUCT_ID,
  REFRESH_TOKEN_KEY,
  ROLE,
  ROLE_ID,
  sendingOtpEmail,
  SIGNUP_STEPS,
  Steps,
  STRIPE_USER_CREATED,
  TENANT_ID,
  userDisabled,
  USER_NAME,
  verifyingOtpValue
} from '@shared/constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import queryString from 'query-string'
import { useAddCarts } from '@modules/product/services/cart/cartService'
import { useCreateFreeTrialSubscription } from '@modules/product'
import { checkAdminFromRoles, getItemByKey, removeItembyKey, setItem, setItembyKey } from '@shared/helper'
import { type IRole } from '@shared/typings'
import { GET_USER_PROFILE } from '@modules/myAccount'

export const OTP: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {
    actions: { setToaster, setUserProfile },
    states: { userProfile }
  }: any = useStoreContext()
  const [response, setResponse] = useState<IResponse>()
  const [sendingOtp, setSendingOtp] = useState<boolean>(false)
  const [verifyingOtp, setVerifyingOtp] = useState<boolean>(false)
  const location = useLocation() as any

  const { search } = location
  const queryParams = new URLSearchParams(search)
  const otp = queryParams.get('otp')
  const _email = queryParams.get('email')

  const email = location?.state?.email ?? _email
  const isTenant = location?.state?.isTenant ?? false
  const signUpSteps = location?.state?.signupSteps
  const isUserSignUpCompleted = [
    Steps.TERMS.toLowerCase(),
    Steps.TENANT.toLowerCase()
  ].includes(
    (signUpSteps ?? '')?.toLowerCase()
  )

  if (!email) {
    navigate(APP_ROUTES.SIGNUP)
  }

  const { mutate: createFreeSubscription, isLoading: isFreeTrialLoading } =
    useCreateFreeTrialSubscription()
  const { mutate: addAllCarts, isLoading: isCartsLoading } = useAddCarts()

  const dumpAllCartsToDB = (navigateTo: string): void => {
    const data = getItemByKey(CART_DATA)
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (data) {
      addAllCarts(data, {
        onSuccess () {
          navigate(navigateTo)
        },
        onError () {
          setToaster({
            isDisplay: true,
            message: cartsErrorResponse,
            type: 'error'
          })
          navigate(APP_ROUTES.SINGLE_SIGN_ON)
        }
      })
      removeItembyKey(CART_DATA)
      removeItembyKey(FREE_TRIAL_PRODUCT)
    }
  }

  useEffect(() => {
    if (sendingOtp) {
      setToaster({
        isDisplay: true,
        message: sendingOtpEmail,
        type: ''
      })
    }

    if (verifyingOtp) {
      setToaster({
        isDisplay: true,
        message: verifyingOtpValue,
        duration: 1500,
        type: ''
      })
    }
  }, [sendingOtp, verifyingOtp])

  useEffect(() => {
    if (!response) {
      return
    }

    const { status, data, message } = response
    if (status === HttpStatus.OK) {
      if (typeof data === 'string') {
        setToaster({
          isDisplay: true,
          message: data,
          type: ''
        })
      } else if ([Steps.REGISTERED, Steps.VERIFIED].includes(signUpSteps?.toLowerCase())) {
        const {
          signup_steps: signUpSteps,
          tenant_name: tenantName,
          is_tenant: isTenant,
          otp: _otp
        } = data ?? {}

        navigate(APP_ROUTES.USER_PROFILE, {
          replace: true,
          state: {
            email,
            isTenant,
            tenantName,
            otp: _otp
          }
        })
      } else if ([Steps.TENANT, Steps.TERMS].includes(signUpSteps?.toLowerCase())) {
        const {
          tenant_id: tenantId,
          is_tenant: isTenant,
          signup_steps: signupSteps,
          access_token: accessToken,
          refresh_token: refreshToken,
          roles,
          profile,
          allowed_products: allowedProducts,
          tenant_profile: tanantProfile
        } = data ?? {}
        setItembyKey(SIGNUP_STEPS, signupSteps?.toLowerCase())
        const { name } = tanantProfile ?? { name: '' }
        const role: IRole = roles?.reduce((agg, role: IRole) => {
          if (role?.is_admin) {
            return {
              name: role.name,
              is_admin: role.is_admin
            }
          }
          return {
            name: role.name,
            is_admin: role.is_admin
          }
        }, {})
        const isAdmin = checkAdminFromRoles(roles)
        const productId: number = roles?.[0]?.product_id ?? allowedProducts?.[0]?.id
        const roleId: number = roles?.[0]?.id;
        (!Number.isNaN(productId)) && setItem(PRODUCT_ID, productId);
        (!Number.isNaN(roleId)) && setItem(ROLE_ID, roleId)
        role !== undefined && setItem(ROLE, role)
        setItem(
          USER_NAME,
          `${profile?.first_name as string ?? ''} ${profile?.last_name as string ?? ''}`
        )

        setUserProfile({
          ...userProfile,
          ...tanantProfile,
          ...profile,
          ...roles,
          company_name: name,
          is_admin: isAdmin
        })
        queryClient.setQueryData([GET_USER_PROFILE], {
          ...userProfile,
          ...tanantProfile,
          ...profile,
          ...roles,
          company_name: name,
          signup_steps: signupSteps,
          is_admin: isAdmin
        })
        setItem(TENANT_ID, tenantId)
        setItem(COMPANY_NAME, name)

        setItem(IS_LOGGED_IN, true)
        setItem(REFRESH_TOKEN_KEY, refreshToken)
        setItem(ACCESS_TOKEN_KEY, accessToken)
        const atTenantStep =
          signupSteps?.toLowerCase() === Steps.TENANT ||
          signupSteps?.toLowerCase() === Steps.TERMS
        if (atTenantStep) {
          setItem(STRIPE_USER_CREATED, true)
        }

        if (!isAdmin) {
          if (allowedProducts.length === 1) {
            navigate(`/${allowedProducts[0].product_path as string}`)
          } else {
            navigate(APP_ROUTES.SINGLE_SIGN_ON)
          }
        } else {
          const carts = getItemByKey(CART_DATA)
          const currentRoute = getItemByKey(CURRENT_ROUTE)
          const isCheckout = getItemByKey(IS_CHECKOUT)
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { tab: token, price_id } = queryString.parse(
            currentRoute
          ) as { tab: string, price_id: string }
          const freeTrialProduct = getItemByKey(FREE_TRIAL_PRODUCT)
          if ((token ?? '').length > 0) {
            navigate(
              `${APP_ROUTES.UNSUBSCRIBE}?tab=${token}&price_id=${price_id}`
            )
          }
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (!freeTrialProduct && !carts) {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (currentRoute && !isCheckout) {
              navigate(currentRoute)
            } else {
              navigate(APP_ROUTES.SINGLE_SIGN_ON, {
                state: {
                  allowedProducts,
                  is_admin: role.is_admin
                }
              })
            }
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          } else if (!freeTrialProduct && carts) {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (isCheckout) {
              dumpAllCartsToDB(APP_ROUTES.CHECKOUT)
            } else {
              dumpAllCartsToDB(
                currentRoute ?? APP_ROUTES.CHECKOUT
              )
            }
          } else {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            freeTrialProduct &&
              createFreeSubscription(freeTrialProduct, {
                onSuccess () {
                  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                  if (!carts) {
                    navigate(APP_ROUTES.SINGLE_SIGN_ON)
                  } else {
                    dumpAllCartsToDB(APP_ROUTES.SINGLE_SIGN_ON)
                  }
                },
                onError () {
                  setToaster({
                    isDisplay: true,
                    message: packageAlreadyAvailed,
                    type: 'error'
                  })
                  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                  if (!carts) {
                    navigate(APP_ROUTES.SINGLE_SIGN_ON)
                  }
                }
              })
          }
        }
      } else {
        setToaster({
          isDisplay: true,
          message: otpEmailSent,
          type: ''
        })
      }
    } else if (status === HttpStatus.Locked) {
      setToaster({
        isDisplay: true,
        message: message ?? userDisabled,
        type: 'error'
      })
    } else if (status === HttpStatus.Bad_Request) {
      setToaster({
        isDisplay: true,
        message: message.includes('password') ? invalidPassword : invalidOtp,
        type: 'error'
      })
    }
  }, [response])

  return (
    <Authentication>
      <Box theme={theme} mb={4} className="back-btn">
        <Button
          defaultBtn
          theme={theme}
          startIcon={<Icon name="IconLeftArrow" />}
          text="Go Back"
          onClick={() => { navigate(APP_ROUTES.SIGNUP) }
          }
        />
      </Box>
      <OtpComponent
        theme={theme}
        passwordFirst={false}
        email={email}
        setResponse={setResponse}
        isLoading={isCartsLoading || isFreeTrialLoading}
        layoutText={{
          title: isUserSignUpCompleted ? 'Finish Logging In' : 'Verification Passcode Sent'
        }}
        otpProps={{
          otpValue: otp,
          signUpSteps,
          setSendingOtp,
          setVerifyingOtp
        }}
        passwordProps={{
          forgetScreenUrl: `${APP_ROUTES.FORGOT_PASSWORD}`
        }}
      />
    </Authentication>
  )
}
