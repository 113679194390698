import { formatRelativeDateTime, getInitials } from '@shared/helper'
import type { INotificationData, INotifications, INotificationsBackend, INotificationsResponse } from '@shared/typings'
import { type QueryClient } from 'react-query'
import parse from 'html-react-parser'
import { GET_ALL_NOTIFICATIONS } from '@layout/components/header/notifications/services'

export type INotificationState = INotifications

export const initialNotificationState: INotificationState = {
  totalUnreadMessages: 0,
  notifications: []
}

export const ACTION_TYPE = {
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  REFETCH_NOTIFICATIONS: 'REFETCH_NOTIFICATIONS',
  READ_NOTIFICATION: 'READ_NOTIFICATION',
  ERROR_READ_NOTIFICATION: 'ERROR_READ_NOTIFICATION'
}

export interface ISetNotifications {
  type: typeof ACTION_TYPE.SET_NOTIFICATIONS
  payload: {
    notifications: INotificationsBackend[]
    totalUnreadMessages: number
    handleClick: (data: INotificationsBackend) => void
  }
}
export interface IRefetchNotifications {
  type: typeof ACTION_TYPE.REFETCH_NOTIFICATIONS
}
export interface IReadNotification {
  type: typeof ACTION_TYPE.READ_NOTIFICATION
  payload: {
    notification: INotificationsBackend
    navigate: (cta: string | undefined) => void
  }
}
export interface IErrorReadNotification {
  type: typeof ACTION_TYPE.ERROR_READ_NOTIFICATION
  payload: {
    notificationData: INotificationsBackend
  }
}

export type Action =
  | ISetNotifications
  | IRefetchNotifications
  | IReadNotification
  | IErrorReadNotification

const notificationDataMapper = (notificationData: INotificationsBackend, handleClick: (data: INotificationsBackend) => void): INotificationData => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { id, created_at, action, detail, first_name, last_name, is_read, ref } = notificationData
  const { date, time } = formatRelativeDateTime(created_at)
  return {
    id,
    action,
    notificationMessage: parse(detail ?? ''),
    userInitials: getInitials(first_name, last_name ?? ''),
    isRead: is_read,
    date,
    time,
    ref,
    onClick: () => { handleClick(notificationData) }
  }
}

const updateNotificationReadStatusInStore = (notifications: INotificationData[], id: number, readStatus: boolean): INotificationData[] => {
  return notifications.map((notification) => {
    if (notification.id === id) {
      notification.isRead = readStatus
    }
    return notification
  })
}

const updateNotificationReadStatusInCache = (queryClient: QueryClient, id: number, readStatus: boolean): void => {
  const cachedNotifications = queryClient.getQueryData(
    [GET_ALL_NOTIFICATIONS]
  ) as { pageParams: any[], pages: INotificationsResponse[] }

  if (cachedNotifications) {
    const updatedPages = cachedNotifications?.pages.map(
      (page) => ({
        ...page,
        notifications: page.notifications.map((notification) => {
          if (notification.id === id) {
            return {
              ...notification,
              is_read: readStatus
            }
          }
          return notification
        })
      })
    )

    const lastPageIndex = updatedPages.length - 1
    updatedPages[lastPageIndex].total_unread += readStatus ? -1 : 1

    const updatedNotificationsForCache = {
      pageParams: cachedNotifications.pageParams,
      pages: updatedPages
    }

    queryClient.setQueryData([GET_ALL_NOTIFICATIONS], updatedNotificationsForCache)
  }
}

const reducer = (queryClient: QueryClient) => (state: INotificationState, action: Action): INotificationState => {
  switch (action.type) {
    case ACTION_TYPE.SET_NOTIFICATIONS: {
      const { payload } = action as ISetNotifications
      const { notifications, totalUnreadMessages, handleClick } = payload
      return {
        ...state,
        totalUnreadMessages,
        notifications: notifications.map(notificationData => notificationDataMapper(notificationData, handleClick))
      }
    }
    case ACTION_TYPE.REFETCH_NOTIFICATIONS: {
      void queryClient.refetchQueries([GET_ALL_NOTIFICATIONS])
      return state
    }
    case ACTION_TYPE.READ_NOTIFICATION: {
      const { payload } = action as IReadNotification
      const { notification, navigate } = payload
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { id, action: cta, is_read } = notification

      if (!is_read) {
        const _notifications = updateNotificationReadStatusInStore(state.notifications, id, true)
        const updatedNotifications = {
          totalUnreadMessages: Math.max(0, state.totalUnreadMessages - 1),
          notifications: _notifications
        }

        updateNotificationReadStatusInCache(queryClient, id, true)

        navigate?.(cta)
        return {
          ...state,
          ...updatedNotifications
        }
      }
      navigate?.(cta)
      return state
    }
    case ACTION_TYPE.ERROR_READ_NOTIFICATION: {
      const { payload } = action as IErrorReadNotification
      const { notificationData: { id } } = payload

      const _notifications = updateNotificationReadStatusInStore(state.notifications, id, false)
      const updatedNotifications = {
        totalUnreadMessages: Math.max(0, state.totalUnreadMessages + 1),
        notifications: _notifications
      }

      updateNotificationReadStatusInCache(queryClient, id, false)

      return {
        ...state,
        ...updatedNotifications
      }
    }
    default:
      return state
  }
}

export default reducer
