import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'
import { isLoggedIn } from '@shared/helper'
import { LoginValidatorComponent } from '@ntpkunity/controls-ums'
import { marketplaceTheme } from '@shared/theme'
import { type ReactElement } from 'react'
import { useStoreContext } from 'store/storeContext'
import { protectedRoutesAllowedToNonAdmin } from '@shared/constants'

const RequireAuth = (): ReactElement => {
  const { pathname } = useLocation()
  const {
    states: { userProfile }
  }: any = useStoreContext()

  if ((isLoggedIn() ?? '')?.length === 0) {
    return <Navigate to={`${APP_ROUTES.SIGNUP}`} replace />
  } else if (!(userProfile?.is_admin ?? false) && !protectedRoutesAllowedToNonAdmin.includes(pathname)) {
    return <Navigate to={`${APP_ROUTES.NEW_HOME_PAGE}`} replace />
  }

  return (
    <LoginValidatorComponent theme={marketplaceTheme} isMarketPlace = {true}>
      <Outlet />
    </LoginValidatorComponent>
  )
}
export default RequireAuth
