import { type FC } from 'react'
import { useTheme } from '@mui/material'
import { BrandsLogoComponent } from '../brandsLogo/brandsLogoComponent'
import { Box } from '@ntpkunity/controls'

import GMapsLogo from '../../../../shared/assets/images/logo-g-maps.svg'
import MitekLogo from '../../../../shared/assets/images/logo-mitek.svg'
import PenLogo from '../../../../shared/assets/images/logo-pen.png'
import PlaidLogo from '../../../../shared/assets/images/logo-plaid.svg'
import SourceLogo from '../../../../shared/assets/images/logo-source.svg'
import StripeLogo from '../../../../shared/assets/images/logo-stripe.svg'
import EmBankLogo from '../../../../shared/assets/images/logo-em-bank.svg'
import HayDockLogo from '../../../../shared/assets/images/logo-haydock.svg'
import CharlesDeanLogo from '../../../../shared/assets/images/logo-charles-dean.svg'
import CloseBrothersLogo from '../../../../shared/assets/images/logo-close-brothers.svg'
import BibbyLogo from '../../../../shared/assets/images/logo-bibby.svg'
import UTBLogo from '../../../../shared/assets/images/logo-utb.svg'
import AutoNationLogo from '../../../../shared/assets/images/logo-autonation.svg'
interface IProductLogoStripComponent {
  productName: string
}

export const ProductLogoStripComponent: FC<IProductLogoStripComponent> = ({ productName }) => {
  const theme = useTheme()

  const logosByProduct: Record<string, { sectionTitle: string, logoItem: Array<{ logo: string, className: string }> }> = {
    Hubex: {
      sectionTitle: 'Our Connected Providers',
      logoItem: [
        { logo: GMapsLogo, className: 'size-32' },
        { logo: StripeLogo, className: 'size-30' },
        { logo: PlaidLogo, className: 'size-30' },
        { logo: MitekLogo, className: 'size-24' },
        { logo: SourceLogo, className: 'size-35' },
        { logo: PenLogo, className: 'size-24' }
      ]
    },
    Flex: {
      sectionTitle: 'Trusted by leading brands!',
      logoItem: [
        { logo: EmBankLogo, className: 'size-28' },
        { logo: HayDockLogo, className: 'size-28' },
        { logo: CloseBrothersLogo, className: 'size-28' },
        { logo: BibbyLogo, className: 'size-30' },
        { logo: UTBLogo, className: 'size-30' },
        { logo: CharlesDeanLogo, className: 'size-24' }
      ]
    },
    Dock: {
      sectionTitle: 'Trusted by leading brands!',
      logoItem: [
        { logo: EmBankLogo, className: 'size-28' },
        { logo: HayDockLogo, className: 'size-28' },
        { logo: UTBLogo, className: 'size-30' },
        { logo: CharlesDeanLogo, className: 'size-24' },
        { logo: AutoNationLogo, className: 'size-24' }
      ]
    }
  }
  return (
    productName && logosByProduct[productName]
      ? (
                <Box theme={theme} pt={{ xs: 5, md: 8 }} pb={{ xs: 5, md: 8 }}>
                    <BrandsLogoComponent {...logosByProduct[productName]} />
                </Box>
        )
      : null
  )
}
