import { type UseMutationResult, type UseQueryResult, useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { QueryKeys } from '@shared/constants'
import { type IFilter, type IUser } from '@shared/typings'
const BASE_URL = process.env.BASE_URL

export const USERAPI = axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
  headers: {
    'Content-type': 'application/json'
  }
})

export const getAllUserFilterFromServer = async (
  pageNumber: number,
  pageSize: number,
  email?: string,
  country?: string,
  fullName?: string,
  companyName?: string,
  status?: string,
  productName?: string,
  allowedTransactions?: number,
  transactionPerformed?: number,
  createdAt?: string,
  apiKeyType?: string,
  isActive?: boolean
): Promise<IUser> => {
  let queryString = `page_number=${pageNumber}&page_size=${pageSize}`
  if (email !== null && email !== '') {
    queryString = queryString.concat(`&code=${email as string}`)
  }
  if (country !== null && country !== '') {
    queryString = queryString.concat(`&description=${country as string}`)
  }
  if (fullName !== null && fullName !== '') {
    queryString = queryString.concat(`&full_name=${fullName as string}`)
  }
  if (companyName !== null && companyName !== '') {
    queryString = queryString.concat(`&company_name=${companyName as string}`)
  }

  if (productName !== null && productName !== '') {
    queryString = queryString.concat(`&product_name=${productName as string}`)
  }
  if (status !== null && status !== '') {
    queryString = queryString.concat(`&status=${status as string}`)
  }
  if (allowedTransactions !== null) {
    queryString = queryString.concat(
      `&allowed_transactions=${allowedTransactions as number}`
    )
  }
  if (transactionPerformed !== null) {
    queryString = queryString.concat(
      `&transaction_performed=${transactionPerformed as number}`
    )
  }
  if (apiKeyType !== null && apiKeyType !== '') {
    queryString = queryString.concat(`&api_key_type=${apiKeyType as string}`)
  }
  if (createdAt !== null && createdAt !== '') {
    queryString = queryString.concat(`&created_at=${createdAt as string}`)
  }
  if (isActive !== null) {
    queryString = queryString.concat(`&is_active=${isActive?.toString() as string}`)
  }
  return await USERAPI.get('/signup/track-ums-users?'.concat(queryString)).then(
    (response) => {
      return response.data
    }
  )
}

export const UseQueryGetAllUserTypeFilter = (
  pageNumber: number,
  pageSize: number
): UseQueryResult<IUser, unknown> => {
  return useQuery(QueryKeys.GET_ALL_USER_CONDITIONS_FILTER, async () => {
    return await getAllUserFilterFromServer(pageNumber, pageSize).then((data) => {
      return data
    })
  })
}

export const UseQueryGetAllUserTypesByParams = (): UseMutationResult<IUser, unknown, IFilter, unknown> => {
  const queryClient = useQueryClient()
  return useMutation<IUser, unknown, IFilter, unknown>(
    QueryKeys.GET_ALL_USER_CONDITIONS_FILTER,

    async (params: IFilter) => {
      return await getAllUserFilterFromServer(
        params.pageNumber,
        params.pageSize,
        params.email,
        params.country,
        params.full_name,
        params.company_name,
        params.status,
        params.product_name,
        params.allowed_transactions,
        params.transaction_performed,
        params.created_at,
        params.api_key_type,
        params.is_active
      ).then((data) => {
        return data
      })
    },
    {
      onSuccess: async (response: any) => {
        queryClient.setQueryData(
          [QueryKeys.GET_ALL_USER_CONDITIONS_FILTER],
          response
        )
      }
    }
  )
}
