export const className = {
  signinContinueBtn: 'gt-signin-continue-btn',
  loginContinueBtn: 'gt-login-continue-btn',
  userProfileContinueBtn: 'gt-user-profile-continue-btn',
  tenantProfileContinueBtn: 'gt-tenant-profile-continue-btn',
  signupDoneBtn: 'gt-signup-done-btn',
  agreeAndContinueBtn: 'gt-agree-and-continue-btn',
  freeSubscriptionConfirmation: 'gt-free-subscription-confirmation',
  contactUsSubmitBtn: 'gt-contact-us-submit-btn',
  paymentPayBtn: 'gt-payment-pay-btn',
  payConfirmationBtn: 'gt-pay-confirmation-btn',
  subscribeBtn: 'gt-subscribe-btn',
  unsubscribeBtn: 'gt-unsubscribe-btn',
  downgradeBtn: 'gt-downgrade-btn',
  upgradeBtn: 'gt-upgrade-btn',
  customPlanSubmitBtn: 'gt-custom-plan-submit-btn',
  homePageProduct: 'gt-product',
  productListing: 'product-listing',
  productDetail: 'gt-product-detail',
  productDetailVideoBtn: 'gt-product-detail-video-btn',
  productDetailContactUsBtn: 'gt-product-detail-contact-us-btn',
  productDetailViewDocBtn: 'gt-product-detail-view-documentation-btn',
  productDetailDownloadPRPackBtn: 'gt-product-detail-download-pr-pack-btn',
  proceedToCheckout: 'gt-proceed-to-checkout-btn',
  checkoutAddNewCard: 'gt-checkout-add-new-card-btn',
  checkoutAddNewCardSaveBtn: 'gt-checkout-add-new-card-save-btn',
  checkoutAddBillingAddress: 'gt-checkout-add-billing-address-btn',
  checkoutAddBillingAddressSaveBtn: 'gt-checkout-add-billing-address-save-btn',
  checkoutPayNowBtn: 'gt-checkout-pay-now-btn',
  checkoutCongratulaionsPopupContinueBtn: 'gt-checkout-congratulation-continue-btn',
  footerDocumentationBtn: 'gt-footer-documentation-btn',
  footerSupportBtn: 'gt-footer-support-btn',
  footerContactBtn: 'gt-footer-contact-btn',
  headerContactUsBtn: 'gt-header-contact-us-btn',
  headerBlogBtn: 'gt-header-blog-btn',
  headerHomeBtn: 'gt-header-home-btn',
  headerProductsBtn: 'gt-header-products-btn',
  subscribedProductsUpgradeBtn: 'gt-subscribed-product-upgrade',
  ellipsisDocumentationOption: 'gt-ellipses-documentation',
  ellipsisPlaygroundOption: 'gt-ellipses-playground',
  ellipsisGoToAppOption: 'gt-ellipses-go-to-app',
  ellipsisUnsubscribe: 'gt-ellipses-unsubscribe',
  ellipsisUserManualOption: 'gt-ellipses-user-manual',
  updatePaymentMethod: 'ht-update-payment-method'
}
