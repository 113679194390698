export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z]).{6,35}$/
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const NUMBER_LENGTH_REGEX = /^.{1,15}$/
export const CONTACT_NUMBER_REGEX = /^.{6,25}$/
export const ALPHABETS_REGEX = /^[A-Za-z ]+$/
export const NUMBERS_REGEX = /^[0-9]*$/
export const WHITE_SPACE_REGEX = /^(?=\s*\S)/
export const MASTER_CARD_REGEX = /^(?:5[1-5])$/ // checks only first 2 digits of card number
export const MASTER_CARD_REGEX_2_SERIES_5 = /^(?:5[1-5])$/ // checks only first 2 digits of card number
export const MASTER_CARD_REGEX_2_SERIES_2 = /^(?:2[2-7])$/ // checks only first 2 digits of card number
export const MASTER_CARD_REGEX_SERIES_5 = /^5[1-5][0-9]{14}$/ // checks whole card number for series 5
export const MASTER_CARD_REGEX_SERIES_2 = /^2[2-7][0-9]{14}$/ // checks whole card number for series 2
export const VISA_CARD_REGEX_2 = /^(?:4[0-9])$/ // checks only first 2 digits of card number
export const VISA_CARD_REGEX = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/ // checks whole card number
/* eslint prefer-regex-literals: "error" */
export const RE_DIGIT = new RegExp(/^\w+$/)
export const ZIP_CODE_REGEX = /^.{1,5}$/
export const LENGTH_REGEX = (length: number): RegExp => new RegExp(`^.{1,${length}}$`)
