import { useEffect, type FC, useMemo, type ReactNode } from 'react'
import { Icon, Box, Menu, Typography, Button, Skeleton } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import {
  useAddToCart,
  useRemoveFromCart
} from '@modules/product/services/cart/cartService'
import { useStoreContext } from 'store/storeContext'
import { useGetProductPricing } from '@modules/product'
import { convertToAmountWithOutSign, getItem, stripeAmount } from 'shared'
import { IS_LOGGED_IN, productRemoved } from '@shared/constants'
import { type ICart } from '@shared/typings'

interface ICheckoutItemListProps {
  buttonText: string
  onClickHandle: () => void
  cart: ICart[]
  buttonDisplay: boolean
  setTotalPrice?: (totalPrice: number) => void
  isDisable?: boolean
  className?: string
  startIcon?: ReactNode
  isLoading?: boolean
}

export const CheckoutItemList: FC<ICheckoutItemListProps> = ({
  cart,
  onClickHandle,
  buttonText,
  buttonDisplay,
  setTotalPrice,
  isDisable,
  className,
  startIcon,
  isLoading
}) => {
  const isLoggedIn = getItem(IS_LOGGED_IN) as unknown as boolean
  const theme = useTheme()
  const { mutate: addToCart } = useAddToCart()
  const { mutate: userRemoveFromCart } = useRemoveFromCart()
  const { isLoading: isPricingLoading } =
    useGetProductPricing()

  const {
    actions: { removeFromCart, updateCart, setToaster }
  }: any = useStoreContext()

  const totalPrice = useMemo(
    () => cart?.reduce((agg, item) => agg + item.amount, 0),
    [cart]
  )

  useEffect(() => {
    setTotalPrice?.(totalPrice)
  }, [totalPrice])

  interface IProductName {
    optionText: string
    productId: number
    price_id: number
    id: number
  }

  const getProductNames = (item: any): IProductName[] => {
    const arr: IProductName[] = []
    if (!isPricingLoading) {
      item.all_packages?.forEach((element) => {
        arr.push({
          optionText: element.name,
          productId: element.product_id,
          price_id: element.id,
          id: item.id ?? 0
        })
      })
      return arr
    }
    return []
  }

  const handlePackageSelection = (option): void => {
    if (isLoggedIn) {
      const body = { product_id: option.productId, price_id: option.price_id }
      addToCart(body, {
        onSuccess (data) {
          console.log('success', data)
        },
        onError (error) {
          console.log(error)
        }
      })
      return
    }
    const { all_packages: allPackages, account_type: accountType, product_name: productName, product_id: productId } =
      cart?.find((item) => item.product_id === option.productId) as ICart

    const { id, name, amount } = allPackages?.find(
      (item) => item.name === option.optionText
    )
    const obj = {
      account_type: accountType,
      product_name: productName,
      product_id: productId,
      price_id: id,
      package_name: name,
      amount,
      all_packages: allPackages
    }
    updateCart(obj)
  }

  const handleRemoveFromCart = (cartId: number, productId: number): void => {
    if (isLoggedIn) {
      userRemoveFromCart(cartId, {
        onSuccess () {
          removeFromCart(productId)
          setToaster({
            isDisplay: true,
            message: productRemoved,
            type: ''
          })
        },
        onError (error) {
          console.log(error)
        }
      })
    } else {
      removeFromCart(productId)
      setToaster({
        isDisplay: true,
        message: productRemoved,
        type: ''
      })
    }
  }
  return (
    <>
      {isLoading === true
        ? <><Box
        theme={theme}
        className="cart-item"
        display={'flex'}
        flexDirection={'column'}
      >
        <Box theme={theme} className="prd-info" display={'flex'}>
          <Skeleton width={'100%'} height={20} theme={theme} sx={{ marginBottom: '4px' }}/>
        </Box>
        <Box theme={theme} className="prd-price-action" display={'flex'}>
        <Box theme={theme} className="pkg-select" flexGrow={1}>
          <Skeleton width={'100%'} height={20} theme={theme} />
        </Box>
      </Box>
      </Box>
      </>
        : cart?.map((item: any) => {
          return <Box
          key={item.price_id}
          theme={theme}
          className="cart-item"
          display={'flex'}
          flexDirection={'column'}
        >
          <Box theme={theme} className="prd-info" display={'flex'}>
            <Typography
              className="prd-name"
              theme={theme}
              component="span"
              variant="body2"
              flexGrow={1}
            >
              {item?.product_name}
            </Typography>
            <Typography
              flexShrink={0}
              className="prd-price"
              textAlign={'right'}
              display={'block'}
              theme={theme}
              component="span"
              variant="body2"
            >
              ${convertToAmountWithOutSign(stripeAmount(item?.amount))}
            </Typography>
          </Box>
          <Box theme={theme} className="prd-price-action" display={'flex'}>
            <Box theme={theme} className="pkg-select" flexGrow={1}>
              <Menu
                theme={theme}
                disablePortal={true}
                options={getProductNames(item)}
                handleSelect={handlePackageSelection}
                render={(cb) => (
                  <>
                    <Button
                      theme={theme}
                      defaultBtn
                      text={item?.package_name}
                      endIcon={<Icon name="ChevronDown" />}
                      onClick={cb}
                    />
                  </>
                )}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              />
            </Box>
            <Typography
              flexShrink={0}
              className="prd-action"
              textAlign={'right'}
              display={'block'}
              theme={theme}
              component="small"
              variant="caption"
            >
              <a
                href="javascript:void(0)"
                onClick={() => { handleRemoveFromCart(item.id, item.product_id) }}
                className="link"
              >
                Remove
              </a>
            </Typography>
          </Box>
        </Box>
        })}

      {isLoading === true
        ? <Box
         theme={theme}
         className="cart-item"
         display={'flex'}
         flexDirection={'column'}
       >
         <Box theme={theme} className="price-item" display={'flex'}>
          <Skeleton width={'100%'} height={20} theme={theme} />
         </Box>
         <Box theme={theme} className="price-item" display={'flex'}>
          <Skeleton width={'100%'} height={20} theme={theme} />
         </Box>
         <Box theme={theme} className="price-item" display={'flex'}>
          <Skeleton width={'100%'} height={20} theme={theme} />
         </Box>
       </Box>
        : <Box
        theme={theme}
        className="cart-item"
        display={'flex'}
        flexDirection={'column'}
      >
        <Box theme={theme} className="price-item" display={'flex'}>
          <Typography
            flexGrow={1}
            theme={theme}
            className="text-muted"
            component="span"
            variant="body2"
          >
            Sub Total
          </Typography>
          <Typography
            flexShrink={0}
            theme={theme}
            textAlign={'right'}
            className="text-dark"
            component="span"
            variant="body2"
          >
            ${convertToAmountWithOutSign(stripeAmount(totalPrice))}
          </Typography>
        </Box>
        <Box theme={theme} className="price-item" display={'flex'}>
          <Typography
            flexGrow={1}
            theme={theme}
            className="text-muted"
            component="span"
            variant="body2"
          >
            Discount (0%)
          </Typography>
          <Typography
            flexShrink={0}
            theme={theme}
            textAlign={'right'}
            className="text-dark"
            component="span"
            variant="body2"
          >
            $00.00
          </Typography>
        </Box>
        <Box theme={theme} className="price-item" display={'flex'}>
          <Typography
            flexGrow={1}
            theme={theme}
            className="text-muted"
            component="span"
            variant="body2"
          >
            Tax (0%)
          </Typography>
          <Typography
            flexShrink={0}
            theme={theme}
            textAlign={'right'}
            className="text-dark"
            component="span"
            variant="body2"
          >
            $00.00
          </Typography>
        </Box>
      </Box>}
      {buttonDisplay && (
        <>
          {isLoading === true
            ? <Box
            theme={theme}
            className="cart-item"
            display={'flex'}
            flexDirection={'column'}
            >
            <Box theme={theme} className="price-total" display={'flex'}>
              <Skeleton width={'100%'} height={24} theme={theme} />
            </Box>
            </Box>
            : <Box
            theme={theme}
            className="cart-item"
            display={'flex'}
            flexDirection={'column'}
          >
            <Box theme={theme} className="price-total" display={'flex'}>
              <Typography
                flexGrow={1}
                theme={theme}
                className="text-dark total-price-label"
                component="span"
                variant="body2"
              >
                Total Payable:
              </Typography>
              <Typography
                flexShrink={0}
                theme={theme}
                textAlign={'right'}
                className="text-primary total-value"
                component="h4"
                variant="h4"
              >
                <span className="text-dark">$ </span>
                {convertToAmountWithOutSign(stripeAmount(totalPrice) ?? 0)}
              </Typography>
            </Box>
          </Box>}
          {isLoading === true
            ? <Box theme={theme} mt={3}>
              <Skeleton width={'100%'} height={49.6} theme={theme} />
            </Box>
            : <Box theme={theme} mt={3}>
            <Button
              onClick={onClickHandle}
              theme={theme}
              primary
              fullWidth
              className={className}
              text={buttonText}
              disabled={isDisable}
              startIcon={startIcon}
            />
          </Box>}
        </>
      )}
    </>
  )
}
