import { useQuery } from 'react-query'
import { type AppError } from 'single-spa'
import { type IProductConnects } from '@shared/typings'
import Http from '@shared/helper/http-api'

const BASE_URL = process.env.BASE_URL
export const PRODUCT_CONNECT = 'signup/get-product-connect'

export const useGetProductConnect = (productId: number | undefined): {
  data: IProductConnects[] | undefined
  error: AppError | unknown
} => {
  const url = `${PRODUCT_CONNECT}/${productId}`
  const { data, error } = useQuery<IProductConnects[]>(
    [PRODUCT_CONNECT, productId],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(url)
    },
    { refetchOnWindowFocus: false, enabled: Boolean(productId) }
  )
  return { data, error }
}
