import Http from '@shared/helper/http-api'
import { type IWaitList } from '@shared/typings'
import { type UseMutateFunction, useMutation } from 'react-query'

const BASE_URL = process.env.BASE_URL
const WAIT_LIST = 'signup/product-wishlist'

export const useJoinWaitList = (): {
  data: IWaitList | undefined
  isLoading: boolean
  mutate: UseMutateFunction<IWaitList, any, IWaitList, unknown>
  error: string
} => {
  const { data, isLoading, mutate, error } = useMutation<IWaitList, string | undefined, IWaitList>(
    async (body) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(WAIT_LIST, body)
    }
  )
  return { data, isLoading, mutate, error: (error as any)?.message }
}
