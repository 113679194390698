import { ElementsConsumer } from '@stripe/react-stripe-js'
import { useTheme } from '@mui/material'
import { Box, Grid } from '@ntpkunity/controls'
import { CardSection } from './cardComponent'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'
import { useModal } from 'react-modal-hook'
import { useStoreContext } from 'store/storeContext'
import { PaymentCard } from './paymentCards'
import { PaymentInvoicePreview } from '../index'
import { messages } from '../../pages'
import { type IAddOns, type ILocation } from '@shared/typings'
import {
  networkError,
  paidConfirmationMessage,
  paymentDeclined,
  className,
  PRODUCT_PRICING_ID,
  PAID,
  STRIPE_USER_CREATED,
  Product
} from '@shared/constants'
import {
  ButtonType,
  DialogHeading,
  IconType,
  AccountType,
  ConfirmationDialog,
  getItem,
  getSubstring
  , convertToDecimal, setItem
} from 'shared'
import { AddOns } from '@modules/addOns/pages'
import {
  useFreeUserCardPayment,
  usePaidUserCardPayment
  , CardFieldsWrap, PaymentBoxWrap
} from '@modules/paymentPlan'
import {
  useGetSubscriptions,
  useGetProductById,
  useGetProductPricingById
} from '@modules/product'
import { type FC, useEffect, useMemo, useState } from 'react'
import { ACCOUNT_TYPE } from '@shared/constants/userProfile'

interface ICheckoutFormProps {
  stripe
  amount: number
  isPaymentPlan: boolean
  selectedAddOns
  totalPayable: number
}

const CheckoutForm: FC<ICheckoutFormProps> = ({
  stripe,
  amount,
  isPaymentPlan,
  selectedAddOns,
  totalPayable
}) => {
  const theme = useTheme()
  const [, setIsPaid] = useState(false)
  const navigate = useNavigate()
  const { state } = useLocation()
  const {
    actions: { setToaster }
  } = useStoreContext()
  const { mutate: paidUserCardPayment, isLoading: paidLoading } =
    usePaidUserCardPayment()
  const { mutate: freeUserCardPayment, isLoading: freeLoading } =
    useFreeUserCardPayment()
  const { data: products } = useGetSubscriptions()

  const formMethod = useForm({
    defaultValues: {
      cardHolderName: '',
      number: '',
      expiry_date: '',
      cvc: ''
    }
  })

  const { handleSubmit, watch } = formMethod
  const formValues = watch()

  const submit: SubmitHandler<any> = () => {
    if (!isPaymentPlan) return
    const { number, expiry_date: expiryDate, cvc } = formValues
    const expiryMonth = getSubstring(expiryDate, 0, 2)
    const expiryYear = getSubstring(expiryDate, 2, 4)
    const data = {
      cardDetails: {
        type: messages.type,
        number,
        exp_month: expiryMonth,
        exp_year: expiryYear,
        cvc
      },
      add_ons: selectedAddOns,
      productPricingId:
        (state as ILocation)?.packageId ?? getItem(PRODUCT_PRICING_ID),
      subscriptionId: products?.filter(
        (product) =>
          product.package_type === AccountType.FREE &&
          product.product_id === +(state as ILocation)?.productId
      )[0]?.id
    }
    if ((state as ILocation)?.accountType === AccountType.FREE) {
      freeUserCardPayment(data, {
        onSuccess (data) {
          const { msg } = data
          if (msg === networkError) {
            setToaster({
              isDisplay: true,
              message: msg,
              type: ''
            })
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          } else if (!data.success) {
            showAlert()
          } else {
            setItem(STRIPE_USER_CREATED, true)
            setIsPaid(true)
            setItem(PAID, true)
            setItem(PRODUCT_PRICING_ID, (state as ILocation)?.packageId)
            setItem(ACCOUNT_TYPE, AccountType.PAID)
            show()
          }
        }
      })
    } else {
      const paidData = {
        cardDetail: data.cardDetails,
        add_ons: selectedAddOns,
        productPricingId: getItem(PRODUCT_PRICING_ID)
      }
      paidUserCardPayment(paidData, {
        onSuccess (data) {
          const { msg } = data
          if (msg === networkError) {
            setToaster({
              isDisplay: true,
              message: msg,
              type: ''
            })
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          } else if (!data.success) {
            showAlert()
          } else {
            setItem(STRIPE_USER_CREATED, true)
            setIsPaid(true)
            setItem(PAID, true)
            show()
          }
        }
      })
    }
  }

  const onConfirm = (): void => { navigate(APP_ROUTES.CUSTOMER_ACCOUNT) }

  const [show] = useModal(
    () => (
      <ConfirmationDialog
        btnClassName={`${className.payConfirmationBtn}-${
          (state as ILocation)?.packageId
        }`}
        buttonText={ButtonType.CONTINUE}
        iconName={IconType.SUCCESS}
        title={DialogHeading.CONGRATULATIONS}
        message={paidConfirmationMessage}
        onConfirm={onConfirm}
      />
    ),
    []
  )

  const [showAlert, hide] = useModal(
    () => (
      <ConfirmationDialog
        buttonText={ButtonType.CLOSE}
        iconName={IconType.ALERT}
        title={DialogHeading.ERROR}
        message={paymentDeclined}
        onConfirm={hide}
      />
    ),
    []
  )

  useEffect(() => {
    setIsPaid(Boolean(getItem(PAID)) ?? false)
    window.addEventListener('storage', onStorageUpdate)
    return () => {
      window.removeEventListener('storage', onStorageUpdate)
    }
  }, [])

  const onStorageUpdate = (e): void => { setIsPaid(JSON.parse(e.newValue).paid) }

  return (
    <>
      {// eslint-disable-next-line @typescript-eslint/no-misused-promises
      }<form onSubmit={handleSubmit(submit)}>
        <CardSection formMethod={formMethod} />
        <Box theme={theme} textAlign={{ xs: 'center', sm: 'left' }}>
          <button
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            disabled={!stripe || freeLoading || paidLoading || (Number.isNaN((+amount)))}
            className={`btn btn-primary ${className.paymentPayBtn}-${
              (state as ILocation)?.productPricingId
            }`}
          >
            {isPaymentPlan
              ? Boolean(freeLoading) || Boolean(paidLoading)
                ? messages.button.wait
                : `${messages.button.pay} $${
                    (!Number.isNaN((+amount))) ? convertToDecimal(+amount / 100 + totalPayable) : 0
                  }`
              : messages.button.save}
          </button>
        </Box>
      </form>
    </>
  )
}

interface IInjectedCheckoutFormProps {
  isPaymentPlan: boolean
}

export const InjectedCheckoutForm: FC<IInjectedCheckoutFormProps> = ({ isPaymentPlan }) => {
  const theme = useTheme()
  const { state } = useLocation()
  const { data: productPricing } = useGetProductPricingById(
    (state as ILocation)?.accountType === AccountType.FREE
      ? (state as ILocation)?.packageId
      : getItem(PRODUCT_PRICING_ID)
  )
  const { data: product } = useGetProductById((state as ILocation)?.productId)
  const { name: productName } = product ?? {}
  const { name, amount } = productPricing ?? {}
  const [selectedAddOns, setSelectedAddOns] = useState<IAddOns[]>([])

  const totalPayable = useMemo(
    () => selectedAddOns.reduce((agg, addon) => agg + addon.value / 100, 0),
    [selectedAddOns]
  )

  return (
    <PaymentBoxWrap theme={theme} className="card-area-wrap">
      <CardFieldsWrap theme={theme} className="card-fields-wrap">
        <Grid theme={theme} container item spacing={3}>
          <Grid
            item
            theme={theme}
            xs={12}
            sm={12}
            md={7}
            lg={8}
            order={{ md: 1, xs: 2 }}
          >
            <ElementsConsumer>
              {({ stripe }) => (
                <CheckoutForm
                  totalPayable={totalPayable}
                  stripe={stripe}
                  amount={amount as number}
                  isPaymentPlan={isPaymentPlan}
                  selectedAddOns={selectedAddOns}
                />
              )}
            </ElementsConsumer>
            <Box theme={theme}>
              {productName?.toLowerCase() === Product.FLEX.toLowerCase() && (
                <AddOns
                  selectedAddOns={selectedAddOns}
                  setSelectedAddOns={setSelectedAddOns}
                />
              )}
            </Box>
          </Grid>
          <Grid
            item
            theme={theme}
            xs={12}
            sm={12}
            md={5}
            lg={4}
            order={{ md: 2, xs: 1 }}
          >
            <Grid theme={theme} container item spacing={3}>
              <Grid item theme={theme} xs={12}>
                {isPaymentPlan
                  ? (
                  <PaymentInvoicePreview
                    totalPayable={totalPayable}
                    amount={amount as number}
                    packageName={name as string}
                    productName={productName as string}
                    selectedAddOns={selectedAddOns}
                  />
                    )
                  : (
                  <PaymentCard />
                    )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardFieldsWrap>
    </PaymentBoxWrap>
  )
}
