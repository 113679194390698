import { useStoreContext } from 'store/storeContext'
import { useEffect } from 'react'

export const LOCAL_STORAGE_ITEM_KEY = 'settings'
export const SESSION_STORAGE_ITEM_KEY = 'unity'

/** Local Storage Hook */
interface IuseLocalStorage {
  storage: any
  setItem: (key: string, data: any) => void
  clearStorage: () => void
  removeItem: (key: string) => void
}

export const useLocalStorage = (): IuseLocalStorage => {
  const {
    states: { localStorage: storage },
    actions: { setLocalStorage }
  } = useStoreContext()

  useEffect(() => {
    setLocalStorage(JSON.parse((localStorage as any).getItem(LOCAL_STORAGE_ITEM_KEY)))
  }, [])

  const setItem = (key: string, data: any): void => {
    const prevData = JSON.parse((localStorage as any).getItem(LOCAL_STORAGE_ITEM_KEY))
    const newData = { ...prevData, [key]: data }

    localStorage.setItem(LOCAL_STORAGE_ITEM_KEY, JSON.stringify(newData))
    setLocalStorage(newData)
  }

  const clearStorage = (): void => {
    localStorage.removeItem(LOCAL_STORAGE_ITEM_KEY)
  }
  const removeItem = (key: string): void => {
    const prevData = JSON.parse((localStorage as any).getItem(LOCAL_STORAGE_ITEM_KEY))
    const newData = { ...prevData, [key]: undefined }

    localStorage.setItem(LOCAL_STORAGE_ITEM_KEY, JSON.stringify(newData))
    setLocalStorage(newData)
  }

  return {
    storage,
    setItem,
    clearStorage,
    removeItem
  }
}

export class LocalStorage {
  getItemByKey (key: string): any {
    const data = JSON.parse((localStorage as any).getItem(LOCAL_STORAGE_ITEM_KEY))
    if (Object.keys(data ?? {}).length > 0) return data[key]
    return null
  }

  setItembyKey (key: string, value: any): void {
    const data = JSON.parse((localStorage as any).getItem(LOCAL_STORAGE_ITEM_KEY))
    localStorage.setItem(
      LOCAL_STORAGE_ITEM_KEY,
      data !== undefined
        ? JSON.stringify({ ...data, [key]: value })
        : JSON.stringify({ [key]: value })
    )
  }

  removeItembyKey (key: string): void {
    const data = JSON.parse((localStorage as any).getItem(LOCAL_STORAGE_ITEM_KEY))
    localStorage.setItem(
      LOCAL_STORAGE_ITEM_KEY,
      JSON.stringify({ ...data, [key]: undefined })
    )
  }

  clearStorage (): void {
    localStorage.removeItem(LOCAL_STORAGE_ITEM_KEY)
  }
}

/** Session Storage Hook */
export const useSessionStorage = (): IuseLocalStorage => {
  const {
    states: { sessionStorage: storage },
    actions: { setSessionStorage }
  } = useStoreContext()

  useEffect(() => {
    setSessionStorage(
      JSON.parse((sessionStorage as any).getItem(SESSION_STORAGE_ITEM_KEY))
    )
  }, [])

  const setItem = (key: string, data: any): void => {
    const prevData = JSON.parse(
      (sessionStorage as any).getItem(SESSION_STORAGE_ITEM_KEY)
    )
    const newData = { ...prevData, [key]: data }

    sessionStorage.setItem(SESSION_STORAGE_ITEM_KEY, JSON.stringify(newData))
    setSessionStorage(newData)
  }

  const clearStorage = (): void => {
    sessionStorage.removeItem(SESSION_STORAGE_ITEM_KEY)
  }
  const removeItem = (key: string): void => {
    const prevData = JSON.parse(
      (sessionStorage as any).getItem(SESSION_STORAGE_ITEM_KEY)
    )
    const newData = { ...prevData, [key]: undefined }

    sessionStorage.setItem(SESSION_STORAGE_ITEM_KEY, JSON.stringify(newData))
    setSessionStorage(newData)
  }

  return {
    storage,
    setItem,
    clearStorage,
    removeItem
  }
}
