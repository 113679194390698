import { type ReactElement } from 'react'
import { useTheme } from '@mui/material'
import {
  Box,
  Grid,
  Typography,
  Button,
  Input
} from '@ntpkunity/controls'
import EllipseDesktop from '@shared/assets/images/newsletter-ellipse-desktop.svg'
import EllipseMobile from '@shared/assets/images/newslettere-ellipse-mobile.svg'
import { NewsLetterWrap } from './newsLetterStyle'
import { Controller, type SubmitHandler, useForm } from 'react-hook-form'
import { useSubscribeNewsLetter } from '@modules/homePage/services/products/productsService'
import { useStoreContext } from 'store/storeContext'
import { FadeAnimation } from '@shared/components'

const messages = {
  subscribedButtonText: 'Subscribe Now',
  subscribedNewsLetter: 'Thank you! You have successfully subscribed'
}
export const NewsLetterComponent = (): ReactElement => {
  const theme = useTheme()

  const { handleSubmit, control, reset } = useForm()

  const { mutate: saveSubscribedNewsLetter } = useSubscribeNewsLetter()

  const {
    actions: { setToaster }
  } = useStoreContext()

  const submit: SubmitHandler<any> = (data) => {
    saveSubscribedNewsLetter(data, {
      onSuccess () {
        reset({ email: '' })
        setToaster({
          isDisplay: true,
          message: messages.subscribedNewsLetter,
          type: ''
        })
      }
    })
  }

  return (
    <NewsLetterWrap theme={theme} className="newsletter-wrap">
      <Box theme={theme} className="inner-content">
        <Box theme={theme} className="circle-shape"></Box>
        <Grid theme={theme} container item spacing={{ md: 6, xs: 6, lg: 15 }} alignItems={'center'}>
          <Grid theme={theme} item md={5} lg={6} xs={12}>
            <FadeAnimation>
              <Box theme={theme} className="newsletter-content">
                  <Typography data-testid="animated-element" theme={theme} variant="h2" component='h2' className='text-white text-h2'>Subscribe to our newsletter</Typography>
                  <Typography theme={theme} variant="body2" component='span' display={'block'} mt={2} className='text-muted text-body2'>
                  Stay ahead of the curve. Sign up for our newsletter to receive the latest industry insights, exclusive Appex Now updates, and innovative strategies right in your inbox.
                  </Typography>
              </Box>
            </FadeAnimation>
          </Grid>

          <Grid theme={theme} item md={7} lg={6} xs={12}>
            <FadeAnimation>
              {// eslint-disable-next-line @typescript-eslint/no-misused-promises
              }<form onSubmit={handleSubmit(submit)}>
                <Box theme={theme} className="subscribe-form-wrap">
                  <Controller
                    name={'email'}
                    control={control}
                    render={({ field }) => (
                      <Input
                        theme={theme}
                        fullWidth
                        type={'email'}
                        placeholder={'Enter your email address...'}
                        {...field}
                      />
                    )}
                  />
                  <Button theme={theme} primary text={messages.subscribedButtonText} type={'submit'} />
                </Box>
              </form>
              <Typography mt={{ md: 1, xs: 2 }} theme={theme} variant="caption" component='small' display={'block'} className="text-muted">Pure information and no spam. Unsubscribe any time.</Typography>
            </FadeAnimation>
          </Grid>
        </Grid>
        <Box theme={theme} className="combine-shapes">
          <Box theme={theme} className="circle-blur"></Box>
          <Box theme={theme} className="ellipse">
            <img src={EllipseDesktop} alt="" className='ellipse-desktop' />
            <img src={EllipseMobile} alt="" className='ellipse-mobile' />
          </Box>
        </Box>
      </Box>
    </NewsLetterWrap>
  )
}
