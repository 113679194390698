import { Grid, Typography, Box, Container } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { Footer, Header } from '@layout/index'
import { MainContent, ProfileHead } from './dashboardLayoutStyle'
import { HelpBox, Sidebar } from '../dashboard/components'
import { Outlet } from 'react-router-dom'
import { useStoreContext } from 'store/storeContext'
import { FIRST_NAME, LAST_NAME } from '@shared/constants'
import { type FC } from 'react'

export const DashboardLayout: FC = () => {
  const theme = useTheme()
  const {
    states: { userProfile }
  } = useStoreContext()
  return (
    <>
      <Header theme={theme} />
      <MainContent theme={theme} className="main-content">
        <Container theme={theme} maxWidth="lg" className="custom-container">
          <ProfileHead theme={theme} className="profile-head">
            <Typography
              className="text-white text-light text-h3"
              theme={theme}
              variant="h3"
              component="h3"
            >
              Hello!
            </Typography>
            <Typography
              className="text-white text-h2 text-capitalize"
              theme={theme}
              variant="h2"
              component="h2"
            >
              {`${userProfile?.[FIRST_NAME] ?? ''} ${
                userProfile?.[LAST_NAME] ?? ''
              }`}
            </Typography>
          </ProfileHead>
          <Box theme={theme} className="content-wrap" mt={{ xs: 0, md: 5 }}>
            <Grid theme={theme} container item spacing={{ xs: 3, md: 7 }}>
              <Grid theme={theme} item xs={12} md={4}>
                <Sidebar />
              </Grid>
              <Grid theme={theme} item xs={12} md={8}>
                <Outlet />
              </Grid>
            </Grid>
            <Box theme={theme} className="help-box-section" display={{ xs: 'block', md: 'none' }}>
              <HelpBox />
            </Box>
          </Box>
        </Container>
      </MainContent>
      <Footer />
    </>
  )
}
