import { Typography, Box, Switch } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { SectionContent } from './emailPrefrencesStyle'
import { type FC } from 'react'

export const EmailPrefrencesPage: FC = (props) => {
  const theme = useTheme()
  return (
    <>
      <SectionContent
        theme={theme}
        mt={{ xs: 0, md: 5 }}
        className="section-content"
      >
        <Typography
          theme={theme}
          variant="h3"
          component="h3"
          className="text-h3"
        >
          Appex Updates
        </Typography>
        <Typography
          theme={theme}
          variant="body2"
          component="span"
          className="text-muted text-body2"
        >
          Choose email content you recieve from us.
        </Typography>
        <Box theme={theme} className="list-wrap" mt={3}>
          <Box
            theme={theme}
            className="list-item"
            display={'flex'}
            alignItems={'center'}
          >
            <Box theme={theme} className="list-info" flexGrow={1}>
              <Typography
                theme={theme}
                variant="subtitle1"
                component="p"
                className="text-subtitle1"
              >
                Product Updates
              </Typography>
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                className="text-muted text-body2"
              >
                The latest product improvements and updates.
              </Typography>
            </Box>
            <Box theme={theme} className="list-action" flexShrink={0}>
              <Switch theme={theme} varient="basic" switchEnabled />
            </Box>
          </Box>
          <Box
            theme={theme}
            className="list-item"
            display={'flex'}
            alignItems={'center'}
          >
            <Box theme={theme} className="list-info" flexGrow={1}>
              <Typography
                theme={theme}
                variant="subtitle1"
                component="p"
                className="text-subtitle1"
              >
                Company News
              </Typography>
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                className="text-muted text-body2"
              >
                Stay in the loop with what’s happening at appex.
              </Typography>
            </Box>
            <Box theme={theme} className="list-action" flexShrink={0}>
              <Switch theme={theme} varient="basic" switchEnabled />
            </Box>
          </Box>
          <Box
            theme={theme}
            className="list-item"
            display={'flex'}
            alignItems={'center'}
          >
            <Box theme={theme} className="list-info" flexGrow={1}>
              <Typography
                theme={theme}
                variant="subtitle1"
                component="p"
                className="text-subtitle1"
              >
                Tips & Inspiration
              </Typography>
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                className="text-muted text-body2"
              >
                Tips and inspirational content on user research and appex.
              </Typography>
            </Box>
            <Box theme={theme} className="list-action" flexShrink={0}>
              <Switch theme={theme} varient="basic" switchEnabled />
            </Box>
          </Box>
          <Box
            theme={theme}
            className="list-item"
            display={'flex'}
            alignItems={'center'}
          >
            <Box theme={theme} className="list-info" flexGrow={1}>
              <Typography
                theme={theme}
                variant="subtitle1"
                component="p"
                className="text-subtitle1"
              >
                Deals
              </Typography>
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                className="text-muted text-body2"
              >
                Promotional offers to save you money.
              </Typography>
            </Box>
            <Box theme={theme} className="list-action" flexShrink={0}>
              <Switch theme={theme} varient="basic" switchEnabled />
            </Box>
          </Box>
          <Box
            theme={theme}
            className="list-item"
            display={'flex'}
            alignItems={'center'}
          >
            <Box theme={theme} className="list-info" flexGrow={1}>
              <Typography
                theme={theme}
                variant="subtitle1"
                component="p"
                className="text-subtitle1"
              >
                Community
              </Typography>
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                className="text-muted text-body2"
              >
                Stay up-to-date on appex&#39;s latest community events.
              </Typography>
            </Box>
            <Box theme={theme} className="list-action" flexShrink={0}>
              <Switch theme={theme} varient="basic" switchEnabled />
            </Box>
          </Box>
        </Box>
      </SectionContent>
      <SectionContent
        theme={theme}
        mt={{ xs: 4, md: 5 }}
        className="section-content"
      >
        <Typography
          theme={theme}
          variant="h3"
          component="h3"
          className="text-h3"
        >
          Your Activity
        </Typography>
        <Typography
          theme={theme}
          variant="body2"
          component="span"
          className="text-muted text-body2"
        >
          Choose emails about your products, and feedback for us.
        </Typography>
        <Box theme={theme} className="list-wrap" mt={3}>
          <Box
            theme={theme}
            className="list-item"
            display={'flex'}
            alignItems={'center'}
          >
            <Box theme={theme} className="list-info" flexGrow={1}>
              <Typography
                theme={theme}
                variant="subtitle1"
                component="p"
                className="text-subtitle1"
              >
                Product Usage
              </Typography>
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                className="text-muted text-body2"
              >
                Receive notifications about your usage. These include product
                updates (delete, archive, invite), confirmations, etc.
              </Typography>
            </Box>
            <Box theme={theme} className="list-action" flexShrink={0}>
              <Switch theme={theme} varient="basic" switchEnabled />
            </Box>
          </Box>
          <Box
            theme={theme}
            className="list-item"
            display={'flex'}
            alignItems={'center'}
          >
            <Box theme={theme} className="list-info" flexGrow={1}>
              <Typography
                theme={theme}
                variant="subtitle1"
                component="p"
                className="text-subtitle1"
              >
                Account Activity
              </Typography>
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                className="text-muted text-body2"
              >
                Get important notifications about you or activity you&#39;ve missed.
              </Typography>
            </Box>
            <Box theme={theme} className="list-action" flexShrink={0}>
              <Switch theme={theme} varient="basic" switchEnabled />
            </Box>
          </Box>
          <Box
            theme={theme}
            className="list-item"
            display={'flex'}
            alignItems={'center'}
          >
            <Box theme={theme} className="list-info" flexGrow={1}>
              <Typography
                theme={theme}
                variant="subtitle1"
                component="p"
                className="text-subtitle1"
              >
                New Messages
              </Typography>
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                className="text-muted text-body2"
              >
                Get Themsberg news, announcements, and product updates.
              </Typography>
            </Box>
            <Box theme={theme} className="list-action" flexShrink={0}>
              <Switch theme={theme} varient="basic" switchEnabled />
            </Box>
          </Box>
          <Box
            theme={theme}
            className="list-item"
            display={'flex'}
            alignItems={'center'}
          >
            <Box theme={theme} className="list-info" flexGrow={1}>
              <Typography
                theme={theme}
                variant="subtitle1"
                component="p"
                className="text-subtitle1"
              >
                Comment Notifications
              </Typography>
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                className="text-muted text-body2"
              >
                Receive notifications every time someone replies to a comment on
                a thread you&#39;re following on a report.
              </Typography>
            </Box>
            <Box theme={theme} className="list-action" flexShrink={0}>
              <Switch theme={theme} varient="basic" switchEnabled />
            </Box>
          </Box>
          <Box
            theme={theme}
            className="list-item"
            display={'flex'}
            alignItems={'center'}
          >
            <Box theme={theme} className="list-info" flexGrow={1}>
              <Typography
                theme={theme}
                variant="subtitle1"
                component="p"
                className="text-subtitle1"
              >
                Feedback
              </Typography>
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                className="text-muted text-body2"
              >
                Help us improve by sharing feedback with us.
              </Typography>
            </Box>
            <Box theme={theme} className="list-action" flexShrink={0}>
              <Switch theme={theme} varient="basic" switchEnabled />
            </Box>
          </Box>
        </Box>
      </SectionContent>
    </>
  )
}
