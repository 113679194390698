import { type AppError, noop, openInNextWindow } from '@shared/helper'
import { type ICustomFile, type FileType } from '@shared/typings'
import { type UseMutateFunction } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
export interface IState {
  filesMap: Map<string, FileType>
  deletedFilesKeys: string[]
  filesUploadedButNotSaved: string[]
}

export const ACTION_TYPE = {
  ADD_FILE: 'ADD_FILE',
  ADD_S3_FILE: 'ADD_S3_FILE',
  UPLOAD_START: 'UPLOAD_START',
  UPLOAD_PROGRESS: 'UPLOAD_PROGRESS',
  UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
  UPLOAD_ERROR: 'UPLOAD_ERROR',
  REMOVE_FILE: 'REMOVE_FILE',
  INITIAL_STATE: 'INITIAL_STATE'
}
export interface AddFilePayload {
  type: typeof ACTION_TYPE.ADD_FILE
  payload: FileType[]
}
export interface AddS3FilePayload {
  type: typeof ACTION_TYPE.ADD_S3_FILE
  payload: {
    file: FileType
    s3Location: string
    s3Key: string
  }
}
export interface UploadStartPayload {
  type: typeof ACTION_TYPE.UPLOAD_START
  payload: {
    key: string
    onUploadCancel: (key: string, file: any, index: number) => void
  }
}
export interface UploadProgressPayload {
  type: typeof ACTION_TYPE.UPLOAD_PROGRESS
  payload: {
    key: string
    progress: number
  }
}
export interface UploadSuccessPayload {
  type: typeof ACTION_TYPE.UPLOAD_SUCCESS
  payload: {
    key: string
    onRemoveFile: (key: string, file: any, index: number) => void
  }
}
export interface UploadErrorPayload {
  type: typeof ACTION_TYPE.UPLOAD_ERROR
  payload: {
    key: string
    file: FileType
    error: string
    onRemoveFile: (key: string, file: any, index: number) => void
    onRetryForError: (key: string, file: any, index: number) => void
  }
}
export interface RemoveFilePayload {
  type: typeof ACTION_TYPE.REMOVE_FILE
  payload: {
    key: string
    file: FileType
  }
}
export interface InitialStatePayload {
  type: typeof ACTION_TYPE.INITIAL_STATE
}

export type ActionTypes =
    | AddFilePayload
    | AddS3FilePayload
    | UploadStartPayload
    | UploadProgressPayload
    | UploadSuccessPayload
    | UploadErrorPayload
    | RemoveFilePayload
    | InitialStatePayload

export const INITIAL_STATE: IState = {
  filesMap: new Map(),
  deletedFilesKeys: [],
  filesUploadedButNotSaved: []
}

export const createFilePayload = (files: File[] | ICustomFile[], dispatch: React.Dispatch<any>): Array<{
  key: string
  file: File | ICustomFile
  abortController: AbortController
}> => {
  return files.map(file => ({
    key: file?.key ?? uuidv4(),
    file,
    abortController: new AbortController(),
    ...((file?.key ?? '').length > 0
      ? {
          onRemoveFile: (key: string, file: any, index: number): void => {
            dispatch({
              type: ACTION_TYPE.REMOVE_FILE,
              payload: { key, file }
            })
          }
        }
      : {})
  }))
}

export const addFilesToReducer = (files: File[] | ICustomFile[], dispatch: React.Dispatch<any>): Array<{
  key: string
  file: File | ICustomFile
  abortController: AbortController
}> => {
  const _files = createFilePayload(files, dispatch)
  dispatch({
    type: ACTION_TYPE.ADD_FILE,
    payload: _files
  })

  return _files
}

export const reducer = (state: IState, action: ActionTypes): IState => {
  switch (action.type) {
    case ACTION_TYPE.ADD_FILE:
    {
      const updatedFilesMap = new Map(state.filesMap)
      const { payload } = action as AddFilePayload
      payload.forEach(item => {
        const isFileTypeImage = item?.file?.type?.includes('image')
        const { file: { name, size, type } } = item
        updatedFilesMap.set(item.key, {
          ...item,
          file: {
            name,
            size,
            type,
            ...(item.file?.location ? { location: item.file?.location } : {}),
            ...(item.file?.key ? { key: item.file?.key } : {})
          },
          viewIcon: true,
          // preview: isFileTypeImage ? 'image' : 'fileIcon',
          preview: 'fileIcon',
          uploadingLabel: 'Uploading',
          error: false,
          isUploading: false
        })
      })
      return {
        ...state,
        filesMap: updatedFilesMap
      }
    }
    case ACTION_TYPE.ADD_S3_FILE:
    {
      const { payload } = action as AddS3FilePayload
      const { file, s3Location, s3Key } = payload
      const updatedFilesMap = new Map(state.filesMap)
      const prevFile = updatedFilesMap.get(file.key) as FileType
      const { file: { name, size, type } } = file
      updatedFilesMap.set(file.key, {
        ...prevFile,
        file: {
          name,
          size,
          type,
          location: s3Location,
          key: s3Key
        },
        isUploading: false
      })
      return {
        ...state,
        filesMap: updatedFilesMap,
        filesUploadedButNotSaved: [...state.filesUploadedButNotSaved, s3Key]
      }
    }
    case ACTION_TYPE.UPLOAD_START:
    {
      const { payload } = action as UploadStartPayload
      const { key, onUploadCancel } = payload
      const updatedFilesMap = new Map(state.filesMap)
      const prevFile = updatedFilesMap.get(key) as FileType
      updatedFilesMap.set(key, {
        ...prevFile,
        error: false,
        isUploading: true,
        uploadingProgress: 0,
        onUploadCancel
      })
      return {
        ...state,
        filesMap: updatedFilesMap
      }
    }
    case ACTION_TYPE.UPLOAD_PROGRESS:
    {
      const { payload } = action as UploadProgressPayload
      const { key, progress } = payload
      const updatedFilesMap = new Map(state.filesMap)
      const prevFile = updatedFilesMap.get(key) as FileType
      updatedFilesMap.set(key, {
        ...prevFile,
        uploadingProgress: progress
      })
      return {
        ...state,
        filesMap: updatedFilesMap
      }
    }
    case ACTION_TYPE.UPLOAD_SUCCESS:
    {
      const { payload } = action as UploadSuccessPayload
      const { key, onRemoveFile } = payload
      const updatedFilesMap = new Map(state.filesMap)
      const prevFile = updatedFilesMap.get(key) as FileType
      updatedFilesMap.set(key, {
        ...prevFile,
        isUploading: false,
        onRemoveFile,
        ...!prevFile?.file?.type?.includes('image') && {
          onViewFile: (key: string, file: any, index: number): void => {
            openInNextWindow(file?.file?.location ?? URL.createObjectURL(file?.file))
          }
        }
      })
      return {
        ...state,
        filesMap: updatedFilesMap
      }
    }
    case ACTION_TYPE.UPLOAD_ERROR:
    {
      const { payload } = action as UploadErrorPayload
      const { key, file, error, onRemoveFile, onRetryForError } = payload
      const updatedFilesMap = new Map(state.filesMap)
      const prevFile = updatedFilesMap.get(key) as FileType
      updatedFilesMap.set(key, {
        ...prevFile,
        isUploading: false,
        error: true,
        onRemoveFile,
        onRetryForError
      })
      return {
        ...state,
        filesMap: updatedFilesMap
      }
    }
    case ACTION_TYPE.REMOVE_FILE:
    {
      const { payload } = action as RemoveFilePayload
      const { key, file } = payload
      const updatedFilesMap = new Map(state.filesMap)
      updatedFilesMap.delete(key)
      return {
        ...state,
        filesMap: updatedFilesMap,
        deletedFilesKeys: (file?.file?.key ?? '').length > 0 ? [...state.deletedFilesKeys, file.file.key] : state.deletedFilesKeys
      }
    }
    case ACTION_TYPE.INITIAL_STATE:
    {
      return INITIAL_STATE
    }
    default:
      return state
  }
}
