import { type ReactElement, type FC } from 'react'
import { useTheme } from '@mui/material'
import { Box, Typography } from '@ntpkunity/controls'
import { WebinarDetailItem } from './webinarDetailItemStyle'
import PlayIcon from '../../../../shared/assets/images/play-circle.svg'
import parse from 'html-react-parser'
import { type IBlog } from '@shared/typings'
import { getThumbnail, formatedDate } from '@shared/helper'

interface WebinarDetailProps {
  webinar: IBlog | undefined
  socialShare?: boolean
}

export const WebinarDetailItemComponent: FC<WebinarDetailProps> = ({ webinar, socialShare }) => {
  const theme = useTheme()
  return (
    <WebinarDetailItem theme={theme} className="webinar-detail-item">
      <Box theme={theme} className='webinar-detail-page-head' textAlign={'center'}>
          <Typography theme={theme} variant='h1' component='h1' mt={2} className='webinar-title text-h2'>
            {webinar?.title}
          </Typography>
          <Box theme={theme} className='webinar-meta' display={'flex'} alignItems={'center'} gap={1} mt={2} justifyContent={'center'}>
            <Typography theme={theme} variant='body2' component='p' className='text-muted fw-medium text-body2'>{formatedDate(webinar?.created_at ?? '', 'DD MMM, YYYY')}</Typography>
            <span className='seperator'></span>
          <Typography theme={theme} variant='body2' component='p' className='text-muted fw-medium text-body2'>{webinar?.author}</Typography>
        </Box>
      </Box>
      <Box theme={theme} className='webinar-content'>
        <Box theme={theme} className='webinar-img' mb={{ xs: 5, md: 8 }}>
        <iframe
                width="100%"
                height="480"
                src={webinar?.image}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>

          {/* <iframe width="100%" height="675" src="https://www.youtube-nocookie.com/embed/61JEuaGo_y4?si=kF4ZNKqYMgv1VUVy" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe> */}
        </Box>
        <Box theme={theme} className='webinar-details-wrap'>
          <Box theme={theme} className='webinar-details'>
          {parse(webinar?.content ?? '')}
          </Box>
        </Box>
      </Box>
    </WebinarDetailItem>
  )
}
