import { type FC } from 'react'
import { Box } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import loader from '@shared/assets/images/loader-body.gif'
import { LoaderWrap } from './LoaderStyle'

export const Loader: FC = () => {
  const theme = useTheme()
  return (
    <LoaderWrap theme={theme} className="loader-wrap">
      <Box theme={theme} className="loader">
        <img src={loader} />
      </Box>
    </LoaderWrap>
  )
}
