import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const SectionContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.section-content': {
    borderBottom: '1px solid' + theme.palette.grey[100],
    '&:last-child': {
      border: 'none'
    },
    '.custom-checkbox-wrap': {
      '.u-custom-control-label': {
        marginBottom: 0,
        '.MuiFormControlLabel-label': {
          color: theme.palette.grey[900]
        }
      }
    },
    '.form-cta': {
      '.btn': {
        marginTop: 32
      }
    },
    '.u-form-group': {
      '.u-form-control': {
        '&.MuiInputBase-adornedEnd': {
          svg: {
            path: {
              stroke: theme.palette.grey[300]
            }
          },
          cursor: 'pointer',
          '.MuiButtonBase-root': {
            padding: 0,
            '&:hover': {
              backgroundColor: 'transparent'
            },
            '.MuiTouchRipple-root': {
              display: 'none'
            }
          }
        }
      }
    },
    'input[type="password"]::-ms-reveal': {
      display: 'none'
    },
    'input[type="password"]::-ms-clear': {
      display: 'none'
    }
  }
}))
