import { CONTACT_NUMBER_REGEX } from './../constants/regex'
import {
  ACCESS_TOKEN_KEY,
  APPEX_NOW_MARKETPLACE,
  CardType,
  CUSTOM_KEY,
  gotoApp,
  paidConfirmationForMultipleProducts,
  paidConfirmationForMultipleProductsForBankTransfer,
  paidConfirmationMessage,
  paidConfirmationMessageForBankTransfer,
  SIGNUP_STEPS,
  Steps,
  TERMLY_API_CACHE,
  Validation
} from '@shared/constants'
import { clearLocalStorage, getItem, getItemByKey, setItembyKey } from './localstorage'
import {
  ALPHABETS_REGEX,
  EMAIL_REGEX,
  LENGTH_REGEX,
  MASTER_CARD_REGEX_2_SERIES_2,
  MASTER_CARD_REGEX_2_SERIES_5,
  MASTER_CARD_REGEX_SERIES_2,
  MASTER_CARD_REGEX_SERIES_5,
  VISA_CARD_REGEX,
  VISA_CARD_REGEX_2,
  WHITE_SPACE_REGEX
} from '@shared/constants/regex'
import { type IRole, type DateTimeFormat, type SelectItem } from '@shared/typings'
import { APP_ROUTES } from '@router/path'
import { logout } from '@ntpkunity/controls-ums'
import { pusher } from 'store/ducks/pusher'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/explicit-function-return-type
export const noop = () => { }

export const validation = (name: string, ...args: string[]): any => {
  const validatorSet = new Set(args)
  return {
    required: {
      value: validatorSet.has(Validation.REQUIRED),
      message: `${name} is required.`
    },
    validate: {
      matchesPattern: (value: string | undefined) => {
        if (
          validatorSet.has(Validation.LIMIT_LENGTH) &&
          (value ?? '').length > 0 &&
          (LENGTH_REGEX(+args[args.indexOf(Validation.LIMIT_LENGTH) + 1]).exec(
            value ?? ''
          ) == null)
        ) {
          return `Maximum character limit is ${args[args.indexOf(Validation.LIMIT_LENGTH) + 1]
            }.`
        }
        if (validatorSet.has(Validation.EMAIL) && value !== undefined && (EMAIL_REGEX.exec(value) == null)) {
          return 'Valid email is required.'
        }
        if (
          validatorSet.has(Validation.WHITE_SPACE) && value !== undefined &&
          (WHITE_SPACE_REGEX.exec(value) == null)
        ) {
          return `${name} is required.`
        }
        if (
          validatorSet.has(Validation.ONLY_ALPHABETS) &&
          value !== undefined &&
          (ALPHABETS_REGEX.exec(value) == null)
        ) {
          return 'Only alphabets are allowed.'
        }
        if (
          validatorSet.has(Validation.PHNONE_NUMBER) && value !== undefined &&
          (CONTACT_NUMBER_REGEX.exec(value) == null)
        ) {
          return 'Contact number is not valid'
        }
        return true
      },
      crossValidation: (value: string | undefined) => {
        // Validation.CROSS_VALIDATE and valueToBeCrossValidated should be adjacent to the each other.
        // e.g validation('Sikandar', Validation.REQUIRED, Validation.CROSS_VALIDATE, 'Netsolpk1!', Validation.LIMIT_LENGTH)
        return validatorSet.has(Validation.CROSS_VALIDATE)
          ? value === args[args.indexOf(Validation.CROSS_VALIDATE) + 1] ||
          'error statement'
          : true
      }
    }
  }
}

export const scrollOnTop = (): void => { window.scroll(0, 0) }

export const scrollToBottom = (element): void => {
  element.scrollTo(0, element.scrollHeight)
}

export const scrollToId = (id: string, offset = 0): void => {
  const elementToScroll = document?.getElementById(id)
  if (elementToScroll == null) return
  window.scrollTo({
    top: elementToScroll.offsetTop - offset,
    behavior: 'smooth'
  })
}

export const hashToId = (hash: string): string => {
  return hash.substring(1)
}
export const openInNextWindow = (url: string): Window | null => window.open(url)

export const redirectTo = (url: string): void => { window.location.replace(url) }

export const logoutMarketplace = (): void => {
  pusher.unsubscribe(APPEX_NOW_MARKETPLACE)
  logout(APP_ROUTES.LOGIN)
  const termlyCache = getItemByKey(TERMLY_API_CACHE)
  localStorage.clear()
  setItembyKey(TERMLY_API_CACHE, termlyCache)
}

export const formatedDate = (date: string | Date, format: string): string =>
  dayjs(date).format(format)
export const unixFormatedDate = (date: string | number, format: string): string =>
  dayjs(+date * 1000).format(format)

export const formatRelativeDateTime = (utcDateString: string): DateTimeFormat => {
  const utcDate = dayjs.utc(utcDateString).tz(dayjs.tz.guess())
  const diffInMilliseconds = dayjs().diff(utcDate)
  const _24HrsInMilliseconds = 24 * 60 * 60 * 1000

  if (Math.abs(diffInMilliseconds) < _24HrsInMilliseconds) {
    return {
      date: '',
      time: dayjs(utcDate).fromNow()
    }
  } else {
    return {
      date: dayjs(utcDate).format('MMM DD, YYYY'),
      time: dayjs(utcDate).format('hh:mm A')
    }
  }
}

export const capitalizeFirstLetter = (str: string): string =>
  str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')

export const getSubstring = (value: string, start: number, end: number): number =>
  +value.substring(start, end)

export const determineCardType = (event, setCardType): string | undefined => {
  switch (event.length) {
    case 0:
      return setCardType('')
    case 2:
      if (
        (MASTER_CARD_REGEX_2_SERIES_5.exec(event) != null) ||
        (MASTER_CARD_REGEX_2_SERIES_2.exec(event) != null)
      ) {
        return setCardType(CardType.MASTERCARD)
      } else if (VISA_CARD_REGEX_2.exec(event) != null) {
        return setCardType(CardType.VISA)
      }
      break
    case 16:
      if (
        (MASTER_CARD_REGEX_SERIES_5.exec(event) != null) ||
        (MASTER_CARD_REGEX_SERIES_2.exec(event) != null)
      ) {
        return setCardType(CardType.MASTERCARD)
      } else if (VISA_CARD_REGEX.exec(event) != null) {
        return setCardType(CardType.VISA)
      }
  }
}

export const convertToAmount = (value: number): string => value?.toLocaleString()
export const convertToDecimal = (amount: number): string => amount.toFixed(2)
export const stripeAmount = (amount: number): number => amount / 100

export const convertToAmountWithDecimals = (
  value: number,
  currency = 'USD',
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
): string =>
  value?.toLocaleString('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits,
    maximumFractionDigits
  })

export const convertToAmountWithOutSign = (
  value: number,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2
): string =>
  value?.toLocaleString('en-US', {
    minimumFractionDigits,
    maximumFractionDigits
  })

export const addTrailingZeros = (num: number, totalLength = 4): string => {
  const _num = num.toString()
  return _num?.split('.')[1]?.length === 1
    ? _num.padEnd(totalLength, '0')
    : _num
}
export const splitFeatureVariant = (variant: string | null): any[] => {
  if (variant === null) {
    return []
  }
  return variant.split(';')
}

export const hasNoPlayground = (name: string): boolean =>
  gotoApp.includes(name.toLowerCase())

const removeItemAll = (arr, value): string[] => {
  let i = 0
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1)
    } else {
      ++i
    }
  }
  return arr
}

export const getInitials = (firstName: string, lastName: string): string => {
  const firstInitial = firstName?.[0]?.toUpperCase() ?? ''
  const lastInitial = lastName?.[0]?.toUpperCase() ?? ''

  return (lastInitial ?? '').length > 0 ? firstInitial + lastInitial : firstInitial
}

export const getOptions = (arr: any): SelectItem[] => {
  return arr
    ?.sort((a, b) => a.name?.localeCompare(b.name))
    .reduce((agg, type) => [...agg, { text: type.name, value: type.id }], [])
}
export const getCountryOptions = (arr: any): string[] => {
  return arr
    ?.sort((a, b) => a.name?.localeCompare(b.name))
    .reduce((agg, type) => [...agg, { label: type.name, id: type.id }], [])
}

export const isLoggedIn = (): any => getItem(ACCESS_TOKEN_KEY)
export const isUserOnBoarded = (): boolean => (isLoggedIn() ?? '').length > 0 && getItemByKey(SIGNUP_STEPS) === Steps.TENANT
export const checkAdminFromRoles = (roles: IRole[]): boolean => roles?.some((role: IRole) => role.is_admin) ?? false
export const isDateValid = (date: Date): boolean => {
  return date instanceof Date && !isNaN(date.getTime())
}

export const generateKey = (key: string): string => `${CUSTOM_KEY}-${key}`

export const generateSuccessMessageForCheckout = (isSelectedTabBankTransfer: boolean, cartLength: number): string => {
  return cartLength > 1
    ? isSelectedTabBankTransfer ? paidConfirmationForMultipleProductsForBankTransfer : paidConfirmationForMultipleProducts
    : isSelectedTabBankTransfer ? paidConfirmationMessageForBankTransfer : paidConfirmationMessage
}

export const getThumbnail = (src: string): string | undefined => {
  const youtubeVideoId = src
    ?.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)
    ?.pop()

  if (youtubeVideoId?.length === 11) {
    const videoThumbnail = `//img.youtube.com/vi/${youtubeVideoId}/0.jpg`
    return videoThumbnail
  }
}

export const isValidNumber = (value: any): boolean => {
  const parsed = parseFloat(value)
  return !isNaN(parsed) && isFinite(parsed)
}
