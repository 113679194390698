import { useTheme } from '@mui/material'
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  Grid,
  Input,
  Select
} from '@ntpkunity/controls'
import { type FC, useEffect, useMemo, useState } from 'react'
import { Controller, type SubmitHandler, useForm } from 'react-hook-form'
import { ActionButton } from './checkoutBillingAddressPopupStyle'
import {
  className,
  getCountryOptions,
  getItem,
  getOptions,
  TENANT_ID,
  UserAction,
  Validation,
  validation
} from 'shared'
import {
  useSaveBillingAddress,
  useUpdateBillingAddress
} from '@modules/checkout/services'
import { useStoreContext } from 'store/storeContext'
import { type IBillingAddress, type ICountry } from '@shared/typings'
import DisableLoader from '@shared/assets/images/loader-disabled-btn.gif'

const messages = {
  button: {
    addAddress: 'Billing Address',
    save: 'Save Address',
    cancel: 'Cancel',
    wait: 'Wait...'
  },
  name: {
    email: 'email',
    country: 'country_id',
    addressLine1: 'address_line_1',
    addressLine2: 'address_line_2',
    county: 'county_id',
    city: 'city',
    state: 'state_id',
    stateName: 'state_name',
    zipCode: 'zip_code'
  },
  label: {
    country: 'Country',
    email: 'Email',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2 (Optional)',
    county: 'County',
    city: 'City',
    state: 'State',
    stateName: 'State/Province/Region',
    zipCode: 'Zip Code'
  },
  saveBillingAddress: 'You have added your billing details successfully!',
  updateBillingAddress: 'You have updated your billing details successfully!'
}

interface CheckoutBillingAddressPopupProps {
  hide: () => void
  actionType: string
  selectedCountry: ICountry | undefined
  setSelectedCountry: (country: ICountry | undefined) => void
  setSelectedCounty: (county: ICountry | undefined) => void
  setSelectedState: (state: ICountry | undefined) => void
  countries: ICountry[]
  states: ICountry[]
  counties: ICountry[]
  billingData: IBillingAddress | undefined
}

export const CheckoutBillingAddressPopup: FC<
CheckoutBillingAddressPopupProps
> = ({
  hide,
  selectedCountry,
  setSelectedCountry,
  setSelectedCounty,
  setSelectedState,
  actionType,
  countries,
  states,
  counties,
  billingData
}) => {
  const theme = useTheme()
  const US = 'United States'
  const { mutate: updateAddress } = useUpdateBillingAddress()
  const { mutate: saveBillingAddress, isLoading } = useSaveBillingAddress()
  const [country, setCountry] = useState<ICountry>()

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm()

  const {
    states: { userProfile },
    actions: { setToaster }
  } = useStoreContext()

  const countryOptions = useMemo(
    () => getCountryOptions(countries),
    [countries]
  )

  useEffect(() => {
    if (actionType === UserAction.EDIT) {
      setSelectedCountry(countries?.find((county) => county.id === billingData?.country_id))
      setCountry(countries?.find((county) => county.id === billingData?.country_id))
    }
  }, [])

  useEffect(() => {
    setSelectedState(states?.find((state) => state.id === billingData?.state_id))
  }, [states])

  const stateOptions = useMemo(() => getOptions(states), [states])

  const countyOptions = useMemo(() => getOptions(counties), [counties])

  const submit: SubmitHandler<any> = (data) => {
    if (selectedCountry?.name !== US) {
      data.county_id = null
      data.state_id = null
    }
    if (actionType === UserAction.EDIT) {
      updateAddress(data, {
        onSuccess () {
          hide()
          setToaster({
            isDisplay: true,
            message: messages.updateBillingAddress,
            type: ''
          })
        }
      })
    } else {
      data.tenant_id = getItem(TENANT_ID)
      saveBillingAddress(data, {
        onSuccess () {
          hide()
          setToaster({
            isDisplay: true,
            message: messages.saveBillingAddress,
            type: ''
          })
        }
      })
    }
  }

  const getCountryById = (id: number): ICountry | undefined => {
    return countries?.find(
      (country) => country.id === id
    )
  }

  return (
      <Dialog
        theme={theme}
        title={messages.button.addAddress}
        size="sm"
        open={true}
        onCloseDialog={() => { hide() }}
        customFooter={
          <>
            <ActionButton theme={theme} className="new-card-buttons">
              {// eslint-disable-next-line @typescript-eslint/no-misused-promises
              }<form className="buttons" onSubmit={handleSubmit(submit)}>
                <Button
                  theme={theme}
                  secondary
                  text={messages.button.cancel}
                  onClick={() => { hide() }}
                ></Button>
                <Button
                  theme={theme}
                  primary
                  className={className.checkoutAddBillingAddressSaveBtn}
                  text={isLoading ? messages.button.wait : messages.button.save}
                  type="submit"
                  fullWidth
                  disabled={isLoading}
                  startIcon={
                    isLoading && (
                      <img src={DisableLoader} alt="Loader" />
                    )
                  }
                ></Button>
              </form>
            </ActionButton>
          </>
        }
      >
        {// eslint-disable-next-line @typescript-eslint/no-misused-promises
        }<form onSubmit={handleSubmit(submit)}>
          <Box theme={theme} className="dialog-form-area">
            <Grid theme={theme} container item spacing={3}>
              <Grid theme={theme} item xs={12}>
                <Controller
                  name={messages.name.email}
                  control={control}
                  rules={validation(
                    messages.label.email,
                    Validation.REQUIRED,
                    Validation.EMAIL
                  )}
                defaultValue={billingData?.email ?? userProfile?.user_name ?? ''}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      fullWidth
                      label={'Email Invoice/Receipt To'}
                      type={'text'}
                      placeholder={messages.label.email}
                      error={errors?.email?.message?.toString()}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12}>
                <Controller
                  name={messages.name.country}
                  control={control}
                  defaultValue={billingData?.country_id ?? ''}
                  rules={validation(messages.label.country, Validation.REQUIRED)}
                  render={({ field }) => (
                    <Autocomplete
                      theme={theme}
                      capitalizeLabel={true}
                      placeholder="Country"
                      label={messages.label.country}
                      onChange={(e, value: any) => {
                        field?.onChange(value.id)
                        setCountry(getCountryById(value.id))
                        setSelectedCountry(getCountryById(value.id))
                      }}
                      value={{ id: field.value, label: getCountryById(field.value)?.name ?? '' }}
                      items={countryOptions}
                      searchMatchFrom="start"
                      error={errors?.country_id?.message?.toString()}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12}>
                <Controller
                  name={messages.name.addressLine1}
                  control={control}
                  rules={validation(
                    messages.label.addressLine1,
                    Validation.REQUIRED,
                    Validation.LIMIT_LENGTH,
                    '50'
                  )}
                  defaultValue={billingData?.address_line_1}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      theme={theme}
                      type="text"
                      label={messages.label.addressLine1}
                      placeholder="House number, street name"
                      {...field}
                      error={errors?.address_line_1?.message?.toString()}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12}>
                <Controller
                  name={messages.name.addressLine2}
                  control={control}
                  rules={validation(
                    messages.label.addressLine2,
                    Validation.LIMIT_LENGTH,
                    '50'
                  )}
                  defaultValue={billingData?.address_line_2}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      theme={theme}
                      type="text"
                      label={messages.label.addressLine2}
                      placeholder="e.g. company, apartment, building..."
                      {...field}
                      error={errors?.address_line_2?.message?.toString()}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Controller
                  name={messages.name.city}
                  control={control}
                  defaultValue={billingData?.city}
                  rules={validation(
                    messages.label.city,
                    Validation.REQUIRED,
                    Validation.LIMIT_LENGTH,
                    '50'
                  )}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      theme={theme}
                      type="text"
                      label={messages.label.city}
                      placeholder="Type here..."
                      error={errors?.city?.message?.toString()}
                      {...field}
                    />
                  )}
                />
              </Grid>
              {country?.name !== US && (
                <Grid theme={theme} item xs={6}>
                  <Controller
                    name={messages.name.stateName}
                    control={control}
                    defaultValue={billingData?.state_name ?? ''}
                    rules={validation(
                      messages.label.stateName,
                      Validation.REQUIRED,
                      Validation.LIMIT_LENGTH,
                      '50'
                    )}
                    render={({ field }) => (
                      <Input
                        fullWidth
                        theme={theme}
                        type="text"
                        label={messages.label.stateName}
                        placeholder="Type here..."
                        {...field}
                        error={errors?.state_name?.message?.toString()}
                      />
                    )}
                  />
                </Grid>
              )}
              {country?.name === US && (
                <Grid theme={theme} item xs={6}>
                  <Controller
                    name={messages.name.state}
                    control={control}
                    defaultValue={billingData?.state_id ?? ''}
                    rules={validation(messages.label.state, Validation.REQUIRED)}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        theme={theme}
                        label={messages.label.state}
                        items={stateOptions}
                        sxProps={false}
                        placeholder="States"
                        disablePortal={false}
                        onChange={(e) => {
                          onChange(e)
                          setValue(messages.name.county, null)
                          setSelectedState(
                            states.find((state) => state.id === e.target.value)
                          )
                        }}
                        value={value}
                        selectError={errors?.state_id?.message?.toString()}
                      ></Select>
                    )}
                  />
                </Grid>
              )}
              <Grid theme={theme} item xs={6}>
                <Controller
                  name={messages.name.zipCode}
                  control={control}
                  defaultValue={billingData?.zip_code}
                  rules={validation(messages.label.zipCode, Validation.REQUIRED)}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      theme={theme}
                      type="text"
                      maskNumeric
                      masking
                      format="#####"
                      label={messages.label.zipCode}
                      placeholder="Type here..."
                      {...field}
                      error={errors?.zip_code?.message?.toString()}
                    />
                  )}
                />
              </Grid>
              {country?.name === US && (
                <Grid theme={theme} item xs={6}>
                  <Controller
                    name={messages.name.county}
                    control={control}
                    defaultValue={billingData?.county_id ?? ''}
                    rules={validation(messages.label.county, Validation.REQUIRED)}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        theme={theme}
                        label={messages.label.county}
                        items={countyOptions}
                        sxProps={false}
                        placeholder="Select"
                        disablePortal={false}
                        onChange={(e) => {
                          onChange(e)
                          setSelectedCounty(
                            counties.find(
                              (county) => county.id === e.target.value
                            )
                          )
                        }}
                        value={value}
                        selectError={errors?.county_id?.message?.toString()}
                      ></Select>
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </form>
      </Dialog>
  )
}
