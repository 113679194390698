import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'
import { CustomColors } from '@shared/theme'

export const AppFooter = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.app-footer': {
    backgroundColor: theme.palette.grey[900],
    padding: '120px 0',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.grey[200],
    '.custom-container': {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 32,
        paddingRight: 32
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: 24,
        paddingRight: 24
      }
    },
    ul: {
      padding: 0,
      margin: 0,
      listStyle: 'none',
      li: {
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        marginBottom: 15,
        '&:last-child': {
          marginBottom: 0
        },
        a: {
          fontSize: theme.typography.body2.fontSize,
          lineHeight: theme.typography.body2.lineHeight,
          textDecoration: 'none',
          transition: 'all .3s ease-in-out',
          '&:hover': {
            color: theme.palette.common.white
          },
          [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.caption.fontSize,
            lineHeight: theme.typography.caption.lineHeight
          }
        }
      }
    },
    '.footer-img': {
      marginBottom: 40,
      img: {
        maxWidth: 128
      },
      '&.logo-footer': {
        maxHeight: 36,
        img: {
          height: 36,
          maxWidth: '100%'
        }
      }
    },
    '.footer-title': {
      marginBottom: 32,
      color: theme.palette.common.white,
      '.MuiTypography-root': {
        fontWeight: theme.customVariables.fontWeightSemiBold,
        [theme.breakpoints.down('md')]: {
          fontSize: theme.typography.body2.fontSize,
          lineHeight: theme.typography.body2.lineHeight
        }
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: 24
      }
    },
    '.footer-logo': {
      maxHeight: 24,
      img: {
        maxHeight: 24
      }
    },
    '.links': {
      li: {
        a: {
          color: theme.palette.grey[200],
          cursor: 'pointer'
        },
        '.styled-tooltip': {
          a: {
            color: theme.palette.grey[400]
          }
        }
      }
    },
    '.bottom-links': {
      li: {
        display: 'inline-block',
        marginBottom: 0,
        marginRight: 25,
        '&:last-child': {
          marginRight: 0
        },
        a: {
          color: theme.palette.grey[200]
        }
      }
    },
    '.our-address': {
      '.links': {
        li: {
          marginBottom: 8
        }
      },
      [theme.breakpoints.down('md')]: {
        borderBottom: '1px solid' + theme.palette.grey[700],
        paddingBottom: 40,
        marginBottom: 16
      }
    },
    '.social': {
      li: {
        display: 'inline-block',
        marginBottom: 0,
        marginRight: 8,
        verticalAlign: 'middle',
        '&:last-child': {
          marginRight: 0
        }
      },
      '.social-link': {
        textAlign: 'center',
        backgroundColor: 'transparent',
        border: '1px solid' + theme.palette.grey[700],
        width: 40,
        height: 40,
        borderRadius: '50%',
        lineHeight: '40px',
        transition: 'all .3s ease-in-out',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        svg: {
          width: 16,
          height: 16,
          fill: theme.palette.common.white,
          path: {
            fill: theme.palette.common.white
          }
        },
        '&.link-yt': {
          svg: {
            width: 24,
            height: 24
          }
        },
        '&.link-medium': {
          svg: {
            width: 24,
            height: 24
          }
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
          svg: {
            fill: theme.palette.common.white,
            path: {
              fill: theme.palette.common.white
            }
          }
        }
      }
    },
    '.text-body2': {
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.caption.fontSize,
        lineHeight: theme.typography.caption.lineHeight
      }
    },
    [theme.breakpoints.down('md')]: {
      padding: '40px 0 95px 0'
    },
    '.styled-tooltip': {
      '.MuiTooltip-tooltip': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.grey[900],
        '.MuiTooltip-arrow': {
          color: theme.palette.common.white,
          marginTop: -9,
          transform: 'translate3d(10px, 0px, 0px) !important'
        }
      }
    },
    '.badge': {
      color: theme.palette.common.white,
      background: CustomColors.gradient,
      padding: '4px 12px 4px 12px',
      display: 'inline-block',
      borderRadius: 24,
      fontWeight: theme.customVariables.fontWeightSemiBold
    }
  }
}))
