import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const WaitListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.waitlist-wrap': {
    '.badge': {
      backgroundColor: 'rgba(0, 51, 253, 0.1)',
      padding: '8px 16px',
      borderRadius: 24,
      fontWeight: theme.customVariables.fontWeightSemiBold,
      letterSpacing: '0.5px'
    },
    '.text-error': {
      color: theme.palette.error.main
    },
    '.img-area': {
      maxHeight: 422,
      img: {
        maxHeight: 422
      },
      [theme.breakpoints.down('md')]: {
        maxHeight: 200,
        img: {
          maxHeight: 200
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    },
    '.notify-form-wrap': {
      '.u-form-group': {
        margin: '0 0 16px 0',
        '.u-form-control': {
          backgroundColor: 'rgba(255, 255, 255, 0.04)',
          '.MuiInputBase-input': {
            '&::placeholder': {
              opacity: 0.26
            }
          }
        },
        [theme.breakpoints.up('md')]: {
          margin: '0 24px 0 0',
          flexGrow: 1,
          '.u-form-control': {
            backgroundColor: 'transparent',
            height: 'auto',
            '.MuiInputBase-input': {
              padding: 0
            },
            '&.Mui-focused': {
              fieldset: {
                borderColor: 'transparent'
              }
            },
            '&:hover': {
              fieldset: {
                borderColor: 'transparent'
              }
            },
            fieldset: {
              borderColor: 'transparent'
            }
          }
        }
      },
      '.MuiButton-root': {
        width: '100%'
      },
      [theme.breakpoints.up('md')]: {
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderRadius: 12,
        padding: '12px 12px 12px 32px',
        display: 'flex',
        alignItems: 'center',
        '.MuiButton-root': {
          flexShrink: 0,
          width: 'auto'
        }
      }
    }
  }
}))
