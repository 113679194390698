import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const WebinarListItem = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.webinar-list-item': {
    height: '100%',
    padding: 24,
    [theme.breakpoints.down('md')]: {
      padding: 16
    },
    '.fw-medium': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '.webinar-img': {
      maxHeight: 190,
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      position: 'relative',
      img: {
        maxWidth: '100%',
        maxHeight: 190,
        objectFit: 'cover',
        objectPosition: 'center center',
        width: '100%',
        height: '100%'
      },
      '.play-icon': {
        position: 'absolute',
        left: '50%',
        top: '50%',
        zIndex: 1,
        transform: 'translate(-50%, -50%)',
        width: 48,
        height: 48,
        img: {
          maxHeight: 48,
          objectFit: 'contain'
        }
      }
    },
    '.webinar-meta': {
      '.seperator': {
        display: 'inline-block',
        width: 4,
        height: 4,
        borderRadius: '50%',
        backgroundColor: theme.palette.grey[300]
      }
    },
    '.webinar-content': {
      '.webinar-title': {
        a: {
          color: theme.palette.grey[900],
          textDecoration: 'none'
        }
      },
      '.webinar-tags': {
        display: 'inline-block',
        width: '100%'
      },
      '.badge': {
        padding: '4px 12px',
        backgroundColor: 'rgba(0, 51, 253, 0.1)',
        borderRadius: 24,
        fontWeight: theme.customVariables.fontWeightSemiBold,
        letterSpacing: '0.5px',
        margin: '0 5px 5px 0',
        float: 'left'
      }
    },
    '.text-truncate': {
      display: '-webkit-box',
      '-webkitBoxOrient': 'vertical',
      overflow: 'hidden',
      '&.lines-2': {
        '-webkitLineClamp': '2'
      },
      '&.lines-3': {
        '-webkitLineClamp': '3'
      }
    }
  }
}))
