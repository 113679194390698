import { styled } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import { type IAppBarProps } from './header.types'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const CustomAppBar = styled(
  AppBar,
  {}
)<Partial<IAppBarProps>>(({ theme }) => ({
  '&.u-custom-app-header': {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    padding: '20px 0',
    borderBottom: '1px solid' + theme.palette.grey[100],
    [theme.breakpoints.down('md')]: {
      padding: '20px 0'
    },
    '&.top-nav': {
      padding: '20px 0',
      backgroundColor: theme.palette.common.white,
      zIndex: 1204
    },
    '.u-toolbar': {
      minHeight: 'auto',
      [theme.breakpoints.up('sm')]: {
        minHeight: 'auto'
      }
    },
    '.app-logo': {
      maxHeight: 20,
      [theme.breakpoints.up('md')]: {
        maxHeight: 24
      },
      img: {
        height: 20,
        maxWidth: '100%',
        [theme.breakpoints.up('md')]: {
          height: 24
        }
      },
      a: {
        cursor: 'Pointer'
      },
      '&.dummy-logo': {
        maxHeight: 24,
        [theme.breakpoints.up('md')]: {
          maxHeight: 24
        },
        img: {
          height: 24,
          [theme.breakpoints.up('md')]: {
            height: 24
          }
        }
      }
    },
    '.toggle-nav': {
      padding: 0,
      '.menu-icon': {
        height: 24,
        width: 24,
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
          zIndex: 99,
          position: 'relative'
        },
        '.toggle-bar': {
          position: 'relative',
          top: '50%',
          transition: 'all 0.3s ease-in-out',
          '&:after': {
            height: 2,
            width: 16,
            transition: 'all 0.3s ease-in-out',
            boxShadow: '0 10px 0 0' + theme.palette.grey[900],
            content: "''",
            position: 'absolute',
            top: -6,
            left: 0
          },
          '&:before': {
            height: 2,
            width: 16,
            transition: 'all 0.3s ease-in-out',
            boxShadow: '0 -10px 0 0' + theme.palette.grey[900],
            content: "''",
            position: 'absolute',
            top: 6,
            left: 0
          }
        }
      },
      '&.change': {
        '.toggle-bar': {
          backgroundColor: 'transparent',
          transition: 'all 0.6s ease-in-out',
          '&:after': {
            top: 0,
            left: 6,
            boxShadow: '0 10px 0 0' + theme.palette.primary.main,
            transform: 'translateY(-8px) rotate(45deg)',
            transition: 'all 0.3s ease-in-out'
          },
          '&:before': {
            top: 0,
            left: 6,
            boxShadow: '0 -10px 0 0 ' + theme.palette.primary.main,
            transform: 'translateY(6px) rotate(-45deg)',
            transition: 'all 0.3s ease-in-out'
          }
        }
      }
    },
    '.header-nav-wrap': {
      flexGrow: 1,
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      },
      [theme.breakpoints.down('md')]: {
        border: 0,
        margin: 0,
        padding: '32px',
        width: '100%',
        top: 0,
        left: 0,
        right: 'auto',
        position: 'fixed',
        maxHeight: 'none',
        height: '100% !important',
        backgroundColor: theme.palette.common.white,
        transition: 'all .5s',
        boxShadow: 'none',
        transform: 'translateX(100%)',
        zIndex: 1,
        '&.menu-open': {
          transform: 'translateX(0)',
          overflow: 'scroll'
        }
      },
      '.u-custom-chip': {
        '&.MuiChip-outlined': {
          border: 'none',
          margin: '0 8px'
        },
        '.MuiChip-label': {
          fontWeight: theme.customVariables.fontWeightSemiBold
        }
      },
      '.navbar-nav': {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.body2.fontSize,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 0,
        marginBottom: 0,
        marginTop: 0,
        listStyle: 'none',
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
          '&.nav-center': {
            flexGrow: 1
          },
          '&.nav-right': {
            flexShrink: 0,
            '.nav-item': {
              marginRight: 32,
              '&:last-child': {
                marginRight: 0
              }
            }
          }
        },
        '.nav-item': {
          marginRight: 40,
          alignItems: 'center',
          display: 'flex',
          '&:last-child': {
            marginRight: 0
          },
          [theme.breakpoints.down('md')]: {
            marginRight: 0,
            marginBottom: 25,
            padding: 0,
            minHeight: 'auto'
          },
          '&.link-user': {
            borderLeft: '1px solid' + theme.palette.grey[100],
            paddingLeft: 40,
            [theme.breakpoints.down('md')]: {
              paddingLeft: 0,
              borderTop: '1px solid' + theme.palette.grey[100],
              borderLeft: 'none',
              paddingTop: 25
            },
            '.nav-link': {
              color: theme.palette.grey[900],
              fontWeight: theme.customVariables.fontWeightSemiBold,
              borderRadius: 0,
              fontSize: theme.typography.body2.fontSize,
              textAlign: 'left',
              cursor: 'pointer',
              textTransform: 'capitalize',
              '.MuiTypography-root': {
                fontWeight: theme.customVariables.fontWeightSemiBold,
                display: 'inline-block',
                verticalAlign: 'middle'
              },
              [theme.breakpoints.down('md')]: {
                width: 'auto'
              }
            }
          },
          '&.icon-only-link': {
            borderLeft: '1px solid' + theme.palette.grey[100],
            paddingLeft: 40,
            maxHeight: 24,
            [theme.breakpoints.down('md')]: {
              paddingLeft: 0,
              borderTop: '1px solid' + theme.palette.grey[100],
              borderLeft: 'none',
              paddingTop: 25,
              maxHeight: '100%'
            }
          },
          '&.icon-item': {
            '.nav-link': {
              width: 24,
              height: 24,
              svg: {
                path: {
                  stroke: theme.palette.grey[300]
                }
              }
            }
          },
          '.nav-link': {
            position: 'relative',
            display: 'block',
            width: '100%',
            padding: 0,
            color: theme.palette.grey[600],
            textDecoration: 'none',
            backgroundColor: 'transparent',
            transition: 'all .3s ease-in-out',
            '&:hover': {
              color: theme.palette.primary.main
            },
            '&.dropdown-toggle': {
              '&:after': {
                display: 'inline-block',
                width: 0,
                height: 0,
                marginLeft: '.255em',
                verticalAlign: '.255em',
                content: "''",
                borderTop: '.3em solid',
                borderRight: '.3em solid transparent',
                borderBottom: 0,
                borderLeft: '.3em solid transparent'
              }
            },
            '.u-avator': {
              backgroundColor: theme.palette.grey[400]
            }
          },
          '.arrow-btn': {
            '.down-arrow': {
              display: 'inline-block',
              verticalAlign: 'middle',
              marginLeft: 10,
              path: {
                stroke: theme.palette.grey[900]
              }
            }
          },
          '&.dropdown': {
            position: 'relative',
            '.u-dropdown-menu': {
              '.MuiMenu-paper': {
                marginTop: 8,
                minWidth: 280,
                maxHeight: '435px !important'
              }
            },
            '&.profile-link': {
              '.u-dropdown-menu': {
                '.MuiMenu-paper': {
                  minWidth: 170
                },
                '.u-dropdown-item': {
                  position: 'relative',
                  '&:last-child': {
                    // marginTop: 16,
                    // "&::before": {
                    //   display: "block",
                    //   content: '" "',
                    //   borderStyle: "solid",
                    //   borderWidth: "1px 0 0 0",
                    //   height: 0,
                    //   width: "calc(100% - 48px)",
                    //   position: "absolute",
                    //   top: -8,
                    //   left: 0,
                    //   right: 0,
                    //   margin: "auto",
                    //   borderColor: theme.palette.grey[100],
                    // },
                  }
                }
              },
              '&.logged-in-dropdown': {
                '.u-dropdown-menu': {
                  '.MuiMenu-paper': {
                    minWidth: 280
                  },
                  '.u-dropdown-item': {
                    '&:last-child': {
                      marginTop: 16,
                      '&::before': {
                        display: 'block',
                        content: '" "',
                        borderStyle: 'solid',
                        borderWidth: '1px 0 0 0',
                        height: 0,
                        width: 'calc(100% - 48px)',
                        position: 'absolute',
                        top: -8,
                        left: 0,
                        right: 0,
                        margin: 'auto',
                        borderColor: theme.palette.grey[100]
                      }
                    }
                  }
                }
              }
            },
            '&:hover': {
              '.dropdown-menu': {
                display: 'block'
              }
            },
            '.dropdown-menu': {
              position: 'static',
              float: 'none',
              top: '100%',
              zIndex: 1000,
              display: 'none',
              minWidth: '10rem',
              margin: '.125rem 0 0',
              color: '#212529',
              textAlign: 'left',
              listStyle: 'none',
              backgroundColor: theme.palette.common.white,
              backgroundClip: 'padding-box',
              borderRadius: '.25rem',
              right: 0,
              left: 'auto',
              padding: '25px 0',
              border: 'none',
              boxShadow: '0px 21px 81px 0px rgba(0, 0, 0, 0.09)',
              [theme.breakpoints.up('md')]: {
                position: 'absolute'
              },
              [theme.breakpoints.down('md')]: {
                paddingBottom: 0,
                border: 'none',
                boxShadow: 'none'
              },
              '.dropdown-item': {
                display: 'block',
                width: '100%',
                clear: 'both',
                fontWeight: theme.typography.fontWeightRegular,
                textAlign: 'inherit',
                backgroundColor: 'transparent',
                border: 0,
                padding: '0 0 15px 25px',
                color: '#707070',
                whiteSpace: 'normal',
                transition: 'all .3s ease-in-out',
                textDecoration: 'none',
                '&:last-child': {
                  padding: '0 0 0 25px'
                },
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: theme.palette.primary.main
                },
                '&:focus': {
                  backgroundColor: 'transparent',
                  color: theme.palette.primary.main
                },
                [theme.breakpoints.down('md')]: {
                  fontSize: 20,
                  fontWeight: 900
                }
              }
            }
          },
          '&.cart-dropdown': {
            '.u-dropdown-menu': {
              '.MuiMenu-paper': {
                minWidth: 320,
                maxHeight: '486px !important'
              }
            },
            '.cart-counter': {
              fontFamily: theme.typography.fontFamily,
              width: 16,
              height: 16,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 10,
              lineHeight: '9px',
              fontWeight: theme.typography.fontWeightBold,
              position: 'absolute',
              top: -6,
              right: -8,
              boxShadow: '0px 0px 0px 1px #fff'
            }
          },
          '&.notification-dropdown': {
            position: 'relative',
            '.notification-wrap': {
              overflow: 'visible'
            },
            '.notification-counter': {
              width: 8,
              height: 8,
              backgroundColor: theme.palette.primary.main,
              borderRadius: '50%',
              position: 'absolute',
              top: -2,
              right: 2,
              boxShadow: '0px 0px 0px 1px #fff'
            }
          }
        },
        '&.nav-right': {
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        }
      }
    },
    '.link-user': {
      '.u-avator': {
        backgroundColor: 'transparent',
        width: 32,
        height: 32,
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: 5
      },
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '.MuiTouchRipple-root': {
        display: 'none'
      }
    },
    '.custom-container': {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 32,
        paddingRight: 32
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: 24,
        paddingRight: 24
      }
    },
    '.text-uppercase': {
      textTransform: 'uppercase'
    },
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-dark': {
      color: theme.palette.grey[900]
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.u-dropdown-menu': {
      '.MuiMenu-paper': {
        borderRadius: 16
      },
      '.u-dropdown-item': {
        padding: '8px 24px 8px 24px'
      },
      '.custom-child-wrap': {
        '.drodown-menu-title': {
          padding: '16px 24px 0px',
          '.MuiTypography-root': {
            lineHeight: theme.typography.body1.lineHeight,
            fontSize: 10,
            letterSpacing: '0.5px',
            fontWeight: theme.typography.fontWeightMedium
          }
        },
        '.u-nav-wrap': {
          '.u-list-item': {
            padding: '12px 24px',
            '.u-list-item-icon': {
              marginRight: 12,
              img: {
                maxHeight: 24
              },
              '&.collapse-icon': {
                marginRight: 0
              }
            },
            '&:hover': {
              backgroundColor: 'transparent'
            },
            '&:focus': {
              backgroundColor: 'transparent',
              '.u-list-item-icon': {
                svg: {
                  path: {
                    stroke: 'currentColor'
                  }
                }
              },
              '.u-list-item-text': {
                color: theme.palette.grey[600]
              }
            }
          },
          '.u-nested-list': {
            marginLeft: 36,
            '.u-list-item': {
              padding: '8px 12px',
              '&:hover': {
                backgroundColor: '#F5F5F7',
                '.u-list-item-text': {
                  color: theme.palette.grey[900]
                }
              }
            }
          }
        },
        '.custom-list-wrap': {
          '.MuiDivider-root': {
            margin: '8px 24px'
          }
        },
        '.cart-dropdown-wrap': {
          '.cart-item': {
            borderBottom: '1px solid' + theme.palette.grey[50],
            marginBottom: 24,
            paddingBottom: 24,
            '.prd-info': {
              '.prd-name': {
                color: theme.palette.grey[900],
                fontWeight: theme.typography.fontWeightMedium
              },
              '.prd-price': {
                color: theme.palette.grey[900],
                fontWeight: theme.typography.fontWeightMedium
              }
            },
            '.prd-price-action': {
              '.prd-action': {
                color: theme.palette.error.main,
                '.link': {
                  textDecoration: 'none',
                  color: theme.palette.error.main,
                  cursor: 'pointer'
                }
              },
              '.pkg-select': {
                '.btn': {
                  '&.btn-default': {
                    fontSize: theme.typography.caption.fontSize,
                    lineHeight: theme.typography.caption.lineHeight,
                    color: theme.palette.grey[600],
                    fontWeight: theme.typography.fontWeightRegular,
                    '.MuiButton-endIcon': {
                      width: 18,
                      height: 18,
                      marginRigt: 4,
                      svg: {
                        width: 18,
                        height: 18
                      }
                    }
                  }
                },
                '.u-dropdown-menu': {
                  '.MuiMenu-paper': {
                    minWidth: 200
                  }
                }
              }
            },
            '.price-item': {
              marginBottom: 8,
              '&:last-child': {
                marginBottom: 0
              }
            },
            '.price-total': {
              '.total-price-label': {
                lineHeight: theme.typography.h4.lineHeight,
                fontWeight: theme.typography.fontWeightMedium
              },
              '.total-value': {
                span: {
                  fontSize: theme.typography.body2.fontSize,
                  lineHeight: theme.typography.h4.lineHeight,
                  fontWeight: theme.typography.fontWeightMedium,
                  verticalAlign: 'top'
                }
              }
            },
            '&:last-child': {
              borderBottom: 'none',
              marginBottom: 0,
              paddingBottom: 0
            }
          },
          '.empty-cart-img': {
            height: 200,
            img: {
              maxHeight: 200,
              maxWidth: '100%'
            }
          }
        }
      }
    }
  }
}))

export const HeaderSearchWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.header-search-wrap': {
    boxShadow: '0px 32px 64px rgba(0, 0, 0, 0.16)',
    position: 'fixed',
    width: '100%',
    backgroundColor: theme.palette.common.white,
    transition: 'all 0.3s ease-in-out 0s',
    borderRadius: '0px 0px 16px 16px',
    top: '-100%',
    zIndex: 1024,
    paddingBottom: 16,
    '&.search-open': {
      top: 0
    },
    '.u-toolbar': {
      padding: '20px 0',
      [theme.breakpoints.down('md')]: {
        padding: 0
      }
    },
    '.header-nav-wrap': {
      flexGrow: 1.4,
      '.navbar-nav': {
        '&.nav-center': {
          '.nav-item': {
            '.nav-link': {
              '&:before': {
                display: 'block',
                content: '""',
                width: 40,
                height: 4,
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
                position: 'absolute',
                left: 0,
                right: 0,
                margin: 'auto',
                bottom: -8,
                transition: 'all 0.3s ease-in-out 0s',
                opacity: 0,
                [theme.breakpoints.down('md')]: {
                  bottom: 16
                }
              },
              '&.active': {
                color: theme.palette.grey[900],
                fontWeight: theme.customVariables.fontWeightSemiBold,
                '.MuiTypography-root': {
                  fontWeight: theme.customVariables.fontWeightSemiBold
                },
                '&:before': {
                  opacity: 1
                }
              }
            }
          },
          [theme.breakpoints.down('md')]: {
            flexGrow: 1,
            flexWrap: 'nowrap',
            overflowX: 'auto',
            overflowY: 'hidden',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              width: 0,
              height: 0
            }
          }
        },
        '&.nav-right': {
          '.nav-item': {
            '&.icon-item': {
              '.nav-link': {
                [theme.breakpoints.down('md')]: {
                  height: 'auto'
                }
              }
            }
          },
          [theme.breakpoints.down('md')]: {
            display: 'block'
          }
        },
        [theme.breakpoints.down('md')]: {
          flexDirection: 'row',
          '.nav-item': {
            marginRight: 32,
            marginBottom: 0,
            flexShrink: 0,
            '.nav-link': {
              padding: '23px 0'
            }
          }
        }
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        position: 'relative',
        height: 'auto !important',
        transform: 'none',
        boxShadow: 'none'
      }
    },
    '.search-area': {
      maxWidth: 628,
      margin: 'auto',
      '.u-custom-autocomplete': {
        '.MuiAutocomplete-root': {
          '.u-form-group': {
            '.MuiInputBase-formControl': {
              height: 72,
              borderRadius: 48,
              padding: '0 40px 0px 0',
              '.MuiInputBase-input': {
                padding: '24px 40px',
                [theme.breakpoints.down('md')]: {
                  padding: '16px 24px'
                }
              },
              svg: {
                path: {
                  stroke: theme.palette.grey[300]
                }
              },
              '&:hover': {
                fieldset: {
                  borderColor: theme.palette.primary.main
                }
              },
              [theme.breakpoints.down('md')]: {
                height: 56,
                padding: '0 24px 0px 0'
              }
            }
          }
        },
        '.MuiAutocomplete-popper': {
          position: 'relative !important',
          transform: 'translate3d(0px, 0px, 0px) !important',
          '.MuiAutocomplete-paper': {
            boxShadow: 'none',
            border: 'none',
            borderRadius: 0,
            '.MuiAutocomplete-listbox': {
              '.MuiAutocomplete-option': {
                padding: '8px 24px'
              }
            }
          }
        }
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%'
      }
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: 8
    }
  }
}))

export const ResponsiveHeaderMenu = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.responsive-header-menu': {
    padding: '12px 40px',
    borderRadius: 40,
    boxShadow: '0px 16px 48px -8px rgba(0, 0, 0, 0.16)',
    position: 'fixed',
    backgroundColor: theme.palette.common.white,
    border: '1px solid' + theme.palette.grey[50],
    left: 0,
    bottom: 16,
    width: 'auto',
    maxWidth: 232,
    right: 0,
    margin: 'auto',
    display: 'none',
    '.navbar-nav': {
      display: 'flex',
      alignItems: 'center',
      listStyle: 'none outside none',
      padding: 0,
      margin: 0,
      gap: 40,
      justifyContent: 'center',
      '.nav-item': {
        '&.dropdown': {
          position: 'relative',
          '.u-dropdown-menu': {
            '.MuiMenu-paper': {
              marginTop: -33,
              minWidth: 'calc(100% - 32px)',
              maxHeight: '435px !important'
            }
          },
          '&.profile-link': {
            '.u-dropdown-menu': {
              '.MuiMenu-paper': {
                marginTop: -66
              }
            },
            '&.logged-in-dropdown': {
              '.u-dropdown-menu': {
                '.u-dropdown-item': {
                  '&:last-child': {
                    marginTop: 16,
                    '&::before': {
                      display: 'block',
                      content: '" "',
                      borderStyle: 'solid',
                      borderWidth: '1px 0 0 0',
                      height: 0,
                      width: 'calc(100% - 48px)',
                      position: 'absolute',
                      top: -8,
                      left: 0,
                      right: 0,
                      margin: 'auto',
                      borderColor: theme.palette.grey[100]
                    }
                  }
                }
              }
            }
          },
          '&.cart-dropdown': {
            '.u-dropdown-menu': {
              '.MuiMenu-paper': {
                minWidth: 'calc(100% - 32px)',
                maxHeight: '486px !important'
              }
            },
            '.cart-counter': {
              fontFamily: theme.typography.fontFamily,
              width: 16,
              height: 16,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 10,
              lineHeight: '9px',
              fontWeight: theme.typography.fontWeightBold,
              position: 'absolute',
              top: -6,
              right: -8,
              boxShadow: '0px 0px 0px 1px #fff'
            }
          },
          '&.notification-dropdown': {
            position: 'relative',
            '.u-dropdown-menu': {
              '.MuiMenu-paper': {
                marginTop: -73
              }
            },
            '.notification-wrap': {
              maxWidth: '100%',
              overflow: 'visible'
            },
            '.notification-counter': {
              width: 8,
              height: 8,
              backgroundColor: theme.palette.primary.main,
              borderRadius: '50%',
              position: 'absolute',
              top: -2,
              right: 2,
              boxShadow: '0px 0px 0px 1px #fff'
            }
          }
        }
      },
      '.nav-link': {
        textDecoration: 'none',
        svg: {
          path: {
            stroke: theme.palette.grey[300]
          }
        },
        '.u-avator': {
          backgroundColor: theme.palette.grey[400]
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  }
}))
