import {
  Grid,
  Typography,
  Box,
  Button,
  Select,
  Icon,
  TooltipProgressBar,
  DateRangePicker,
  CircleLoader
} from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { ContentBox, SectionHead } from './dashboardPageStyle'
import { useState, useMemo, useEffect } from 'react'
import { useGetSubscriptions } from '@modules/product/services/subscription/subscriptionService'
import { type IProductResponse } from '@shared/typings'
import { convertToAmount, formatedDate, isDateValid } from '@shared/helper'
import { dateFormat } from '@shared/constants'
import { useProductUsageTrendDashboard, useProjectedCostDashboard } from '@modules/dashboard/services/dashboardService'
import { type FC } from 'react'

import { DateObject } from 'react-multi-date-picker'
import DateRange from '@shared/components/DateRange'
export const Dashboard: FC = () => {
  const theme = useTheme()
  const { data: subscribedProduct } = useGetSubscriptions()
  const [selectedProduct, setSelectedProduct] = useState<number>()
  const currentDate = new Date()
  const thirtyDaysAgo = new Date(currentDate)
  thirtyDaysAgo.setDate(currentDate.getDate() - 29)
  const [dateRange, setDateRange] = useState([thirtyDaysAgo, currentDate])
  const [startDate, endDate] = dateRange
  const [dateRange1, setDateRange1] = useState<DateObject[]>([
    new DateObject().set({ date: startDate }),
    new DateObject().set({ date: endDate })
  ])
  const [, setSelectedStartDate] = useState<Date>()
  const [projectCostIFrameLoaded, setProjectCostIFrameLoaded] = useState<boolean>(false)
  const [productTrendIFrameLoaded, setProductTrendIFrameLoaded] = useState<boolean>(false)

  const [productTrend, setProductTrend] = useState<string>()
  const [projectedCost, setProjectCost] = useState<string>()

  const [, setSelectedEndDate] = useState<Date>()
  const { data: projectCost, isLoading: projectedCostLoading } = useProjectedCostDashboard(selectedProduct, startDate, endDate)
  const { data: productUsageTrend, isLoading: productTrendtLoading } = useProductUsageTrendDashboard(selectedProduct, startDate, endDate)
  const projectCostIFrameLoading = projectedCostLoading || !projectCostIFrameLoaded
  const productTrendIFrameLoading = productTrendtLoading || !productTrendIFrameLoaded
  const subscribedProductOptions = useMemo(
    () =>
      subscribedProduct
        ?.filter(product => product?.is_active)
        ?.sort((a, b) => a.product_name?.localeCompare(b.product_name))
        ?.reduce(
          (agg, type) => [...agg, { text: type.product_name, value: type.product_id }],
          []
        ),
    [subscribedProduct]
  )

  useEffect(() => {
    if ((productUsageTrend ?? '').length > 0) {
      setProductTrend(productUsageTrend)
    }
    if ((projectCost ?? '').length > 0) {
      setProjectCost(projectCost)
    }
  }, [productUsageTrend, projectCost])

  useEffect(() => {
    if (Object.keys(subscribedProduct ?? {}).length > 0) {
      setSelectedProduct(subscribedProduct?.[0]?.product_id)
    }
  }, [subscribedProduct])
  return (
    <>
      <SectionHead theme={theme} className="section-head" mb={3} mt={{ xs: 0, md: 5 }} display={'flex'} alignItems={{ xs: 'flex-start', sm: 'flex-end' }} flexDirection={{ xs: 'column', sm: 'row' }}>
        <Typography
          theme={theme}
          variant="h3"
          component="h3"
          className="text-h3"
          flexGrow={1}
        >
          Dashboard
        </Typography>
        <Box theme={theme} className="head-control-wrap" gap={3} display={'flex'} alignItems={'center'} mt={{ xs: 2, sm: 0 }}>
          <Box theme={theme} minWidth={178}>
            <Box theme={theme} width={'100%'}>
              <Select
                label="Select Product"
                value={selectedProduct ?? ''}
                fullWidth
                theme={theme}
                items={subscribedProductOptions ?? []}
                onChange={(e) => {
                  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                  if (e.target.value) {
                    setSelectedProduct(
                      e.target.value as number)
                  }

                  setProductTrendIFrameLoaded(false)
                  setProjectCostIFrameLoaded(false)
                }}
                sxProps={false}
                placeholder="Select"
              />
            </Box>
          </Box>
          <DateRange
              label="Select Date Range"
              theme={theme}
              value={dateRange1}
              onFocus={onfocus}
              onBlur={onblur}
              masking={true}
              maskNumeric={true}
              placeholder="DD/MM/YYYY - DD/MM/YYYY"
              onChange={(value) => {
                setDateRange1(value)
              }}
              format="MM/DD/YYYY"
              onClose={() => {
                const startDate = dateRange1?.[0]?.toDate()
                const endDate = dateRange1?.[1]?.toDate()

                if (isDateValid(startDate)) {
                  setSelectedStartDate(startDate)
                }

                if (isDateValid(endDate)) {
                  setSelectedEndDate(endDate)
                }
                if (isDateValid(startDate) && isDateValid(endDate)) {
                  setDateRange([startDate, endDate])
                }
              }}
              range
              rangeHover
              maxDate={new Date()}
              endAdornment={
                <Icon name="CalendarIcon" className="calendar-icon" />
              }
              dateSeparator={' - '}
            />

        </Box>
      </SectionHead>
      <ContentBox className='metabase-chart' theme={theme}>
        <Box theme={theme} mb={3} position='relative'>
          {projectCostIFrameLoading ? <CircleLoader theme={theme} /> : null}

          <iframe
             style={{ display: !projectCostIFrameLoaded ? 'none' : 'block' }}
            width="100%"
            height="480"
            src={projectedCost}
            title="Projected Cost"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            // onLoad={()=>setTimeout(() => {
            //   setProjectCostIFrameLoaded(true)
            // }, 5000)} // Use the onLoad event handler here
           onLoad={() => { setProjectCostIFrameLoaded(true) }}

          ></iframe>
        </Box>
        <Box theme={theme} mb={3} position='relative'>
          {productTrendIFrameLoading ? <CircleLoader theme={theme} /> : null}

          <iframe
             style={{ display: !productTrendIFrameLoaded ? 'none' : 'block' }}

            width="100%"
            height="480"
            src={productTrend}
            title="Product Usage Trend"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            // onLoad={()=>setTimeout(() => {
            //   setProductTrendIFrameLoaded(true)
            // }, 5000)}
             onLoad={() => { setProductTrendIFrameLoaded(true) }}

          ></iframe>
        </Box>
      </ContentBox>
      <Grid theme={theme} container item spacing={3} alignItems="stretch">
        {/* <Grid theme={theme} item xs={12} lg={6}>
          <ContentBox theme={theme} className="content-box" height={'100%'}>
            <Box
              theme={theme}
              display={'flex'}
              alignItems={'center'}
              className="box-header"
              mb={3}
            >
              <Typography
                theme={theme}
                variant="h4"
                component="h4"
                className="fw-sb"
                flexGrow={1}
              >
                Upcoming Features
              </Typography>
            </Box>
            <Box theme={theme} className="prd-features-wrap">
              <Box theme={theme} className="feat-item">
                <Box theme={theme} className="feat-img"></Box>
                <Box theme={theme} className="feat-content">
                  <Typography theme={theme} variant="body1" component="p" className="fw-medium">
                    Sample Product Name
                  </Typography>
                  <Typography
                    theme={theme}
                    className="text-muted"
                    variant="body2"
                    component="span"
                    display={'block'}
                    mt={0.5}
                    mb={0.75}
                  >
                    Sample feature description to be launched.
                  </Typography>
                  <Button
                    theme={theme}
                    defaultBtn
                    endIcon={
                      <>
                        <Icon name="IconLeftArrow" />
                      </>
                    }
                    text="View Details"
                  />
                </Box>
              </Box>
              <Box theme={theme} className="feat-item">
                <Box theme={theme} className="feat-img"></Box>
                <Box theme={theme} className="feat-content">
                  <Typography theme={theme} variant="body1" component="p" className="fw-medium">
                    Sample Product Name
                  </Typography>
                  <Typography
                    theme={theme}
                    className="text-muted"
                    variant="body2"
                    component="span"
                    display={'block'}
                    mt={0.5}
                    mb={0.75}
                  >
                    Sample feature description to be launched.
                  </Typography>
                  <Button
                    theme={theme}
                    defaultBtn
                    endIcon={
                      <>
                        <Icon name="IconLeftArrow" />
                      </>
                    }
                    text="View Details"
                  />
                </Box>
              </Box>
              <Box theme={theme} className="feat-item">
                <Box theme={theme} className="feat-img"></Box>
                <Box theme={theme} className="feat-content">
                  <Typography theme={theme} variant="body1" component="p" className="fw-medium">
                    Sample Product Name
                  </Typography>
                  <Typography
                    theme={theme}
                    className="text-muted"
                    variant="body2"
                    component="span"
                    display={'block'}
                    mt={0.5}
                    mb={0.75}
                  >
                    Sample feature description to be launched.
                  </Typography>
                  <Button
                    theme={theme}
                    defaultBtn
                    endIcon={
                      <>
                        <Icon name="IconLeftArrow" />
                      </>
                    }
                    text="View Details"
                  />
                </Box>
              </Box>
              <Box theme={theme} className="feat-item">
                <Box theme={theme} className="feat-img"></Box>
                <Box theme={theme} className="feat-content">
                  <Typography theme={theme} variant="body1" component="p" className="fw-medium">
                    Sample Product Name
                  </Typography>
                  <Typography
                    theme={theme}
                    className="text-muted"
                    variant="body2"
                    component="span"
                    display={'block'}
                    mt={0.5}
                    mb={0.75}
                  >
                    Sample feature description to be launched.
                  </Typography>
                  <Button
                    theme={theme}
                    defaultBtn
                    endIcon={
                      <>
                        <Icon name="IconLeftArrow" />
                      </>
                    }
                    text="View Details"
                  />
                </Box>
              </Box>
            </Box>
          </ContentBox>
        </Grid> */}

        <Grid theme={theme} item xs={12} lg={6}>
          <ContentBox theme={theme} className="content-box" height={'100%'}>
            <>
              <Box
                theme={theme}
                display={'flex'}
                alignItems={'center'}
                className="box-header"
                mb={3}
              >
                <Typography
                  theme={theme}
                  variant="h4"
                  component="h4"
                  className="fw-sb"
                  flexGrow={1}
                >
                  Subscribed Products{' '}
                </Typography>
              </Box>
              <Box
                theme={theme}
                className="prd-features-wrap"
              >
                {subscribedProduct
                  ?.filter((prod) => prod.is_active)
                  ?.map((product: IProductResponse) => {
                    const excessTransactions =
                      product.transactions_performed - product.allowed_transactions
                    const excessTransactionPercentage = Math.min(
                      (excessTransactions / product.allowed_transactions) * 100,
                      100
                    )
                    const transactionsPercentage =
                      (product.remaining_transactions /
                        product.allowed_transactions) *
                      100
                    return (

                      <Box theme={theme} className="feat-item" key={product?.id} >
                        <Box theme={theme} className="feat-img"><img src={product.icon_url} alt={product.product_name} /></Box>
                        <Box theme={theme} className="feat-content">
                          <Typography theme={theme} variant="body1" component="p" className="fw-medium">
                            {product?.product_name}
                          </Typography>
                          <Typography
                            theme={theme}
                            className="text-muted"
                            variant="body2"
                            component="span"
                            display={'block'}
                            mt={0.5}
                            mb={0.75}
                          >
                            {product?.status}
                          </Typography>
                          <Box
                            theme={theme}
                            className="progress-wrap"
                            mt={1.5}
                            mb={1.5}
                          >
                            <TooltipProgressBar
                              theme={theme}
                              value={
                                excessTransactions > 0
                                  ? excessTransactionPercentage
                                  : transactionsPercentage
                              }
                              title={
                                convertToAmount(
                                  excessTransactions > 0
                                    ? excessTransactions
                                    : product.remaining_transactions
                                ) as any
                              }
                            />
                          </Box>
                          <Box
                            theme={theme}
                            className="prd-detail-item"
                            display={'flex'}
                            mt={0.5}
                          >
                            <Typography
                              flexGrow={1}
                              theme={theme}
                              className="text-muted"
                              variant="caption"
                              component="small"
                              mr={2}
                            >
                              Remaining Transactions:
                            </Typography>
                            <Typography
                              flexShrink={0}
                              theme={theme}
                              className="text-muted"
                              variant="caption"
                              component="small"
                            >
                              {product?.remaining_transactions}

                            </Typography>
                          </Box>
                          <Box
                            theme={theme}
                            className="prd-detail-item"
                            display={'flex'}
                            mt={0.5}
                          >
                            <Typography
                              flexGrow={1}
                              theme={theme}
                              className="text-muted"
                              variant="caption"
                              component="small"
                              mr={2}
                            >
                              Renewal Date:
                            </Typography>
                            <Typography
                              flexShrink={0}
                              theme={theme}
                              className="text-muted"
                              variant="caption"
                              component="small"
                            >
                              {formatedDate(product.end_date, dateFormat)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )
                  })}
              </Box>
            </>
          </ContentBox>
        </Grid>
      </Grid>
    </>
  )
}
