export const APP_ROUTES = {
  BASE_URL: '/' /* modules/homePage/home/homePage.tsx */,
  HOME_PAGE:
    '/market-place/appexnow-home-old' /* modules/homePage/home/homePage.tsx */,
  NEW_HOME_PAGE:
    '/' /* modules/homePage/home/homePage.tsx */,
  SIGNIN:
    '/market-place/authentication' /* modules/authentication/signin/signin.tsx */,
  OTP: '/market-place/otp' /* modules/authentication/otp/otp.tsx */,
  SIGNUP:
    '/market-place/signup' /* modules/authentication/registration/registration.tsx */,
  CUSTOMER_ACCOUNT:
    '/market-place/customer-account' /* modules/customerAccount/customer-account.tsx */,
  PAYMENT_PLAN:
    '/market-place/payment-plan' /* modules/paymentPlan/PaymentPaln.tsx */,
  PACKAGE_LIST:
    '/market-place/package-list' /* modules/product/UpdatePackage.tsx */,
  TRANSACTION_HISTORY:
    '/market-place/transaction-history' /* modules/product/transaction/TransactionHistory.tsx */,
  CONTACT_US: '/market-place/contact-us' /* modules/contactUs/ContactUs.tsx */,
  PRIVACY_POLICY:
    '/market-place/privacy-policy' /* modules/PrivacyPolicy/PrivacyPolicy.tsx */,
  UPDATE_PAYMENT:
    '/market-place/update-payment' /* modules/product/PaymentCards.tsx */,
  TERMS_AND_CONDITION:
    '/market-place/terms-and-conditions' /* modules/TermsAndCondition/TermsAndCondition.tsx */,
  TERMS_OF_SERVICES:
    '/market-place/terms-of-sevices' /* modules/TermsAndCondition/TermsAndCondition.tsx */,
  DOCUMENTATION:
    '/market-place/documentation' /* modules/documentation/documentation.tsx */,
  CREATE_USER:
    '/market-place/create-user' /* modules/userManagement/UserCreationform.tsx */,
  DASHBOARD:
    '/market-place/dashboard' /* modules/dashboard/pages/dashboardPage.tsx */,
  PROFILE:
    '/market-place/profile' /* modules/dashboard/profile/profilePage.tsx */,
  MY_ACCOUNT:
    '/market-place/my-account' /* modules/dashboard/profile/profilePage.tsx */,
  BILLING_DETAILS:
    '/market-place/billing-details' /* modules/dashboard/profile/profilePage.tsx */,
  EMAIL_PREFERENCES:
    '/market-place/email-preferences' /* modules/dashboard/profile/profilePage.tsx */,
  PRODUCTS_LISTING: '/market-place/:category',
  PRODUCTS_DETAIL:
    '/market-place/products-detail' /* modules/homePage/home/homePage.tsx */,
  DETAIL_PAGE:
    '/market-place/product',
  PRODUCT:
    '/market-place/product/:productName' /* modules/homePage/home/homePage.tsx */,
  EMAIL_VERIFICATION: '/market-place/email-verification',
  USER_PROFILE: '/market-place/user-profile',
  APP_USER_PROFILE: '/market-place/app-user-profile',
  TENANT_PROFILE: '/market-place/tenant-profile',
  FORGOT_PASSWORD: '/market-place/forgot-password',
  CHANGE_PASSWORD: '/market-place/change-password',
  EMAIL_LINK_EXPIRED: '/market-place/email-link-expired',
  RESEND_PASSWORD_LINK: '/market-place/resend-password-link',
  RESET_PASSWORD: '/market-place/reset-password',
  TERMS_COND_AGREEMENT: '/market-place/terms-cond-agreement',
  LOGIN: '/market-place/login',
  NEW_UMS_USERS: '/market-place/track-ums-users',
  SINGLE_SIGN_ON: '/market-place/allowed-products',
  Marketing_Links:
    '/market-place/marketing-links' /* modules/authentication/pages/singleSignOn/singleSignOnPage.tsx */,
  CHECKOUT: '/market-place/checkout',
  UNSUBSCRIBE:
    '/market-place/unsubscribe' /* modules/unsubscription/pages/unsubscription/unsubscriptionPage.tsx */,
  UNSUBSCRIPTION_ERROR_PAGE: '/market-place/unsubscription-error',
  PRODUCTS: '/market-place/products',
  FEEDBACK: '/market-place/feedback' /* modules/feedback/pages/feedback/feedbackPage.tsx */,
  BLOGS: '/blog' /* modules/blog/pages/blogListing/blogListingPage.tsx */,
  BLOG_DETAIL: '/blog/:title' /* modules/blog/pages/blogDetails/blogDetailsPage.tsx */,
  WEBINAR: '/webinar' /* modules/webinar/pages/webinarListing/webinarListingPage.tsx */,
  WEBINAR_DETAIL: '/webinar/:title' /* modules/blog/pages/webinarDetails/webinarDetailsPage.tsx */
}
