import React, { type FC } from 'react'
import { useTheme } from '@mui/material'
import { Typography, Dialog, Box, Icon } from '@ntpkunity/controls'
import { DialogContent, UnsubPopupWrap } from './emailVerificationPopupStyle'
import { useStoreContext } from 'store/storeContext'

interface UnsubEmailVerificationPopupProps {
  resendEmail: () => void
  hide: () => void
}

export const UnsubEmailVerificationPopup: FC<
UnsubEmailVerificationPopupProps
> = ({ resendEmail, hide }) => {
  const theme = useTheme()

  const {
    states: { userProfile }
  } = useStoreContext()

  return (
    <>
      <UnsubPopupWrap theme={theme} className="unsub-popup-wrap">
        <Dialog
          theme={theme}
          variant="confirmation"
          size="sm"
          open={true}
          onCloseDialog={hide}
          noFooter={true}
          disablePortal={true}
        >
          <Box theme={theme} className="dialog-icon">
            <Icon name="DialogEnvelopIcon" />
          </Box>
          <DialogContent theme={theme} className="dialog-content-wrap">
            <Typography
              theme={theme}
              mb={2}
              className="content-title"
              variant="h2"
              component="h2"
            >
              Verification Email Sent
            </Typography>
            <Typography
              theme={theme}
              className="text-muted"
              variant="body2"
              component="span"
              display={'block'}
            >
              A verification email has been sent to{' '}
              <span className="text-dark fw-medium">{userProfile?.user_name}</span>. Please click
              the link in the email to finish un-subscription. The verification
              link is valid for <span className="text-dark fw-medium">30 minutes</span>.
            </Typography>
            <Typography
              theme={theme}
              className="text-muted"
              variant="body2"
              component="span"
              display={'block'}
              mt={2}
            >
              Didn’t receive verification email?{' '}
              <a
                className="text-primary link"
                href="javascript:void(0)"
                onClick={resendEmail}
                data-testid="email-verification-resend-btn"
              >
                Resend
              </a>
            </Typography>
          </DialogContent>
        </Dialog>
      </UnsubPopupWrap>
    </>
  )
}
