import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const BrandsLogoWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.brands-logo-wrap': {
    position: 'relative',
    '.logo-container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '100%',
      overflow: 'hidden',
      userSelect: 'none',
      '.brands-logo-items': {
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        justifyContent: 'space-around',
        minWidth: '100%',
        animation: 'scroll 15s linear infinite'
      }
    },
    '.brand-logo-item': {
      borderRight: '1px solid' + theme.palette.grey[50],
      height: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 64px',
      img: {
        maxHeight: 24,
        [theme.breakpoints.down('md')]: {
          maxHeight: 18
        }
      },
      '&.size-27': {
        img: {
          maxHeight: 27,
          [theme.breakpoints.down('md')]: {
            maxHeight: 22
          }
        }
      },
      '&.size-28': {
        img: {
          maxHeight: 28,
          [theme.breakpoints.down('md')]: {
            maxHeight: 22
          }
        }
      },
      '&.size-30': {
        img: {
          maxHeight: 30,
          [theme.breakpoints.down('md')]: {
            maxHeight: 24
          }
        }
      },
      '&.size-32': {
        img: {
          maxHeight: 32,
          [theme.breakpoints.down('md')]: {
            maxHeight: 24
          }
        }
      },
      '&.size-35': {
        img: {
          maxHeight: 35,
          [theme.breakpoints.down('md')]: {
            maxHeight: 30
          }
        }
      },
      [theme.breakpoints.down('md')]: {
        padding: '0 32px'
      }
    },
    '.carousel-container': {
      padding: 0
    },
    '.carousel-button-group': {
      display: 'none'
    },
    '@keyframes scroll': {
      from: {
        transform: 'translateX(0)'
      },
      to: {
        transform: 'translateX(calc(-100%))'
      }
    }
  }
}))
