import {
  type IUnsubscriptionPayload,
  type IUnsubscriptionConfirmationPayload
} from '@shared/typings'
import Http from '@shared/helper/http-api'
import { useMutation } from 'react-query'

const BASE_URL = process.env.BASE_URL
export const UNSUBSCRIPTION_CONFIRMATION =
  'marketplace/subscription/unsubscription-confirmation'
const UNSUBSCRIBE = 'marketplace/subscription/unsubscribe-product'

export const useConfirmUnsubscription = (): any => {
  const { data, isLoading, mutate, error } = useMutation<
  IUnsubscriptionConfirmationPayload,
  any
  >(async (body) => {
    const apiService = Http.createConnection({
      baseUrl: BASE_URL,
      withAuthentication: true
    })
    return await apiService.post<any>(UNSUBSCRIPTION_CONFIRMATION, body)
  })
  return { data, isLoading, mutate, error: error?.message }
}

export const useUnsubscribeProduct = (): any => {
  const { data, isLoading, mutate, error } = useMutation<
  IUnsubscriptionPayload,
  any
  >(async (body) => {
    const apiService = Http.createConnection({
      baseUrl: BASE_URL,
      withAuthentication: true
    })
    return await apiService.post<any>(UNSUBSCRIBE, body)
  })
  return { data, isLoading, mutate, error: error?.message }
}
