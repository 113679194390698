import { type ReactElement } from 'react'
import { useTheme } from '@mui/material'
import { Box, Container, Grid, Typography, CircleLoader } from '@ntpkunity/controls'
import { HomePageLayout } from '@layout/index'
import { RelatedBlogsComponent } from '@modules/blog/components'
import { WebinarDetailsPageWrap } from './webinarDetailsStyle'
import { WebinarDetailItemComponent, WebinarListItemComponent } from '@modules/webinar/webinarModule'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetAllBlogs, useGetBlogDetails } from '@modules/blog'
import { Helmet } from 'react-helmet'
import { FullPageLoader } from '@modules/homePage/pages/detailPage/detailPageStyle'
import { marketplaceTheme } from '@shared/theme'
import { APP_ROUTES } from '@router/path'

export const WebinarDetailsPage = (): ReactElement => {
  const theme = useTheme()
  const { title } = useParams()
  const navigate = useNavigate()

  const { data: webinarDetail } = useGetBlogDetails(decodeURIComponent(title ?? ''))
  const queryParams = '?is_webinar=true'
  const { data: webinars } = useGetAllBlogs(queryParams)

  return (
    <HomePageLayout>
      <Helmet>
        <meta name="description" content={webinarDetail?.meta_description} />
      </Helmet>
      <WebinarDetailsPageWrap theme={theme} className='webinar-details-page-wrap'>
        <Container className='custom-container' theme={theme}>
          <Box theme={theme} className='page-head' textAlign={'center'}>
            <Typography
              display={'inline-block'}
              mb={2}
              theme={theme}
              className="badge text-primary text-uppercase"
              variant="caption"
              component="small"
            >
              OUR WEBINAR
            </Typography>
          </Box>
          {
            webinarDetail
              ? <WebinarDetailItemComponent
            webinar={webinarDetail}
            socialShare={true}
            />
              : <FullPageLoader theme={marketplaceTheme} className="full-page-loader">
          <CircleLoader theme={marketplaceTheme} />
        </FullPageLoader>
          }
          <Box theme={theme} className='related-section' mt={{ xs: 5, md: 15 }}>
            <RelatedBlogsComponent
              blogSectionTitle='More like this'
             />
             <Box theme={theme} className='webinar-list-items-wrap'>
              <Grid theme={theme} container item spacing={3} alignItems={'stretch'} justifyContent={'center'}>
              {
                webinars?.map((webinar) => (
                  <Grid key={webinar.id} theme={theme} item xs={12} sm={6} md={4}>
                    <WebinarListItemComponent
                      onClick={() => { navigate(`${APP_ROUTES.WEBINAR}/${webinar.title_url}`) }}
                      webinar={webinar}
                    />
                  </Grid>
                ))
              }
              </Grid>

            </Box>
          </Box>
        </Container>
      </WebinarDetailsPageWrap>
    </HomePageLayout>
  )
}
