import { Box, type IBoxProps } from '@ntpkunity/controls'
import { styled } from '@mui/material/styles'

export const ScrollableContainer = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.inner-scrollable-area': {
    transform: 'translateZ(0)',
    textAlign: 'justify',
    overflowY: 'unset !important',
    [theme.breakpoints.up('md')]: {
      height: 280
    },
    [theme.breakpoints.between(1600, 1920)]: {
      height: 330
    },
    [theme.breakpoints.between(1195, 1500)]: {
      height: 440
    },
    [theme.breakpoints.up('xl')]: {
      height: 440
    },
    [theme.breakpoints.down('md')]: {
      height: 440
    },
    '.viewport': {
      width: '100%',
      height: '100%',
      overflowY: 'scroll',
      paddingRight: 6
    }
  }
}))

export const ScrollIconWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.scroll-icon': {
    width: 64,
    margin: 'auto',
    backgroundColor: theme.palette.common.white,
    borderRadius: 40,
    overflow: 'hidden',
    padding: '16px 12px',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.16)',
    border: '1px solid' + theme.palette.grey[100],
    zIndex: 10,
    textAlign: 'center',
    position: 'fixed',
    left: '50%',
    bottom: 0,
    transform: 'translateX(-50%)'
  }
}))
