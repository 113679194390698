import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.main-content': {
    '.tab-list-underline': {
      [theme.breakpoints.down('md')]: {
        borderBottom: 'none'
      }
    },
    '.text-danger': {
      color: theme.palette.error.main
    },
    [theme.breakpoints.down('sm')]: {
      '.tab-list-underline': {
        backgroundColor: theme.palette.grey[50],
        padding: '2px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'space-between',
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
        '.underline-varient-btn': {
          backgroundColor: 'transparent',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          padding: '8px 4px',
          border: 'none',
          cursor: 'pointer',
          borderRadius: 6,
          fontSize: theme.typography.body2.fontSize,
          lineHeight: theme.typography.body2.lineHeight,
          fontWeight: theme.typography.body2.fontWeight,
          color: theme.palette.grey[600],
          whiteSpace: 'normal',
          '&:not(:first-of-type)': {
            marginLeft: 0
          },
          '&:hover': {
            color: theme.palette.grey[600]
          },
          '&::after': {
            display: 'none'
          },
          '&.Mui-selected': {
            color: theme.palette.grey[900],
            backgroundColor: theme.palette.common.white,
            fontWeight: theme.typography.fontWeightMedium,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)'
          }
        }
      }
    }
  }
}))

export const CustomerPageFooter = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.customer-page-footer': {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 150
    }
  }
}))

export const OtpContainer = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.otp-container': {
    '.otp-field-wrap': {
      display: 'flex',
      justifyContent: 'center',
      '.u-form-group': {
        marginBottom: 0,
        marginRight: 16,
        '&:last-child': {
          marginRight: 0
        },
        '.u-form-control': {
          height: 72,
          fontSize: theme.typography.h2.fontSize,
          lineHeight: theme.typography.h2.lineHeight,
          fontWeight: theme.typography.fontWeightBold,
          '.MuiInputBase-input': {
            height: 72,
            textAlign: 'center',
            '&::placeholder': {
              opacity: 1
            }
          }
        }
      },
      '.u-form-control': {
        height: 72,
        fontSize: theme.typography.h2.fontSize,
        lineHeight: theme.typography.h2.lineHeight,
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        textAlign: 'center',
        width: '100%',
        padding: '12px 5px',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: theme.shape.borderRadius,
        outline: 'none',
        marginRight: 16,
        '&:last-child': {
          marginRight: 0
        },
        '&::placeholder': {
          opacity: 1,
          color: theme.palette.common.black
        },
        '&:hover': {
          borderColor: theme.palette.primary.main
        },
        '&:focus': {
          borderWidth: 2,
          borderColor: theme.palette.primary.main
        }
      },
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        '.u-form-control': {
          flex: '0 0 25%',
          maxWidth: '25%',
          margin: '8px !important',
          '&:last-child': {
            marginRight: 8
          }
        }
      }
    }
  }
}))
