import { Box, type IBoxProps } from '@ntpkunity/controls'
import { styled } from '@mui/material/styles'

export const CartSummaryWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.cart-summary-wrap': {
    padding: 40,
    borderRadius: 16,
    border: '1px solid' + theme.palette.grey[100],
    boxShadow: '0px 64px 64px -48px rgba(0, 0, 0, 0.1)',
    '.cart-item': {
      borderBottom: '1px solid' + theme.palette.grey[100],
      marginBottom: 24,
      paddingBottom: 24,
      '.prd-info': {
        '.prd-name': {
          color: theme.palette.grey[900],
          fontWeight: theme.typography.fontWeightMedium
        },
        '.prd-price': {
          color: theme.palette.grey[900],
          fontWeight: theme.typography.fontWeightMedium
        }
      },
      '.prd-price-action': {
        '.prd-action': {
          color: theme.palette.error.main,
          '.link': {
            textDecoration: 'none',
            color: theme.palette.error.main
          }
        },
        '.pkg-select': {
          '.btn': {
            '&.btn-default': {
              fontSize: theme.typography.caption.fontSize,
              lineHeight: theme.typography.caption.lineHeight,
              color: theme.palette.grey[600],
              fontWeight: theme.typography.fontWeightRegular,
              '.MuiButton-endIcon': {
                width: 18,
                height: 18,
                marginRigt: 4,
                svg: {
                  width: 18,
                  height: 18
                }
              }
            }
          },
          '.u-dropdown-menu': {
            '.MuiMenu-paper': {
              minWidth: 200
            }
          }
        }
      },
      '.price-item': {
        marginBottom: 8,
        '&:last-child': {
          marginBottom: 0
        }
      },
      '.price-total': {
        '.total-price-label': {
          lineHeight: theme.typography.h4.lineHeight,
          fontWeight: theme.typography.fontWeightMedium
        },
        '.total-value': {
          span: {
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.h4.lineHeight,
            fontWeight: theme.typography.fontWeightMedium,
            verticalAlign: 'top'
          }
        }
      },
      '&.item-total': {
        borderBottom: 'none',
        marginBottom: 0,
        paddingBottom: 0
      },
      '&:last-child': {
        borderBottom: 'none',
        marginBottom: 0,
        paddingBottom: 0
      }
    },
    [theme.breakpoints.down('md')]: {
      padding: 24
    },
    '.empty-cart-img': {
      maxHeight: 280,
      [theme.breakpoints.down('md')]: {
        maxHeight: 200
      },
      img: {
        maxHeight: 280,
        minHeight: 280,
        [theme.breakpoints.down('md')]: {
          maxHeight: 200,
          minHeight: 200
        }
      }
    },
    '.empty-cart-message': {
      '.MuiTypography-h4': {
        [theme.breakpoints.down('md')]: {
          fontSize: theme.typography.subtitle1.fontSize,
          lineHeight: theme.typography.subtitle1.lineHeight
        }
      }
    }
  }
}))

export const CartSummaryMobile = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.cart-summary-mobile': {
    '.u-accordian-wrap': {
      marginBottom: 0,
      backgroundColor: 'transparent',
      '.main-title': {
        fontWeight: theme.typography.fontWeightMedium
      },
      '.u-accordion-header': {
        justifyContent: 'flex-start',
        '.sub-title': {
          position: 'absolute',
          right: 0,
          top: 0,
          '.MuiTypography-h4': {
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }
        },
        '&.filter-accordion': {
          '.MuiAccordionSummary-content': {
            justifyContent: 'flex-start',
            flexGrow: 0
          }
        }
      },
      '.u-accordian-body': {
        paddingBottom: 0,
        '&.filter-accordion': {
          maxHeight: '100%',
          overflowY: 'visible',
          borderBottom: 'none'
        }
      }
    }
  }
}))
