import {
  Grid,
  Typography,
  Box,
  Button,
  Container,
  Icon,
  Input
} from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { Footer, Header } from '@layout/index'
import { MainContent, ProfileWrap } from './profileStyle'
import { type FC } from 'react'

export const ProfilePage: FC = () => {
  const theme = useTheme()
  return (
    <>
      <Header theme={theme} />
      <MainContent theme={theme} className="main-content">
        <Container theme={theme} maxWidth="lg" className="custom-container">
          <ProfileWrap theme={theme} className="profile-wrap">
            <Box theme={theme} className="profile-section">
              <Typography theme={theme} variant="h3" component="h3" mb={3}>
                About You
              </Typography>
              <Box theme={theme} className="profile-form">
                <Grid theme={theme} container item spacing={3}>
                  <Grid theme={theme} item xs={12} sm={6}>
                    <Input
                      theme={theme}
                      fullWidth
                      type={'text'}
                      label="First Name"
                      placeholder="Type here..."
                    />
                  </Grid>
                  <Grid theme={theme} item xs={12} sm={6}>
                    <Input
                      theme={theme}
                      fullWidth
                      type={'text'}
                      label="Last Name"
                      placeholder="Type here..."
                    />
                  </Grid>
                  <Grid theme={theme} item xs={12}>
                    <Input
                      theme={theme}
                      fullWidth
                      type={'text'}
                      label="Email Address"
                      placeholder="sample@sample.com"
                      value={'sample@sample.com'}
                      disabled
                    />
                  </Grid>
                  <Grid theme={theme} item xs={12}>
                    <Input
                      theme={theme}
                      fullWidth
                      type={'tel'}
                      label="Mobile Number"
                      placeholder="000 000 0000"
                    />
                  </Grid>
                </Grid>
                <Box
                  theme={theme}
                  className="cta-area"
                  mt={3}
                  textAlign="center"
                >
                  <Button theme={theme} primary text="Update My Information" />
                </Box>
              </Box>
            </Box>
            <Box theme={theme} className="profile-section">
              <Typography theme={theme} variant="h3" component="h3" mb={3}>
                Change Your Password
              </Typography>
              <Box theme={theme} className="profile-form">
                <Grid theme={theme} container item spacing={3}>
                  <Grid theme={theme} item xs={12}>
                    <Input
                      theme={theme}
                      fullWidth
                      type={'password'}
                      label="Current Password"
                      placeholder="Type here..."
                      endAdornment={
                        <>
                          <Icon name="IcView" />
                        </>
                      }
                    />
                  </Grid>
                  <Grid theme={theme} item xs={12}>
                    <Input
                      theme={theme}
                      fullWidth
                      type={'password'}
                      label="New Password"
                      placeholder="Type here..."
                      endAdornment={
                        <>
                          <Icon name="IcView" />
                        </>
                      }
                      helperText="8 characters minimum, including capital letters, numbers and special characters"
                    />
                  </Grid>
                  <Grid theme={theme} item xs={12}>
                    <Input
                      theme={theme}
                      fullWidth
                      type={'password'}
                      label="Confirm New Password"
                      placeholder="Type here..."
                      endAdornment={
                        <>
                          <Icon name="IcView" />
                        </>
                      }
                    />
                  </Grid>
                </Grid>
                <Box
                  theme={theme}
                  className="cta-area"
                  mt={3}
                  textAlign="center"
                >
                  <Button theme={theme} primary text="Update my Password" />
                </Box>
              </Box>
            </Box>
          </ProfileWrap>
        </Container>
      </MainContent>
      <Footer />
    </>
  )
}
