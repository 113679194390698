import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'
import VideoPlaceholderImage from '../../../../shared/assets/images/demo-video-ph.svg'

export const VideoPopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.video-popup-wrap': {
    '.u-dialog': {
      '.MuiDialog-paper': {
        border: 'none',
        borderRadius: 24,
        [theme.breakpoints.down('md')]: {
          margin: 24,
          bottom: 'auto',
          position: 'relative',
          maxHeight: '100%'
        }
      },
      '.u-dialog-content': {
        overflow: 'hidden',
        '&.has-no-footer': {
          padding: 0
        },
        '.video-area': {
          maxHeight: 480,
          [theme.breakpoints.down('md')]: {
            maxHeight: 400,
            iframe: {
              height: 400
            }
          }
        }
      }
    }
  }
}))

export const DialogContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.dialog-content-wrap': {
    '.text-dark': {
      color: theme.palette.grey[900]
    },
    '.text-white': {
      color: theme.palette.common.white
    },
    '.text-uppercase': {
      textTransform: 'uppercase'
    },
    '.fw-medium': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    link: {
      textDecoration: 'none'
    },
    '.letter-spacing': {
      letterSpacing: '0.2em'
    },
    '.video-placeholder': {
      minHeight: 480,
      maxHeight: 480,
      backgroundImage: `url(${VideoPlaceholderImage})`,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      width: '100%',
      height: '100%',
      textAlign: 'left',
      padding: 40,
      '.video-title': {
        fontSize: 96,
        lineHeight: '104px'
      },
      [theme.breakpoints.up('md')]: {
        padding: 80
      }
    }
  }
}))
