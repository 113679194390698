import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'
import Amazing from '../../../../shared/assets/images/amazing.svg'
import Good from '../../../../shared/assets/images/good.svg'
import Okay from '../../../../shared/assets/images/okay.svg'
import Bad from '../../../../shared/assets/images/bad.svg'
import Terrible from '../../../../shared/assets/images/terrible.svg'

export const FeedbackPageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.feedback-page-wrap': {
    '.feedback-img': {
      maxHeight: 400,
      img: {
        maxHeight: 400,
        [theme.breakpoints.down('md')]: {
          maxHeight: 200
        }
      },
      [theme.breakpoints.down('md')]: {
        maxHeight: 200
      }
    },
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.link': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer'
      }
    },
    '.u-form-group': {
      '.u-form-control': {
        textarea: {
          '&.MuiInputBase-input': {
            height: 'auto !important'
          }
        }
      }
    },
    '.submit-feedback-wrap': {
      '.feedback-form': {
        paddingBottom: 32,
        marginBottom: 32,
        borderBottom: '1px solid' + theme.palette.grey[100]
      },
      '.radio-rating-group': {
        '.custom-radio-wrap': {
          width: '100%',
          '.MuiFormGroup-row': {
            gap: 8,
            '.u-custom-control-label': {
              marginRight: 0,
              marginBottom: 0,
              flexDirection: 'column',
              alignItems: 'center',
              padding: '16px 8px',
              border: '1px solid' + theme.palette.grey[100],
              color: theme.palette.grey[600],
              backgroundColor: 'transparent',
              lineHeight: 'normal',
              flexBasis: '18.5%',
              maxWidth: '18.5%',
              borderRadius: 8,
              '.custom-radio': {
                marginRight: 0,
                position: 'relative',
                svg: {
                  display: 'none'
                },
                '&::before': {
                  display: 'block',
                  content: '" "',
                  width: 35,
                  height: 34,
                  backgroundPosition: 'center center',
                  backgroundSize: 34,
                  opacity: 0.55,
                  zIndex: 1,
                  position: 'relative',
                  backgroundRepeat: 'no-repeat'
                },
                '&::after': {
                  backgroundColor: '#FFCC00',
                  display: 'block',
                  width: 32,
                  height: 32,
                  borderRadius: '50%',
                  position: 'absolute',
                  content: "''",
                  top: 4,
                  right: -2,
                  opacity: 0,
                  boxShadow: 'none',
                  left: 'auto'
                },
                '&.Mui-checked': {
                  color: theme.palette.grey[900],
                  '&::before': {
                    opacity: 1
                  },
                  '&::after': {
                    opacity: 1
                  },
                  '~': {
                    '.MuiFormControlLabel-label': {
                      fontWeight: theme.customVariables.fontWeightSemiBold
                    }
                  }
                }
              },
              '.MuiFormControlLabel-label': {
                fontSize: theme.typography.caption.fontSize,
                marginTop: 8,
                lineHeight: 'normal',
                padding: 0,
                fontWeight: theme.typography.fontWeightMedium,
                fontFamily: theme.typography.fontFamily
              },
              '&:nth-child(1)': {
                '.custom-radio': {
                  '&::before': {
                    backgroundImage: `url(${Amazing})`
                  }
                }
              },
              '&:nth-child(2)': {
                '.custom-radio': {
                  '&::before': {
                    backgroundImage: `url(${Good})`
                  }
                }
              },
              '&:nth-child(3)': {
                '.custom-radio': {
                  '&::before': {
                    backgroundImage: `url(${Okay})`
                  }
                }
              },
              '&:nth-child(4)': {
                '.custom-radio': {
                  '&::before': {
                    backgroundImage: `url(${Bad})`
                  }
                }
              },
              '&:nth-child(5)': {
                '.custom-radio': {
                  '&::before': {
                    backgroundImage: `url(${Terrible})`
                  }
                }
              },
              '&:hover': {
                borderColor: theme.palette.grey[900]
              },
              '&.selected': {
                borderColor: theme.palette.grey[900]
              },
              [theme.breakpoints.down('sm')]: {
                flexShrink: 0,
                minWidth: 76
              }
            },
            [theme.breakpoints.down('sm')]: {
              flexWrap: 'nowrap',
              overflowY: 'hidden',
              overflowX: 'auto',
              paddingLeft: 24,
              paddingRight: 24,
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                width: 0,
                height: 0
              }
            }
          }
        },
        [theme.breakpoints.down('sm')]: {
          marginLeft: -24,
          marginRight: -24,
          width: 'calc(100% + 48px)'
        }
      },
      '.u-form-group': {
        '.MuiFormControl-root': {
          '.textarea': {
            '.MuiInputBase-root': {
              '.MuiInputBase-input::placeholder': {
                opacity: 0.38
              }
            }
          }
        }
      },
      '.fb-radio-group': {
        '.custom-radio-wrap': {
          '.u-custom-control-label': {
            marginBottom: 0
          }
        }
      }
    }
  }
}))
