import { Box, Button, Typography } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import EmptyCartImage from '@shared/assets/images/empty-cart-img.svg'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'
import { type FC } from 'react'

const EmptyCartUI: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <Box
      theme={theme}
      className="empty-cart"
      textAlign={'center'}
      data-testid="empty-cart"
    >
      <Box theme={theme} className="empty-cart-img">
        <img src={EmptyCartImage} alt="Your cart is lonely!" />
      </Box>
      <Box theme={theme} className="empty-cart-message" mt={3}>
        <Typography
          className="text-dark"
          theme={theme}
          variant="h4"
          component="h4"
        >
          Your cart is lonely!
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="caption"
          component="small"
          display={'block'}
          mt={1}
        >
          Looks like you have removed the product(s) from your cart. Search for
          items to start adding them here.
        </Typography>
      </Box>
      <Box theme={theme} mt={3}>
        <Button
          secondary
          theme={theme}
          fullWidth
          text="Explore Products"
          onClick={() => { navigate(APP_ROUTES.PRODUCTS) }}
        />
      </Box>
    </Box>
  )
}

export default EmptyCartUI
