import { type FC } from 'react'
import { useTheme } from '@mui/material'
import { WebinarListItem } from './webinarListItemStyle'
import { Box, Typography } from '@ntpkunity/controls'
import { NavLink } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'
import parse from 'html-react-parser'
import { type IBlog } from '@shared/typings'
import PlayIcon from '../../../../shared/assets/images/play-circle.svg'
import { getThumbnail } from '@shared/helper/methods'
import { formatedDate } from '@shared/helper'

export interface IWebinarProps {
  onClick?: () => void
  webinar: IBlog
}

export const WebinarListItemComponent: FC<IWebinarProps> = ({ webinar, onClick }) => {
  const theme = useTheme()

  return (
    <WebinarListItem theme={theme} className="webinar-list-item">
      <NavLink
        to={`${APP_ROUTES.WEBINAR}/${webinar.title_url}`}
        style={{ textDecoration: 'none' }}
      >
        <Box theme={theme} className='webinar-img'>
          <img src={getThumbnail(webinar.image)} alt={webinar.title} />
          <span className="play-icon">
            <img src={PlayIcon} alt="Play" />
          </span>
        </Box>
      </NavLink>
      <Box theme={theme} className='webinar-meta' display={'flex'} alignItems={'center'} gap={1} mt={2}>
        <Typography theme={theme} variant='caption' component='small' className='text-muted fw-medium'>{formatedDate(webinar.created_at, 'DD MMM, YYYY')}</Typography>
        <span className='seperator'></span>
        <Typography theme={theme} variant='caption' component='small' className='text-muted fw-medium'>{webinar.author}</Typography>
      </Box>
      <Box theme={theme} className='webinar-content'>
        <Typography theme={theme} variant='h4' component='h4' mt={1} className='webinar-title text-truncate lines-2'>
          <a href="javascript:void(0)" onClick={onClick}>{webinar.title}</a>
        </Typography>
        <Typography theme={theme} variant='body2' component='span' mt={1} display={'block'} className='text-muted text-truncate lines-3'>{parse(webinar?.content ?? '')}</Typography>
          <Box theme={theme} mt={2} className='webinar-tags'>
          {webinar.tags?.map(({ title }, index) => (
            <Typography
              key={index}
              theme={theme}
              className="badge text-primary text-uppercase"
              variant="caption"
              component="small"
            >
              {title}
            </Typography>
          ))}
        </Box>
      </Box>
    </WebinarListItem>
  )
}
