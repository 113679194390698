import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.main-content': {
    '.text-danger': {
      color: theme.palette.error.main
    },
    '.user-mgmt-table': {
      '.control-wrap': {
        '.table-wrap': {
          '.scroll-hide': {
            borderTop: 'none',
            borderRight: 'none',
            height: 90,
            '&.spr-border': {
              '::after': {
                marginTop: 0
              }
            }
          }
        }
      },
      '.u-table-wrap': {
        '.u-table': {
          '.u-table-body': {
            tr: {
              '&:nth-child(2n+1)': {
                td: {
                  backgroundColor: theme.palette.common.white
                }
              },
              '&:hover': {
                td: {
                  backgroundColor: 'rgb(242, 244, 255)',
                  '&:first-child': {
                    borderTopLeftRadius: theme.shape.borderRadius,
                    borderBottomLeftRadius: theme.shape.borderRadius
                  },
                  '&:last-child': {
                    borderTopRightRadius: theme.shape.borderRadius,
                    borderBottomRightRadius: theme.shape.borderRadius
                  }
                }
              },
              '.loader-cell': {
                padding: 25
              }
            }
          }
        },
        '.u-table-container': {
          border: 'none',
          maxHeight: '100vh',
          '&::-webkit-scrollbar-thumb': {
            minHeight: 110
          }
        }
      }
    }
  }
}))
export const PageHeader = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.page-header': {
    padding: '0 0 24px 0',
    fontFamily: theme.typography.fontFamily,
    '&.border-bottom': {
      borderBottom: '1px solid' + theme.palette.grey[100]
    },
    '.action-area': {
      '.btn': {
        '&:not(:first-of-type)': {
          marginLeft: 16
        }
      }
    }
  }
}))
