import { useTheme } from '@mui/material'
import { type FC, useState, useEffect } from 'react'
import { Authentication } from '@modules/authentication'
import { EmailVerificationLinkComponent, type IResponse } from '@ntpkunity/controls-ums'

import { useStoreContext } from 'store/storeContext'
import {
  Box,
  Button,
  Grid,
  Icon,
  LinearProgressBar,
  Typography
} from '@ntpkunity/controls'
import { APP_ROUTES } from '@router/path'
import {
  verificationEmailSent
} from '@shared/constants'
import { useLocation, useNavigate } from 'react-router-dom'

export const EmailVerification: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [response, setResponse] = useState<IResponse>()
  const location = useLocation() as any
  const email = location?.state?.email
  const isTenant = location?.state?.isTenant as boolean

  const {
    actions: { setToaster }
  } = useStoreContext()

  useEffect(() => {
    if (response !== undefined) {
      setToaster({
        isDisplay: true,
        message: verificationEmailSent,
        type: ''
      })
    }
  }, [response])

  if (email === undefined) {
    navigate(APP_ROUTES.SIGNUP)
  }

  return (
    <Authentication>
      <Box theme={theme} mb={4} className="back-btn">
        <Button
          defaultBtn
          theme={theme}
          startIcon={<Icon name="IconLeftArrow" />}
          text="Go Back"
          onClick={() => { navigate(APP_ROUTES.SIGNUP) }
          }
        />
      </Box>
      <EmailVerificationLinkComponent
        theme={theme}
        setResponse={setResponse}
        verificationScreenUrl={`${APP_ROUTES.USER_PROFILE}`}
        email={email}
      />
      {isTenant && (
        <>
          <Box theme={theme} className="progress-steps">
            <Grid
              theme={theme}
              container
              item
              spacing={1}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Grid theme={theme} item xs={4}>
                <Box theme={theme} className="active-step progress-step">
                  <LinearProgressBar theme={theme} value={100} />
                </Box>
              </Grid>
              <Grid theme={theme} item xs={4}>
                <Box theme={theme} className="progress-step">
                  <LinearProgressBar theme={theme} value={0} />
                </Box>
              </Grid>
              <Grid theme={theme} item xs={4}>
                <Box theme={theme} className="progress-step">
                  <LinearProgressBar theme={theme} value={0} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Typography
            theme={theme}
            variant="caption"
            component="small"
            display={'block'}
            textAlign={'center'}
            mt={2}
          >
            25% Completed
          </Typography>
        </>
      )}
    </Authentication>
  )
}
