import './root.component.css'
import { StyledEngineProvider } from '@mui/material'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import StoreProvider, { useStoreContext } from './store/storeContext'
import { ModalProvider } from 'react-modal-hook'
import { BrowserRouter } from 'react-router-dom'
import { Router } from './router'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import BaseLayout from '@layout/baseLayout/baseLayout'
import { useGetProfile } from '@modules/myAccount/services'
import { type ReactElement, useEffect } from 'react'
import { marketplaceTheme } from '@shared/theme'
import { LoginValidatorComponent } from '@ntpkunity/controls-ums'
import { Helmet } from 'react-helmet'
import { FullPageLoader } from '@modules/homePage/pages/detailPage/detailPageStyle'
import { CircleLoader } from '@ntpkunity/controls'
import { isLoggedIn } from '@shared/helper'

export default function Root (props): ReactElement {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <StoreProvider>
        <LoginValidatorComponent isPublic={true} theme={marketplaceTheme} isMarketPlace={true}>
          <App {...props} />
        </LoginValidatorComponent>
      </StoreProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider >
  )
}

const App = (): ReactElement => {
  const {
    states: { userProfile },
    actions: { setUserProfile }
  } = useStoreContext()
  const { data: _userProfile, isLoading: _userProfileLoading } = useGetProfile()
  const _isLoggedIn = (isLoggedIn() ?? '').length > 0
  const userLoggedInAndUserProfileNotLoaded = _isLoggedIn && !((userProfile?.user_name ?? '').length > 0)

  useEffect(() => {
    if (Object.keys(_userProfile ?? {}).length > 0) {
      setUserProfile({
        ..._userProfile
      })
    }
  }, [_userProfile])

  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = 'hs-script-loader'
    script.src = '//js.hs-scripts.com/24225027.js'
    document.body.appendChild(script)

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  if (_userProfileLoading || userLoggedInAndUserProfileNotLoaded) {
    return (
      <FullPageLoader theme={marketplaceTheme} className="full-page-loader">
        <CircleLoader theme={marketplaceTheme} />
      </FullPageLoader>
    )
  }

  return (
    <>
      <Helmet>
        <script
          async
          type="text/javascript"
          src="https://app.termly.io/embed.min.js"
          data-auto-block="off"
          data-website-uuid={process.env.TERMLY_APP_ID}
        ></script>
      </Helmet>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={marketplaceTheme}>
          <BaseLayout>
            <ModalProvider>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </ModalProvider>
          </BaseLayout>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </>
  )
}
