import { List, Icon } from '@ntpkunity/controls'
import { ProfileMenu } from './sidebarStyle'
import { useTheme } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'
import { type FC, useEffect, useState } from 'react'
import clsx from 'clsx'
import { SidebarMenu } from '@shared/constants'
import { HelpBox } from '../helpBox/helpBoxComponent'
import { useGetSubscriptions } from '@modules/product/services/subscription/subscriptionService'
export const Sidebar: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { data: subscribedProduct } = useGetSubscriptions()
  const [scroll, setScroll] = useState(false)
  const [selectedTab, setSelectedTab] = useState(
    SidebarMenu.SUBSCRIBED_PRODUCTS
  )
  const {
    DASHBOARD,
    MY_ACCOUNT,
    BILLING_DETAILS,
    TRANSACTION_HISTORY,
    CUSTOMER_ACCOUNT,
    CREATE_USER,
    EMAIL_PREFERENCES
  } = APP_ROUTES

  useEffect(() => {
    switch (pathname) {
      case DASHBOARD:
      { setSelectedTab(SidebarMenu.DASHBOARD); return }
      case MY_ACCOUNT:
      { setSelectedTab(SidebarMenu.MY_ACCOUNT); return }
      case BILLING_DETAILS:
      { setSelectedTab(SidebarMenu.BILLING_DETAILS); return }
      case TRANSACTION_HISTORY:
      { setSelectedTab(SidebarMenu.TRANSACTION_HISTORY); return }
      case CUSTOMER_ACCOUNT:
      { setSelectedTab(SidebarMenu.SUBSCRIBED_PRODUCTS); return }
      case CREATE_USER:
      { setSelectedTab(SidebarMenu.MANAGE_USERS); return }
      case EMAIL_PREFERENCES:
      { setSelectedTab(SidebarMenu.EMAIL_PREFERENCES) }
    }
  }, [pathname])
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 200)
    })
  }, [])

  const isDashboardVisible = subscribedProduct && subscribedProduct.length > 0

  const sidebarMenu = [
    ...(isDashboardVisible
      ? [
          {
            icon: <Icon name="DashboardIcon" />,
            label: SidebarMenu.DASHBOARD,
            onClick: () => {
              setSelectedTab(SidebarMenu.DASHBOARD)
              navigate(DASHBOARD)
            },
            selected: selectedTab === SidebarMenu.DASHBOARD
          }

        ]
      : []),
    {
      icon: <Icon name="PersonIcon" />,
      label: SidebarMenu.MY_ACCOUNT,
      onClick: () => {
        setSelectedTab(SidebarMenu.MY_ACCOUNT)
        navigate(MY_ACCOUNT)
      },
      selected: selectedTab === SidebarMenu.MY_ACCOUNT
    },
    {
      icon: <Icon name="PayCardIcon" />,
      label: SidebarMenu.BILLING_DETAILS,
      onClick: () => {
        setSelectedTab(SidebarMenu.BILLING_DETAILS)
        navigate(BILLING_DETAILS)
      },
      selected: selectedTab === SidebarMenu.BILLING_DETAILS
    },
    {
      icon: <Icon name="PaperIcon" />,
      label: SidebarMenu.TRANSACTION_HISTORY,
      onClick: () => {
        setSelectedTab(SidebarMenu.TRANSACTION_HISTORY)
        navigate(TRANSACTION_HISTORY)
      },
      selected: selectedTab === SidebarMenu.TRANSACTION_HISTORY
    },
    {
      icon: <Icon name="BoxPackIcon" />,
      label: SidebarMenu.SUBSCRIBED_PRODUCTS,
      onClick: () => {
        setSelectedTab(SidebarMenu.SUBSCRIBED_PRODUCTS)
        navigate(CUSTOMER_ACCOUNT)
      },
      selected: selectedTab === SidebarMenu.SUBSCRIBED_PRODUCTS
    },
    {
      icon: <Icon name="UserManagementIcon" />,
      label: SidebarMenu.MANAGE_USERS,
      onClick: () => {
        setSelectedTab(SidebarMenu.MANAGE_USERS)
        navigate(CREATE_USER)
      },
      selected: selectedTab === SidebarMenu.MANAGE_USERS
    }
    // {
    //   icon: <Icon name="EmailEnvelopIcon" />,
    //   label: SidebarMenu.EMAIL_PREFERENCES,
    //   onClick: () => {
    //     setSelectedTab(SidebarMenu.EMAIL_PREFERENCES);
    //     navigate(EMAIL_PREFERENCES);
    //   },
    //   selected: selectedTab === SidebarMenu.EMAIL_PREFERENCES,
    // },
  ]
  return (
    <ProfileMenu theme={theme} className={clsx('profile-menu', !scroll ? '' : 'menu-fixed')}>
      <List theme={theme} options={sidebarMenu} />
      <HelpBox />
    </ProfileMenu>
  )
}
