import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.main-content': {
    paddingTop: 112,
    paddingBottom: 80,
    [theme.breakpoints.up('md')]: {
      paddingTop: 213,
      paddingBottom: 120
    },
    '.custom-container': {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 32,
        paddingRight: 32
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: 24,
        paddingRight: 24
      }
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-danger': {
      color: theme.palette.error.main
    },
    '.text-white': {
      color: theme.palette.common.white
    },
    '.fw-sb': {
      fontWeight: theme.customVariables.fontWeightSemiBold
    }
  }
}))

export const ProfileWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.profile-wrap': {
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    minHeight: 'calc(100vh - 242px)',
    '.profile-section': {
      borderBottom: '1px solid' + theme.palette.grey[100],
      paddingBottom: 32,
      marginBottom: 32,
      '&:last-child': {
        paddingBottom: 0,
        marginBotto: 0,
        border: 'none'
      },
      '.u-form-group': {
        '.MuiInputBase-adornedEnd': {
          svg: {
            path: {
              stroke: theme.palette.grey[300]
            }
          }
        }
      }
    }
  }
}))
