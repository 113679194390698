import { useTheme } from '@mui/material'

import { CalendlyPopupComponent, HomePageLayout } from 'layout'
import { Controller, useForm } from 'react-hook-form'
import { type FC, useEffect, useState } from 'react'
import { ConatctPage } from './contactUsStyle'
import { FadeAnimation, getItem, scrollOnTop, validation } from 'shared'
import { contactUsMsg, className, Validation, IS_LOGGED_IN } from '@shared/constants'
import {
  Box,
  Grid,
  Typography,
  Input,
  Button,
  Textarea,
  ContactInput,
  Container,
  Icon
} from '@ntpkunity/controls'
import { useContactUs } from '@modules/contactUs'
import DisableLoader from '@shared/assets/images/loader-disabled-btn.gif'
import ContactImage from '../../../../shared/assets/images/contact-img.svg'
import HelpCenterIcon from '../../../../shared/assets/images/ic-help-center.svg'
import ContactSalesIcon from '../../../../shared/assets/images/ic-contact-sales.svg'
import ChatIcon from '../../../../shared/assets/images/ic-chat.svg'
import FeedbackIcon from '../../../../shared/assets/images/ic-feedback.svg'
import { useStoreContext } from 'store/storeContext'
import { useModal } from 'react-modal-hook'
import { type IContactUs } from '@shared/typings'
import { Link, useLocation } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'

export const messages = {
  name: {
    firstName: 'name',
    lastName: 'last_name',
    email: 'email',
    contactNumber: 'phone',
    company: 'company',
    additionalDetails: 'additional_details'
  },
  label: {
    firstName: 'First Name',
    lastName: 'Last Name (Optional)',
    email: 'Email Address',
    contactNumber: 'Contact Number',
    company: 'Company Name',
    additionalDetails: 'Your turn! Leave your message...'
  },
  button: {
    sumbit: 'Submit',
    wait: 'Wait...'
  }
}

export const ContactUs: FC = () => {
  const theme = useTheme()
  const formMethod = useForm()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const isCalendly = queryParams.get('isCalendly')
  const { mutate: contactUs, isLoading } = useContactUs()
  const [countryFlagCode, setCountryFlagCode] = useState<string>('')
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors }
  } = formMethod
  const formValues = watch()

  const {
    states: { userProfile },
    actions: { setToaster }
  } = useStoreContext()

  const [showCalendlyPopup, hideCalendlyPopup] = useModal(
    () => <CalendlyPopupComponent hide={hideCalendlyPopup} />,
    []
  )

  useEffect(() => {
    if (userProfile != null) {
      const { first_name: name, last_name: lastName, user_name: email, company_name: companyName, contact_number: contactNumber, business_country_code: countryCode } = userProfile
      reset({
        name,
        email,
        last_name: lastName,
        phone: contactNumber,
        company: companyName,
        business_country_code: countryCode
      })
    }
  }, [userProfile])

  useEffect(() => {
    scrollOnTop()
    if ((isCalendly ?? '').length > 0) {
      showCalendlyPopup()
    }
  }, [])

  const submit = (): void => {
    contactUs(formValues as IContactUs, {
      onSuccess () {
        const isLoggedIn = getItem(IS_LOGGED_IN) as unknown as boolean
        if (userProfile !== null) {
          const { first_name: name, last_name: lastName, user_name: email, company_name: companyName, contact_number: contactNumber, business_country_code: countryCode } = userProfile
          reset({
            name: isLoggedIn ? name : '',
            email: isLoggedIn ? email : '',
            last_name: isLoggedIn ? lastName : '',
            phone: isLoggedIn ? contactNumber === null ? '' : contactNumber : '',
            company: isLoggedIn ? companyName : '',
            additional_details: ''
          })
          setCountryFlagCode(countryCode ?? 'US')
        }
        setToaster({
          isDisplay: true,
          message: contactUsMsg,
          type: ''
        })
      }
    })
  }

  return (
    <>
      <HomePageLayout>
        {// eslint-disable-next-line @typescript-eslint/no-misused-promises
        }<form onSubmit={handleSubmit(submit)}>
          <ConatctPage
            theme={theme}
            className="contact-page"
            pt={{ xs: 2, md: 4 }}
          >
            <Container theme={theme} maxWidth="lg" className="custom-container">
              <Grid
                theme={theme}
                container
                item
                spacing={{ xs: 5, md: 15 }}
                alignItems={'center'}
                justifyContent={'center'}
                pb={{ xs: 8, md: 0 }}
              >
                <Grid theme={theme} item xs={12} md={5}>
                  <FadeAnimation>
                    <Box
                      theme={theme}
                      className="contact-img"
                      textAlign={{ xs: 'center', md: 'left' }}
                    >
                      <img src={ContactImage} alt="Contact Us" />
                    </Box>
                  </FadeAnimation>

                  <Box theme={theme} pt={5} overflow={'hidden'}>
                    <FadeAnimation direction='down'>
                      <Box theme={theme}>
                        <Typography
                          theme={theme}
                          className="text-h2"
                          variant="h2"
                          component="h2"
                          textAlign={{ xs: 'center', md: 'left' }}
                        >
                          We’d love to hear from you. Get in touch!
                        </Typography>

                        <Typography
                          theme={theme}
                          className="text-body2 text-muted"
                          variant="body2"
                          component="span"
                          display={'block'}
                          mt={{ xs: 2, md: 3 }}
                          textAlign={{ xs: 'center', md: 'left' }}
                        >
                          Looking for support? We’re here to help! Get in touch if
                          you have any questions about our products, subscription or
                          need assistance, feel free to ask.
                        </Typography>
                      </Box>
                    </FadeAnimation>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={12} md={5}>
                <FadeAnimation>
                  <Box theme={theme}>
                    <Typography
                      theme={theme}
                      className="text-h3"
                      variant="h3"
                      component="h3"
                      textAlign={{ xs: 'center', md: 'left' }}
                    >
                      Fill out this form, we‘ll quickly get back to you.
                    </Typography>
                    <Box theme={theme} className="contact-form" mt={4}>
                      <Grid
                        theme={theme}
                        container
                        item
                        rowSpacing={4}
                        columnSpacing={{ xs: 2, md: 3 }}
                      >
                        <Grid theme={theme} item xs={6}>
                          <Controller
                            name={messages.name.firstName}
                            control={control}
                            rules={validation(
                              'First Name',
                              Validation.REQUIRED,
                              Validation.WHITE_SPACE,
                              Validation.LIMIT_LENGTH,
                              '50',
                              Validation.ONLY_ALPHABETS
                            )}
                            render={({ field }) => (
                              <Input
                                theme={theme}
                                fullWidth
                                label={messages.label.firstName}
                                type={'text'}
                                {...field}
                                placeholder="Type here..."
                                error={errors?.name?.message?.toString()}
                              />
                            )}
                          />
                        </Grid>
                        <Grid theme={theme} item xs={6}>
                          <Controller
                            name={messages.name.lastName}
                            control={control}
                            render={({ field }) => (
                              <Input
                            theme={theme}
                            fullWidth
                            label={messages.label.lastName}
                            type={'text'}
                            {...field}
                            placeholder="Type here..."
                          />
                            )}
                          />
                        </Grid>
                        <Grid theme={theme} item xs={12}>
                          <Controller
                            name={messages.name.email}
                            control={control}
                            rules={validation(
                              messages.label.email,
                              Validation.REQUIRED,
                              Validation.EMAIL
                            )}
                            render={({ field }) => (
                              <Input
                                theme={theme}
                                fullWidth
                                label={messages.label.email}
                                type={'text'}
                                {...field}
                                placeholder="example@example.com"
                                error={errors?.email?.message?.toString()}
                              />
                            )}
                          />
                        </Grid>
                        <Grid theme={theme} item xs={12} md={6}>
                          <Controller
                            name={messages.name.company}
                            control={control}
                            rules={validation(
                              'Company name',
                              Validation.REQUIRED,
                              Validation.WHITE_SPACE,
                              Validation.LIMIT_LENGTH,
                              '50'
                            )}
                            render={({ field }) => (
                              <Input
                                theme={theme}
                                fullWidth
                                label={messages.label.company}
                                type={'string'}
                                {...field}
                                placeholder="Type here..."
                                error={errors?.company?.message?.toString()}
                              />
                            )}
                          />
                        </Grid>
                        <Grid theme={theme} item xs={12} md={6}>
                          <Controller
                            name={messages.name.contactNumber}
                            control={control}
                            rules={validation(
                              'Contact number',
                              Validation.REQUIRED,
                              Validation.PHNONE_NUMBER
                            )}
                            render={({ field }) => (
                              <ContactInput
                                theme={theme}
                                setCountryFlagCode={setCountryFlagCode}
                                countryFlagCode={countryFlagCode}
                                phoneCountryCode={userProfile?.business_country_code as string}
                                type={'number'}
                                fullWidth
                                label="Contact Number"
                                {...field}
                                error={errors?.phone?.message?.toString()}
                              />
                            )}
                          />
                        </Grid>
                        <Grid theme={theme} item xs={12}>
                          <Controller
                            name={messages.name.additionalDetails}
                            control={control}
                            rules={validation(
                              'Additional detail',
                              Validation.REQUIRED,
                              Validation.LIMIT_LENGTH,
                              '200'
                            )}
                            render={({ field }) => (
                              <Textarea
                                id={''}
                                theme={theme}
                                fullWidth
                                label={messages.label.additionalDetails}
                                type={'string'}
                                placeholder="Type here..."
                                {...field}
                                error={errors?.additional_details?.message?.toString()}
                              />
                            )}
                          />
                        </Grid>
                        <Grid theme={theme} item xs={12}>
                          <Button
                            data-testid="submit-btn"
                            primary
                            fullWidth
                            type="submit"
                            theme={theme}
                            disabled={isLoading}
                            text={messages.button.sumbit}
                            className={className.contactUsSubmitBtn}
                            startIcon={
                              isLoading && (
                                <img src={DisableLoader} alt="Loader" />
                              )
                            }
                          ></Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  </FadeAnimation>
                </Grid>
              </Grid>

              <Box
                theme={theme}
                pt={{ xs: 8, md: 15 }}
                pb={{ xs: 8, md: 15 }}
                className="schedule-meeting-section"
              >
                <Box
                  theme={theme}
                  p={{ xs: 4, md: 8 }}
                  className="schedule-meeting-wrap"
                  display={'flex'}
                  flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                  alignItems={'center'}
                >
                  <Box theme={theme} className="plan-content" flexGrow={1}>
                    <FadeAnimation>
                      <Typography
                        theme={theme}
                        variant="h4"
                        component="h4"
                        className="text-h4"
                      >
                        Something urgent? Hop on a call...
                      </Typography>
                      <Typography
                        className="text-muted text-body2"
                        mt={1}
                        theme={theme}
                        variant="body2"
                        component="span"
                        display={'block'}
                      >
                        Schedule a 30 minute call with one of our team members and
                        get things done real quick.
                      </Typography>
                    </FadeAnimation>
                  </Box>
                  <Box
                    theme={theme}
                    className="plan-cta"
                    flexShrink={0}
                    width={{ xs: '100%', md: 'auto' }}
                    mt={{ xs: 2, md: 0 }}
                  >
                    <FadeAnimation>
                      <Button
                        theme={theme}
                        fullWidth
                        className={className.productDetailContactUsBtn}
                        secondary
                        text={'Schedule Meeting'}
                        onClick={showCalendlyPopup}
                      />
                    </FadeAnimation>
                  </Box>
                </Box>
              </Box>

              <Box
                theme={theme}
                className="contact-info-section"
                pt={{ xs: 8, md: 0 }}
                pb={{ xs: 8, md: 0 }}
              >
                <Grid
                  theme={theme}
                  container
                  item
                  spacing={3}
                  alignItems={'stretch'}
                >
                  <Grid
                    theme={theme}
                    item
                    xs={12}
                    md={3}
                    className="grid-col-item"
                  >
                    <FadeAnimation>
                      <Box
                        theme={theme}
                        className="contact-info-item"
                        pl={4}
                        pr={4}
                      >
                        <Typography
                          theme={theme}
                          display={'block'}
                          variant="caption"
                          component="small"
                          className="text-uppercase text-primary text-small"
                        >
                          Our Address
                        </Typography>
                        <Typography
                          theme={theme}
                          mt={{ xs: 1, md: 2 }}
                          display={'block'}
                          variant="body2"
                          component="span"
                          className="text-muted text-body2"
                        >
                          Corporate Headquarters, 16000 Ventura Blvd, Suite 770,
                          Encino, CA 91436, USA
                        </Typography>
                      </Box>
                    </FadeAnimation>
                  </Grid>
                  <Grid
                    theme={theme}
                    item
                    xs={12}
                    md={3}
                    className="grid-col-item"
                  >
                    <FadeAnimation delay={200}>
                      <Box
                        theme={theme}
                        className="contact-info-item"
                        pl={4}
                        pr={4}
                      >
                        <Typography
                          theme={theme}
                          display={'block'}
                          variant="caption"
                          component="small"
                          className="text-uppercase text-primary text-small"
                        >
                          OUR OFFICE HOURS
                        </Typography>
                        <Typography
                          theme={theme}
                          mt={{ xs: 1, md: 2 }}
                          display={'block'}
                          variant="body2"
                          component="span"
                          className="text-muted text-body2"
                        >
                          Monday - Friday
                          <br />
                          9:00 am to 6:00 pm
                        </Typography>
                      </Box>
                    </FadeAnimation>
                  </Grid>
                  <Grid
                    theme={theme}
                    item
                    xs={12}
                    md={3}
                    className="grid-col-item"
                  >
                    <FadeAnimation delay={400}>
                      <Box
                        theme={theme}
                        className="contact-info-item"
                        pl={4}
                        pr={4}
                      >
                        <Typography
                          theme={theme}
                          display={'block'}
                          variant="caption"
                          component="small"
                          className="text-uppercase text-primary text-small"
                        >
                          GET IN TOUCH
                        </Typography>
                        <Typography
                          theme={theme}
                          mt={{ xs: 1, md: 2 }}
                          display={'block'}
                          variant="body2"
                          component="span"
                          className="text-muted text-body2"
                        >
                          <a
                            href={`mailto:${process.env.INFO_EMAIL as string}`}
                            className="text-muted link"
                          >
                            {process.env.INFO_EMAIL}
                          </a>
                          <br />
                          +1 818 222 9195
                        </Typography>
                      </Box>
                    </FadeAnimation>
                  </Grid>
                  <Grid
                    theme={theme}
                    item
                    xs={12}
                    md={3}
                    className="grid-col-item"
                  >
                    <FadeAnimation delay={600}>
                      <Box
                        theme={theme}
                        className="contact-info-item"
                        pl={4}
                        pr={4}
                      >
                        <Typography
                          theme={theme}
                          display={'block'}
                          variant="caption"
                          component="small"
                          className="text-uppercase text-primary text-small"
                        >
                          OUR SOCIALS
                        </Typography>
                        <Box
                          theme={theme}
                          className="social"
                          mt={{ xs: 1, md: 2 }}
                        >
                          <ul>
                            <li>
                              <a
                                href="https://www.linkedin.com/showcase/netsol-flex/?viewAsMember=true"
                                target="_blank"
                                className="social-link link-in"
                                rel="noreferrer"
                              >
                                <Icon name="LinkedInIcon" />
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://twitter.com/AppexNow"
                                target="_blank"
                                className="social-link link-twt"
                                rel="noreferrer"
                              >
                                <Icon name="TwitterIcon" />
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.youtube.com/channel/UCqTySRaTv5PVWeiZfeiyCaA"
                                target="_blank"
                                className="social-link link-yt"
                                rel="noreferrer"
                              >
                                <Icon name="YoutubeIcon" />
                              </a>
                            </li>
                            <li>
                              <a
                                href={`${process.env.MEDIUM_CHANNEL as string}`}
                                target="_blank"
                                className="social-link link-medium"
                                rel="noreferrer"
                              >
                                <Icon name="MediumIcon" />
                              </a>
                            </li>
                          </ul>
                        </Box>
                      </Box>
                    </FadeAnimation>
                  </Grid>
                </Grid>
              </Box>

              <Box
                theme={theme}
                className="contact-help-section"
                pt={{ xs: 8, md: 15 }}
                pb={{ xs: 5.75, md: 4.75 }}
              >
                <Grid
                  theme={theme}
                  container
                  item
                  spacing={{ xs: 2, md: 3 }}
                  alignItems={'stretch'}
                >
                  <Grid theme={theme} item xs={12} md={3}>
                    <Box theme={theme} className="contact-help-box">
                      <FadeAnimation>
                        <Box theme={theme} className="icon" mb={2}>
                          <img src={HelpCenterIcon} alt="Help Center" />
                        </Box>
                        <Typography
                          theme={theme}
                          variant="h4"
                          component="h4"
                          className="text-h4"
                        >
                          Help Center
                        </Typography>
                        <Typography
                          theme={theme}
                          variant="body2"
                          mt={{ xs: 1, md: 2 }}
                          component="span"
                          display={'block'}
                          className="text-muted text-body2"
                        >
                          Any technical questions? We are here to lend a hand.
                        </Typography>
                        <Typography
                          theme={theme}
                          variant="body2"
                          mt={2}
                          component="span"
                          display={'block'}
                          className="text-primary text-body2"
                        >
                          <a
                            href={`mailto:${process.env.SUPPORT_EMAIL as string}`}
                            className="text-primary link"
                          >
                            Contact support
                          </a>
                        </Typography>
                      </FadeAnimation>
                    </Box>
                  </Grid>
                  <Grid theme={theme} item xs={12} md={3}>
                    <Box theme={theme} className="contact-help-box">
                      <FadeAnimation delay={200}>
                        <Box theme={theme} className="icon" mb={2}>
                          <img src={ContactSalesIcon} alt="Contact Sales" />
                        </Box>
                        <Typography
                          theme={theme}
                          variant="h4"
                          component="h4"
                          className="text-h4"
                        >
                          Contact Sales
                        </Typography>
                        <Typography
                          theme={theme}
                          variant="body2"
                          mt={{ xs: 1, md: 2 }}
                          component="span"
                          display={'block'}
                          className="text-muted text-body2"
                        >
                          {"We have solutions adapted to all your needs. Let's talk about it!"}
                        </Typography>
                        <Typography
                          theme={theme}
                          variant="body2"
                          mt={2}
                          component="span"
                          display={'block'}
                          className="text-primary text-body2"
                        >
                          <a
                            href={`mailto:${process.env.SALES_EMAIL as string}`}
                            className="text-primary link"
                          >
                            Contact our experts
                          </a>
                        </Typography>
                      </FadeAnimation>
                    </Box>
                  </Grid>
                  <Grid theme={theme} item xs={12} md={3}>
                    <Box theme={theme} className="contact-help-box">
                      <FadeAnimation delay={400}>
                      <Box theme={theme} className="icon" mb={2}>
                        <img src={ChatIcon} alt="Chat With Us!" />
                      </Box>
                      <Typography
                        theme={theme}
                        variant="h4"
                        component="h4"
                        className="text-h4"
                      >
                        Chat With Us!
                      </Typography>
                      <Typography
                        theme={theme}
                        variant="body2"
                        mt={{ xs: 1, md: 2 }}
                        component="span"
                        display={'block'}
                        className="text-muted text-body2"
                      >
                        Our support team typically responds within a few hours.
                      </Typography>
                      <Typography
                        theme={theme}
                        variant="body2"
                        mt={2}
                        component="span"
                        display={'block'}
                        className="text-primary text-body2"
                      >
                        <a
                          href="javascript:void(0)"
                          className="text-primary link"
                        >
                          Start conversation
                        </a>
                      </Typography>
                      </FadeAnimation>
                    </Box>
                  </Grid>
                  <Grid theme={theme} item xs={12} md={3}>
                    <Box theme={theme} className="contact-help-box">
                    <FadeAnimation delay={600}>
                      <Box theme={theme} className="icon" mb={2}>
                        <img src={FeedbackIcon} alt="Give Feedback" />
                      </Box>
                      <Typography
                        theme={theme}
                        variant="h4"
                        component="h4"
                        className="text-h4"
                      >
                        Give Feedback
                      </Typography>
                      <Typography
                        theme={theme}
                        variant="body2"
                        mt={{ xs: 1, md: 2 }}
                        component="span"
                        display={'block'}
                        className="text-muted text-body2"
                      >
                        Tell us about your ideas, issues, experience and
                        suggestions.
                      </Typography>
                      <Typography
                        theme={theme}
                        variant="body2"
                        mt={2}
                        component="span"
                        display={'block'}
                        className="text-primary text-body2"
                      >
                      <Link className="text-primary link"
                        to={APP_ROUTES.FEEDBACK}
                        >
                          Give us feedback
                      </Link>
                      </Typography>
                      </FadeAnimation>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </ConatctPage>
        </form>
      </HomePageLayout>
    </>
  )
}
