import { type FC } from 'react'
import { useTheme } from '@mui/material'
import { ProductFeaturesWrap } from './productFeaturesStyle'
import { Box, Grid, Typography } from '@ntpkunity/controls'
import { type IProduct, type IFeatureDetails } from '@shared/typings'
import { splitFeatureVariant } from '@shared/helper'
import { Col6Template } from './Col6Template'
import { Col12Template } from './Col12Template'
import { gridLayoutFormat } from '@shared/constants'
import { FadeAnimation } from '@shared/components'

interface IProductFeaturessProps {
  product?: IProduct
  productFeatures: IFeatureDetails[] | undefined
}

export const ProductFeaturesComponent: FC<IProductFeaturessProps> = ({ product, productFeatures }) => {
  const theme = useTheme()

  return (
    <ProductFeaturesWrap theme={theme} className='product-features-wrap'>
      <Box
        theme={theme}
        className="section-head"
        textAlign={'center'}
      >
        <FadeAnimation direction='up'>
        <Typography
          display={'inline-block'}
          mb={{ xs: 2, md: 3 }}
          theme={theme}
          className="badge text-primary text-uppercase"
          variant="caption"
          component="small"
        >
          PRODUCT FEATURES
        </Typography>
        <Typography
          theme={theme}
          variant="h2"
          component="h2"
          className="text-h2"
        >
          {product?.name} Features at a Glance!
        </Typography>
        <Typography
          theme={theme}
          className="text-muted text-body2"
          variant="body1"
          component="p"
          mt={{ xs: 2, md: 1 }}
        >
          Discover why {product?.name} is an ideal solution for your business needs.
        </Typography>
        </FadeAnimation>
      </Box>

      <Box theme={theme} className='feature-items-wrap' mt={{ xs: 5, md: 8 }}>
        <Grid theme={theme} item container spacing={3}>
          {
            productFeatures?.map((feature: IFeatureDetails, index: number) => {
              const [className, gridLayout] = splitFeatureVariant(feature.feature_variant)

              return gridLayout === gridLayoutFormat.COL_6
                ? <Col6Template key={index} className={className} gridLayout={gridLayout} feature={feature} />
                : <Col12Template key={index} className={className} gridLayout={gridLayout} feature={feature} />
            })
          }
        </Grid>
      </Box>
    </ProductFeaturesWrap>
  )
}
