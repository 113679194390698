import { type ReactElement, useEffect } from 'react'
import { useTheme } from '@mui/material'
import { Header, Footer } from 'layout'
import { scrollOnTop } from '@shared/helper/methods'
import { MainContent } from './privacyPolicyStyle'
import { Accordion, Box, Container, Typography } from '@ntpkunity/controls'

export const PrivacyPolicy = (): ReactElement => {
  const theme = useTheme()

  useEffect(() => {
    scrollOnTop()
  }, [])

  return (
    <>
      <Header theme={theme} />
      <MainContent theme={theme} className="main-content">
        <Container theme={theme} maxWidth="lg" className="custom-container">
          <Typography mb={4} theme={theme} variant="h2" component="h2" data-testid="privacy-policy">
            Privacy Policy
          </Typography>
          <Box theme={theme} className="policy-content">
            <Typography
              theme={theme}
              variant="subtitle2"
              component="span"
              display={'block'}
            >
              General Principles:
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              NETSOL Technologies, Inc. (” NETSOL “) understands that your
              privacy is important to you and that you care about how your
              personal data is used and shared online. We respect and value the
              privacy of everyone who visits this website, {process.env.PROD_DOMAIN_URL}{' '}
              (“Our Site”) and will only collect and use personal data in ways
              that are described here, and in a manner that is consistent with
              our obligations and your rights under the law.
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              Please read this Privacy Policy and Our Cookie Policy which
              constitutes part of this agreement carefully and ensure that you
              understand it. Your acceptance of our Privacy Policy is deemed to
              occur upon your first use of Our Site. If you do not accept and
              agree with this Privacy Policy, you must stop using our Site
              immediately.
            </Typography>
            <Typography
              theme={theme}
              variant="subtitle2"
              component="span"
              display={'block'}
            >
              What Does This Policy Cover?
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              This Privacy Policy applies only to your use of our site. Our site
              may contain links to other websites. Please note that we have no
              control over how your data is collected, stored, or used by other
              websites and we advise you to check the privacy policies of any
              such websites before providing any data to them.
            </Typography>
            <Typography
              theme={theme}
              variant="subtitle2"
              component="span"
              display={'block'}
            >
              What Data is Being Collected?
            </Typography>
            <Typography
              theme={theme}
              variant="subtitle2"
              component="span"
              display={'block'}
            >
              Our Websites:
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              {process.env.FLEX_MARKETING_DOMAIN}, {process.env.PROD_DOMAIN_URL}
              <br />
              Data we collect from you through our websites include your name,
              job title, business / company name, email and phone number. These
              are details you provide when engaging with us on our contact us /
              demo request forms. Our site may also place and access certain
              first party cookies on your computer or device. These cookies are
              detailed in our Cookie Policy. This policy is incorporated into
              this Privacy Policy by this reference.
            </Typography>
            <Typography
              theme={theme}
              variant="subtitle2"
              component="span"
              display={'block'}
            >
              Job Applicants:
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              As part of any recruitment process NETSOL collects and processes
              personal data relating to job applicants. We will collect your
              work history, contact details and any other information you have
              on your CV. We will always be clear about what we are asking for
              and why, and we will always ask for your consent to provide the
              information we need to adhere to local employment law.
            </Typography>
            <Typography
              theme={theme}
              variant="subtitle2"
              component="span"
              display={'block'}
            >
              How Do We Use Your Data?
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              All personal data is processed and stored securely, for no longer
              than is necessary in light of the reason(s) for which it was first
              collected. We will comply with our obligations and safeguard your
              rights under the GDPR at all times.
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              Our use of your personal data will always have a lawful basis,
              either because it is necessary for our performance of a contract
              with you, because you have consented to our use of your personal
              data (e.g. by subscribing to emails), or because it is in our
              legitimate interests. Specifically, we may use your data for the
              following purposes:
            </Typography>
            <ul className="list">
              <li>
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="span"
                  display={'block'}
                >
                  General Principles:
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                >
                  NETSOL Technologies, Inc. (” NETSOL “) understands that your
                  privacy is important to you and that you care about how your
                  personal data is used and shared online. We respect and value the
                  privacy of everyone who visits this website, {process.env.PROD_DOMAIN_URL}{' '}
                  (“Our Site”) and will only collect and use personal data in ways
                  that are described here, and in a manner that is consistent with
                  our obligations and your rights under the law.
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                >
                  Please read this Privacy Policy and Our Cookie Policy which
                  constitutes part of this agreement carefully and ensure that you
                  understand it. Your acceptance of our Privacy Policy is deemed to
                  occur upon your first use of Our Site. If you do not accept and
                  agree with this Privacy Policy, you must stop using our Site
                  immediately.
                </Typography>
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="span"
                  display={'block'}
                >
                  What Does This Policy Cover?
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                >
                  This Privacy Policy applies only to your use of our site. Our site
                  may contain links to other websites. Please note that we have no
                  control over how your data is collected, stored, or used by other
                  websites and we advise you to check the privacy policies of any
                  such websites before providing any data to them.
                </Typography>
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="span"
                  display={'block'}
                >
                  What Data is Being Collected?
                </Typography>
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="span"
                  display={'block'}
                >
                  Our Websites:
                </Typography>
              </li>
            </ul>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              With your permission and/or where permitted by law, we may also
              use your data for marketing purposes which may include contacting
              you by email and/or telephone with information, news and offers on
              our products and services. We will not, however, send you any
              unsolicited marketing or spam and will take all reasonable steps
              to ensure that we fully protect your rights and comply with our
              obligations under the GDPR and the Privacy and Electronic
              Communications (EC Directive) Regulations 2003.
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              You have the right to withdraw your consent to us using your
              personal data at any time, and to request that we delete it. You
              can do this by using the contact details provided in this Privacy
              Policy.
            </Typography>
            <Typography
              theme={theme}
              variant="subtitle2"
              component="span"
              display={'block'}
            >
              How Long Do We Keep Your Data?
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              We do not keep your personal data for any longer than is necessary
              in light of the reason(s) for which it was first collected. Data
              will therefore be retained for 2 years following its last use.
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              If you’ve applied for a job with us:
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              If you were unsuccessful, we will hold your application data for 8
              months from the date of decision and we may contact you with
              details of relevant posts during that time. At the end of this
              period, we will delete your information.
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              If you become a NETSOL employee, the data we gather about you will
              become part of your employee records. We will inform you during
              your employment of the retention periods of your data after you
              leave.
            </Typography>
            <Typography
              theme={theme}
              variant="subtitle2"
              component="span"
              display={'block'}
            >
              How and Where Do We Store Your Data?
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              We only keep your personal data for as long as we need to in order
              to use it as described above and/or for as long as we have your
              permission to keep it.
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              Some or all of your data may be stored outside of the European
              Economic Area (“the EEA”) (The EEA consists of all EU member
              states, plus Norway, Iceland, and Liechtenstein). You are deemed
              to accept and agree to this by using our site and submitting
              information to us. If we do store data outside the EEA, We will
              take all reasonable steps to ensure that your data is treated as
              safely and securely as it would be within the UK and under the
              GDPR including using GDPR Compliant suppliers to process and store
              Personal Data.
            </Typography>
            <Typography
              theme={theme}
              variant="subtitle2"
              component="span"
              display={'block'}
            >
              Do We Share Your Data?
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              Here at NETSOL we take your privacy seriously and the information
              we hold about you is confidential. We may share your information
              with other companies in our group for providing you with our
              products and services, maintaining your account and monitoring the
              use of our products and services. This includes our parent
              companies and its subsidiaries.
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              We may sometimes contract with third parties to supply products
              and services to you on our behalf. These may include payment
              processing, delivery of goods, search engine facilities,
              advertising, and marketing. In some cases, the third parties may
              require access to some or all of your data. Where any of your data
              is required for such a purpose, we will take all reasonable steps
              to ensure that your data will be handled safely, securely, and in
              accordance with your rights, our obligations, and the obligations
              of the third party under the law.
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              We may compile statistics about the use of our site including data
              on traffic, usage patterns, user numbers, sales, and other
              information. All such data will be anonymised and will not include
              any personally identifying data, or any anonymised data that can
              be combined with other data and used to identify you. We may from
              time to time share such data with third parties such as
              prospective investors, affiliates, partners, and advertisers. Data
              will only be shared and used within the bounds of the law.
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              We may sometimes use third party data processors that are located
              outside of the European Economic Area (“the EEA”) (The EEA
              consists of all EU member states, plus Norway, Iceland, and
              Liechtenstein). Where we transfer any personal data outside the
              EEA, we will take all reasonable steps to ensure that your data is
              treated as safely and securely as it would be within the UK and
              under the GDPR.
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              In certain circumstances, we may be legally required to share
              certain data held by us, which may include your personal data, for
              example, where we are involved in legal proceedings, where we are
              complying with legal requirements, a court order, or a
              governmental authority.
            </Typography>
            <Typography
              theme={theme}
              variant="subtitle2"
              component="span"
              display={'block'}
            >
              Links to Other Websites
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              Please note that this data Privacy Policy only applies to NETSOL
              Technologies Ltd. If you follow a link to another site from this
              one, we are not responsible for the safety of the information you
              give there. You are responsible for reviewing and abiding by the
              privacy policies of these third party sites to ensure they comply
              with the data protection.
            </Typography>
            <Typography
              theme={theme}
              variant="subtitle2"
              component="span"
              display={'block'}
            >
              What Happens If Our Business Changes Hands?
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              We may, from time to time, expand or reduce our business and this
              may involve the sale and/or the transfer of control of all or part
              of our business. Any personal data that you have provided will,
              where it is relevant to any part of our business that is being
              transferred, be transferred along with that part and the new owner
              or newly controlling party will, under the terms of this Privacy
              Policy, be permitted to use that data only for the same purposes
              for which it was originally collected by Us.
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              In the event that any of your data is to be transferred in such a
              manner, you will not be contacted in advance.
            </Typography>
            <Typography
              theme={theme}
              variant="subtitle2"
              component="span"
              display={'block'}
            >
              Your Rights and How to Contact Us:
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              As a data subject within the EU, you have the following rights
              under the GDPR, which this Policy and our use of personal data
              have been designed to uphold:
            </Typography>
            <ul className="list">
              <li>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                >
                  The right to be informed about our collection and use of
                  personal data
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                >
                  The right of access to the personal data we hold about you.
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                >
                  The right to rectification if any personal data we hold about
                  you is inaccurate or incomplete.
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                >
                  The right to be forgotten – i.e. the right to ask us to delete
                  any personal data We hold about you (We only hold your
                  personal data for a limited time, as explained in this policy
                  but if you would like us to delete it sooner, please contact
                  us.
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                >
                  The right to restrict (i.e. prevent) the processing of your
                  personal data.
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                >
                  The right to data portability (obtaining a copy of your
                  personal data to re-use with another service or organisation).
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                >
                  The right to object to us using your personal data for
                  particular purposes and Rights with respect to automated
                  decision-making and profiling.
                  <br />
                  Data we collect from you through our websites include your name,
                  job title, business / company name, email and phone number. These
                  are details you provide when engaging with us on our contact us /
                  demo request forms. Our site may also place and access certain
                  first party cookies on your computer or device. These cookies are
                  detailed in our Cookie Policy. This policy is incorporated into
                  this Privacy Policy by this reference.
                </Typography>
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="span"
                  display={'block'}
                >
                  Job Applicants:
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  As part of any recruitment process NETSOL collects and processes
                  personal data relating to job applicants. We will collect your
                  work history, contact details and any other information you have
                  on your CV. We will always be clear about what we are asking for
                  and why, and we will always ask for your consent to provide the
                  information we need to adhere to local employment law.
                </Typography>
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="span"
                  display={'block'}
                >
                  How Do We Use Your Data?
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  All personal data is processed and stored securely, for no longer
                  than is necessary in light of the reason(s) for which it was first
                  collected. We will comply with our obligations and safeguard your
                  rights under the GDPR at all times.
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  Our use of your personal data will always have a lawful basis,
                  either because it is necessary for our performance of a contract
                  with you, because you have consented to our use of your personal
                  data (e.g. by subscribing to emails), or because it is in our
                  legitimate interests. Specifically, we may use your data for the
                  following purposes:
                </Typography>
                <ul className="list">
                  <li>
                    <Typography
                      className="text-muted"
                      theme={theme}
                      variant="body2"
                      component="span"
                      display={'block'}
                    >
                      Providing and managing your Account.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="text-muted"
                      theme={theme}
                      variant="body2"
                      component="span"
                      display={'block'}
                    >
                      Providing and managing your access to our Site.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="text-muted"
                      theme={theme}
                      variant="body2"
                      component="span"
                      display={'block'}
                    >
                      Supplying our products and services to you (please note that
                      We require your personal data in order to enter into a
                      contract with you).
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="text-muted"
                      theme={theme}
                      variant="body2"
                      component="span"
                      display={'block'}
                    >
                      Personalising and tailoring our products and services for you.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="text-muted"
                      theme={theme}
                      variant="body2"
                      component="span"
                      display={'block'}
                    >
                      Replying to emails from you.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="text-muted"
                      theme={theme}
                      variant="body2"
                      component="span"
                      display={'block'}
                    >
                      Supplying you with emails that you have opted into (you may
                      unsubscribe or opt-out at any time by clicking unsubscribe.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="text-muted"
                      theme={theme}
                      variant="body2"
                      component="span"
                      display={'block'}
                    >
                      Analysing your use of our Site and gathering feedback to
                      enable us to continually improve our Site and your user
                      experience.
                    </Typography>
                  </li>
                </ul>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  With your permission and/or where permitted by law, we may also
                  use your data for marketing purposes which may include contacting
                  you by email and/or telephone with information, news and offers on
                  our products and services. We will not, however, send you any
                  unsolicited marketing or spam and will take all reasonable steps
                  to ensure that we fully protect your rights and comply with our
                  obligations under the GDPR and the Privacy and Electronic
                  Communications (EC Directive) Regulations 2003.
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  You have the right to withdraw your consent to us using your
                  personal data at any time, and to request that we delete it. You
                  can do this by using the contact details provided in this Privacy
                  Policy.
                </Typography>
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="span"
                  display={'block'}
                >
                  How Long Do We Keep Your Data?
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  We do not keep your personal data for any longer than is necessary
                  in light of the reason(s) for which it was first collected. Data
                  will therefore be retained for 2 years following its last use.
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  If you’ve applied for a job with us:
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  If you were unsuccessful, we will hold your application data for 8
                  months from the date of decision and we may contact you with
                  details of relevant posts during that time. At the end of this
                  period, we will delete your information.
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  If you become a NETSOL employee, the data we gather about you will
                  become part of your employee records. We will inform you during
                  your employment of the retention periods of your data after you
                  leave.
                </Typography>
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="span"
                  display={'block'}
                >
                  How and Where Do We Store Your Data?
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  We only keep your personal data for as long as we need to in order
                  to use it as described above and/or for as long as we have your
                  permission to keep it.
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  Some or all of your data may be stored outside of the European
                  Economic Area (“the EEA”) (The EEA consists of all EU member
                  states, plus Norway, Iceland, and Liechtenstein). You are deemed
                  to accept and agree to this by using our site and submitting
                  information to us. If we do store data outside the EEA, We will
                  take all reasonable steps to ensure that your data is treated as
                  safely and securely as it would be within the UK and under the
                  GDPR including using GDPR Compliant suppliers to process and store
                  Personal Data.
                </Typography>
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="span"
                  display={'block'}
                >
                  Do We Share Your Data?
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  Here at NETSOL we take your privacy seriously and the information
                  we hold about you is confidential. We may share your information
                  with other companies in our group for providing you with our
                  products and services, maintaining your account and monitoring the
                  use of our products and services. This includes our parent
                  companies and its subsidiaries.
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  We may sometimes contract with third parties to supply products
                  and services to you on our behalf. These may include payment
                  processing, delivery of goods, search engine facilities,
                  advertising, and marketing. In some cases, the third parties may
                  require access to some or all of your data. Where any of your data
                  is required for such a purpose, we will take all reasonable steps
                  to ensure that your data will be handled safely, securely, and in
                  accordance with your rights, our obligations, and the obligations
                  of the third party under the law.
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  We may compile statistics about the use of our site including data
                  on traffic, usage patterns, user numbers, sales, and other
                  information. All such data will be anonymised and will not include
                  any personally identifying data, or any anonymised data that can
                  be combined with other data and used to identify you. We may from
                  time to time share such data with third parties such as
                  prospective investors, affiliates, partners, and advertisers. Data
                  will only be shared and used within the bounds of the law.
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  We may sometimes use third party data processors that are located
                  outside of the European Economic Area (“the EEA”) (The EEA
                  consists of all EU member states, plus Norway, Iceland, and
                  Liechtenstein). Where we transfer any personal data outside the
                  EEA, we will take all reasonable steps to ensure that your data is
                  treated as safely and securely as it would be within the UK and
                  under the GDPR.
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  In certain circumstances, we may be legally required to share
                  certain data held by us, which may include your personal data, for
                  example, where we are involved in legal proceedings, where we are
                  complying with legal requirements, a court order, or a
                  governmental authority.
                </Typography>
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="span"
                  display={'block'}
                >
                  Links to Other Websites
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  Please note that this data Privacy Policy only applies to NETSOL
                  Technologies Ltd. If you follow a link to another site from this
                  one, we are not responsible for the safety of the information you
                  give there. You are responsible for reviewing and abiding by the
                  privacy policies of these third party sites to ensure they comply
                  with the data protection.
                </Typography>
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="span"
                  display={'block'}
                >
                  What Happens If Our Business Changes Hands?
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  We may, from time to time, expand or reduce our business and this
                  may involve the sale and/or the transfer of control of all or part
                  of our business. Any personal data that you have provided will,
                  where it is relevant to any part of our business that is being
                  transferred, be transferred along with that part and the new owner
                  or newly controlling party will, under the terms of this Privacy
                  Policy, be permitted to use that data only for the same purposes
                  for which it was originally collected by Us.
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  In the event that any of your data is to be transferred in such a
                  manner, you will not be contacted in advance.
                </Typography>
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="span"
                  display={'block'}
                >
                  Your Rights and How to Contact Us:
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  As a data subject within the EU, you have the following rights
                  under the GDPR, which this Policy and our use of personal data
                  have been designed to uphold:
                </Typography>
                <ul className="list">
                  <li>
                    <Typography
                      className="text-muted"
                      theme={theme}
                      variant="body2"
                      component="span"
                      display={'block'}
                    >
                      The right to be informed about our collection and use of
                      personal data
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="text-muted"
                      theme={theme}
                      variant="body2"
                      component="span"
                      display={'block'}
                    >
                      The right of access to the personal data we hold about you.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="text-muted"
                      theme={theme}
                      variant="body2"
                      component="span"
                      display={'block'}
                    >
                      The right to rectification if any personal data we hold about
                      you is inaccurate or incomplete.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="text-muted"
                      theme={theme}
                      variant="body2"
                      component="span"
                      display={'block'}
                    >
                      The right to be forgotten – i.e. the right to ask us to delete
                      any personal data We hold about you (We only hold your
                      personal data for a limited time, as explained in this policy
                      but if you would like us to delete it sooner, please contact
                      us.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="text-muted"
                      theme={theme}
                      variant="body2"
                      component="span"
                      display={'block'}
                    >
                      The right to restrict (i.e. prevent) the processing of your
                      personal data.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="text-muted"
                      theme={theme}
                      variant="body2"
                      component="span"
                      display={'block'}
                    >
                      The right to data portability (obtaining a copy of your
                      personal data to re-use with another service or organisation).
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="text-muted"
                      theme={theme}
                      variant="body2"
                      component="span"
                      display={'block'}
                    >
                      The right to object to us using your personal data for
                      particular purposes and Rights with respect to automated
                      decision-making and profiling.
                      <br />
                      You can exercise any of these rights by contacting our EU GDPR
                      representative, Joanne Hope:
                    </Typography>
                  </li>
                </ul>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  Tel: +1 818 222 9195 Email:{' '}
                  <a
                    className="text-primary"
                    href={`mailto:${process.env.INFO_EMAIL as string}`}
                  >
                    {process.env.INFO_EMAIL}
                  </a>{' '}
                  Post: Corporate Headquarters, 16000 Ventura Blvd, Suite 770,
                  Encino, CA 91436, USA
                  <br />
                  We’ll respond to your request without delay and at the latest
                  within one month of your request.
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  You can use the same contact details if you have any cause for
                  complaint about our use of your personal data. We will do our best
                  to solve the problem for you. If we are unable to help, you also
                  have the right to lodge a complaint with the UK’s supervisory
                  authority, the Information Commissioner’s Office{' '}
                  <a className="text-primary" href="https://ico.org.uk">
                    https://ico.org.uk
                  </a>
                  . For further information about your rights, please contact the
                  Information Commissioner’s Office or your local Citizens Advice
                  Bureau.
                </Typography>
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="span"
                  display={'block'}
                >
                  How Can You Control Your Data?
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  When you submit personal data via our site, you may be given
                  options to restrict our use of your data. In particular, we aim to
                  give you strong controls on our use of your data for direct
                  marketing purposes (including the ability to opt-out of receiving
                  emails from us which you may do by unsubscribing using the links
                  provided in our emails and at the point of providing your
                  details).
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  You may also wish to sign up to one or more of the preference
                  services operating in the UK: The Telephone Preference Service
                  (“the TPS”), the Corporate Telephone Preference Service (“the
                  CTPS”), and the Mailing Preference Service (“the MPS”). These may
                  help to prevent you receiving unsolicited marketing. Please note,
                  however, that these services will not prevent you from receiving
                  marketing communications that you have consented to receiving.
                </Typography>
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="span"
                  display={'block'}
                >
                  How Can You Access Your Data?
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  You have the right to ask for a copy of any of your personal data
                  held by us (where such data is held). Under the GDPR, no fee is
                  payable and we will provide any and all information in response to
                  your request free of charge. Please contact us using the contact
                  details provided in this privacy policy.
                </Typography>
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="span"
                  display={'block'}
                >
                  Your Right to Withhold Information
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  You may access our site without providing any data at all.
                  However, to use all features and functions available you may be
                  required to submit or allow for the collection of certain data.
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  You may restrict our use of Cookies. For more information, see our{' '}
                  <a
                    className="text-primary"
                    href="https://netsoltech.com/cookies-policy"
                  >
                    Cookie Policy
                  </a>
                  .
                </Typography>
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="span"
                  display={'block'}
                >
                  Changes to Our Privacy Policy
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mb={2}
                >
                  We may change this Privacy Policy from time to time (for example,
                  if the law changes). Any changes will be immediately posted on our
                  site and you will be deemed to have accepted the terms of the
                  Privacy Policy on your first use of our site following the
                  alterations. We recommend that you check this page regularly to
                  keep up-to-date.
                </Typography>
              </li>
            </ul>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              Tel: +1 818 222 9195 Email:{' '}
              <a
                className="text-primary"
                href={`mailto:${process.env.INFO_EMAIL as string}`}
              >
                {process.env.INFO_EMAIL}
              </a>{' '}
              Post: Corporate Headquarters, 16000 Ventura Blvd, Suite 770,
              Encino, CA 91436, USA
              <br />
              We’ll respond to your request without delay and at the latest
              within one month of your request.
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              You can use the same contact details if you have any cause for
              complaint about our use of your personal data. We will do our best
              to solve the problem for you. If we are unable to help, you also
              have the right to lodge a complaint with the UK’s supervisory
              authority, the Information Commissioner’s Office{' '}
              <a className="text-primary" href="https://ico.org.uk">
                https://ico.org.uk
              </a>
              . For further information about your rights, please contact the
              Information Commissioner’s Office or your local Citizens Advice
              Bureau.
            </Typography>
            <Typography
              theme={theme}
              variant="subtitle2"
              component="span"
              display={'block'}
            >
              How Can You Control Your Data?
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              When you submit personal data via our site, you may be given
              options to restrict our use of your data. In particular, we aim to
              give you strong controls on our use of your data for direct
              marketing purposes (including the ability to opt-out of receiving
              emails from us which you may do by unsubscribing using the links
              provided in our emails and at the point of providing your
              details).
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              You may also wish to sign up to one or more of the preference
              services operating in the UK: The Telephone Preference Service
              (“the TPS”), the Corporate Telephone Preference Service (“the
              CTPS”), and the Mailing Preference Service (“the MPS”). These may
              help to prevent you receiving unsolicited marketing. Please note,
              however, that these services will not prevent you from receiving
              marketing communications that you have consented to receiving.
            </Typography>
            <Typography
              theme={theme}
              variant="subtitle2"
              component="span"
              display={'block'}
            >
              How Can You Access Your Data?
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              You have the right to ask for a copy of any of your personal data
              held by us (where such data is held). Under the GDPR, no fee is
              payable and we will provide any and all information in response to
              your request free of charge. Please contact us using the contact
              details provided in this privacy policy.
            </Typography>
            <Typography
              theme={theme}
              variant="subtitle2"
              component="span"
              display={'block'}
            >
              Your Right to Withhold Information
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              You may access our site without providing any data at all.
              However, to use all features and functions available you may be
              required to submit or allow for the collection of certain data.
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              You may restrict our use of Cookies. For more information, see our{' '}
              <a
                className="text-primary"
                href="https://netsoltech.com/cookies-policy"
              >
                Cookie Policy
              </a>
              .
            </Typography>
            <Typography
              theme={theme}
              variant="subtitle2"
              component="span"
              display={'block'}
            >
              Changes to Our Privacy Policy
            </Typography>
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
              display={'block'}
              mb={2}
            >
              We may change this Privacy Policy from time to time (for example,
              if the law changes). Any changes will be immediately posted on our
              site and you will be deemed to have accepted the terms of the
              Privacy Policy on your first use of our site following the
              alterations. We recommend that you check this page regularly to
              keep up-to-date.
            </Typography>

            <Box theme={theme} className="cppa-policy custom-accordion" mt={5}>
              <Accordion
                theme={theme}
                varient="filters"
                items={[
                  {
                    content: (
                      <>
                        <Typography
                          mb={4}
                          theme={theme}
                          variant="h2"
                          component="h2"
                        >
                          Privacy Policy CCPA
                        </Typography>
                        <Typography
                          theme={theme}
                          variant="subtitle2"
                          component="span"
                          display={'block'}
                        >
                          Categories of Personal Information We Collect:
                        </Typography>
                        <Typography
                          className="text-muted"
                          theme={theme}
                          variant="body2"
                          component="span"
                          display={'block'}
                          mb={2}
                        >
                          The California Consumer Privacy Act (“CCPA”) obliges
                          us to reveal certain information regarding categories
                          of personal info that we collect. We gather the listed
                          categories of personal information (subject to
                          applicable legal requirements and restrictions):
                        </Typography>
                        <ul className="list">
                          <li>
                            <Typography
                              mb={4}
                              theme={theme}
                              variant="body2"
                              component="span"
                              display={'block'}
                            >
                              Privacy Policy CCPA
                            </Typography>
                            <Typography
                              theme={theme}
                              variant="subtitle2"
                              component="span"
                              display={'block'}
                            >
                              Categories of Personal Information We Collect:
                            </Typography>
                            <Typography
                              className="text-muted"
                              theme={theme}
                              variant="body2"
                              component="span"
                              display={'block'}
                            >
                              The California Consumer Privacy Act (“CCPA”) obliges
                              us to reveal certain information regarding categories
                              of personal info that we collect. We gather the listed
                              categories of personal information (subject to
                              applicable legal requirements and restrictions):
                            </Typography>
                          </li>
                        </ul>
                        <Typography
                          theme={theme}
                          variant="subtitle2"
                          component="span"
                          display={'block'}
                        >
                          Categories of Personal Information Disclosed.
                        </Typography>
                        <Typography
                          className="text-muted"
                          theme={theme}
                          variant="body2"
                          component="span"
                          display={'block'}
                          mb={2}
                        >
                          CCPA requires that we disclose the categories of
                          personal information that we may reveal for a
                          commercial purpose. In general, we may disclose the
                          following categories of personal information in
                          support of our business purposes identified above:
                        </Typography>
                        <ul className="list">
                          <li>
                            <Typography
                              theme={theme}
                              variant="subtitle2"
                              component="span"
                              display={'block'}
                            >
                              Categories of Personal Information Disclosed.
                            </Typography>
                            <Typography
                              className="text-muted"
                              theme={theme}
                              variant="body2"
                              component="span"
                              display={'block'}
                            >
                              CCPA requires that we disclose the categories of
                              personal information that we may reveal for a
                              commercial purpose. In general, we may disclose the
                              following categories of personal information in
                              support of our business purposes identified above:
                            </Typography>
                            <ul className="list">
                              <li>
                                <Typography
                                  className="text-muted"
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={'block'}
                                >
                                  Name, contact information and other identifiers
                                </Typography>
                              </li>
                              <li>
                                <Typography
                                  className="text-muted"
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={'block'}
                                >
                                  Geolocation information
                                </Typography>
                              </li>
                              <li>
                                <Typography
                                  className="text-muted"
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={'block'}
                                >
                                  Employment information
                                </Typography>
                              </li>
                            </ul>
                            <Typography
                              theme={theme}
                              variant="subtitle2"
                              component="span"
                              display={'block'}
                            >
                              Categories of Personal Information Sold.
                            </Typography>
                          </li>
                        </ul>
                        <Typography
                          theme={theme}
                          variant="subtitle2"
                          component="span"
                          display={'block'}
                        >
                          Categories of Personal Information Sold.
                        </Typography>
                        <Typography
                          className="text-muted"
                          theme={theme}
                          variant="body2"
                          component="span"
                          display={'block'}
                          mb={2}
                        >
                          We do not disclose personal information to third
                          parties in exchange for financial return from third
                          parties however we do make certain categories of
                          personal information available to third parties, in
                          order to receive certain services or benefits from
                          them (such as when we allow third party tags to
                          collect browsing history and other information on our
                          Site to improve and measure our ad campaigns),
                          including:
                        </Typography>
                        <ul className="list">
                          <li>
                            <Typography
                              className="text-muted"
                              theme={theme}
                              variant="body2"
                              component="span"
                              display={'block'}
                            >
                              We do not disclose personal information to third
                              parties in exchange for financial return from third
                              parties however we do make certain categories of
                              personal information available to third parties, in
                              order to receive certain services or benefits from
                              them (such as when we allow third party tags to
                              collect browsing history and other information on our
                              Site to improve and measure our ad campaigns),
                              including:
                            </Typography>
                            <ul className="list">
                              <li>
                                <Typography
                                  className="text-muted"
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={'block'}
                                >
                                  Name, contact information and other identifiers
                                </Typography>
                              </li>
                              <li>
                                <Typography
                                  className="text-muted"
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={'block'}
                                >
                                  Geolocation information
                                </Typography>
                              </li>
                              <li>
                                <Typography
                                  className="text-muted"
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={'block'}
                                >
                                  Employment information
                                </Typography>
                              </li>
                            </ul>
                            <Typography
                              theme={theme}
                              variant="subtitle2"
                              component="span"
                              display={'block'}
                            >
                              California Residents
                            </Typography>
                            <Typography
                              className="text-muted"
                              theme={theme}
                              variant="body2"
                              component="span"
                              display={'block'}
                            >
                              This section provides information for California
                              residents as per the California Consumer Privacy Act
                              (“CCPA”), that obliges us to provide residents of
                              California specific information regarding how we
                              manage their personal information, online or offline.
                              However, this section does not address or apply to our
                              management of publicly available information which is
                              made legally available by state or federal governments
                              or other personal information that is subject to an
                              exemption under the CCPA.
                            </Typography>
                          </li>
                        </ul>
                        <Typography
                          theme={theme}
                          variant="subtitle2"
                          component="span"
                          display={'block'}
                        >
                          California Residents
                        </Typography>
                        <Typography
                          className="text-muted"
                          theme={theme}
                          variant="body2"
                          component="span"
                          display={'block'}
                          mb={2}
                        >
                          This section provides information for California
                          residents as per the California Consumer Privacy Act
                          (“CCPA”), that obliges us to provide residents of
                          California specific information regarding how we
                          manage their personal information, online or offline.
                          However, this section does not address or apply to our
                          management of publicly available information which is
                          made legally available by state or federal governments
                          or other personal information that is subject to an
                          exemption under the CCPA.
                        </Typography>
                        <Typography
                          className="text-muted"
                          theme={theme}
                          variant="body2"
                          component="span"
                          display={'block'}
                          mb={2}
                        >
                          California Residents’ Rights. California residents
                          have been granted particular rights by the California
                          law and imposes restrictions on certain business
                          practices as mentioned below.
                        </Typography>
                        <ul className="list">
                          <li>
                            <Typography
                              className="text-muted"
                              theme={theme}
                              variant="body2"
                              component="span"
                              display={'block'}
                            >
                              California Residents’ Rights. California residents
                              have been granted particular rights by the California
                              law and imposes restrictions on certain business
                              practices as mentioned below.
                            </Typography>
                            <ul className="list">
                              <li>
                                <Typography
                                  className="text-muted"
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={'block'}
                                >
                                  Do-not-sell: California residents have the right
                                  to opt-out of the sale of their personal
                                  information. If you are a California resident, you
                                  can submit an opt-out request here. We do not sell
                                  personal information about residents who we know
                                  are younger than 16 years old without opt-in
                                  consent.
                                </Typography>
                              </li>
                              <li>
                                <Typography
                                  className="text-muted"
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={'block'}
                                >
                                  Notice before collection: We are required to
                                  notify California residents, at or before the
                                  point of collection of their personal information,
                                  the categories of personal information collected
                                  and the purposes for which such information is
                                  used.
                                </Typography>
                              </li>
                              <li>
                                <Typography
                                  className="text-muted"
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={'block'}
                                >
                                  Request to delete: California residents have the
                                  right to request, at no charge, deletion of their
                                  personal information that we have collected about
                                  them and to have such personal information
                                  deleted, except where an exemption applies. We
                                  will respond to verifiable requests received from
                                  California residents as required by law.
                                </Typography>
                              </li>
                              <li>
                                <Typography
                                  className="text-muted"
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={'block'}
                                >
                                  Request to know: California residents have the
                                  right to request and, subject to certain
                                  exemptions, receive a copy of the specific pieces
                                  of personal information that we have collected
                                  about them in the prior 12 months and to have this
                                  delivered, free of charge, either (a) by mail or
                                  (b) electronically in a portable and, to the
                                  extent technically feasible, readily useable
                                  format that allows the individual to transmit this
                                  information to another entity without hindrance.
                                  California residents also have the right to
                                  request that we provide them certain information
                                  about how we have handled their personal
                                  information in the prior 12 months, including:
                                </Typography>
                              </li>
                              <ul className="list">
                                <li>
                                  <Typography
                                    className="text-muted"
                                    theme={theme}
                                    variant="body2"
                                    component="span"
                                    display={'block'}
                                  >
                                    categories of personal information collected;
                                  </Typography>
                                </li>
                                <li>
                                  <Typography
                                    className="text-muted"
                                    theme={theme}
                                    variant="body2"
                                    component="span"
                                    display={'block'}
                                  >
                                    categories of sources of personal information;
                                  </Typography>
                                </li>
                                <li>
                                  <Typography
                                    className="text-muted"
                                    theme={theme}
                                    variant="body2"
                                    component="span"
                                    display={'block'}
                                  >
                                    business and/or commercial purposes for
                                    collecting and selling their personal
                                    information;
                                  </Typography>
                                </li>
                                <li>
                                  <Typography
                                    className="text-muted"
                                    theme={theme}
                                    variant="body2"
                                    component="span"
                                    display={'block'}
                                  >
                                    categories of third parties/with whom we have
                                    disclosed or shared their personal information;
                                  </Typography>
                                </li>
                                <li>
                                  <Typography
                                    className="text-muted"
                                    theme={theme}
                                    variant="body2"
                                    component="span"
                                    display={'block'}
                                  >
                                    categories of personal information that we have
                                    disclosed or shared with a third party for a
                                    business purpose; and
                                  </Typography>
                                </li>
                                <li>
                                  <Typography
                                    className="text-muted"
                                    theme={theme}
                                    variant="body2"
                                    component="span"
                                    display={'block'}
                                  >
                                    categories of third parties to whom the
                                    residents’ personal information has been sold
                                    and the specific categories of personal
                                    information sold to each category of third
                                    party.
                                  </Typography>
                                </li>
                              </ul>
                            </ul>
                            <Typography
                              className="text-muted"
                              theme={theme}
                              variant="body2"
                              component="span"
                              display={'block'}
                            >
                              California residents may make a Request to Know up to
                              twice every 12 months, at no charge. We will respond
                              to verifiable requests received from California
                              residents as required by law.
                            </Typography>
                            <ul className="list">
                              <li>
                                <Typography
                                  className="text-muted"
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={'block'}
                                >
                                  Discrimination and financial incentives: The CCPA
                                  prohibits discrimination against California
                                  residents for exercising their rights under the
                                  CCPA. A business may offer financial incentives
                                  for the collection, sale or deletion of California
                                  residents’ personal information, where the
                                  incentive is not unjust, unreasonable, coercive or
                                  usurious, and is made available in compliance with
                                  applicable transparency, informed consent, and
                                  opt-out requirements. California residents have
                                  the right to be notified of any financial
                                  incentive offers and their material terms, the
                                  right to opt-out of such incentives at any time,
                                  and may not be included in such incentives without
                                  their prior informed opt-in consent. We do not
                                  offer any such incentives at this time.
                                </Typography>
                              </li>
                            </ul>
                            <Typography
                              theme={theme}
                              variant="subtitle2"
                              component="span"
                              display={'block'}
                            >
                              Submitting Verifiable Requests.
                            </Typography>
                            <Typography
                              className="text-muted"
                              theme={theme}
                              variant="body2"
                              component="span"
                              display={'block'}
                            >
                              We will respond to verifiable requests received from
                              California residents as required by law.
                            </Typography>
                            <Typography
                              className="text-muted"
                              theme={theme}
                              variant="body2"
                              component="span"
                              display={'block'}
                            >
                              Requests to Know and Requests to Delete may be
                              submitted:
                            </Typography>
                          </li>
                          <ul className="list">
                            <li>
                              <Typography
                                className="text-muted"
                                theme={theme}
                                variant="body2"
                                component="span"
                                display={'block'}
                              >
                                categories of personal information collected;
                              </Typography>
                            </li>
                            <li>
                              <Typography
                                className="text-muted"
                                theme={theme}
                                variant="body2"
                                component="span"
                                display={'block'}
                              >
                                categories of sources of personal information;
                              </Typography>
                            </li>
                            <li>
                              <Typography
                                className="text-muted"
                                theme={theme}
                                variant="body2"
                                component="span"
                                display={'block'}
                              >
                                business and/or commercial purposes for
                                collecting and selling their personal
                                information;
                              </Typography>
                            </li>
                            <li>
                              <Typography
                                className="text-muted"
                                theme={theme}
                                variant="body2"
                                component="span"
                                display={'block'}
                              >
                                categories of third parties/with whom we have
                                disclosed or shared their personal information;
                              </Typography>
                            </li>
                            <li>
                              <Typography
                                className="text-muted"
                                theme={theme}
                                variant="body2"
                                component="span"
                                display={'block'}
                              >
                                categories of personal information that we have
                                disclosed or shared with a third party for a
                                business purpose; and
                              </Typography>
                            </li>
                            <li>
                              <Typography
                                className="text-muted"
                                theme={theme}
                                variant="body2"
                                component="span"
                                display={'block'}
                              >
                                categories of third parties to whom the
                                residents’ personal information has been sold
                                and the specific categories of personal
                                information sold to each category of third
                                party.
                              </Typography>
                            </li>
                          </ul>
                        </ul>
                        <Typography
                          className="text-muted"
                          theme={theme}
                          variant="body2"
                          component="span"
                          display={'block'}
                          mb={2}
                        >
                          California residents may make a Request to Know up to
                          twice every 12 months, at no charge. We will respond
                          to verifiable requests received from California
                          residents as required by law.
                        </Typography>
                        <ul className="list">
                          <li>
                            <Typography
                              className="text-muted"
                              theme={theme}
                              variant="body2"
                              component="span"
                              display={'block'}
                            >
                              For more information about our privacy practices,
                              contact us as mentioned above.
                            </Typography>
                            <Typography
                              theme={theme}
                              variant="subtitle2"
                              component="span"
                              display={'block'}
                            >
                              REQUEST TO KNOW AND REQUEST TO DELETE PAGE:
                            </Typography>
                          </li>
                        </ul>
                        <Typography
                          theme={theme}
                          variant="subtitle2"
                          component="span"
                          display={'block'}
                        >
                          Submitting Verifiable Requests.
                        </Typography>
                        <Typography
                          className="text-muted"
                          theme={theme}
                          variant="body2"
                          component="span"
                          display={'block'}
                          mb={2}
                        >
                          We will respond to verifiable requests received from
                          California residents as required by law.
                        </Typography>
                        <Typography
                          className="text-muted"
                          theme={theme}
                          variant="body2"
                          component="span"
                          display={'block'}
                          mb={2}
                        >
                          Requests to Know and Requests to Delete may be
                          submitted:
                        </Typography>
                        <ul className="list">
                          <li>
                            <Typography
                              className="text-muted"
                              theme={theme}
                              variant="body2"
                              component="span"
                              display={'block'}
                            >
                              By email at{' '}
                              <a
                                className="text-primary"
                                href={`mailto:${process.env.INFO_EMAIL as string}`}
                              >
                                {process.env.INFO_EMAIL}
                              </a>
                            </Typography>
                          </li>
                        </ul>
                        <Typography
                          className="text-muted"
                          theme={theme}
                          variant="body2"
                          component="span"
                          display={'block'}
                          mb={2}
                        >
                          For more information about our privacy practices,
                          contact us as mentioned above.
                        </Typography>
                        <Typography
                          theme={theme}
                          variant="subtitle2"
                          component="span"
                          display={'block'}
                        >
                          REQUEST TO KNOW AND REQUEST TO DELETE PAGE:
                        </Typography>
                        <Typography
                          className="text-muted"
                          theme={theme}
                          variant="body2"
                          component="span"
                          display={'block'}
                          mb={2}
                        >
                          California residents (few exemptions) have the right
                          to request the following without any charges;
                        </Typography>
                        <Typography
                          className="text-muted"
                          theme={theme}
                          variant="body2"
                          component="span"
                          display={'block'}
                          mb={2}
                        >
                          Request to delete: California residents can request
                          deletion of their personal information that we
                          collected about them.
                        </Typography>
                        <Typography
                          className="text-muted"
                          theme={theme}
                          variant="body2"
                          component="span"
                          display={'block'}
                          mb={2}
                        >
                          Request to know: California residents can request and
                          obtain a copy of certain pieces of personal
                          information that we collected12 months prior about
                          them. They can also request us to provide information
                          how we handled the personal data about them 12 months
                          prior to request.
                        </Typography>
                        <ul className="list">
                          <li>
                            <Typography
                              className="text-muted"
                              theme={theme}
                              variant="body2"
                              component="span"
                              display={'block'}
                            >
                              Request to delete: California residents can request
                              deletion of their personal information that we
                              collected about them.
                            </Typography>
                            <Typography
                              className="text-muted"
                              theme={theme}
                              variant="body2"
                              component="span"
                              display={'block'}
                            >
                              Request to know: California residents can request and
                              obtain a copy of certain pieces of personal
                              information that we collected12 months prior about
                              them. They can also request us to provide information
                              how we handled the personal data about them 12 months
                              prior to request.
                            </Typography>
                            <ul className="list">
                              <li>
                                <Typography
                                  className="text-muted"
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={'block'}
                                >
                                  categories and sources of personal information
                                  collected;
                                </Typography>
                              </li>
                              <li>
                                <Typography
                                  className="text-muted"
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={'block'}
                                >
                                  business or commercial purposes for collecting and
                                  selling their personal information;
                                </Typography>
                              </li>
                              <li>
                                <Typography
                                  className="text-muted"
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={'block'}
                                >
                                  categories of third parties to whom we have
                                  disclosed their personal information for a
                                  business purpose;
                                </Typography>
                              </li>
                              <li>
                                <Typography
                                  className="text-muted"
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={'block'}
                                >
                                  categories of personal information that we have
                                  disclosed to a third party for a business purpose;
                                </Typography>
                              </li>
                              <li>
                                <Typography
                                  className="text-muted"
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={'block'}
                                >
                                  categories of third parties to whom the residents’
                                  personal information has been sold and the
                                  specific categories of personal information sold
                                  to each category.
                                </Typography>
                              </li>
                            </ul>
                            <Typography
                              className="text-muted"
                              theme={theme}
                              variant="body2"
                              component="span"
                              display={'block'}
                            >
                              You may submit up to two requests to know per year.
                            </Typography>
                          </li>
                          <li>
                            <Typography
                              className="text-muted"
                              theme={theme}
                              variant="body2"
                              component="span"
                              display={'block'}
                            >
                              categories of personal information that we have
                              disclosed to a third party for a business purpose;
                            </Typography>
                          </li>
                          <li>
                            <Typography
                              className="text-muted"
                              theme={theme}
                              variant="body2"
                              component="span"
                              display={'block'}
                            >
                              categories of third parties to whom the residents’
                              personal information has been sold and the
                              specific categories of personal information sold
                              to each category.
                            </Typography>
                          </li>
                        </ul>
                        <Typography
                          className="text-muted"
                          theme={theme}
                          variant="body2"
                          component="span"
                          display={'block'}
                        >
                          You may submit up to two requests to know per year.
                        </Typography>
                      </>
                    ),
                    title: 'Privacy Notice for California Residents',
                    key: 'string',
                    isExpanded: false,
                    subtitle: undefined
                  }
                ]}
              />
            </Box>
          </Box>
        </Container>
      </MainContent>
      <Footer />
    </>
  )
}
