import { type FC } from 'react'
import { useTheme } from '@mui/material'
import { BlogListItem } from './blogstyle'
import { Box, Grid, Typography } from '@ntpkunity/controls'
import { type IBlog } from '@shared/typings'
import { formatedDate } from '@shared/helper'
import parse from 'html-react-parser'
import { NavLink } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'

interface BlogItemProps {
  onClick?: () => void
  blog: IBlog
}

export const BlogItemComponent: FC<BlogItemProps> = ({
  onClick,
  blog
}) => {
  const theme = useTheme()

  return (
    <BlogListItem theme={theme} className="blog-list-item">
      <Grid theme={theme} container item justifyContent={'center'}>
        <Grid theme={theme} item lg={10} md={12}>
          <Grid theme={theme} container item spacing={{ xs: 2, md: 10 }} alignItems={'center'}>
              <Grid theme={theme} item xs={12} md={7} order={{ xs: 2, md: 1 }}>
                <NavLink
                  to={`${APP_ROUTES.BLOGS}/${blog.title_url}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Box theme={theme} className='blog-meta' display={'flex'} alignItems={'center'} gap={1}>
                    <Typography theme={theme} variant='body2' component='small' className='text-muted text-body2 fw-medium'>{formatedDate(blog.created_at, 'DD MMM, YYYY')}</Typography>
                    <span className='seperator'></span>
                    <Typography theme={theme} variant='body2' component='small' className='text-muted text-body2 fw-medium'>{blog.author}</Typography>
                  </Box>
                  <Box theme={theme} className='blog-content'>
                    <Typography theme={theme} variant='h2' component='h2' mt={1} className='blog-title text-h3 text-truncate lines-2'>
                      <a href="javascript:void(0)" onClick={onClick}>
                        <NavLink
                          to={`${APP_ROUTES.BLOGS}/${blog.title_url}`}
                          style={{ textDecoration: 'none' }}
                        >
                        {blog.title}
                        </NavLink>
                      </a>
                    </Typography>
                    <Typography theme={theme} variant='body1' component='p' mt={1} className='text-muted text-body1 text-truncate lines-3'>{parse(blog?.content ?? '')}</Typography>
                      <Box theme={theme} mt={2} display={'flex'} alignItems={'center'} gap={1} className='blog-tags'>
                      {blog.tags?.map(({ title }, index) => (
                        <Typography
                          key={index}
                          theme={theme}
                          className="badge text-primary text-uppercase"
                          variant="caption"
                          component="small"
                        >
                          {title}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </NavLink>
              </Grid>
            <Grid theme={theme} item xs={12} md={5} order={{ xs: 1, md: 2 }}>
              <a href="javascript:void(0)" onClick={onClick}>
              <NavLink
                  to={`${APP_ROUTES.BLOGS}/${blog.title_url}`}
                  style={{ textDecoration: 'none' }}
                >
                <Box theme={theme} className='blog-img'>
                  <img src={blog.image} alt={''} />
                </Box>
                </NavLink>
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BlogListItem>
  )
}
