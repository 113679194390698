import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const SectionContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.section-content': {
    borderBottom: '1px solid' + theme.palette.grey[100],
    '&:last-child': {
      border: 'none'
    },
    '.list-item': {
      border: '1px solid' + theme.palette.grey[100],
      padding: 24,
      marginBottom: 16,
      borderRadius: theme.shape.borderRadius,
      '&:last-child': {
        marginBottom: 0
      },
      '.icon-text': {
        cursor: 'pointer',
        svg: {
          marginLeft: 8
        },
        [theme.breakpoints.down('md')]: {
          fontSize: theme.typography.caption.fontSize,
          lineHeight: theme.typography.caption.lineHeight,
          svg: {
            marginLeft: 5,
            width: 15,
            height: 15
          }
        }
      },
      '.list-info-wrap': {
        marginRight: 24,
        [theme.breakpoints.down('md')]: {
          marginRight: 0,
          borderRight: 'none',
          paddingRight: 0,
          marginBottom: 16
        }
      },
      '.list-info': {
        flexDirection: 'column',
        borderBottom: '1px solid' + theme.palette.grey[100],
        marginBottom: 24,
        paddingBottom: 24,
        '&:last-child': {
          border: 'none',
          marginBottom: 0,
          paddingBottom: 0
        }
      },
      '.list-action': {
        flex: '0 0 220px',
        maxWidth: 220,
        borderLeft: '1px solid' + theme.palette.grey[100],
        paddingLeft: 24,
        [theme.breakpoints.down('md')]: {
          flex: '0 0 100%',
          maxWidth: '100%',
          borderTop: '1px solid' + theme.palette.grey[100],
          paddingTop: 16,
          borderLeft: 'none',
          paddingLeft: 0
        }
      },
      [theme.breakpoints.down('md')]: {
        padding: 16,
        marginLeft: -16,
        marginRight: -16,
        marginBottom: 8
      }
    },
    '.link-text': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }
}))
