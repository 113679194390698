import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.main-content': {
    padding: '115px 24px 50px',
    minHeight: '100vh',
    [theme.breakpoints.up('md')]: {
      paddingTop: 75,
      paddingBottom: 0
    },
    '.custom-container': {
      height: '100%',
      [theme.breakpoints.up('md')]: {
        paddingLeft: 32,
        paddingRight: 32
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    '.text-danger': {
      color: theme.palette.error.main
    },
    '.full-page-wrap': {
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    '.text-h2': {
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight
      }
    }
  }
}))

export const ProductBox = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.product-box': {
    padding: '40px 24px',
    border: '1px solid' + theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.3s ease-in-out 0s',
    '.prd-link': {
      textDecoration: 'none',
      color: theme.palette.grey[900],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      '&:before': {
        display: 'block',
        content: "' '",
        backgroundColor: '#F5F5F7',
        width: '100%',
        height: 96,
        position: 'absolute',
        top: 7,
        borderRadius: theme.shape.borderRadius
      }
    },
    '.prd-img': {
      height: 120,
      marginBottom: 16,
      position: 'relative',
      img: {
        maxHeight: 120
      }
    },
    '.prd-name': {
      fontWeight: theme.customVariables.fontWeightSemiBold
    },
    '&:hover': {
      boxShadow: '0px 32px 32px -24px rgba(0, 26, 128, 0.25)'
    }
  }
}))
