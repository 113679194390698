import { type ReactElement, useEffect } from 'react'
import { useTheme } from '@mui/material'
import {
  Box,
  Button,
  Container,
  Icon,
  Menu,
  Typography
} from '@ntpkunity/controls'
import { MainContent } from '../privacyPolicy/privacyPolicyStyle'
import {
  TermsAndConditions as Terms,
  scrollOnTop,
  TermOfServices,
  openInNextWindow
} from 'shared'
import { Header, Footer } from 'layout'

export const TermsOfService = (): ReactElement => {
  const theme = useTheme()

  useEffect(() => {
    scrollOnTop()
  }, [])

  const handleSelection = (value, key, test): void => {
    switch (key) {
      case TermOfServices.PRINT:
        window.print()
        return
      case TermOfServices.DOWNLOAD:
        openInNextWindow(process.env.TERMS_AND_CONDITIONS ?? '')
    }
  }

  return (
    <>
      <Header theme={theme} />
      <MainContent theme={theme} className="main-content">
        <Container theme={theme} maxWidth="lg" className="custom-container">
          <Box theme={theme} display={'flex'} flexDirection={'row'}>
            <Typography
              mb={4}
              theme={theme}
              variant="h2"
              component="h2"
              flexGrow={1}
              mr={3}
            >
              Terms and Conditions of Use and End User License Agreement
            </Typography>
            <Box theme={theme} flexShrink={0} className="action-btn">
              <Menu
                theme={theme}
                handleOptionClick={handleSelection}
                options={[
                  {
                    optionText: (
                      <>
                        <Icon className="menu-icon" name="PrintIcon" />{' '}
                        {'Print'}
                      </>
                    ),
                    optionkey: 'Print',
                    optionValue: 'PrintValue'
                  },
                  {
                    optionText: (
                      <>
                        <Icon className="menu-icon" name="ExportIcon" />{' '}
                        {'Download'}
                      </>
                    ),
                    optionkey: 'Download',
                    optionValue: 'DownloadValue'
                  }
                ]}
                render={(cb) => (
                  <Button
                    theme={theme}
                    secondary
                    iconText={
                      <>
                        <Icon name="MoreIcon" />
                      </>
                    }
                    onClick={cb}
                  />
                )}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              />
            </Box>
          </Box>
          <Box theme={theme} className="policy-content">
            <Terms />
          </Box>
        </Container>
      </MainContent>
      <Footer />
    </>
  )
}
