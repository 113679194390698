import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const PaymentCardWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.cards-area-wrap': {
    '.card-item': {
      '&.default': {
        borderWidth: 2,
        borderColor: theme.palette.primary.main
      },
      '&:last-child': {
        marginRight: 0
      }
    }
  }
}))
