import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const SectionContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.section-content': {
    '.list-item': {
      border: '1px solid' + theme.palette.grey[100],
      padding: 24,
      marginBottom: 16,
      borderRadius: theme.shape.borderRadius,
      '&:last-child': {
        marginBottom: 0
      },
      [theme.breakpoints.down('md')]: {
        padding: 16
      }
    }
  }
}))
