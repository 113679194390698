import { Typography } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { useModal } from 'react-modal-hook'
import { useGetAddOns, AddAddOns } from '@modules/addOns'
import { type FC, type ReactElement } from 'react'
import { type IAddOns } from '@shared/typings'

interface IAddOnsProps {
  selectedAddOns: IAddOns[]
  setSelectedAddOns: (addon: IAddOns[]) => void
}

export const AddOns: FC<IAddOnsProps> = ({ selectedAddOns, setSelectedAddOns }): ReactElement => {
  const theme = useTheme()
  const { data: addOns } = useGetAddOns()

  const [show, hide] = useModal(
    () => (
      <AddAddOns
        hide={hide}
        addOns={addOns}
        selectedAddOns={selectedAddOns}
        setSelectedAddOns={setSelectedAddOns}
      />
    ),
    [addOns, selectedAddOns, setSelectedAddOns]
  )

  return (
    <Typography theme={theme} variant="body1" component="p" mt={3}>
      Add-Ons are an easy way to get more out of your subscription.{' '}
      <a href="javascript:void(0)" className="text-primary link" onClick={show}>
        Click here
      </a>{' '}
      to purchase.
    </Typography>
  )
}
