import { type FC } from 'react'
import { useTheme } from '@mui/material'
import { Box, Typography } from '@ntpkunity/controls'
import clsx from 'clsx'
import { BrandsLogoWrap } from './brandsLogoStyle'

interface logo {
  logo: string
  className?: string
}

interface BrandLogoProps {
  logoItem: logo []
  sectionTitle?: string
}

export const BrandsLogoComponent: FC<BrandLogoProps> = ({ logoItem, sectionTitle }) => {
  const theme = useTheme()
  return (
    <BrandsLogoWrap theme={theme} className='brands-logo-wrap'>
      {sectionTitle &&
        <Typography theme={theme} variant='h3' component='h3' className='text-h3' textAlign={'center'} mb={{ xs: 3, md: 5 }}>
          {sectionTitle}
        </Typography>
      }
      <Box theme={theme} className='logo-container'>
        <Box theme={theme} className='brands-logo-items'>
            {logoItem.map((logoItems, index) => (
              <Box theme={theme}
                className={clsx(logoItems.className, {
                  'brand-logo-item': true
                })}
                key={index}>
                <img src={logoItems.logo} alt='' />
              </Box>
            ))}
        </Box>
        <Box theme={theme} className='brands-logo-items'>
            {logoItem.map((logoItems, index) => (
              <Box theme={theme}
                className={clsx(logoItems.className, {
                  'brand-logo-item': true
                })}
                key={index}>
                <img src={logoItems.logo} alt='' />
              </Box>
            ))}
        </Box>
        <Box theme={theme} className='brands-logo-items'>
            {logoItem.map((logoItems, index) => (
              <Box theme={theme}
                className={clsx(logoItems.className, {
                  'brand-logo-item': true
                })}
                key={index}>
                <img src={logoItems.logo} alt='' />
              </Box>
            ))}
        </Box>
      </Box>
    </BrandsLogoWrap>
  )
}
