import { Box, Typography, Grid } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import NoProductImage from '@shared/assets/images/no-prd-img-new.svg'
import { useNavigate } from 'react-router'
import { APP_ROUTES } from '@router/path'
import { type ReactElement } from 'react'

export const messages = {
  noFreeProduct: 'You have not subscribed free trial.',
  noSubscribedProduct: 'You have not subscribed any product yet.'
}

interface IProps {
  isTrialTab: boolean
}

const EmptyState = ({ isTrialTab }: IProps): ReactElement => {
  const theme = useTheme()
  const navigate = useNavigate()
  return (
    <Grid theme={theme} item xs={12}>
      <Box
        theme={theme}
        className="no-products"
        mt={10}
        mb={10}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Box theme={theme} className="no-prd-img" mb={5}>
          <img src={NoProductImage} alt="No Product" />
        </Box>
        <Typography
          theme={theme}
          variant="h3"
          component="h3"
          mb={2}
          className="text-h3"
        >
          There are no items here!
        </Typography>
        <Typography
          theme={theme}
          className="text-muted text-body2"
          variant="body2"
          component="span"
        >
          {isTrialTab ? messages.noFreeProduct : messages.noSubscribedProduct}{' '}
          Go to the{' '}
          <span
            onClick={() => { navigate(APP_ROUTES.NEW_HOME_PAGE) }}
            className="text-primary link"
          >
            homepage
          </span>{' '}
          to browse products.
        </Typography>
      </Box>
    </Grid>
  )
}

export default EmptyState
