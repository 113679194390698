import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'
import GreenCheckCircle from '../../../../shared/assets/images/check-cirlce-green.svg'

export const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.main-content': {
    marginBottom: -80,
    marginTop: -12,
    '.no-package': {
      img: {
        maxWidth: '100%'
      },
      '.loader-wrap': {
        height: 'calc(100vh - 240px)'
      }
    },
    '.fw-reg': {
      fontWeight: theme.typography.fontWeightRegular
    },
    '.text-capitalize': {
      textTransform: 'capitalize'
    },
    '.product-features': {
      position: 'relative',
      overflow: 'hidden',
      '.custom-container': {
        position: 'relative'
      },
      '.left-shape-img': {
        position: 'absolute',
        left: -120,
        bottom: 0
      },
      '.right-shape-img': {
        position: 'absolute',
        right: -75,
        top: 0,
        [theme.breakpoints.down('md')]: {
          display: 'none'
        }
      },
      '.dots-img': {
        position: 'absolute',
        top: 0,
        [theme.breakpoints.down('md')]: {
          display: 'none'
        }
      },
      '.string-img-wrap': {
        position: 'sticky',
        width: 92,
        left: '65%',
        bottom: 21,
        img: {
          display: 'block'
        }
      },
      '.text-h2': {
        fontSize: 48,
        lineHeight: '56px',
        [theme.breakpoints.down('md')]: {
          fontSize: theme.typography.h2.fontSize,
          lineHeight: theme.typography.h2.lineHeight
        }
      },
      '.sec-title-wrap': {
        position: 'relative',
        zIndex: 2
      },
      '.feat-item': {
        border: '1px solid' + theme.palette.grey[50],
        boxShadow: '0px 24px 40px -24px rgba(0, 0, 0, 0.16)',
        borderRadius: '24px',
        padding: '16px 16px 40px',
        height: '100%',
        [theme.breakpoints.down('md')]: {
          '.text-h4': {
            fontSize: theme.typography.subtitle1.fontSize,
            lineHeight: theme.typography.subtitle1.lineHeight
          }
        },
        '.box-bg': {
          width: '100%',
          borderRadius: '16px 16px 80px 80px',
          backgroundColor: theme.palette.grey[50],
          height: 128,
          '&.bg-seagreen': {
            backgroundColor: '#CCFAF6'
          },
          '&.bg-lightskin': {
            backgroundColor: '#FDEFD9'
          },
          '&.bg-lightblue': {
            backgroundColor: '#D9E0FF'
          }
        },
        '.feat-icon': {
          width: 112,
          height: 112,
          backgroundColor: theme.palette.common.white,
          border: '1px solid' + theme.palette.grey[50],
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '-80px auto 24px',
          boxShadow: '0px 36px 36px -24px rgba(0, 0, 0, 0.1)',
          img: {
            maxHeight: 40,
            maxWidth: 40
          }
        }
      }
    },
    '.price-box': {
      height: '100%'
    }
  }
}))

export const HeroBanner = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.hero-banner': {
    '.hero-content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      maxWidth: 792,
      margin: 'auto auto 40px',
      '.prd-title': {
        fontSize: 64,
        lineHeight: '80px',
        background: 'linear-gradient(to right, #0033FD 0, #00E4D0 100%)',
        backgroundClip: 'text',
        textFillColor: 'transparent',
        [theme.breakpoints.down('md')]: {
          fontSize: theme.typography.h2.fontSize,
          lineHeight: theme.typography.h2.lineHeight
        }
      },
      '.text-justify': {
        textAlign: 'justify'
      },
      '.btn': {
        '&.btn-default': {
          // color: theme.palette.grey[900],
          fontWeight: theme.typography.fontWeightMedium,
          '&:hover': {
            // color: theme.palette.grey[900],
          },
          '&:focus': {
            // color: theme.palette.grey[900],
            '&:hover': {
              // color: theme.palette.grey[900],
            }
          }
          // ".MuiButton-endIcon": {
          //   transform: "rotate(180deg)",
          //   marginRight: 0,
          // },
        }
      },
      [theme.breakpoints.down('md')]: {
        margin: 'auto auto 24px'
      }
    },
    '.hero-img': {
      maxHeight: 376,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      img: {
        maxWidth: '100%',
        maxHeight: 376
      },
      [theme.breakpoints.down('md')]: {
        maxHeight: 160,
        img: {
          maxHeight: 160
        }
      }
    }
  }
}))

export const ProductPackages = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.prd-packages-wrap': {
    '.badge': {
      backgroundColor: 'rgba(0, 51, 253, 0.1)',
      padding: '8px 16px',
      borderRadius: 24,
      fontWeight: theme.customVariables.fontWeightSemiBold
    },
    '.package-card': {
      height: '100%',
      padding: 40,
      borderRadius: 16,
      transition: 'all 0.3s ease-in-out 0s',
      '&:hover': {
        backgroundColor: '#F5F5F7',
        '.package-action': {
          '.btn': {
            '&.btn-secondary': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.grey[900],
                borderColor: theme.palette.grey[900]
              },
              '&.Mui-disabled, &:disabled, &.disabled': {
                borderColor: theme.palette.grey[100],
                backgroundColor: 'transparent',
                color: theme.palette.grey[300]
              }
            }
          }
        },
        '.pkg-img': {
          transform: 'scale(1.1) translateX(10px)'
        }
      },
      '&.subscribed': {
        backgroundColor: '#F5F5F7',
        '.package-action': {
          '.btn': {
            // "&.btn-secondary": {
            //   color: theme.palette.common.white,
            //   backgroundColor: theme.palette.primary.main,
            //   borderColor: theme.palette.primary.main,
            //   "&:hover": {
            //     color: theme.palette.common.white,
            //     backgroundColor: theme.palette.grey[900],
            //     borderColor: theme.palette.grey[900],
            //   },
            // },
            '&.btn-primary': {
              '&:hover': {
                borderColor: theme.palette.grey[300],
                color: theme.palette.common.white
              }
            }
          }
        }
      },
      '.pkg-shout': {
        '.prd-desc': {
          [theme.breakpoints.up('lg')]: {
            minHeight: 60
          }
        }
      },
      [theme.breakpoints.down('md')]: {
        padding: 32,
        border: '1px solid' + theme.palette.grey[100]
      },
      '.pkg-img': {
        transition: 'all 0.3s ease-in-out 0s'
      }
    },
    '.seperator': {
      backgroundColor: theme.palette.grey[100],
      height: 4,
      width: 40,
      borderRadius: 47
    },
    '.pkg-options': {
      '.list': {
        margin: 0,
        paddingLeft: 32,
        color: theme.palette.grey[600],
        listStyle: 'none outside none',
        li: {
          position: 'relative',
          marginBottom: 8,
          lineHeight: '20px',
          '&:last-child': {
            marginBottom: 0
          },
          '&:before': {
            content: "' '",
            display: 'block',
            backgroundImage: `url(${GreenCheckCircle})`,
            width: 24,
            height: 24,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            position: 'absolute',
            left: -32,
            top: -2
          }
        }
      }
    },
    '.package-action': {
      '.btn': {
        padding: '12px 6px',
        marginTop: 16,
        [theme.breakpoints.down('md')]: {
          '&.btn-secondary': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            '&:hover': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.grey[900],
              borderColor: theme.palette.grey[900]
            }
          }
        }
      }
    },
    '.gwZiig': {
      paddingBottom: 90
    },
    '.sc-bxivhb': {
      minWidth: '292px !important'
    },
    '.sc-ifAKCX': {
      backgroundColor: theme.palette.common.white,
      border: '1px solid' + theme.palette.grey[100],
      width: 48,
      height: 48,
      marginTop: -60,
      top: '50%',
      borderRadius: '50%',
      transform: 'translateY(-50%)',
      '&.fDBYnF': {
        right: -24,
        transform: 'translatey(-50%) rotate(180deg)'
      },
      '&.jlBBEG': {
        left: -24
      }
    },
    '.custom-plan-img': {
      height: 320,
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        height: 200
      },
      img: {
        maxHeight: 320,
        [theme.breakpoints.down('md')]: {
          maxHeight: 200
        }
      }
    },
    '.custom-plan-wrap': {
      backgroundColor: '#F5F5F7',
      padding: 40,
      borderRadius: 16,
      [theme.breakpoints.down('md')]: {
        padding: 32,
        '.plan-cta': {
          '.btn': {
            width: '100%',
            '&.btn-secondary': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.grey[900],
                borderColor: theme.palette.grey[900]
              }
            }
          }
        }
      }
    }
  }
}))

export const FullPageLoader = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.full-page-loader': {
    height: 'calc(100vh - 240px)'
  }
}))

export const SupportWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.support-wrap': {
    '.section-head': {
      '.badge': {
        backgroundColor: 'rgba(0, 51, 253, 0.1)',
        padding: '8px 16px',
        borderRadius: 24,
        fontWeight: theme.customVariables.fontWeightSemiBold,
        letterSpacing: '0.5px'
      }
    },
    '.support-box': {
      backgroundColor: '#F5F5F7',
      height: '100%',
      borderRadius: 16,
      padding: 40,
      [theme.breakpoints.down('md')]: {
        padding: 24
      },
      '.box-img': {
        maxHeight: 401,
        textAlign: 'center',
        img: {
          maxHeight: 401,
          maxWidth: '100%'
        }
      },
      '.styled-tooltip': {
        '.MuiTooltip-popper[data-popper-placement*="bottom-start"]': {
          '.MuiTooltip-arrow': {
            left: '8px !important',
            transform: 'none !important',
            top: 1
          }
        }
      },
      '.btn-default': {
        color: theme.palette.primary.main,
        '&.with-icon': {
          '&:hover': {
            color: theme.palette.primary.main
          },
          '&:focus': {
            color: theme.palette.primary.main,
            '&:hover': {
              color: theme.palette.primary.main
            }
          }
        }
      },
      '&.api-docs': {
        '.btn-default': {
          '.MuiButton-endIcon': {
            transform: 'rotate(180deg)',
            marginRight: 0
          }
        }
      }
    }
  }
}))
