import { useTheme } from '@mui/material'
import { type FC, useEffect, useState } from 'react'
import { ChangePasswordComponent, type IResponse } from '@ntpkunity/controls-ums'

import {
  changePassword,
  HttpStatus
} from '@shared/constants'
import { useStoreContext } from 'store/storeContext'
import { Footer, Header } from '@layout/components'
import { Box, Container } from '@ntpkunity/controls'
import { MainContent } from './changePasswordStyle'
import { APP_ROUTES } from '@router/path'
import { scrollOnTop } from '@shared/helper'

export const ChangePassword: FC = () => {
  const theme = useTheme()
  const [response, setResponse] = useState<IResponse>()
  const {
    actions: { setToaster }
  } = useStoreContext()

  useEffect(() => {
    if (response?.status === HttpStatus.OK) {
      setToaster({
        isDisplay: true,
        message: changePassword,
        type: ''
      })
    } else if (response?.status === HttpStatus.Conflict) {
      setToaster({
        isDisplay: true,
        message: response?.message,
        type: 'error'
      })
    }
  }, [response])

  useEffect(() => {
    scrollOnTop()
  }, [])

  return (
    <>
      <Header theme={theme} />
      <MainContent theme={theme} className="main-content">
        <Container theme={theme} maxWidth="lg" className="custom-container">
          <Box theme={theme} className="full-page-wrap">
            <ChangePasswordComponent
              theme={theme}
              setResponse={setResponse}
              next_screen_url={`${APP_ROUTES.MY_ACCOUNT}`}
            />
          </Box>
        </Container>
      </MainContent>

      <Footer />
    </>
  )
}
