import { Box, type IBoxProps } from '@ntpkunity/controls'
import { styled } from '@mui/material/styles'

export const AddOnItem = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.addon-item': {
    border: '1px solid' + theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    padding: 24,
    display: 'inline-flex',
    width: '100%',
    marginBottom: 16,
    '&:last-child': {
      marginBottom: 0
    },
    '&.selected': {
      borderColor: theme.palette.primary.main
    },
    '.label-area': {
      width: '75%',
      margin: '0 16px 0 0',
      display: 'inline-block'
    },
    '.addon-price': {
      display: 'inline-block',
      width: '24%',
      textAlign: 'right',
      marginTop: 1
    },
    '.custom-checkbox-wrap': {
      display: 'inline-block',
      width: '100%',
      '.u-custom-control-label': {
        marginBottom: 0,
        width: '100%',
        wordBreak: 'break-word',
        '.MuiFormControlLabel-label': {
          fontSize: theme.typography.h4.fontSize,
          lineHeight: theme.typography.h4.lineHeight,
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.grey[900],
          paddingTop: 1,
          marginLeft: 8
        }
      }
    },
    '.text-primary': {
      color: theme.palette.primary.main
    }
  }
}))
