import { useTheme } from '@mui/material'
import { Box } from '@ntpkunity/controls'
import AppBrandingImage from '@shared/assets/images/app-branding-img.jpg'
import { Header, Footer } from 'layout'
import { AuthPage } from './AuthenticationStyle'
import { type FC, type ReactNode, useEffect } from 'react'
import { scrollOnTop } from '@shared/helper/methods'

interface IAuthenticationProps {
  children: ReactNode
}

export const Authentication: FC<IAuthenticationProps> = ({ children }) => {
  const theme = useTheme()

  useEffect(() => {
    scrollOnTop()
  }, [])

  return (
    <>
      <Header theme={theme} />
      <AuthPage theme={theme} className="auth-page">
        <Box theme={theme} className="auth-left-img">
          <img src={AppBrandingImage} alt="form-img" />
        </Box>
        <Box theme={theme} className="auth-right-panel">
          <Box theme={theme} className="right-content">
            {children}
          </Box>
        </Box>
      </AuthPage>
      <Footer />
    </>
  )
}
