import { useTheme } from '@mui/material'
import { Dialog } from '@ntpkunity/controls'
import { type FC } from 'react'
import { InlineWidget } from 'react-calendly'
import { CalendlyPopupWrap } from './calendlyPopupStyle'
import { useStoreContext } from 'store/storeContext'

interface CalendlyPopupComponentProps {
  hide: () => void
}

export const CalendlyPopupComponent: FC<CalendlyPopupComponentProps> = ({
  hide
}) => {
  const theme = useTheme()
  const {
    states: { userProfile }
  }: any = useStoreContext()

  const prefill = {
    email: userProfile?.user_name,
    firstName: userProfile?.first_name,
    lastName: userProfile?.last_name,
    customAnswers: {
      a1: userProfile?.company_name
    }
  }

  const pageSettings = {
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: '#0033FD'
  }

  return (
    <>
      <CalendlyPopupWrap theme={theme} className="calendly-popup">
        <Dialog
          theme={theme}
          variant="confirmation"
          size="sm"
          open={true}
          disablePortal={true}
          noFooter={true}
          onCloseDialog={hide}
        >
          <InlineWidget
            url={process.env.CALENDLY_URL ?? ''}
            prefill={prefill}
            pageSettings={pageSettings}
          />
        </Dialog>
      </CalendlyPopupWrap>
    </>
  )
}
