import { LocalStorage } from './browserStorage'

const _localStorageService: LocalStorage = new LocalStorage()

export const getItem = (key: string): string => _localStorageService.getItemByKey(key)

export const setItem = (key: string, value: any): void => { _localStorageService.setItembyKey(key, value) }

export const removeItem = (key: string): void => { _localStorageService.removeItembyKey(key) }

export const clearLocalStorage = (): void => { _localStorageService.clearStorage() }

export const getItemByKey = (key: string): any => {
  const data = JSON.parse((localStorage as any).getItem(key))
  return data
}

export const setItembyKey = (key: string, value: any): void => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const removeItembyKey = (key: string): void => {
  localStorage.removeItem(key)
}
