import { type Theme } from '@mui/material'
import { Box, Icon, Menu, Notification } from '@ntpkunity/controls'
import { APPEX_NOW_MARKETPLACE, NOTIFICATION } from '@shared/constants'
import { type INotificationsBackend } from '@shared/typings'
import { useCallback, useEffect, type FC } from 'react'
import { useInView } from 'react-intersection-observer'
import { useNavigate } from 'react-router-dom'
import { type StoreContextType } from 'store/configs'
import { ACTION_TYPE } from 'store/ducks/notifications'
import { usePusher } from 'store/ducks/pusher'
import { useStoreContext } from 'store/storeContext'
import { LIMIT, useGetAllNotifications, useMarkNotificationRead } from './services/NotificationService'

export interface INotificationComponentProps {
  theme: Theme
}

const THRESHOLD = LIMIT - 10 // 5 -> WHEN 10TH RECORD IS VISIBLE
const ALTERNATE_THRESHOLD = LIMIT - 2 // 13 -> WHEN 2ND RECORD IS VISIBLE

export const NotificationComponent: FC<INotificationComponentProps> = ({ theme }) => {
  const navigate = useNavigate()
  const [notificationsRef, notificationsTargetRefInView] = useInView()

  const {
    states: { userProfile, notificationState },
    actions: { notificationDispatch }
  }: StoreContextType = useStoreContext()

  const { totalUnreadMessages, notifications } = notificationState

  const {
    data: notificationsData,
    isFetchedAfterMount: notificationsFetchedAfterMount,
    isFetching: isFetchingNotifications,
    isFetchingNextPage: isFetchingNextPageNotifications,
    fetchNextPage: fetchNextPageNotifications,
    hasNextPage: hasNextPageNotifications
  } = useGetAllNotifications()

  const { mutateAsync: markNotificationAsRead } = useMarkNotificationRead()

  const handleNotificationClick = (notification: INotificationsBackend): void => {
    const { is_read: isRead } = notification
    if (!isRead) {
      void markNotificationAsRead(notification)
    }
    notificationDispatch({
      type: ACTION_TYPE.READ_NOTIFICATION,
      payload: {
        notification,
        navigate: (cta: string | undefined) => {
          if ((cta ?? '').length > 0) {
            navigate(cta ?? '')
          }
        }
      }
    })
  }

  const handlePusherNotification = useCallback((data: INotificationsBackend) => {
    notificationDispatch({
      type: ACTION_TYPE.REFETCH_NOTIFICATIONS
    })
  }, [])

  usePusher(
    APPEX_NOW_MARKETPLACE,
    `${NOTIFICATION}-${userProfile?.company_name ?? ''}`,
    handlePusherNotification
  )

  useEffect(() => {
    if (notificationsTargetRefInView) {
      void fetchNextPageNotifications()
    }
  }, [notificationsTargetRefInView])

  useEffect(() => {
    if (notificationsData && notificationsFetchedAfterMount) {
      const notifications = notificationsData.pages
        .flatMap((page, pageIndex) => {
          return page.notifications
            .map((notification, notificationIndex) => {
              const totalRecordsSoFar = notificationsData.pages.length * LIMIT
              const notificationOverallIndex = notificationIndex + (pageIndex * LIMIT)
              const indexAtApiToHit = totalRecordsSoFar - THRESHOLD
              const alternateIndexAtApiToHit = totalRecordsSoFar - ALTERNATE_THRESHOLD

              return (
                {
                  ...notification,
                  ref: Math.floor(indexAtApiToHit) === notificationOverallIndex ||
                    Math.floor(alternateIndexAtApiToHit) === notificationOverallIndex
                    ? notificationsRef
                    : null
                }
              )
            })
        })

      const totalUnreadMessages =
        (notificationsData?.pages.length ?? 0) > 0
          ? notificationsData.pages[notificationsData.pages.length - 1].total_unread
          : 0

      notificationDispatch({
        type: ACTION_TYPE.SET_NOTIFICATIONS,
        payload: {
          notifications,
          totalUnreadMessages,
          handleClick: handleNotificationClick
        }
      })
    }
  }, [notificationsData])

  return (
    <Menu
      theme={theme}
      disablePortal={true}
      customChildren={
        <>
          <Box
            theme={theme}
            className="notification-dropdown-wrap"
            maxWidth={480}
          >
            <Notification
              title='Notifications'
              theme={theme}
              items={notifications}
              notificationsLoading={hasNextPageNotifications && isFetchingNextPageNotifications}
            />
          </Box>
        </>
      }
      options={[]}
      render={(cb) => (
        <>
          <a className="nav-link" href="javascript:void(0)" onClick={cb}>
            <Icon name="BellIcon" />
            {
              totalUnreadMessages > 0
                ? (
                  <span className="notification-counter">
                    {/* {totalUnreadMessages > 9 ? '9+' : totalUnreadMessages} */}
                  </span>
                  )
                : null
            }
          </a>
        </>
      )}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    />
  )
}
