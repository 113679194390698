import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const BlogListingPageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.blog-listing-page-wrap': {
    paddingTop: 33,
    [theme.breakpoints.up('md')]: {
      paddingTop: 24,
      paddingBottom: 38
    },
    '.custom-container': {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 32,
        paddingRight: 32
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: 24,
        paddingRight: 24
      }
    },
    '.badge': {
      backgroundColor: 'rgba(0, 51, 253, 0.1)',
      padding: '8px 16px',
      borderRadius: 24,
      fontWeight: theme.customVariables.fontWeightSemiBold,
      letterSpacing: '0.5px'
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-danger': {
      color: theme.palette.error.main
    },
    '.list': {
      paddingLeft: 15
    },
    '.page-head': {
      margin: 'auto auto 40px',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '55%',
        width: '100%',
        margin: 'auto auto 64px'
      }
    }
  }
}))
