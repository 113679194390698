import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.main-content': {
    '.main-content': {
      paddingTop: 153,
      paddingBottom: 80,
      [theme.breakpoints.up('md')]: {
        paddingTop: 73,
        paddingBottom: 0
      }
    }
  }
}))
