import { type IBillingAddress, type IOrderDetail, type IPlaceOrderPayload } from '@shared/typings'
import Http from '@shared/helper/http-api'
import { type UseMutateFunction, useMutation, useQuery, useQueryClient } from 'react-query'
import { type AppError } from '@shared/helper'

const BASE_URL = process.env.BASE_URL

const SAVE_BILLING_ADDRESS = 'marketplace/order/billing_address'
const UPDATE_BILLING_ADDRESS = 'marketplace/order/update-billing-address'
const GET_BILLING_ADDRESS = 'marketplace/order/get-billing-address'
const PLACE_ORDER = 'marketplace/order/place-order'

export const useSaveBillingAddress = (): {
  data: IBillingAddress | undefined
  isLoading: boolean
  mutate: UseMutateFunction
  error: string
} => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate, error } = useMutation<IBillingAddress, any>(
    async (body) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(SAVE_BILLING_ADDRESS, body)
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(GET_BILLING_ADDRESS)
      }
    }
  )
  return { data, isLoading, mutate, error: error?.message }
}

export const useUpdateBillingAddress = (): {
  data: IBillingAddress | undefined
  isLoading: boolean
  mutate: UseMutateFunction
  error: string
} => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate, error } = useMutation<IBillingAddress, any>(
    async (body) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.patch<any>(UPDATE_BILLING_ADDRESS, body)
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(GET_BILLING_ADDRESS)
      }
    }
  )
  return { data, isLoading, mutate, error: error?.message }
}

export const useGetBillingAddress = (): {
  data: IBillingAddress | undefined
  error: AppError | unknown
} => {
  const { data, error } = useQuery(
    GET_BILLING_ADDRESS,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(GET_BILLING_ADDRESS)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error }
}

export const usePlaceOrder = (): {
  data: IOrderDetail | undefined
  isLoading: boolean
  mutate: UseMutateFunction<IOrderDetail, any, IPlaceOrderPayload, unknown>
  error: string
} => {
  const { data, isLoading, mutate, error } = useMutation<IOrderDetail, any, IPlaceOrderPayload>(
    async (body) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(PLACE_ORDER, body)
    }
  )
  return { data, isLoading, mutate, error: error?.message }
}
