import Http from '@shared/helper/http-api'
import { type ITransaction } from '@shared/typings'
import { useQuery } from 'react-query'
import { type AppError } from 'single-spa'

const BASE_URL = process.env.BASE_URL

const TRANSACTION_HISTORY = 'marketplace/invoices/get-by-customer-id'
const RECEIPT_TRANSACTION_HISTORY =
  'marketplace/user-management/payment-intent'

const fallback = []

export const usePaymentIntents = (customerId: number | undefined | null): {
  data: any
  isFetching: boolean
  isLoading: boolean
  isError: boolean
  error: AppError | unknown
} => {
  const url = `${RECEIPT_TRANSACTION_HISTORY}`

  const {
    data: { data } = fallback,
    isFetching,
    isLoading,
    isError,
    error
  } = useQuery(
    [RECEIPT_TRANSACTION_HISTORY, customerId],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(url)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, isFetching, isLoading, isError, error }
}

export const useTransactionHistory = (customerId: number | undefined | null): {
  data: ITransaction[]
  isFetching: boolean
  isLoading: boolean
  isError: boolean
  error: AppError | unknown
} => {
  const url = `${TRANSACTION_HISTORY}`

  const {
    data: { data } = fallback,
    isFetching,
    isLoading,
    isError,
    error
  } = useQuery(
    [TRANSACTION_HISTORY, customerId],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(url)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, isFetching, isLoading, isError, error }
}
