import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const NewsLetterWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.newsletter-wrap': {
    backgroundColor: theme.palette.grey[900],
    position: 'relative',
    margin: 40,
    borderRadius: 40,
    '.inner-content': {
      padding: 120,
      overflow: 'hidden',
      [theme.breakpoints.down('lg')]: {
        padding: '120px 64px'
      },
      [theme.breakpoints.down('md')]: {
        padding: '64px 32px'
      }
    },
    '.newsletter-content': {
      position: 'relative',
      zIndex: 20
    },
    '.text-white': {
      color: theme.palette.common.white
    },
    '.text-muted': {
      color: theme.palette.grey[200]
    },
    [theme.breakpoints.down('md')]: {
      margin: '80px 24px',
      overflow: 'hidden',
      borderRadius: 24
    },
    '.subscribe-form-wrap': {
      '.u-form-group': {
        margin: '0 0 16px 0',
        '.u-form-control': {
          backgroundColor: 'rgba(255, 255, 255, 0.04)',
          '.MuiInputBase-input': {
            color: theme.palette.common.white,
            '&::placeholder': {
              opacity: 1
            }
          },
          '&.Mui-focused': {
            '.MuiInputBase-input': {
              '&::placeholder': {
                opacity: 1
              }
            }
          },
          fieldset: {
            borderColor: 'rgba(255, 255, 255, 0.2)'
          }
        },
        [theme.breakpoints.up('md')]: {
          margin: '0 24px 0 0',
          flexGrow: 1,
          '.u-form-control': {
            backgroundColor: 'transparent',
            height: 'auto',
            '.MuiInputBase-input': {
              padding: 0
            },
            '&.Mui-focused': {
              fieldset: {
                borderColor: 'transparent'
              }
            },
            '&:hover': {
              fieldset: {
                borderColor: 'transparent'
              }
            },
            fieldset: {
              borderColor: 'transparent'
            }
          }
        }
      },
      '.MuiButton-root': {
        width: '100%'
      },
      [theme.breakpoints.up('md')]: {
        backgroundColor: 'rgba(255, 255, 255, 0.04)',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        borderRadius: 12,
        padding: '12px 12px 12px 32px',
        display: 'flex',
        alignItems: 'center',
        '.MuiButton-root': {
          flexShrink: 0,
          width: 'auto'
        }
      }
    },
    '.combine-shapes': {
      position: 'absolute',
      bottom: 0,
      top: 'auto',
      left: '50%',
      transform: 'translateX(-25%)',
      '.ellipse': {
        maxHeight: 96,
        '.ellipse-desktop': {
          maxHeight: 96,
          maxWidth: '100%',
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        },
        '.ellipse-mobile': {
          maHeight: 110,
          maxWidth: '100%',
          [theme.breakpoints.up('md')]: {
            display: 'none'
          }
        },
        [theme.breakpoints.down('md')]: {
          maxHeight: 110
        }
      },
      '.circle-blur': {
        width: 120,
        height: 120,
        position: 'absolute',
        background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.04) 100%)',
        borderRadius: '50%',
        boxShadow: '0px 16px 32px rgba(0, 0, 0, 0.2)',
        backdropFilter: 'blur(12px)',
        top: -50,
        zIndex: 10,
        left: 0,
        [theme.breakpoints.down('md')]: {
          width: 96,
          height: 96,
          top: 25,
          left: 15
        }
      },
      [theme.breakpoints.down('md')]: {
        bottom: 'auto',
        top: 0,
        left: 'auto',
        transform: 'none',
        right: 0
      }
    },
    '.circle-shape': {
      position: 'absolute',
      top: 0,
      left: 80,
      width: 80,
      height: 40,
      borderRadius: '0 0 12rem 12rem',
      backgroundColor: '#F3B140',
      [theme.breakpoints.down('md')]: {
        top: 'auto',
        left: 'auto',
        width: 64,
        height: 32,
        borderRadius: '12rem 12rem 0 0',
        bottom: 0,
        right: 87
      }
    }
  }
}))
