import { retryPaymentSuccessMsg } from '@shared/constants'
import { type AppError } from '@shared/helper'
import Http from '@shared/helper/http-api'
import { type IResponse, type ICardDetail, type IPaymentPlan } from '@shared/typings'
import { type UseMutateFunction, useMutation, useQuery, useQueryClient, type UseMutationResult } from 'react-query'
import { useStoreContext } from 'store/storeContext'

const BASE_URL = process.env.BASE_URL

const PAYMENT_METHOD = 'marketplace/user-management/payment-method'
const DEFAULT_CARD = 'marketplace/user-management/payment-method/default'
const RETRY_PAYMENT = 'marketplace/payment/retry-payment'
export const GET_PAYMENT_PLAN = 'marketplace/order/get-payment-plan'
const STRIPE_BASE_URL = 'https://api.stripe.com/v1'
const CREATE_TOKEN = 'tokens'

export const useStripeCreateToken = (): UseMutationResult<any, unknown, any, unknown> => {
  return useMutation(
    async (cardData: any) => {
      const apiService = Http.createConnection({
        baseUrl: STRIPE_BASE_URL,
        withAuthentication: true
      })
      const _cardData = Object.entries(cardData.card)
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        .map(([key, value]) => `card[${key}]=${value}`)
        .join('&')
      return await apiService.post<any>(CREATE_TOKEN, _cardData, {
        headers: {
          Authorization: `Bearer ${process.env.STRIPE_PUBLISHABLE_KEY ?? ''}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    }
  )
}

export const useAddNewCard = (): {
  data: ICardDetail
  isLoading: boolean
  isError: boolean
  error: AppError | unknown
  mutate: UseMutateFunction
} => {
  const queryClient = useQueryClient()
  const { data, isLoading, isError, error, mutate } = useMutation(
    async (cardData) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(PAYMENT_METHOD, cardData)
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(PAYMENT_METHOD)
      }
    }
  )
  return { data, isLoading, isError, error, mutate }
}

export const useGetCards = (): {
  data: any
  isLoading: boolean
  isError: boolean
  error: AppError | unknown
  isFetching: boolean
} => {
  const { data, error, isLoading, isError, isFetching } = useQuery(
    PAYMENT_METHOD,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(PAYMENT_METHOD)
    },
    {
      refetchOnWindowFocus: false
    }
  )
  return { data, error, isLoading, isError, isFetching }
}

export const useDeleteCard = (): {
  data: ICardDetail[] | undefined
  isLoading: boolean
  isError: boolean
  error: AppError | unknown
  mutate: UseMutateFunction<ICardDetail[] | IResponse, unknown, string, unknown>
} => {
  const queryClient = useQueryClient()
  const { data, isLoading, isError, error, mutate } = useMutation<ICardDetail[], AppError | unknown, string>(
    async (cardId) => {
      const url = `${PAYMENT_METHOD}/${cardId}`
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.delete<any>(url)
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(PAYMENT_METHOD)
      }
    }
  )
  return { data, isLoading, isError, error, mutate }
}

export const useDefaultCard = (): {
  data: ICardDetail[] | undefined
  isLoading: boolean
  isError: boolean
  error: AppError | unknown
  mutate: UseMutateFunction<ICardDetail[] | IResponse, unknown, string, unknown>
} => {
  const queryClient = useQueryClient()
  const { data, isLoading, isError, error, mutate } = useMutation<ICardDetail[], AppError | unknown, string>(
    async (cardId) => {
      const url = `${DEFAULT_CARD}/${cardId}`
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(url, {})
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(PAYMENT_METHOD)
      }
    }
  )
  return { data, isLoading, isError, error, mutate }
}

export const useRetryPayment = (): UseMutationResult<any, AppError | unknown, number, unknown> => {
  const queryClient = useQueryClient()
  const { actions: { setToaster } } = useStoreContext()
  return useMutation(
    async (orderId: number) => {
      const url = `${RETRY_PAYMENT}/${orderId}`
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(url, {})
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(GET_PAYMENT_PLAN)
        setToaster({
          isDisplay: true,
          message: retryPaymentSuccessMsg,
          type: ''
        })
      },
      onError: (error: AppError) => {
        setToaster({
          isDisplay: true,
          message: error?.message,
          type: 'error'
        })
      }
    }
  )
}

export const useGetPaymentPlan = (): {
  data: IPaymentPlan[] | undefined
  error: AppError | unknown
  isLoading: boolean
} => {
  const { data, error, isLoading } = useQuery<IPaymentPlan[]>(
    GET_PAYMENT_PLAN,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(GET_PAYMENT_PLAN)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error, isLoading }
}
