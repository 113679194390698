import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const ProductWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.products-wrap': {
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-white': {
      color: theme.palette.common.white
    },
    '.link': {
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '.cta': {
      '.btn': {
        padding: '12px 36px',
        [theme.breakpoints.down('sm')]: {
          width: '100%'
        }
      },
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        padding: '32px 32px',
        width: '100%',
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 32px 64px rgba(0, 0, 0, 0.18)'
      }
    },
    '.no-products': {
      textAlign: 'center',
      '.no-prd-img': {
        maxHeight: 320,
        img: {
          maxWidth: '100%',
          maxHeight: 320
        },
        [theme.breakpoints.down('md')]: {
          maxHeight: 200,
          img: {
            maxHeight: 200
          }
        }
      }
    }
  }
}))
export const ProductCard = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.product-card': {
    '.prd-img': {
      position: 'relative',
      height: 120,
      textAlign: 'center',
      overflow: 'hidden',
      cursor: 'pointer',
      img: {
        maxWidth: '100%',
        maxHeight: 120
      },
      '&.with-overlay': {
        '&::before': {
          display: 'block',
          content: "' '",
          backgroundColor: 'rgba(0,0,0,0.7)',
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
          transition: 'all 0.3s ease-in-out 0s',
          opacity: 1
        },
        '.prd-overlay-content': {
          position: 'absolute',
          top: '50%',
          zIndex: 10,
          transform: 'translateY(-50%)',
          left: 32,
          right: 32,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: 'auto',
          transition: 'all 0.3s ease-in-out 0s',
          opacity: 1,
          '.btn': {
            marginTop: 15,
            '&.btn-primary': {
              '&:hover': {
                color: theme.palette.grey[900],
                borderColor: theme.palette.common.white,
                backgroundColor: theme.palette.common.white
              }
            },
            '&.btn-secondary': {
              color: theme.palette.common.white,
              '&:hover': {
                color: theme.palette.grey[900],
                borderColor: theme.palette.common.white,
                backgroundColor: theme.palette.common.white
              }
            }
          }
        }
        // "&:hover": {
        //   "&:before": {
        //     opacity: 1,
        //   },
        //   ".prd-overlay-content": {
        //     transform: "translateY(-50%)",
        //     opacity: 1,
        //   },
        // },
      },
      '.text-danger': {
        color: theme.palette.error.main
      },
      '&::before': {
        display: 'block',
        content: "' '",
        backgroundColor: '#F5F5F7',
        width: '100%',
        height: 96,
        position: 'absolute',
        top: 12,
        borderRadius: theme.shape.borderRadius,
        zIndex: -1
      }
    },
    '.prod-details': {
      '.item': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        '.MuiTypography-root': {
          flex: '0 0 43%',
          maxWidth: '43%',
          '&:first-child': {
            flex: '0 0 53%',
            maxWidth: '53%'
          },
          '&:last-child': {
            textAlign: 'right'
          }
        }
      },
      '.progress-wrap': {
        '.u-progress-bar': {
          '&.progress-header': {
            backgroundColor: theme.palette.grey[100],
            height: 8
          }
        },
        '&.progress-danger': {
          '.u-progress-bar': {
            '.MuiLinearProgress-bar': {
              backgroundColor: theme.palette.error.main
            }
          }
        }
      },
      '.prd-action': {
        display: 'flex',
        position: 'relative',
        '.btn': {
          '&:first-child': {
            flexGrow: 1
          },
          '&:not(:first-of-type)': {
            marginLeft: 8,
            flexShrink: 0
          },
          '&.btn-secondary': {
            '&:focus': {
              backgroundColor: 'transparent',
              borderColor: theme.palette.grey[300],
              color: theme.palette.grey[900],
              '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.grey[900],
                borderColor: theme.palette.grey[900]
              }
            },
            '&.with-icon': {
              '&:focus': {
                svg: {
                  path: {
                    stroke: theme.palette.grey[900]
                  }
                },
                '&:hover': {
                  svg: {
                    path: {
                      stroke: theme.palette.common.white
                    }
                  }
                }
              }
            }
          }
        },
        '.u-dropdown-menu': {
          '.MuiMenu-paper': {
            minWidth: 243,
            marginTop: 8,
            [theme.breakpoints.down('md')]: {
              minWidth: 'calc(50% - 48px)',
              left: '24px !important'
            },
            [theme.breakpoints.down('sm')]: {
              minWidth: 'calc(100% - 48px)',
              left: '24px !important'
            }
          },
          '.u-dropdown-item': {
            position: 'relative',
            '&:last-child': {
              marginTop: 16,
              '&::before': {
                display: 'block',
                content: '""',
                borderStyle: 'solid',
                borderWidth: '1px 0 0 0',
                width: 'calc(100% - 32px)',
                height: 0,
                borderColor: '#E5E5E5',
                position: 'absolute',
                top: -16,
                margin: '8px 0'
              },
              '&:hover': {
                color: theme.palette.error.main
              }
            }
          }
        },
        '&.is-unsubscribed': {
          '.u-dropdown-menu': {
            '.u-dropdown-item': {
              '&:last-child': {
                marginTop: 0,
                '&::before': {
                  display: 'none'
                },
                '&:hover': {
                  color: theme.palette.grey[900]
                }
              }
            }
          }
        }
      }
    }
  }
}))
