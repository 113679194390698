import { type FC } from 'react'
import { useTheme } from '@mui/material'
import { BlogListItem } from './blogListItemStyle'
import { Box, Typography } from '@ntpkunity/controls'
import { type IBlog } from '@shared/typings'
import { formatedDate } from '@shared/helper'
import parse from 'html-react-parser'
import { NavLink } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'
interface BlogItemProps {
  blog: IBlog
  onClick?: () => void
}

export const BlogListItemComponent: FC<BlogItemProps> = ({
  blog,
  onClick
}) => {
  const theme = useTheme()

  return (
    <BlogListItem theme={theme} className="blog-list-item">
      <NavLink
        to={`${APP_ROUTES.BLOGS}/${blog.title_url}`}
        style={{ textDecoration: 'none' }}
      >
        <Box theme={theme} className='blog-img'>
          <img src={blog.image} alt={blog.title} />
        </Box>
      </NavLink>
      <Box theme={theme} className='blog-meta' display={'flex'} alignItems={'center'} gap={1} mt={2}>
        <Typography theme={theme} variant='caption' component='small' className='text-muted fw-medium'>{formatedDate(blog.created_at, 'DD MMM, YYYY')}</Typography>
        <span className='seperator'></span>
        <Typography theme={theme} variant='caption' component='small' className='text-muted fw-medium'>{blog.author}</Typography>
      </Box>
      <Box theme={theme} className='blog-content'>
        <Typography theme={theme} variant='h4' component='h4' mt={1} className='blog-title text-truncate lines-2'>
          <a href="javascript:void(0)" onClick={onClick}>{blog.title}</a>
        </Typography>
        <Typography theme={theme} variant='body2' component='span' mt={1} display={'block'} className='text-muted text-truncate lines-3'>{parse(blog?.content ?? '')}</Typography>
          <Box theme={theme} mt={2} className='blog-tags'>
          {blog.tags?.map(({ title }, index) => (
            <Typography
              key={index}
              theme={theme}
              className="badge text-primary text-uppercase"
              variant="caption"
              component="small"
            >
              {title}
            </Typography>
          ))}
        </Box>
      </Box>
    </BlogListItem>
  )
}
