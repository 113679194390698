import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.main-content': {
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-danger': {
      color: theme.palette.error.main
    },
    '.custom-container': {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 32,
        paddingRight: 32
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: 24,
        paddingRight: 24
      }
    }
  }
}))

export const TableWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.table-wrap': {
    '.u-table-wrap': {
      '.u-table': {
        '.u-table-head, .u-table-body': {
          td: {
            '&.cell-md': {
              minWidth: 292,
              maxWidth: 292
            }
          }
        },
        '.u-table-body': {
          tr: {
            '&:nth-child(2n+1)': {
              td: {
                backgroundColor: theme.palette.common.white
              }
            },
            '&:hover': {
              td: {
                backgroundColor: 'rgb(242, 244, 255)',
                '&:first-child': {
                  borderTopLeftRadius: theme.shape.borderRadius,
                  borderBottomLeftRadius: theme.shape.borderRadius
                },
                '&:last-child': {
                  borderTopRightRadius: theme.shape.borderRadius,
                  borderBottomRightRadius: theme.shape.borderRadius
                }
              }
            },
            '.loader-cell': {
              padding: 25
            }
          }
        }
      },
      '.u-table-container': {
        border: 'none'
      }
    },
    '.status-indicator': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor: theme.palette.primary.main,
      marginRight: 8,
      '&.ind-danger': {
        backgroundColor: theme.palette.error.main
      },
      '&.ind-success': {
        backgroundColor: theme.palette.success.main
      }
    },
    '.text-right': {
      textAlign: 'right'
    },
    '.loader-cell': {
      '.loader': {
        width: 80,
        height: 80,
        img: {
          maxHeight: 80
        }
      }
    },
    '.MuiTablePagination-root': {
      marginTop: '-4px',
      paddingTop: '4px',
      borderColor: theme.palette.grey[100],
      color: theme.palette.grey[600],
      fontSize: theme.typography.htmlFontSize,
      fontFamily: theme.typography.fontFamily
    },
    '.MuiTablePagination-select:focus': {
      backgroundColor: 'transparent'
    },
    '.MuiTablePagination-selectIcon': {
      top: 2,
      left: 32
    }
  }
}))
