import { type FC, type ReactNode } from 'react'
import { useTheme } from '@mui/material'
import { Footer, Header } from '../index'
import { MainContent } from './homePageLayoutStyle'

export interface HomePageLayoutProps {
  children: ReactNode
}

export const HomePageLayout: FC<HomePageLayoutProps> = ({ children }) => {
  const theme = useTheme()
  return (
    <>
      <Header theme={theme} />
      <MainContent theme={theme} className="main-content">
        {children}
      </MainContent>
      <Footer />
    </>
  )
}
