import { Box, type IBoxProps } from '@ntpkunity/controls'
import { styled } from '@mui/material/styles'

export const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.main-content': {
    paddingTop: 112,
    paddingBottom: 80,
    [theme.breakpoints.up('md')]: {
      paddingTop: 145
    },
    '.custom-container': {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 32,
        paddingRight: 32
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: 24,
        paddingRight: 24
      }
    },
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.link': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '.text-dark': {
      color: theme.palette.grey[900]
    },
    [theme.breakpoints.down('md')]: {
      '.text-h2': {
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight
      },
      '.text-h4': {
        fontSize: theme.typography.subtitle1.fontSize,
        lineHeight: theme.typography.subtitle1.lineHeight
      }
    },
    '.cards-area-wrap': {
      '.scrollable-area': {
        '&.is-checkout': {
          paddingTop: 12
        }
      }
    }
  }
}))

export const StartCheckoutWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.start-checkout-wrap': {}
}))
