export const setFavicon = (): void => {
  let favicon: any = document.querySelector("link[rel~='icon']")
  if (favicon == null) {
    favicon = document.createElement('link')
    favicon.rel = 'icon'
    document.getElementsByTagName('head')[0].appendChild(favicon)
  }
  favicon.href =
    'https://marketplace-bucket-assets.s3.ap-south-1.amazonaws.com/favicon.ico'
}
