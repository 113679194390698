import { type FC, useState, useEffect } from 'react'
import { useTheme } from '@mui/material'
import { Box, Grid, Typography, Accordion, LinearProgressBar } from '@ntpkunity/controls'
import { useModal } from 'react-modal-hook'
import { ProductDemoVideoComponent } from '../../../homePage/components/productDemoVideo/productDemoVideo'
import PlayIcon from '../../../../shared/assets/images/ic-play-circle.svg'
import { HowToConnectWrap } from './howToConnectStyle'
import { useGetProductConnect } from './howToConnectService'
import { type IProduct, type IProductConnects } from '@shared/typings'
import { FadeAnimation } from '@shared/components'

interface IProductConnectProps {
  product?: IProduct
}
export const HowToConnectComponent: FC<IProductConnectProps> = ({ product }) => {
  const productName = product?.name
  const { data: products } = useGetProductConnect(product?.id)

  const theme = useTheme()
  const [videoUrl, setVideoUrl] = useState<string>('')
  const [activeStep, setActiveStep] = useState(0)
  const [progress, setProgress] = useState(0)
  const [progressSteps, setProgressSteps] = useState(0)
  const stepDuration = 10200 // 5 seconds per step

  function handleStepClick (stepIndex: number): void {
    setProgressSteps(0)
    setActiveStep(stepIndex)
    setProgress((stepIndex + 1) * (100 / ((products ?? []))?.[0]?.product_usage_steps.length))
  }

  const totalSteps = ((products ?? []))[0]?.product_usage_steps.length
  const setStepWidht = (totalSteps: number): number => {
    let maxWidth

    switch (totalSteps) {
      case 5:
        maxWidth = 20
        break
      case 4:
        maxWidth = 25
        break
      case 3:
        maxWidth = 33.33
        break
      case 2:
        maxWidth = 50
        break
      case 1:
        maxWidth = 100
        break
      default:
        maxWidth = null
        break
    }

    return maxWidth
  }

  const [showDemoVideo, hideDemoVideo] = useModal(
    () => (
      <ProductDemoVideoComponent hide={hideDemoVideo} videoLink={(products ?? [])?.[0]?.video_url} />
    ),
    [videoUrl]
  )
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const stepDuration = 10200 // 5 seconds per step
        const increment = 100 / totalSteps
        const nextProgress = prevProgress + increment
        if (nextProgress >= increment * (activeStep + 1)) {
          setActiveStep((prevStep) => prevStep === 3 ? 0 : prevStep + 1)
        } else if (nextProgress >= 100) {
          clearInterval(timer)
          setActiveStep(0)
          setProgress(0)
        }
        return nextProgress
      })
      setProgressSteps(0)
    }, stepDuration) // 1 second interval for progress updates

    return () => {
      clearInterval(timer)
    }
  }, [activeStep, products])

  useEffect(() => {
    const timer = setInterval(() => {
      setProgressSteps((prevProgress) => (prevProgress === 100 ? 0 : prevProgress + 1))
    }, 100)

    return () => {
      clearInterval(timer)
    }
  }, [activeStep])

  const getThumbnail = (src: string): string | undefined => {
    const youtubeVideoId = src
      ?.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)
      ?.pop()

    if (youtubeVideoId?.length === 11) {
      const videoThumbnail = `//img.youtube.com/vi/${youtubeVideoId}/0.jpg`
      return videoThumbnail
    }
  }
  return (
    <>
      <HowToConnectWrap theme={theme} className='how-to-connect-wrap'>
        {(products != null) && products.length > 0 && products?.map((product: IProductConnects, index: any) => (

          <Box key={index} theme={theme} className="inner-content">
            <Grid theme={theme} container item spacing={3} justifyContent={'center'}>
              <Grid theme={theme} item xs={12} lg={10}>
                <FadeAnimation>
                  <Typography
                    display={'inline-block'}
                    mb={3}
                    theme={theme}
                    className="badge text-white text-uppercase"
                    variant="caption"
                    component="small"
                  >
                    HOW TO CONNECT
                  </Typography>
                </FadeAnimation>
              </Grid>
            </Grid>
            <Grid theme={theme} container item spacing={{ xs: 3, md: 10 }} justifyContent={'center'}>
              <Grid theme={theme} item xs={12} md={6.5} lg={5}>
                <FadeAnimation>
                  <Box theme={theme} className='main-title'>
                    <Typography theme={theme} className='text-h1 text-white' variant='h1' component='h1'>
                      {product?.main_text}
                      {' '}
                      <span className='fw-light text-muted'>Connecting {productName} with your ecosystem.</span>
                    </Typography>
                  </Box>
                </FadeAnimation>
              </Grid>
              <Grid theme={theme} item xs={12} md={5.5} lg={5}>
                <FadeAnimation>
                  <Box
                    theme={theme}
                    className="prd-img"
                    mb={2}
                    onClick={() => {
                      setVideoUrl(product?.video_url)
                      showDemoVideo()
                    }}
                  >
                    <img src={getThumbnail(product?.video_url)} />
                    <span className="play-icon">
                      <img src={PlayIcon} alt="Play" />
                    </span>
                  </Box>
                </FadeAnimation>
              </Grid>
            </Grid>
            <Grid theme={theme} container item spacing={3} justifyContent={'center'}>
            <Grid theme={theme} item xs={12} lg={10} overflow={'hidden'}>
              <FadeAnimation direction='down'>
                  <Typography theme={theme} mt={{ xs: 0.75, md: 3 }} className='text-muted text-body2' display={'block'} component='span' variant='body2'>
                    {product?.sub_text}
                  </Typography>
              </FadeAnimation>
            </Grid>
            </Grid>
            <Grid theme={theme} container item spacing={3} justifyContent={'center'} mt={{ xs: 5, md: 12 }}>
              <Grid theme={theme} item xs={12} lg={10}>
                <FadeAnimation>
                  <Box theme={theme} className='progress-steps'>
                    {product?.product_usage_steps?.map(({ step_headline: stepHeadline, step_description: stepDescription }, index) => (
                      <Box theme={theme} className={`step-item ${index === activeStep ? 'step-item active' : 'step-item '}`} key={stepHeadline} maxWidth={`${setStepWidht(totalSteps)}%`}>
                        <LinearProgressBar theme={theme} value={index === activeStep ? progressSteps : 0} />
                        <Typography mt={{ xs: 0, md: 2 }} ml={{ xs: 2, md: 0 }} theme={theme} variant='body2' component='span' display={'block'} className='text-muted text-body2 step-count'>
                          0{index + 1}.
                        </Typography>
                        <Typography mt={{ xs: 0, md: 0.5 }} ml={{ xs: 2, md: 0 }} theme={theme} variant='h4' component='h4' className='fw-normal text-muted text-h4' onClick={() => { handleStepClick(index) }}>
                          {stepHeadline}
                        </Typography>
                        <Typography mt={{ xs: 0, md: 2 }} ml={{ xs: 2, md: 0 }} theme={theme} variant='body2' component='span' display={'block'} className='text-muted text-body2 desc-text'>
                          {index === activeStep && stepDescription}
                        </Typography>
                      </Box>
                    ))}

                  </Box>
                </FadeAnimation>

              </Grid>
            </Grid>
          </Box>
        ))}
      </HowToConnectWrap>
    </>
  )
}
