import {
  Grid,
  Button,
  Box,
  Dialog,
  Input,
  Checkbox,
  Icon
} from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import {
  CardIcon,
  getSubstring,
  validation,
  determineCardType,
  Validation,
  className
} from 'shared'
import { Controller, type SubmitHandler, useForm } from 'react-hook-form'
import { AddNewCardButtons } from '@modules/billingDetails'
import { type FC, useState } from 'react'
import { useAddNewCard, useStripeCreateToken } from '../services/newCard/newCardService'
import { useStoreContext } from 'store/storeContext'
import { DialogContent } from './addNewCardStyle'
import DisableLoader from '@shared/assets/images/loader-disabled-btn.gif'
import { type ISuccess } from '@shared/typings'

const messages = {
  title: 'Add New Card',
  label: {
    cardHolderName: 'Card Holder Name',
    cardNumber: 'Card Number',
    expiryDate: 'Expiry Date',
    cvc: 'CVC',
    defaultCard: 'Mark as default card'
  },
  name: {
    cardNumber: 'number',
    cardHolderName: 'card_holder_name',
    expiryData: 'expiry_date',
    cvc: 'cvc',
    isDefault: 'is_default'
  },
  validation: {
    cardNumber: 'Card number',
    cardHolderName: 'Card holder name',
    expiryData: 'Expiry date',
    cvc: 'CVC'
  },
  button: {
    save: 'Save Card',
    wait: 'Wait...',
    cancel: 'Cancel'
  }
}

interface IAddNewCardProps {
  hide: () => void
  numberOfCards: number
}

export const AddNewCard: FC<IAddNewCardProps> = ({ hide, numberOfCards }) => {
  const theme = useTheme()
  const [cardType, setCardType] = useState<string>('')
  const { mutate: createToken, isLoading: creatingToken } = useStripeCreateToken()
  const { mutate: addNewCard, isLoading } = useAddNewCard()
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm()
  const {
    actions: { setToaster }
  } = useStoreContext()

  const submit: SubmitHandler<any> = (formData) => {
    const { number, expiry_date: expiryData, cvc, is_default: isDefault } = formData
    const expiryMonth = getSubstring(expiryData, 0, 2)
    const expiryYear = getSubstring(expiryData, 2, 4)

    const createTokenPayload = {
      card: {
        number,
        exp_month: expiryMonth,
        exp_year: expiryYear,
        cvc
      }
    }

    createToken(createTokenPayload, {
      onSuccess (data) {
        const payload = {
          token: data?.id,
          is_default: isDefault
        }
        addNewCard(payload as any, {
          onSuccess (data: ISuccess) {
            const { success } = data
            if (success) {
              hide()
            }
            setToaster({
              isDisplay: true,
              message: data.message,
              type: ''
            })
          },
          onError (error) {
            setToaster({
              isDisplay: true,
              message: (error as any).message,
              type: 'error'
            })
          }
        })
      },
      onError (error) {
        setToaster({
          isDisplay: true,
          message: (error as any).message,
          type: 'error'
        })
      }
    })
  }

  return (
    <Dialog
      theme={theme}
      size="sm"
      title={messages.title}
      open={true}
      onCloseDialog={hide}
      customFooter={
        <>
          <AddNewCardButtons theme={theme} className="new-card-buttons">
            {// eslint-disable-next-line @typescript-eslint/no-misused-promises
            }<form className="buttons" onSubmit={handleSubmit(submit)}>
              <Button
                secondary
                theme={theme}
                text={messages.button.cancel}
                onClick={hide}
              />
              <Button
                className={`save-button ${className.checkoutAddNewCardSaveBtn}`}
                primary
                theme={theme}
                disabled={isLoading || creatingToken}
                text={(isLoading || creatingToken) ? messages.button.wait : messages.button.save}
                type="submit"
                startIcon={
                  (isLoading || creatingToken) && (
                    <img src={DisableLoader} alt="Loader" />
                  )
                }
              />
            </form>
          </AddNewCardButtons>
        </>
      }
    >
      <DialogContent theme={theme} className="dilaog-content">
        <form>
          <Controller
            name={messages.name.cardNumber}
            control={control}
            rules={validation(
              messages.validation.cardNumber,
              Validation.REQUIRED,
              Validation.WHITE_SPACE,
              Validation.LIMIT_LENGTH,
              '50'
            )}
            render={({ field }) => (
              <Input
                maskNumeric
                masking
                format="#### #### #### ####"
                theme={theme}
                fullWidth
                label={messages.label.cardNumber}
                type="texts"
                // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
                min={9999999999999999}
                // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
                max={9999999999999999}
                {...field}
                placeholder="0000 0000 0000 0000"
                error={errors?.[messages.name.cardNumber]?.message?.toString()}
                onChange={(e) => {
                  field.onChange(e)
                  determineCardType(e, setCardType)
                }}
                endAdornment={
                  <>{(cardType.length > 0) && <Icon name={CardIcon[cardType]} />}</>
                }
              />
            )}
          />

          <Controller
            name={messages.name.cardHolderName}
            control={control}
            rules={validation(
              messages.validation.cardHolderName,
              Validation.REQUIRED,
              Validation.ONLY_ALPHABETS
            )}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                label={messages.label.cardHolderName}
                type="text"
                {...field}
                placeholder="Type here..."
                error={errors?.[
                  messages.name.cardHolderName
                ]?.message?.toString()}
              />
            )}
          />

          <Grid theme={theme} container item spacing={3}>
            <Grid theme={theme} item xs={6} md={6}>
              <Controller
                name={messages.name.expiryData}
                control={control}
                rules={validation(
                  messages.validation.expiryData,
                  Validation.REQUIRED
                )}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    fullWidth
                    maskNumeric
                    masking
                    format="##/##"
                    label={messages.label.expiryDate}
                    type="text"
                    {...field}
                    placeholder="MM/YY"
                    error={errors?.[
                      messages.name.expiryData
                    ]?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={6} md={6}>
              <Controller
                name={messages.name.cvc}
                control={control}
                rules={validation(messages.validation.cvc, Validation.REQUIRED)}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    fullWidth
                    label={messages.label.cvc}
                    min={999}
                    max={999}
                    type={'text'}
                    {...field}
                    placeholder="000"
                    error={errors?.[messages.name.cvc]?.message?.toString()}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box theme={theme} mt={3}>
            <Controller
              control={control}
              name={messages.name.isDefault}
              defaultValue={numberOfCards < 1}
              render={({ field }) => (
                <Checkbox
                  theme={theme}
                  label={messages.label.defaultCard}
                  checkBoxChecked={field.value}
                  checkBoxDisabled={numberOfCards < 1}
                  {...field}
                  onChange={(e) => { setValue(messages.name.isDefault, e.target.checked) }
                  }
                ></Checkbox>
              )}
            />
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  )
}
