import { GET_PAYMENT_PLAN } from '@modules/billingDetails'
import { type ActionTypes, ACTION_TYPE, addFilesToReducer } from '@modules/billingDetails/components/proofOfPaymentDialog/proofOfPaymentReducer'
import { type AppError } from '@shared/helper'
import Http from '@shared/helper/http-api'
import { type PresignedUrlsResponse, type IProofOfPaymentPayload, type IDocumentKeys, type ICustomFile, type IBulkDelete } from '@shared/typings'
import { useMutation, useQuery, type UseQueryResult, type UseMutationResult, useQueryClient } from 'react-query'
import { useStoreContext } from 'store/storeContext'

const BASE_URL = process.env.BASE_URL
const GET_PRE_SIGNED_URLS = 'marketplace/user-management/get-presigned-urls'
const SAVE_PROOF_OF_PAYMENT = 'marketplace/order/payment-proof'
const UPLOAD_IMAGE = 'marketplace/user-management/upload-document'
const DELETE_FILE = 'marketplace/admin/product/delete-file'
const BULK_DELETE_FILE = 'marketplace/user-management/bulk-file-delete'

export const useGetS3PreSignedUrls = (paymentProofKeys: string[] | null, dispatch: React.Dispatch<ActionTypes>): UseQueryResult<PresignedUrlsResponse, AppError | undefined> => {
  const url = (paymentProofKeys ?? []).reduce((acc, key, index) => {
    const separator = index === 0 ? '?' : '&'
    return `${acc}${separator}keys=${key}`
  }, GET_PRE_SIGNED_URLS)

  return useQuery(
    [GET_PRE_SIGNED_URLS, url],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<PresignedUrlsResponse>(url)
    },
    {
      refetchOnWindowFocus: false,
      enabled: (paymentProofKeys ?? []).length > 0,
      onSuccess: (s3FilesData) => {
        const files = Object.values(s3FilesData)
          .map(file => ({
            name: file.file_name,
            size: file.file_size,
            type: file.file_type,
            location: file.url,
            key: file.key
          })) as ICustomFile[]

        addFilesToReducer(files, dispatch)
      }
    }
  )
}

export const useSaveProofOfPayment = (deletedFilesKeys: string[], dispatch: React.Dispatch<ActionTypes>): UseMutationResult<any, AppError, IProofOfPaymentPayload, unknown> => {
  const queryClient = useQueryClient()
  const { actions: { setToaster } } = useStoreContext()
  const { mutate: deleteBulkFiles } = useBulkDeleteFiles()

  return useMutation(
    async (data) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(SAVE_PROOF_OF_PAYMENT, data)
    },
    {
      onSuccess: (data, variables, context) => {
        void queryClient.invalidateQueries(GET_PAYMENT_PLAN)
        if (deletedFilesKeys.length > 0) {
          deleteBulkFiles(
            { keys: deletedFilesKeys },
            {
              onSuccess: () => {
                dispatch({
                  type: ACTION_TYPE.INITIAL_STATE
                })
              }
            }
          )
        }
        setToaster({
          isDisplay: true,
          message: "You've successfully uploaded your proof of payment! We'll update you shortly.",
          type: ''
        })
      },
      onError: (error) => {
        setToaster({
          isDisplay: true,
          message: error?.message,
          type: 'error'
        })
      }
    }
  )
}

export const useUpdateProofOfPayment = (deletedFilesKeys: string[], dispatch: React.Dispatch<ActionTypes>): UseMutationResult<any, AppError, IDocumentKeys & { paymentProofId: number }, unknown> => {
  const queryClient = useQueryClient()
  const { actions: { setToaster } } = useStoreContext()
  const { mutate: deleteBulkFiles } = useBulkDeleteFiles()
  return useMutation(
    async (data) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.patch<any>(`${SAVE_PROOF_OF_PAYMENT}/${data.paymentProofId}`, data)
    },
    {
      onSuccess: (data, variables, context) => {
        void queryClient.invalidateQueries(GET_PAYMENT_PLAN)
        if (deletedFilesKeys.length > 0) {
          deleteBulkFiles(
            { keys: deletedFilesKeys },
            {
              onSuccess: () => {
                dispatch({
                  type: ACTION_TYPE.INITIAL_STATE
                })
              }
            }
          )
        }
        setToaster({
          isDisplay: true,
          message: variables.document_keys?.length === 0 ? 'Deletion confirmed! We are awaiting proof of payment.' : "You've successfully updated your proof of payment! We'll update you shortly.",
          type: ''
        })
      },
      onError: (error) => {
        setToaster({
          isDisplay: true,
          message: error?.message,
          type: 'error'
        })
      }
    }
  )
}

export const useDeleteFile = (): UseMutationResult<any, AppError, string, unknown> => {
  return useMutation(
    async (key) => {
      const url = `${DELETE_FILE}/${key}`
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.delete<any>(url)
    }
  )
}

export const useBulkDeleteFiles = (): UseMutationResult<any, AppError | undefined, IBulkDelete, unknown> => {
  return useMutation(
    async (body) => {
      const url = `${BULK_DELETE_FILE}`
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(url, body, { retry: 5 })
    }
  )
}

export const useUploadFile = (dispatch: React.Dispatch<ActionTypes>): any => {
  const mutation = useMutation(
    async (files: any) => {
      const formData = new FormData()
      formData.append('files', files.file)

      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(
        UPLOAD_IMAGE,
        formData,
        {
          signal: files.abortController.signal,
          onUploadProgress: progressEvent => {
            dispatch({
              type: ACTION_TYPE.UPLOAD_PROGRESS,
              payload: {
                key: files.key,
                progress: Math.round((progressEvent.loaded * 100) / progressEvent.total)
              }
            })
          }
        }
      )
    },
    {
      onMutate: (variables) => {
        dispatch({
          type: ACTION_TYPE.UPLOAD_START,
          payload: {
            key: variables.key,
            onUploadCancel: (key: string, file: any, index: number): void => {
              mutation.reset()
              variables.abortController.abort()
            }
          }
        })
        return variables.abortController
      },
      onSuccess: (data, variables, context) => {
        const { Location, key } = data
        dispatch({
          type: ACTION_TYPE.UPLOAD_SUCCESS,
          payload: {
            key: variables.key,
            onRemoveFile: (key: string, file: any, index: number): void => {
              dispatch({
                type: ACTION_TYPE.REMOVE_FILE,
                payload: { key, file }
              })
            }
          }
        })
        dispatch({
          type: ACTION_TYPE.ADD_S3_FILE,
          payload: {
            file: variables,
            s3Location: Location,
            s3Key: key
          }
        })
      },
      onError: (error: string, variables, context) => {
        if (context?.signal?.reason?.name === 'AbortError' ?? context?.signal?.aborted) {
          dispatch({
            type: ACTION_TYPE.REMOVE_FILE,
            payload: { ...variables }
          })
        } else {
          dispatch({
            type: ACTION_TYPE.UPLOAD_ERROR,
            payload: {
              key: variables.key,
              file: variables,
              error,
              onRetryForError: (key: string, file: any, index: number): void => {
                void mutation.mutateAsync(variables)
                dispatch({
                  type: ACTION_TYPE.UPLOAD_START,
                  payload: {
                    key,
                    onUploadCancel: (key: string, file: any, index: number): void => {
                      mutation.reset()
                      variables.abortController.abort()
                    }
                  }
                })
              },
              onRemoveFile: (key: string, file: any, index: number): void => {
                dispatch({
                  type: ACTION_TYPE.REMOVE_FILE,
                  payload: { key, file }
                })
              }
            }
          })
        }
      }
    }
  )

  return mutation
}
