import { type FC } from 'react'
import { useTheme } from '@mui/material'
import { Typography, Dialog, Box } from '@ntpkunity/controls'
import { DialogContent, VideoPopupWrap } from './productDemoVideoStyle'
interface IProductDemoVideoComponent {
  hide: () => void
  videoLink: string
}
export const ProductDemoVideoComponent: FC<IProductDemoVideoComponent> = ({
  hide,
  videoLink
}) => {
  const theme = useTheme()
  return (
    <>
      <VideoPopupWrap theme={theme} className="video-popup-wrap">
        <Dialog
          theme={theme}
          variant="confirmation"
          open={true}
          onCloseDialog={hide}
          noFooter={true}
          disablePortal={true}
        >
          <DialogContent theme={theme} className="dialog-content-wrap">
            <Box theme={theme} className="video-area">
              <Box
                theme={theme}
                className="video-placeholder"
                display={'none'}
                flexDirection={'column'}
              >
                <Box theme={theme} flexGrow={1}>
                  <Typography
                    theme={theme}
                    variant="h4"
                    component="h4"
                    className="text-white text-uppercase fw-medium letter-spacing"
                  >
                    Flex
                  </Typography>
                </Box>
                <Box theme={theme} flexShrink={0}>
                  <Typography
                    theme={theme}
                    variant="h4"
                    component="h4"
                    className="text-white text-uppercase fw-medium letter-spacing"
                  >
                    40 Min
                  </Typography>
                  <Typography
                    mt={1}
                    theme={theme}
                    variant="h2"
                    component="h2"
                    className="text-white video-title"
                  >
                    Demo Video
                  </Typography>
                </Box>
              </Box>
              <iframe
                width="100%"
                height="480"
                src={videoLink}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </Box>
          </DialogContent>
        </Dialog>
      </VideoPopupWrap>
    </>
  )
}

ProductDemoVideoComponent.propTypes = {}

ProductDemoVideoComponent.defaultProps = {}
