import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const HelpBoxWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.help-box': {
    padding: '48px 24px 24px',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    flexShrink: 0,
    position: 'relative',
    marginTop: 120,
    '.help-icon': {
      width: 56,
      height: 56,
      borderRadius: '50%',
      backgroundColor: theme.palette.common.white,
      boxShadow: '0px 4px 4px rgba(0, 36, 156, 0.1), 0px 0px 0px 8px #F8F8F8',
      textAlign: 'center',
      margin: 'auto',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      top: -28,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      svg: {
        path: {
          stroke: theme.palette.primary.main
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 0
    }
  }
}))
