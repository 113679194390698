import { useTheme } from '@mui/material'
import { Accordion, Box, Typography } from '@ntpkunity/controls'
import { type IAddOns } from '@shared/typings'
import { convertToDecimal } from '@shared/helper'
import { type FC } from 'react'
import { InvoicePreview, InvoicePreviewMobile } from './invoiceStyle'
import { Loader } from '@shared/components'

interface IPaymentInvoicePreview {
  amount: number
  packageName: string
  productName: string
  selectedAddOns: IAddOns[]
  totalPayable: number
}

export const PaymentInvoicePreview: FC<IPaymentInvoicePreview> = ({
  amount,
  packageName,
  productName,
  selectedAddOns,
  totalPayable
}) => {
  const theme = useTheme()

  return (
    <>
      <InvoicePreview
        theme={theme}
        className="invoice-preview"
        display={{ md: 'block', xs: 'none' }}
      >
        <Box theme={theme} className="invoice-items">
          <Box theme={theme} className="item">
            <Box theme={theme} className="prd-price">
              <Typography
                className="text-muted"
                theme={theme}
                variant="body2"
                component="span"
              >
                {`$${(!Number.isNaN(amount)) ? convertToDecimal(amount / 100) : 0}`}
              </Typography>
            </Box>
            <Box theme={theme} className="prd-name">
              <Typography
                className="text-muted"
                theme={theme}
                variant="body2"
                component="span"
                display={'block'}
              >
                {productName}:
              </Typography>
              <Typography
                className="text-muted"
                theme={theme}
                variant="body2"
                component="span"
                display={'block'}
              >
                {packageName}{' '}
                {packageName !== 'Custom Package - EMBANK' && 'Package'}
              </Typography>
            </Box>
          </Box>
          {selectedAddOns?.map((addon) => (
            <Box key={addon.id} theme={theme} className="item">
              <Box theme={theme} className="prd-price">
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                >
                  ${convertToDecimal(addon.value / 100)}
                </Typography>
              </Box>
              <Box theme={theme} className="prd-name">
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                >
                  Add-on:
                </Typography>
                <Typography
                  className="text-muted"
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                >
                  {addon.name}
                </Typography>
              </Box>
            </Box>
          ))}
          <Box theme={theme} className="item item-total">
            <Box theme={theme} className="prd-price">
              {(!Number.isNaN(amount))
                ? (
                <>
                  <Typography theme={theme} variant="body2" component="span">
                    $
                  </Typography>
                  <Typography
                    className="text-primary"
                    theme={theme}
                    variant="h3"
                    component="h3"
                  >
                    {convertToDecimal(amount / 100 + totalPayable)}
                  </Typography>
                </>
                  )
                : (
                <Loader />
                  )}
            </Box>
            <Box theme={theme} className="prd-name">
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                display={'block'}
              >
                Total Payable:
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box theme={theme} className="disclaimer">
          <Typography
            theme={theme}
            variant="caption"
            component="small"
            className="text-muted"
          >
            By proceeding for payment, you confirm you are authorized to use the
            credit card provided and authorize us or our third party vendor(s)
            to make payment. If your card cannot be charged, your purchase may
            be suspended or cancelled and you will need to resolve the issue to
            proceed further.
          </Typography>
        </Box>
      </InvoicePreview>

      <InvoicePreviewMobile
        theme={theme}
        className="invoice-preview-mobile"
        display={{ xs: 'block', md: 'none' }}
      >
        <Accordion
          theme={theme}
          varient="filters"
          items={[
            {
              content: (
                <>
                  <Box theme={theme} className="invoice-items">
                    <Box theme={theme} className="item">
                      <Box theme={theme} className="prd-price">
                        <Typography
                          className="text-muted"
                          theme={theme}
                          variant="body2"
                          component="span"
                        >
                          {`$${(!Number.isNaN(amount)) ? convertToDecimal(amount / 100) : 0}`}
                        </Typography>
                      </Box>
                      <Box theme={theme} className="prd-name">
                        <Typography
                          className="text-muted"
                          theme={theme}
                          variant="body2"
                          component="span"
                          display={'block'}
                        >
                          {productName}:
                        </Typography>
                        <Typography
                          className="text-muted"
                          theme={theme}
                          variant="body2"
                          component="span"
                          display={'block'}
                        >
                          {packageName !== 'Custom Package - EMBANK' &&
                            'Package'}
                        </Typography>
                      </Box>
                    </Box>
                    {selectedAddOns?.map((addon) => (
                      <Box key={addon.id} theme={theme} className="item">
                        <Box theme={theme} className="prd-price">
                          <Typography
                            className="text-muted"
                            theme={theme}
                            variant="body2"
                            component="span"
                          >
                            ${convertToDecimal(addon.value / 100)}
                          </Typography>
                        </Box>
                        <Box theme={theme} className="prd-name">
                          <Typography
                            className="text-muted"
                            theme={theme}
                            variant="body2"
                            component="span"
                            display={'block'}
                          >
                            Add-on:
                          </Typography>
                          <Typography
                            className="text-muted"
                            theme={theme}
                            variant="body2"
                            component="span"
                            display={'block'}
                          >
                            {addon.name}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <Box theme={theme} className="disclaimer">
                    <Typography
                      theme={theme}
                      variant="caption"
                      component="small"
                      className="text-muted"
                    >
                      By proceeding for payment, you confirm you are authorized
                      to use the credit card provided and authorize us or our
                      third party vendor(s) to make payment. If your card cannot
                      be charged, your purchase may be suspended or cancelled
                      and you will need to resolve the issue to proceed further.
                    </Typography>
                  </Box>
                </>
              ),
              title: 'Total Payable:',
              key: 'string',
              isExpanded: false,
              subtitle: (
                <>
                  <Box
                    theme={theme}
                    className="prd-price"
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <Typography
                      theme={theme}
                      variant="body2"
                      component="span"
                      mr={0.5}
                    >
                      $
                    </Typography>
                    <Typography
                      className="text-primary"
                      theme={theme}
                      variant="h4"
                      component="h4"
                    >
                      ${(!Number.isNaN(amount)) ? convertToDecimal(amount / 100) : 0}
                    </Typography>
                  </Box>
                </>
              )
            }
          ]}
        />
      </InvoicePreviewMobile>
    </>
  )
}
