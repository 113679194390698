import { Box, Typography, Icon, Checkbox } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { PaymentCards } from '../../pages'
import { useGetCardInfo } from '@modules/paymentPlan'
import { type ReactElement } from 'react'

export const PaymentCard = (): ReactElement => {
  const theme = useTheme()
  const { data: cardDetail } = useGetCardInfo()

  return (
    <PaymentCards theme={theme} className={'payment-cards-wrap no-card'}>
      {Object.keys(cardDetail?.data ?? {}).length > 0
        ? (
        <>
          <Typography theme={theme} variant="subtitle1" component="p" mb={2}>
            Choose Default Card
          </Typography>
          <Box theme={theme} className="card-item selected">
            <Checkbox
              label={`${cardDetail?.data?.brand as string} - ${cardDetail?.data?.last4 as string}`}
              checkBoxChecked
            ></Checkbox>
            <Box theme={theme} className="card-icon">
              <Icon name="PaymentCardIcon" />
            </Box>
          </Box>
        </>
          )
        : (
        <Box theme={theme}>
          <Typography
            mb={3}
            theme={theme}
            variant="body2"
            component="span"
            className="text-muted"
          >
            Your card has not been configured
          </Typography>
        </Box>
          )}
      <a href="#" className="link text-primary">
        <Typography
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          textAlign={'center'}
          mt={2}
        >
          Add New Card
        </Typography>
      </a>
    </PaymentCards>
  )
}
