import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.main-content': {
    paddingTop: 65,
    paddingBottom: 24,
    [theme.breakpoints.up('md')]: {
      paddingTop: 138,
      paddingBottom: 80
    },
    '.custom-container': {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 32,
        paddingRight: 32
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: 24,
        paddingRight: 24
      }
    },
    '.text-dark': {
      color: theme.palette.grey[900]
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-danger': {
      color: theme.palette.error.main
    },
    '.text-white': {
      color: theme.palette.common.white
    },
    '.text-capitalize': {
      textTransform: 'capitalize'
    },
    '.fw-sb': {
      fontWeight: theme.customVariables.fontWeightSemiBold
    },
    '.fw-medium': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '.content-wrap': {
      '.form-cta': {
        '.btn': {
          [theme.breakpoints.down('sm')]: {
            width: '100%'
          }
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      '.text-h3': {
        fontSize: theme.typography.h4.fontSize,
        lineHeight: theme.typography.h4.lineHeight
      },
      '.text-subtitle1': {
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight
      },
      '.text-body2': {
        fontSize: theme.typography.caption.fontSize,
        lineHeight: theme.typography.caption.lineHeight
      }
    },
    '.help-box-section': {
      backgroundColor: '#F8F8F8',
      marginTop: 24,
      borderRadius: 16,
      padding: '40px 24px 24px'
    }
  }
}))

export const ProfileHead = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.profile-head': {
    height: 280,
    borderRadius: 16,
    background: 'linear-gradient(60deg, #0033FD 13.4%, #00E4D0 86.6%)',
    position: 'relative',
    overflow: 'hidden',
    padding: 64,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '&:before': {
      display: 'block',
      content: "' '",
      background: '#00E4D0',
      position: 'absolute',
      width: 658.44,
      height: 291.77,
      left: -130.92,
      top: -208.45,
      filter: 'blur(100px)',
      transform: 'rotate(6.96deg)',
      zIndex: -1
    },
    '&:after': {
      display: 'block',
      content: "' '",
      background: '#F3B140',
      position: 'absolute',
      width: 508,
      height: 298,
      right: 246,
      bottom: -188,
      filter: 'blur(100px)',
      transform: 'rotate(160.25deg)',
      zIndex: -1,
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    '.text-light': {
      opacity: 0.5
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: -24,
      marginRight: -24,
      borderRadius: 0,
      padding: 24,
      height: 136,
      '.text-h3': {
        fontSize: theme.typography.body1.fontSize,
        lineHeight: theme.typography.body1.lineHeight
      },
      '.text-h2': {
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight
      }
    }
  }
}))
