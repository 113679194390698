import { useTheme } from '@mui/material'
import { type FC, useEffect, useState } from 'react'
import { type IResponse, ExpiredLinkComponent } from '@ntpkunity/controls-ums'
import {
  expiredLinkTypes,
  HttpStatus,
  resetPasswordEmailSent,
  verificationEmailSent
} from '@shared/constants'
import { useStoreContext } from 'store/storeContext'
import { Footer, Header } from '@layout/components'
import { Box, Container } from '@ntpkunity/controls'
import { useLocation } from 'react-router-dom'

import { MainContent } from './emailLinkExpirationStyle'
interface IState {
  token: string
  priceId?: string
  verificationScreenUrl: string
  resendEmailEndPoint?: string
  type: 'verfication' | 'password'
  layoutText: {
    title: string
    subTitle: string
    buttonText: string
  }
}

export const EmailLinkExpired: FC = () => {
  const theme = useTheme()
  const { state } = useLocation()
  const {
    token,
    verificationScreenUrl,
    type,
    layoutText,
    resendEmailEndPoint,
    priceId
  } = (state as IState) ?? {}
  const [response, setResponse] = useState<IResponse>()
  const {
    actions: { setToaster }
  } = useStoreContext()

  useEffect(() => {
    if (response?.status === HttpStatus.OK) {
      setToaster({
        isDisplay: true,
        message:
          (type as string).toLowerCase() === expiredLinkTypes.password
            ? resetPasswordEmailSent
            : verificationEmailSent,
        type: ''
      })
    }
  }, [response])

  // useEffect(() => {
  //   if (userProfile?.signup_steps) {
  //     navigate(signupSteps[userProfile?.signup_steps?.toLowerCase()]);
  //   }
  // }, [userProfile]);

  return (
    <>
      <Header theme={theme} />
      <MainContent theme={theme} className="main-content">
        <Container theme={theme} maxWidth="lg" className="custom-container">
          <Box theme={theme} className="full-page-wrap">
            <ExpiredLinkComponent
              priceId={priceId}
              resendEmailEndPoint={resendEmailEndPoint}
              theme={theme}
              setResponse={setResponse}
              verificationScreenUrl={verificationScreenUrl}
              type={type}
              token={token}
              layoutText={layoutText}
            />
          </Box>
        </Container>
      </MainContent>

      <Footer />
    </>
  )
}
