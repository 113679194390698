import { Box, Typography } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { type FC } from 'react'

export const TermsAndConditions: FC = () => {
  const theme = useTheme()

  return (
    <>
      <Typography
        className="text-muted"
        theme={theme}
        variant="body2"
        component="span"
        display={'block'}
        mb={2}
      >
        IMPORTANT - READ CAREFULLY: The products and services that are described
        on the electronic enrollment portal referencing these Terms and
        Conditions of Use and End User License Agreement (this “Agreement”) or
        on the services enrollment or order form to which this Agreement is
        attached (as applicable), including, without limitation, any and all
        websites, software, tools, digital advertising, and related content and
        services, informational web pages, online reporting service, any online
        access to the electronic enrollment portal, and any other online tool,
        solution, platform or service provided to Customer(the “Services”) are
        provided by NetSol Technologies, Inc. a Nevada corporation
        NetSol(“NetSol”) for Customer. This Agreement is a legal contract
        between you, the Customer (“Customer”) subscribing to the Services, and
        NetSol. The Services are subject to the terms set forth in this
        Agreement.
      </Typography>
      <Typography
        className="text-muted"
        theme={theme}
        variant="body2"
        component="span"
        display={'block'}
        mb={2}
      >
        Customer’s enrollment for any Services authorizes NetSol to begin
        providing the Services. Customer agrees to pay the applicable fees for
        the Services.
      </Typography>
      <Typography
        className="text-muted"
        theme={theme}
        variant="body2"
        component="span"
        display={'block'}
        mb={2}
      >
        IN ORDER TO USE THE SERVICES, CUSTOMER MUST FIRST AGREE TO THE TERMS SET
        FORTH IN THIS AGREEMENT BY CLICKING “I AGREE AND CONTINUE” ON THE
        ENROLLMENT PORTAL AFTER MAKING THE SERVICE SELECTIONS, OR BY SELECTING
        SERVICES AND SIGNING A SERVICES ENROLLMENT FORM. CUSTOMER MAY NOT USE
        THE SERVICES IF CUSTOMER DOES NOT ACCEPT THE TERMS OF THIS AGREEMENT.
        CUSTOMER UNDERSTANDS AND AGREES THAT CUSTOMER’S ENROLLMENT FOR AND/OR
        USE OF THE SERVICES CONSTITUTES CUSTOMER’S ACCEPTANCE OF THE TERMS OF
        THIS AGREEMENT.
      </Typography>
      <Typography
        className="text-muted"
        theme={theme}
        variant="body2"
        component="span"
        display={'block'}
        mb={2}
      >
        Before you continue, you should print or save a local copy of this
        Agreement for your records.
      </Typography>
      <Box theme={theme} className="points">
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          1. Services
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          NetSol agrees to provide the Services to Customer in accordance with
          the terms and conditions of this Agreement. NetSol may provide all or
          part of the Services through one or more third party service providers
          and/or any NetSol affiliated company (each, a “Service Provider”)
          selected by NetSol. NetSol, may in its sole discretion change any
          Service Provider, at any time and for any reason. Upon request by
          Customer, NetSol will notify Customer of any such change in Service
          Providers.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          2. Use of the Services
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          In order to access certain Services, Customer may be required to
          provide information about Customer and Customer’s business (such as
          identification, billing or contact details). Customer agrees that any
          information Customer provides will be accurate, complete and up to
          date. Customer agrees to maintain the confidentiality of passwords
          associated with any account Customer uses to access the Services.
          Customer acknowledges that the Services may allow Customer to
          designate administrators for the Services, thereby granting certain
          rights and authorizations relating to, among other things, selection
          of, and preferences for, Services and billing. Customer agrees that
          Customer is solely responsible for all fees, costs or other expenses
          charged related to any selections made by Customer or Customer’s
          designees.
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          CUSTOMER SHALL, AS REQUIRED BY APPLICABLE LAW OR REGULATION, PROVIDE
          NOTICE TO CUSTOMER’S CUSTOMERS AND OBTAIN CONSENT, IF REQUIRED FOR USE
          OF THE SERVICES, IN CUSTOMER’S PRIVACY POLICY AND/OR AS OTHERWISE
          REQUIRED BY LAW OR REGULATION.
        </Typography>

        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          3. Third-Party Services.
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          NetSol may reference, enable Customer to access, or promote (including
          on the NetSol Website) Third-Party Services. These Third-Party
          Services are provided for Customer convenience only and NetSol does
          not approve, endorse, or recommend any Third-Party Services.{' '}
          <Typography
            theme={theme}
            variant="body2"
            component="span"
            sx={{ fontWeight: 600 }}
          >
            Customer access and use of any Third-Party Service is at Customer’s
            own risk and NetSol disclaims all responsibility and liability for
            Customer’s use of any Third-Party Service. Third-Party Services are
            not Services and are not governed by this Agreement or NetSol’s
            Privacy Policy. Customer’s use of any Third-Party Service, including
            those linked from the NetSol Website, is subject to that Third-Party
            Service’s own terms of use and privacy policies (if any).
          </Typography>
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          4. Restrictions on Use of Services
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          Customer agrees to comply with any use restrictions and policies
          posted by NetSol or any applicable Service Provider. Customer agrees
          not to access (or attempt to access) any of the Services by any means
          other than through the interfaces that are made available to Customer
          by NetSol or the applicable Service Provider. Customer agrees not to
          engage in any activity that interferes with or disrupts the Services
          (or the servers and networks which are connected to the Services).
          Without limiting the foregoing, Customer agrees not to
          reverse-engineer, reverse-assemble, decompile, or otherwise attempt to
          derive any source code of any Services or other program associated
          with the Services.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          5. Compliance with Applicable Laws
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          Customer shall use the Services and perform its obligations under this
          Agreement, in compliance with all applicable laws and regulations.
          Customer may use the Services only for purposes that are permitted
          hereunder and in accordance with applicable laws and regulations.
          Customer shall at all times comply with all applicable laws,
          legislation, rules, regulations, governmental requirements and
          industry standards with respect to the Customer’s use of the Services
          and the performance by each Customer of the Customer’s obligations
          hereunder, including, but not limited to, any applicable provisions of
          Title V of the Gramm-Leach-Bliley Financial Services Modernization Act
          of 1999 and regulations promulgated under that Act or other federal,
          state, and local laws, rules, regulations, and ordinances governing
          the privacy and security of customer information that apply to the
          Customer.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          6. Ownership of Content and Services
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          Customer acknowledges and agrees that all materials and information
          (such as data files, written text, computer software, music, audio
          files or other sounds, photographs, manuals, documents, illustrations,
          pricing information, videos or other images) which are provided by
          NetSol, its Service Providers, in connection with the Services, or to
          which Customer may have access as part of, or through Customer’s use
          of, the Services (collectively, the “Provided Content”), together with
          the Services, including any copyright and/or other intellectual
          property rights in the Services and the Provided Content, are owned by
          NetSol, and/or its respective licensors; provided, however, that all
          Customer Content (as defined in Section 6) shall be owned by Customer.
          Customer may not modify, rent, lease, loan, sell, or distribute the
          Provided Content or the Services or create derivative works based on
          the Provided Content or the Services (either in whole or in part),
          except as expressly provided in this Agreement. Customer further
          acknowledges that the Services may contain information which is
          designated confidential. Customer agrees not to disclose such
          information without NetSol’ s prior written consent.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          7. Customer Content
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          Customer agrees that it is solely responsible for all content,
          materials and information provided by Customer or that Customer (or
          any third party acting on behalf of Customer) creates, transmits or
          displays while using the Services (“Customer Content”) and for the
          consequences of these actions (including any loss, liability, fine or
          damage). NetSol reserves the right (but shall have no obligation) to
          pre-screen, review, flag, filter, modify, refuse or remove any or
          all Customer Content from any Service as determined in NetSol’ s
          reasonable discretion. Customer represents, warrants and covenants
          that the Customer Content shall not contain anything that infringes
          copyrights, trademarks, publicity or any other rights of others;
          violates any law or regulation, is defamatory or libelous; is abusive,
          harassing, or threatening; is obscene, vulgar, or profane; or violates
          someone’s privacy.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          8. Licenses
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          Provided that Customer has paid all applicable fees for the Services
          and is otherwise in compliance with this Agreement, NetSol grants
          Customer a limited, restricted, revocable, personal, worldwide,
          royalty-free, non-assignable, non-transferable, non-sub-licensable and
          non-exclusive license to use the Services and the Provided Content
          provided to Customer, subject to and in accordance with the terms and
          conditions of this Agreement and the Service Provider Terms (as
          defined in Section 10, below). This license is for the sole purpose of
          enabling Customer to use and enjoy the benefit of the Services in the
          manner permitted by this Agreement. Customer retains any copyright
          and/or other intellectual property rights Customer already holds in
          Customer Content. The products and services of the Service Providers
          may contain license terms specific to such products or services, and
          Customer agrees to comply with all such license terms included in any
          click-through or browser-wrap license or of which NetSol otherwise
          makes Customer aware. Customer may not (and Customer may not permit
          anyone else to) copy, modify, create a derivative work of, reverse
          engineer, decompile or otherwise attempt to extract the source code of
          any software provided as part of or in connection with the Services
          (the “Software”) or any part thereof, or any of the other proprietary
          elements of the Service, or allow any third parties to use the
          Services, or any portion thereof, in any way. Customer may not assign,
          transfer or sub‐license any rights to use the Software, or any of the
          other proprietary elements of the Service, nor may Customer grant a
          security interest in or over Customer’s rights to use the Software or
          any other proprietary elements of the Service. Customer agrees not to
          remove, obscure, or alter any proprietary rights notices (including
          copyright and trademark notices) which may be affixed to or contained
          within the Services.
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          By submitting, posting or displaying the Customer Content, Customer
          grants NetSol, and each Service Provider to which NetSol provides the
          Customer Content for the purposes of providing the Services, a
          royalty-free and non-exclusive license to reproduce, adapt, modify,
          translate, publish, publicly perform, publicly display and distribute
          the Customer Content for the sole purpose of providing the Services as
          contemplated in this Agreement. Customer agrees that this license
          includes a right for NetSol and the Service Providers to make Customer
          Content available to other companies, organizations or individuals
          with whom they have relationships for the sole purpose of providing
          the Services. Customer agrees that NetSol and the Service Providers,
          in performing the Services, may (a) transmit or distribute the
          Customer Content, excluding Customer Data, over various public
          networks and in various media; and (b) make such changes to the
          Customer Content as are necessary to conform and adapt the Customer
          Content to the technical requirements of connecting networks, devices,
          services or media. Customer represents and warrants that it has and
          shall have all right, power and authority necessary to grant the
          rights and licenses granted to NetSol and the Services Providers in
          this Section 8. Neither NetSol nor the Service Provider will use the
          Customer Content for any other purposes than those intended with the
          Services or as otherwise provided herein.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          9. Access to Customer’s Web Sites, Systems and Data
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          Customer hereby authorizes NetSol, each Service Provider, that
          produces, collects or receives Customer Data, to transfer or provide
          the Customer Data to each other in connection with the provision of
          the Services hereunder. As used herein, “Customer Data” means data
          that has been provided by or specifically belongs to the Customer,
          including, without limitation, data that consumers provide to NetSol
          or the Service Providers through the Services, whether via websites,
          applications, tools or other means (including, without limitation,
          sales lead form contact information and credit application data).
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          The Customer Data shall continue to be owned by the Customer. Neither
          NetSol nor the Service Provider will use the Customer Data or other
          customer information for any other purposes than those intended with
          the Services or as otherwise provided herein. Nothing in this
          Agreement shall obligate NetSol or any Service Provider to retain any
          Customer Data. In addition, NetSol and each Service Provider agrees
          that it shall (i) take commercially reasonable steps to protect the
          Customer Data; (ii) comply with applicable data privacy law with
          respect to the Customer Data; and (iii) maintain reasonably
          appropriate physical, electronic and procedural safeguards for any
          Customer Data relating to Customer’s customers to which NetSol has
          access pursuant to the terms of this Agreement. Such safeguards shall
          include appropriate measures designed to: (i) protect the security and
          confidentiality of such customer information, (ii) protect against
          anticipated threats or hazards to the security or integrity of such
          information, and (iii) protect against unauthorized access to or use
          of such information.
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          In the event that NetSol and/or the applicable Service Provider(s)
          require Customer Data from Customer’s computer systems in order to
          perform certain Services, Customer shall either (a) authorize NetSol
          or such Service Provider to access Customer’s computer systems, (b)
          provide the Customer Data to NetSol and/or such Service Provider(s) in
          a standard file format and delivery method reasonably acceptable to
          NetSol, or (c) waive NetSol’s obligations to provide such Services for
          which such Customer Data was required. In the event that access to
          Customer’s computer systems (and not just access to Customer Data
          therein) is required in order to perform certain Services, Customer
          hereby authorizes NetSol and/or the applicable Service Provider to
          access the same for the sole purpose of providing such Services.
          Customer represents and warrants that it has obtained all rights,
          consents and authorizations required to provide any such access and/or
          Customer Data provided to NetSol and/or any Service Provider. Customer
          hereby grants to NetSol and the Service Providers a royalty-free,
          non-exclusive, perpetual, irrevocable license to use the Customer Data
          provided or made available by Customer or generated by Customer’s use
          of the Services, solely for the purposes of performing the Services
          and de-identified manner for any reasonable business purpose in
          perpetuity.
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          NetSol and/or the applicable Service Providers may access Customer
          Data in accounts created and managed by NetSol and/or the Service
          Providers on third party sites or, if Customer provides its account
          information and authorizes access to accounts managed by Customer,
          NetSol and/or the applicable Service Providers may access Customer’s
          accounts directly on those third-party sites and may integrate such
          accounts with the Services.NetSol may access Customer Data concerning
          Customer’s use of the Services to improve or modify the Services.

        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          10. Modification to Services
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          NetSol reserves the right to modify or change the Services provided
          hereunder by modifying current features, deleting features and/or
          adding features, upon prior written notice to Customer. Some
          modifications or changes may result in a fee increase or decrease for
          such Services. Customer’s continued use of the Services hereunder
          after notice will constitute Customer’s acceptance of the change in
          the Services and Customer’s agreement to pay the fees associated with
          such change in Services.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          11. Billing and Service Provider Terms
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          Customer agrees to pay the fees for the Services that NetSol provides
          in the amounts set forth in the online registration process or, if an
          order form was used, on the order form. NetSol will invoice Customer
          directly for the Services that NetSol provides and Customer agrees to
          pay such invoice on a monthly basis from the date of the subscription
          (the billable month) (subscriptions entered into on the 31st of the
          month will be billed on the 30th of each month)(the monthly payment
          due date) . If the order form provides for additional pricing based on
          excess volume, the excess volume will invoice 5 days prior to the
          monthly payment due date and will be collected with the monthly
          payment. Cancellations of Services that Customer provides that occur
          on or before 5th day preceding the monthly payment due date will be
          billed for the remainder of that month. Cancellations of Services that
          Customer provides that occur after the 5th day preceding the monthly
          payment due date, will be billed for the remainder of that month and
          the following month. Any changes in service packages (e.g., to
          increase or reduce the amount of the package) will take effect as of
          the first day of the month following the month in which Customer
          requests the change so long as Customer makes the request not less
          than five (5) business days prior to the end of the billable month.
          NetSol may increase fees by giving Customer not less than 8 business
          days written notice prior to the effective date of the price increase.
          If Customer fails to pay for the Services that NetSol provides when
          due, Customer agrees to pay to NetSol, or if any auto-pay method is
          declined, in addition to the fees for the Services that NetSol
          provides: (i) a finance charge equal to the lower of 1.5% per month or
          the maximum amount permitted by law, and (ii) all costs incurred in
          connection with collection of past due amounts (including collection
          agency fees, reasonable attorneys’ fees, and court costs). In addition
          to the other rights reserved hereunder, NetSol expressly reserves the
          right to terminate the Services and disable Customer’s access to the
          Services for non-payment.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          12. Taxes
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          All fees are subject to applicable sales, use, excise or similar
          taxes, whether or not included at the time the fees are billed.
          Customer assumes exclusive liability for, and shall pay before
          delinquency, all sales, use, excise and other taxes, charges or
          contributions of any kind now or hereafter imposed on, with respect
          to, or measured by the Services except for taxes based on the net
          income of NetSol or Service Providers.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          13. Customer Warranties
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          CUSTOMER HEREBY REPRESENTS AND WARRANTS THAT ANY AND ALL CUSTOMER
          CONTENT SUBMITTED FOR PUBLICATION OR DISPLAYED ON CUSTOMER’S WEBSITE
          OR IN ANY SOCIAL MEDIA, WILL NOT VIOLATE OR OTHERWISE INFRINGE UPON
          ANY COPYRIGHT, TRADEMARK, PATENT, STATUTORY, COMMON LAW OR PROPRIETARY
          RIGHT OF OTHERS, OR CONTAIN ANYTHING CONSIDERED OBSCENE OR LIBELOUS.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          14. Representations and Warranties
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          Each Party hereby represents, warrants and covenants to the other
          that: (a) in performing its obligations and exercising its rights
          under this Agreement, it will comply with all applicable federal,
          state and local laws, regulations, rules and ordinances and other
          decrees of any governmental authority; and (b) it has all necessary
          rights and licenses required to grant the rights granted by it to the
          other Party herein. Customer further represents and warrants that any
          and all Customer Content submitted for publication or displayed on
          Customer’s website or in digital advertising, or in any social media,
          will not violate or otherwise infringe upon any copyright, trademark,
          patent, statutory, common law or proprietary right of others, or
          contain anything considered obscene or libelous.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          15. Termination
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          This Agreement and/or the applicable Services may be terminated or
          suspended as provided below:
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          Customer may cancel the Services online through its account, if online
          cancellation functionality is available, or, if not available, by
          providing written notice to NetSol delivered by email to {process.env.SALES_EMAIL}.
          Cancellations of Services that Customer provides
          that occur on or before 5th day preceding the monthly payment due date
          will be billed for the remainder of that month. Cancellations of
          Services that Customer provides that occur after the 5th day preceding
          the monthly payment due date, will be billed for the remainder of that
          month and the following month. If NetSol materially defaults in its
          performance under this Agreement and fails to substantially cure such
          default within thirty (30) days after receiving written notice
          specifying the default or, for those defaults that cannot reasonably
          be cured within thirty (30) days, fails to promptly commence curing
          such default and thereafter proceed with all due diligence to
          substantially cure the default, then Customer may terminate this
          Agreement by written notice to NetSol.
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          Customer acknowledges and agrees that NetSol may stop (permanently or
          temporarily) providing the Services, or any part thereof, to Customer
          and/or may terminate this Agreement if: (a) Customer has breached any
          provision of this Agreement (or has acted in a manner which clearly
          shows that Customer does not intend to, or is unable to comply with
          the provisions of this Agreement); (b) NetSol is required to do so by
          law (for example, where the provision of the Services to Customer is,
          or becomes, unlawful); (c) the Service Provider selected by Customer
          or NetSol to provide the Services has terminated its relationship with
          NetSol or ceases to offer the Services, or any part thereof, to
          Customer; (d) the provision of the Services to Customer by NetSol is,
          in NetSol’ s sole opinion, no longer commercially viable, or (e)
          NetSol provides Customer with thirty (30) days prior written notice of
          termination of the Services, or any part thereof, with or without
          cause. In addition, NetSol may disable access to Customer’s account if
          Customer fails to pay the fees owed for the Services when due.
          Customer acknowledges and agrees that if NetSol disables access to
          Customer’s account, Customer may be prevented from accessing the
          Services, Customer’s account details or any files or other content
          which is contained in Customer’s account. Customer acknowledges and
          agrees that NetSol, will not be liable in any way for any
          inconvenience, delay, damages, losses or other claims related to
          disabling Customer’s access to the account due to such non-payment.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          16. DISCLAIMERS
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          CUSTOMER EXPRESSLY UNDERSTANDS AND AGREES THAT, CUSTOMER’S USE OF THE
          SERVICES AND THE PROVIDED CONTENT IS AT CUSTOMER’S SOLE RISK AND ARE
          PROVIDED “AS IS” AND “AS AVAILABLE.” IN PARTICULAR, NETSOL, DOES NOT
          REPRESENT OR WARRANT TO CUSTOMER THAT: (A) CUSTOMER’S USE OF THE
          SERVICES OR THE PROVIDED CONTENT WILL MEET CUSTOMER’S REQUIREMENTS, OR
          (B) CUSTOMER’S USE OF THE SERVICES OR PROVIDED CONTENT WILL BE
          UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR. ANY MATERIAL
          DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES,
          INCLUDING ANY PROVIDED CONTENT, IS DONE AT CUSTOMER’S OWN DISCRETION
          AND RISK AND THAT CUSTOMER WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE
          TO CUSTOMER’S COMPUTER SYSTEM OR OTHER DEVICE OR LOSS OF CUSTOMER DATA
          THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY
          CUSTOMER FROM NETSOL, OR THROUGH OR FROM THE USE OF THE SERVICES SHALL
          CREATE ANY WARRANTY. EXCEPT AS EXPRESSLY PROVIDED HEREIN, NETSOL,
          MAKES NO REPRESENTATIONS OR WARRANTIES AND EXPRESSLY DISCLAIM ALL
          REPRESENTATIONS AND WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
          IMPLIED, WITH RESPECT TO THE SERVICES AND THE PROVIDED CONTENT
          INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          17. LIMITATION OF LIABILITY
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          SUBJECT TO APPLICABLE LAW, CUSTOMER UNDERSTANDS AND AGREES THAT
          NETSOL, NOR ANY OF THEIR AFFILIATES OR LICENSORS SHALL BE LIABLE TO
          CUSTOMER FOR: (A) ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
          EXEMPLARY DAMAGES WHICH MAY BE INCURRED BY CUSTOMER, HOWEVER CAUSED
          AND UNDER ANY THEORY OF LIABILITY, INCLUDING, BUT NOT LIMITED TO, ANY
          LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY), ANY LOSS OF
          GOODWILL OR BUSINESS REPUTATION, ANY LOSS OF DATA SUFFERED, COST OF
          PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR OTHER INTANGIBLE LOSS;
          OR (B) ANY LOSS OR DAMAGE WHICH MAY BE INCURRED BY CUSTOMER AS A
          RESULT OF: (I) ANY RELIANCE PLACED BY CUSTOMER ON THE COMPLETENESS,
          ACCURACY OR EXISTENCE OF THE PRODUCT OR SERVICES, OR AS A RESULT OF
          ANY RELATIONSHIP OR TRANSACTION BETWEEN CUSTOMER AND ANY THIRD PARTY
          REGARDING CUSTOMER’S USE OF THE SERVICES, (II) ANY CHANGES WHICH
          NETSOL OR ANY SERVICE PROVIDER MAY MAKE TO THE SERVICES, OR FOR ANY
          PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF THE SERVICES (OR
          ANY FEATURES WITHIN THE SERVICES); (III) THE DELETION OF, CORRUPTION
          OF, OR FAILURE TO STORE, ANY CUSTOMER CONTENT, CUSTOMER DATA, AND/OR
          OTHER COMMUNICATIONS DATA MAINTAINED OR TRANSMITTED BY OR THROUGH
          CUSTOMER’S USE OF THE SERVICES; (IV) CUSTOMER’S FAILURE TO PROVIDE
          NETSOL OR ANY SERVICE PROVIDER WITH ACCURATE ACCOUNT INFORMATION; OR
          (V) CUSTOMER’S FAILURE TO KEEP CUSTOMER’S PASSWORD OR ACCOUNT DETAILS
          SECURE AND CONFIDENTIAL. THE ABOVE LIMITATIONS ON LIABILITY SHALL
          APPLY REGARDLESS OF WHETHER A PARTY IS NOTIFIED OF THE POSSIBILITY OF
          SUCH DAMAGE OR LOSS, AND NOTWITHSTANDING THE FAILURE OF ESSENTIAL
          PURPOSE OF ANY REMEDY. IN NO EVENT SHALL THE LIABILITY OF NETSOL, IN
          THE AGGREGATE, EXCEED AN AMOUNT EQUAL TO THE MONTHLY SERVICE FEE PAID
          BY CUSTOMER FOR THE MONTH IN WHICH THE BREACH OR CAUSE OF DAMAGE
          OCCURRED.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          18. Indemnification
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          Customer will defend, indemnify and hold NetSol, and its respective
          employees, officers, directors, managers, shareholders, agents,
          representatives and affiliates (collectively, the “NetSol Indemnified
          Parties”) harmless from and against all third party claims, demands,
          suits, causes of actions, and administrative or regulatory actions of
          whatever kind or character, and including any related liabilities,
          obligations, losses, damages, fines, judgments, settlements, charges,
          costs, and expenses (including attorneys’ fees and accountants’ fees
          and disbursements (collectively, “Claims”) to the extent that such
          Claims relate to, arise out of or result from: (i) any intentional or
          willful misconduct or negligence by Customer or of any of Customer’s
          employees, agents or subcontractors; (ii) a breach of this Agreement
          by Customer, including a breach of any of Customer’s representations,
          warranties or covenants under this Agreement or (iii) Customer’s use
          of the Numbers.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          19. Force Majeure
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          Each party hereto shall be excused from performance hereunder, except
          for payment obligations, to the extent that it is prevented from
          performing any obligation hereunder, in whole or in part, as a result
          of delays caused by the other party or an act of God, war, civil
          disturbance, court order, labor dispute, third party nonperformance or
          other cause beyond its reasonable control, including failures,
          fluctuations or non-availability of electrical power, heat, light, air
          conditioning, computing or information systems or telecommunications
          equipment or the inability of hardware or software leased or acquired
          by sale or license from third parties to process without error or
          malfunction any data. Such nonperformance shall not be a default or
          ground for termination as long as reasonable means are taken to remedy
          expeditiously the problem causing such nonperformance.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          20. Relationship of Parties
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          NetSol and the Service Providers are independent contractors, and no
          agency, partnership, joint venture, employer- employee or other
          similar relationship is intended or created between or among Customer,
          NetSol and any Service Provider under this Agreement.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          21. Waiver
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          Any failure or delay of either party in exercising or enforcing any
          rights or remedies that are available to it under this Agreement (or
          under any applicable law or otherwise) shall not be deemed to be a
          waiver of those or any other rights or remedies.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          22. Notices
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          Except as otherwise required by the Service Provider Terms, any notice
          to be provided under this Agreement shall be in writing and shall be
          delivered to the last known address of the party to receive the
          notice, which shall be considered delivered three days after mailing
          if sent certified mail, return receipt requested, or when received, if
          sent by e-mail, facsimile, prepaid courier, express mail or personal
          delivery.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          23. Entire Agreement
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          This Agreement constitutes the entire agreement and understanding of
          the parties in respect of the subject matter contained herein and
          supersedes all prior agreements, consents and understandings relating
          to such subject matter. The parties agree that there is no oral or
          other agreement relating to such subject matter between the parties
          which has not been incorporated into this Agreement.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          24. Survival
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          The provisions of Sections 6, 9, 11, 12, 14, 15, 16, 17, 18, 19,
          20, 21, 22, 23, 24, 25, 26 and 27 of this Agreement, and any right or
          obligation of the parties in this Agreement which, by its express
          terms or nature and context, is intended to survive termination, will
          survive the termination of this Agreement and the Services.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          25. Changes to this Agreement
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          NetSol may make changes to this Agreement or put into place additional
          terms from time to time at its discretion, and shall provide Customer
          with thirty (30) days’ prior written notice of such changes. When such
          changes are made, NetSol will make a new copy of this Agreement
          available to the Customer, via a notice sent to the Customer’s email
          address or via the maintenance tool point of entry for the Services.
          Customer understands and agrees that its use of the Services after
          such thirty (30)-day period shall constitute Customer’s agreement to
          and, acceptance of the updated Agreement or additional terms.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          26. Severability
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          If any provision of this Agreement shall be unlawful, void, or for any
          reason unenforceable, then that provision shall be deemed severable
          from this Agreement and shall not affect the validity and
          enforceability of any remaining provisions. Headings used in this
          Agreement are for reference purposes only and are not a part of this
          Agreement.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          27. Assignment
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          Customer may not assign this Agreement without the prior written
          approval of NetSol, This Agreement and all rights of NetSol hereunder
          may be assigned by NetSol, including, without limitation, to its
          successor in interest if any, without consent, payment or other
          condition (excepting reasonable notice to Customer). This Agreement
          shall be binding on and inure to the benefit of the parties and their
          respective successors and permitted assigns.
        </Typography>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          28. Choice of Law; Venue
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mb={2}
        >
          This Agreement shall be construed in accordance with the substantive
          laws of the State of California without reference to its conflicts of
          law principles. The parties expressly disclaim any application to this
          Agreement of the provisions of the 1980 United Nations Convention on
          Contracts for the International Sale of Goods. All disputes and
          controversies pertaining to this Agreement, the performance of the
          parties hereunder and the enforcement hereof shall be heard
          exclusively in the federal or state courts located in the County of
          Los Angeles, California, U.S.A, and the parties hereby submit to the
          jurisdiction thereof and venue therein. Notwithstanding the foregoing,
          a party may apply for injunctive remedies (or an equivalent type of
          urgent legal relief) in any appropriate jurisdiction.
        </Typography>
      </Box>
    </>
  )
}
