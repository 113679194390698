import { useTheme } from '@mui/material'
import { type FC, useEffect, useState } from 'react'
import { Authentication } from '@modules/authentication'
import { ForgotPasswordComponent, type IResponse } from '@ntpkunity/controls-ums'

import { APP_ROUTES } from '@router/path'
import { useNavigate } from 'react-router-dom'
import { EMAIL, HttpStatus } from '@shared/constants'
import { getItem } from '@shared/helper'

export const ForgotPassword: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [response, setResponse] = useState<IResponse>()

  useEffect(() => {
    if (response?.status === HttpStatus.OK) {
      navigate(APP_ROUTES.RESEND_PASSWORD_LINK, {
        replace: true,
        state: { email: response?.data?.email }
      })
    }
  }, [response])

  return (
    <Authentication>
      <ForgotPasswordComponent
        email={getItem(EMAIL)}
        theme={theme}
        setResponse={setResponse}
        nextScreenUrl={`${APP_ROUTES.RESET_PASSWORD}`}
      />
    </Authentication>
  )
}
