import { useTheme } from '@mui/material'
import {
  Box,
  Button,
  Container,
  Grid,
  Input,
  Radio,
  Textarea,
  Typography
} from '@ntpkunity/controls'
import { HomePageLayout } from 'layout'
import { useEffect, useState, type FC } from 'react'
import FeedbackImage from '../../../../shared/assets/images/feedback-img.svg'

import { useFeedback } from '@modules/feedback/services'
import {
  feedbackFollowUp,
  feedbackOptionsValue,
  feedbackSuccessMessage,
  Validation
} from '@shared/constants'
import { scrollOnTop, validation } from '@shared/helper'
import { type IFeedback } from '@shared/typings'
import { Controller, useForm, type SubmitHandler } from 'react-hook-form'
import { useStoreContext } from 'store/storeContext'
import { FeedbackPageWrap } from './feedbackStyle'
import DisableLoader from '@shared/assets/images/loader-disabled-btn.gif'
import { FadeAnimation } from '@shared/components'

const messages = {
  name: {
    reaction: 'reaction',
    email: 'email',
    description: 'description',
    isFollowUp: 'is_follow_up'
  },
  validation: {
    email: 'Email',
    description: 'Feedback',
    reaction: 'Experience reaction'
  },
  button: {
    save: 'Submit Feedback'
  }
}

enum feedbackTypeControllerName {
  reaction = 'reaction',
  email = 'email',
  description = 'description',
  isFollowUp = 'is_follow_up',
}

export const FeedbackPage: FC = () => {
  const theme = useTheme()
  const [reaction, setReaction] = useState<string>()
  const { mutate: saveFeedback, isLoading } = useFeedback()

  const {
    states: { userProfile },
    actions: { setToaster }
  }: any = useStoreContext()

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<IFeedback>()

  useEffect(() => {
    if ((userProfile?.user_name ?? '').length > 0) {
      setValue(feedbackTypeControllerName.email, userProfile.user_name)
    }
  }, [userProfile])

  useEffect(() => {
    scrollOnTop()
  }, [])

  const submit: SubmitHandler<IFeedback> = (formData: IFeedback) => {
    formData.is_follow_up = formData.is_follow_up === feedbackFollowUp.YES
    saveFeedback(formData, {
      onSuccess (data) {
        reset({
          email: userProfile?.user_name ?? '',
          reaction: feedbackOptionsValue.AMAZING,
          is_follow_up: feedbackFollowUp.YES,
          description: ''
        })
        setReaction(undefined)
        setToaster({
          isDisplay: true,
          message: feedbackSuccessMessage,
          type: ''
        })
      }
    })
  }

  const getLabelClassName = (feedbackOption: string): string =>
    (reaction ?? feedbackOptionsValue.AMAZING) === feedbackOption
      ? 'selected'
      : ''

  const feedbackOptions = [
    {
      label: 'Amazing',
      value: feedbackOptionsValue.AMAZING,
      labelClassName: getLabelClassName(feedbackOptionsValue.AMAZING)
    },
    {
      label: 'Good',
      value: feedbackOptionsValue.GOOD,
      labelClassName: getLabelClassName(feedbackOptionsValue.GOOD)
    },
    {
      label: 'Okay',
      value: feedbackOptionsValue.OKAY,
      labelClassName: getLabelClassName(feedbackOptionsValue.OKAY)
    },
    {
      label: 'Bad',
      value: feedbackOptionsValue.BAD,
      labelClassName: getLabelClassName(feedbackOptionsValue.BAD)
    },
    {
      label: 'Terrible',
      value: feedbackOptionsValue.TERRIBLE,
      labelClassName: getLabelClassName(feedbackOptionsValue.TERRIBLE)
    }
  ]

  return (
    <HomePageLayout>
      {// eslint-disable-next-line @typescript-eslint/no-misused-promises
      }<form onSubmit={handleSubmit(submit)}>
        <FeedbackPageWrap
          theme={theme}
          className="feedback-page-wrap"
          pt={{ xs: 2, md: 4 }}
          pb={{ xs: 0, md: 5 }}
        >
          <Container theme={theme} maxWidth="lg" className="custom-container">
            <Grid
              theme={theme}
              container
              item
              spacing={{ xs: 8, md: 15 }}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Grid theme={theme} item xs={12} md={6} lg={5}>
                <FadeAnimation>
                  <Box
                    theme={theme}
                    className="feedback-img"
                    textAlign={{ xs: 'center', md: 'left' }}
                  >
                    <img src={FeedbackImage} alt="Feedback" />
                  </Box>
                </FadeAnimation>
                <Box theme={theme} mt={5} overflow={'hidden'}>
                  <FadeAnimation direction='down'>
                    <Box theme={theme}>
                      <Typography
                        theme={theme}
                        className="text-h2"
                        variant="h2"
                        component="h2"
                        textAlign={{ xs: 'center', md: 'left' }}
                      >
                        Your feedback matters to us! We&#39;d love to hear from you.
                      </Typography>
                      <Typography
                        theme={theme}
                        className="text-body2 text-muted"
                        variant="body2"
                        component="span"
                        display={'block'}
                        mt={{ xs: 2, md: 3 }}
                        textAlign={{ xs: 'center', md: 'left' }}
                      >
                        Please feel free to tell us about your ideas, issues,
                        experience and suggestions. We can&#39;t respond
                        individually, but we&#39;ll pass it on to the teams who are
                        working to help make Appex Now better for everyone.
                      </Typography>
                    </Box>
                  </FadeAnimation>

                </Box>
              </Grid>
              <Grid theme={theme} item xs={12} md={6} lg={5}>
                <FadeAnimation>
                  <Box theme={theme} className="submit-feedback-wrap">
                    <Box theme={theme} className="feedback-rating" mb={4}>
                      <Typography
                        theme={theme}
                        variant="subtitle2"
                        component="p"
                        mb={2}
                      >
                        How is your experience about using Appex Now?
                      </Typography>
                      <Box theme={theme} className="radio-rating-group">
                        <Controller
                          name={feedbackTypeControllerName.reaction}
                          control={control}
                          defaultValue={feedbackOptionsValue.AMAZING}
                          rules={validation(
                            messages.validation.reaction,
                            Validation.REQUIRED
                          )}
                          render={({ field }) => (
                            <Radio
                              theme={theme}
                              row={true}
                              options={feedbackOptions}
                              defaultValue={field.value}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e)
                                setReaction(e)
                              }}
                            />
                          )}
                        />
                      </Box>
                    </Box>
                    <Box theme={theme} className="feedback-form">
                      <Typography
                        theme={theme}
                        variant="subtitle2"
                        component="p"
                        mb={2}
                      >
                        Give us your feedback
                      </Typography>
                      <Controller
                        name={feedbackTypeControllerName.description}
                        control={control}
                        rules={validation(
                          messages.validation.description,
                          Validation.REQUIRED,
                          Validation.LIMIT_LENGTH,
                          '300'
                        )}
                        render={({ field }) => (
                          <Textarea
                            id={''}
                            theme={theme}
                            fullWidth
                            type={'text'}
                            placeholder="Type here..."
                            rows={9}
                            {...field}
                            error={errors?.[
                              feedbackTypeControllerName.description
                            ]?.message?.toString()}
                          />
                        )}
                      />
                      <Box theme={theme} mt={4}>
                        <Controller
                          name={feedbackTypeControllerName.email}
                          control={control}
                          rules={validation(
                            messages.validation.email,
                            Validation.REQUIRED,
                            Validation.EMAIL
                          )}
                          defaultValue={userProfile?.user_name ?? ''}
                          render={({ field }) => (
                            <Input
                              type="email"
                              placeholder="sample@sample.com"
                              label="Your Email Address"
                              fullWidth
                              theme={theme}
                              {...field}
                              error={errors?.[
                                feedbackTypeControllerName.email
                              ]?.message?.toString()}
                            />
                          )}
                        />
                      </Box>
                      <Box theme={theme} className="fb-radio-group" mt={4}>
                        <Grid
                          container
                          item
                          theme={theme}
                          rowSpacing={0}
                          columnSpacing={1}
                        >
                          <Grid item theme={theme} md={8} sm={12}>
                            <Typography
                              theme={theme}
                              className="text-dark"
                              variant="subtitle2"
                              component="p"
                              mb={{ xs: 1, md: 0 }}
                            >
                              May we follow you up on your feedback?
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            theme={theme}
                            md={4}
                            sm={12}
                            textAlign={{ xs: 'left', md: 'right' }}
                          >
                            <Controller
                              name={feedbackTypeControllerName.isFollowUp}
                              control={control}
                              defaultValue={feedbackFollowUp.YES}
                              render={({ field }) => (
                                <Radio
                                  theme={theme}
                                  row={true}
                                  defaultValue={field.value as string}
                                  options={[
                                    { label: 'Yes', value: feedbackFollowUp.YES },
                                    { label: 'No', value: feedbackFollowUp.NO }
                                  ]}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                    <Button
                      theme={theme}
                      primary
                      fullWidth
                      disabled={isLoading}
                      text={messages.button.save}
                      type="submit"
                      startIcon={
                        isLoading && (
                          <img src={DisableLoader} alt="Loader" />
                        )
                      }
                    />
                  </Box>
                </FadeAnimation>
              </Grid>
            </Grid>
          </Container>
        </FeedbackPageWrap>
      </form>
    </HomePageLayout>
  )
}
