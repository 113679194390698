import { isUserOnBoarded, type AppError } from '@shared/helper'
import Http from '@shared/helper/http-api'
import { type INotificationsResponse, type INotificationsBackend } from '@shared/typings'
import { useMutation, type UseMutationResult, useInfiniteQuery, type UseInfiniteQueryResult } from 'react-query'
import { type StoreContextType } from 'store/configs'
import { ACTION_TYPE } from 'store/ducks/notifications'
import { useStoreContext } from 'store/storeContext'

const BASE_URL = process.env.BASE_URL
export const GET_ALL_NOTIFICATIONS = 'ums/get-notification'
// export const GET_ALL_NOTIFICATIONS = 'marketplace/admin/notifications'
const READ_NOTIFICATION = 'ums/mark-as-read-notification'
export const LIMIT = 15

export const useMarkNotificationRead = (): UseMutationResult<any, AppError | undefined, INotificationsBackend, unknown> => {
  const {
    actions: { notificationDispatch }
  }: StoreContextType = useStoreContext()

  return useMutation(
    async (notificationData) => {
      const url = `${READ_NOTIFICATION}/${notificationData.id}`
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.put<any>(url, {})
    }, {
      onError: (_, variables, context) => {
        notificationDispatch({
          type: ACTION_TYPE.ERROR_READ_NOTIFICATION,
          payload: {
            notificationData: variables
          }
        })
      }
    }
  )
}

export const useGetAllNotifications = (): UseInfiniteQueryResult<INotificationsResponse, AppError | undefined> => {
  return useInfiniteQuery(
    [GET_ALL_NOTIFICATIONS],
    async ({ pageParam = 0 }) => {
      const url = `${GET_ALL_NOTIFICATIONS}?page=${pageParam}&limit=${LIMIT}`
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get(url)
    },
    {
      getNextPageParam: (lastPage: INotificationsResponse, allPages: INotificationsResponse[]) => lastPage.next_page ?? undefined,
      staleTime: Infinity,
      enabled: isUserOnBoarded()
    }
  )
}
