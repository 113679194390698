import Http from '@shared/helper/http-api'
import { type IFAQ } from '@shared/typings'
import { useQuery } from 'react-query'

const BASE_URL = process.env.BASE_URL

export const FAQS = 'signup/get-faq?'

export const useGetFAQS = (
  productId: number | null = null,
  productName = 'appexnow'
): any => {
  let url = FAQS
  if (productId !== null && productId !== undefined) {
    url += `product_id=${productId ?? ''}`
  } else {
    url += `product_name=${productName}`
  }
  const { data, error, isLoading } = useQuery<IFAQ[]>(
    url,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(url)
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  )
  return { data, error, isLoading }
}
