import { useEffect, type ReactElement } from 'react'
import { useTheme } from '@mui/material'
import { Box, CircleLoader, Container, Grid, Typography } from '@ntpkunity/controls'
import { HomePageLayout } from '@layout/index'
import { BlogDetailItemComponent, BlogListItemComponent, RelatedBlogsComponent } from '@modules/blog/components'
import { BlogDetailsPageWrap } from './blogDetailsStyle'
import { useGetAllBlogs, useGetBlogDetails } from '@modules/blog'
import { useNavigate, useParams } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'
import { scrollOnTop } from '@shared/helper'
import { FullPageLoader } from '@modules/homePage/pages/detailPage/detailPageStyle'
import { marketplaceTheme } from '@shared/theme'
import { Helmet } from 'react-helmet'

export const BlogDetailsPage = (): ReactElement => {
  const theme = useTheme()
  const { title } = useParams()
  const navigate = useNavigate()

  const { data: blogDetail } = useGetBlogDetails(decodeURIComponent(title ?? ''))
  const queryParams = '?is_latest=true'
  const { data: blogs } = useGetAllBlogs(queryParams)

  useEffect(() => {
    scrollOnTop()
  }, [title])

  return (
    <HomePageLayout>
      <Helmet>
        <meta name="description" content={blogDetail?.meta_description} />
      </Helmet>
      <BlogDetailsPageWrap theme={theme} className='blog-details-page-wrap'>
        <Container className='custom-container' theme={theme}>
          <Box theme={theme} className='page-head' textAlign={'center'}>
            <Typography
              display={'inline-block'}
              mb={2}
              theme={theme}
              className="badge text-primary text-uppercase"
              variant="caption"
              component="small"
            >
              OUR BLOG
            </Typography>
          </Box>
          {
            blogDetail
              ? <BlogDetailItemComponent
            blog={blogDetail}
            socialShare={true}
          />
              : <FullPageLoader theme={marketplaceTheme} className="full-page-loader">
          <CircleLoader theme={marketplaceTheme} />
        </FullPageLoader>
          }
          <Box theme={theme} className='related-section' mt={{ xs: 5, md: 15 }}>
            <RelatedBlogsComponent
              blogSectionTitle='More like this'
             />
             <Box theme={theme} className='blog-list-items-wrap'>
              <Grid theme={theme} container item spacing={3} alignItems={'stretch'} justifyContent={'center'}>
              {
                blogs?.map((blog) => (
                  <Grid key={blog.id} theme={theme} item xs={12} sm={6} md={4}>
                    <BlogListItemComponent
                      onClick={() => { navigate(`${APP_ROUTES.BLOGS}/${blog.title_url}`) }}
                      blog={blog}
                    />
                  </Grid>
                ))
              }
              </Grid>
            </Box>
          </Box>
        </Container>
      </BlogDetailsPageWrap>
    </HomePageLayout>
  )
}
