import { useTheme } from '@mui/material'
import { Typography, Container, CircleLoader } from '@ntpkunity/controls'
import { MainContent } from './unsubscriptionStyle'
import { FullPageMessage } from '@shared/components'
import { HomePageLayout } from '@layout/index'
import UnSubSuccessImage from '../../../../shared/assets/images/unsub-success-img.svg'
import queryString from 'query-string'
import { useNavigate } from 'react-router'
import { APP_ROUTES } from '@router/path'
import {
  UNSUBSCRIPTION_CONFIRMATION,
  useUnsubscribeProduct
} from '@modules/unsubscription/services'
import { type ReactElement, useEffect, useState } from 'react'
import {
  errorTitle,
  expiredEmailLinkButtonText,
  expiredEmailLinkSubTitle,
  expiredLinkTypes,
  className,
  invalidLink,
  IS_LOGGED_IN
} from '@shared/constants'
import { formatedDate, getItem } from '@shared/helper'
import { FullPageLoader } from '@modules/homePage/pages/detailPage/detailPageStyle'
import { NavLink } from 'react-router-dom'

interface IUnsubscribeProductResponse {
  success: boolean
  message: string
  data: {
    product_name: string
    price_name: string
    end_date: string
  }
}

export const UnsubscriptionPage = (): ReactElement => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [response, setResponse] = useState<IUnsubscribeProductResponse['data']>()
  const { tab: token, price_id: priceId } = queryString.parse(location?.search) as any
  const { mutate: unsubscribeProduct } = useUnsubscribeProduct()
  const isLoggedIn = getItem(IS_LOGGED_IN) as unknown as boolean

  useEffect(() => {
    if (isLoggedIn) {
      const data = { price_id: priceId, token }
      unsubscribeProduct(data, {
        onSuccess (res) {
          setResponse(res.data)
        },
        onError (error) {
          if (error?.message === invalidLink) {
            navigate(APP_ROUTES.EMAIL_LINK_EXPIRED, {
              replace: true,
              state: {
                token,
                priceId,
                resendEmailEndPoint: `${process.env.BASE_URL as string}/${UNSUBSCRIPTION_CONFIRMATION}`,
                verificationScreenUrl: `${APP_ROUTES.UNSUBSCRIBE}`,
                type: expiredLinkTypes.verfication,
                layoutText: {
                  title: errorTitle,
                  subTitle: expiredEmailLinkSubTitle,
                  buttonText: expiredEmailLinkButtonText,
                  seoButtonText: className.userProfileContinueBtn
                }
              }
            })
          } else {
            navigate(APP_ROUTES.UNSUBSCRIPTION_ERROR_PAGE)
          }
        }
      })
    } else {
      navigate(APP_ROUTES.SIGNUP)
    }
  }, [priceId, token])

  return (
    <>
      <MainContent className="main-content" theme={theme}>
        <HomePageLayout>
          {(response != null)
            ? (
            <Container theme={theme} maxWidth="lg" className="custom-container">
              <FullPageMessage
                messageImage={UnSubSuccessImage}
                imageAltText={'Successfully Unsubscribed'}
              >
                <Typography
                  theme={theme}
                  variant="h2"
                  component="h2"
                  className="text-h2"
                >
                  You’re always welcome back!
                </Typography>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mt={{ xs: 2, md: 3 }}
                  className="text-body2"
                >
                  You have successfully unsubscribed {response?.price_name} package of {response?.product_name}
                  . The cancellation of subscription will be
                  effective at the end of your current billing period on {formatedDate(response?.end_date, 'MMM DD, YYYY')}.
                </Typography>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                  mt={{ xs: 2, md: 3 }}
                  className="text-body2"
                >
                  If you want to view your subscription(s), you can access it
                  from
                  <NavLink
                    className="nav-link click-close text-primary link"
                    to={`${APP_ROUTES.CUSTOMER_ACCOUNT}`}
                    style={{ textDecoration: 'none' }}
                  >
                    {' '}
                    subscribed products{' '}
                  </NavLink>
                  in your profile. If you want to explore, go to{' '}
                  <NavLink
                    data-testid="home-page-link"
                    className="nav-link click-close text-primary link"
                    to={`${APP_ROUTES.NEW_HOME_PAGE}`}
                    style={{ textDecoration: 'none' }}
                  >
                    homepage
                  </NavLink>
                  .
                </Typography>
              </FullPageMessage>
            </Container>
              )
            : (
            <FullPageLoader theme={theme} className="full-page-loader">
              <CircleLoader theme={theme} />
            </FullPageLoader>
              )}
        </HomePageLayout>
      </MainContent>
    </>
  )
}
