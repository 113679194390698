import { Typography, Button, Box, Icon, Skeleton } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { PaymentCardWrap } from './paymentMethodStyle'
import { useDraggable } from 'react-use-draggable-scroll'
import { type ReactElement, useRef, type FC } from 'react'
import { useModal } from 'react-modal-hook'
import { AddNewCard } from '@modules/billingDetails/dialog/addNewCard'
import {
  ButtonType,
  CardType,
  ConfirmationDialog,
  defaultCardMsg,
  DialogHeading,
  getSubstring,
  IconType,
  deleteCardMessage,
  cardLimitMessage,
  markingAsDefault,
  markAsDefault,
  className
} from 'shared'
import {
  useDefaultCard,
  useDeleteCard,
  useGetCards
} from '@modules/billingDetails/services'
import { useStoreContext } from 'store/storeContext'
import { type IResponse, type ICardDetail } from '@shared/typings'
import { APP_ROUTES } from '@router/path'
import { useLocation } from 'react-router-dom'

export const PaymentMethod: FC = (): ReactElement => {
  const theme = useTheme()
  const ref = useRef()
  const { pathname } = useLocation()
  const onCheckoutPage = pathname === APP_ROUTES.CHECKOUT
  const cardIdRefForDeletion = useRef<string>()
  const cardIdRefForMarkingDefault = useRef<string>()
  const { events } = useDraggable(ref as any)
  const { data: cards, isLoading: gettingCards } = useGetCards()
  const { mutate: deleteSelectedCard } = useDeleteCard()
  const { mutate: setDefaultCard, isLoading: markAsDefaultLoading } =
    useDefaultCard()

  const [show, hide] = useModal(
    () => <AddNewCard hide={hide} numberOfCards={cards?.data?.length} />,
    [cards]
  )

  const [showAlert, hideAlert] = useModal(
    () => (
      <ConfirmationDialog
        buttonText={ButtonType.DELETE}
        iconName={IconType.DELETE}
        title={DialogHeading.DELETE}
        message={deleteCardMessage}
        onConfirm={() => {
          deleteCard(cardIdRefForDeletion.current ?? '')
        }}
        cancelButtonText={ButtonType.CANCEL}
        onCancel={hideAlert}
      />
    ),
    []
  )

  const {
    actions: { setToaster }
  } = useStoreContext()

  const checkForDefaultCard = (cardId: string): void => {
    const defaultCard: ICardDetail = cards?.data.find((card) => card.id === cardId)
    if (defaultCard.is_default) {
      setToaster({
        isDisplay: true,
        message: defaultCardMsg,
        type: ''
      })
      return
    }
    cardIdRefForDeletion.current = cardId
    showAlert()
  }

  const deleteCard = (cardId: string): void => {
    deleteSelectedCard(cardId, {
      onSuccess (data) {
        if ((data as IResponse).success !== undefined) {
          hideAlert()
        }
        setToaster({
          isDisplay: true,
          message: (data as IResponse).message,
          type: ''
        })
      }
    })
  }

  const changeDefaultCard = (event, cardId): void => {
    event.preventDefault()
    cardIdRefForMarkingDefault.current = cardId
    setDefaultCard(cardId, {
      onSuccess (data) {
        setToaster({
          isDisplay: true,
          message: (data as IResponse).message,
          type: ''
        })
      }
    })
  }

  const addNewCard = (): void => {
    if (cards?.data?.length >= 3) {
      setToaster({
        isDisplay: true,
        message: cardLimitMessage,
        type: ''
      })
      return
    }
    show()
  }

  return (
    <>
      <PaymentCardWrap theme={theme} className="cards-area-wrap">
        <div className={`scrollable-area ${onCheckoutPage ? 'is-checkout' : ''}`} {...events} ref={ref as any}>
          {
            gettingCards
              ? <Skeleton theme={theme} width={'100%'} height={onCheckoutPage ? 48 : 96} />
              : (
                <>
                  <Box theme={theme} className="card-item item-add-new">
                    <Button
                      fullWidth
                      className={className.checkoutAddNewCard}
                      defaultBtn
                      theme={theme}
                      disabled={gettingCards}
                      startIcon={
                        <>
                          <Icon name="AddIcon" />
                        </>
                      }
                      text={`${cards?.data?.length > 0 ? 'Add New Card' : 'Add Card'}`}
                      onClick={addNewCard}
                    />
                  </Box>
                  {cards?.data?.map((card) => (
                    <Box
                      key={card.id}
                      theme={theme}
                      className={`card-item ${(card as ICardDetail).is_default ? 'default' : ''}`}
                    >
                      {(card as ICardDetail).is_default
                        ? (
                          <Typography
                            theme={theme}
                            className="card-badge"
                            variant="caption"
                            component="small"
                          >
                            Default
                          </Typography>
                          )
                        : (
                          <a
                            className="badge-wrap"
                            href="javascript:void(0)"
                            onClick={(e) => { changeDefaultCard(e, card.id) }}
                          >
                            <Typography
                              theme={theme}
                              className="card-badge mark-default"
                              variant="caption"
                              component="small"
                            >
                              {card.id === cardIdRefForMarkingDefault?.current &&
                                (markAsDefaultLoading || (gettingCards ?? false))
                                ? markingAsDefault
                                : markAsDefault}
                            </Typography>
                          </a>
                          )}
                      <Box theme={theme} className="card-icon">
                        {card.card_details.brand === CardType.VISA
                          ? (
                            <Icon name="VisaPaymentIcon" />
                            )
                          : (
                            <Icon name="MasterCardPaymentIcon" />
                            )}
                      </Box>
                      <Box theme={theme} className="card-info">
                        <Typography
                          theme={theme}
                          variant="body1"
                          component="p"
                          display={'flex'}
                          alignItems={'center'}
                        >
                          {[1, 2, 3].map((val) => (
                            <span key={val} className="hide-text">
                              <span className="text-dot"></span>
                              <span className="text-dot"></span>
                              <span className="text-dot"></span>
                              <span className="text-dot"></span>
                            </span>
                          ))}
                          {card.card_details.last4}
                        </Typography>
                        <Typography
                          theme={theme}
                          variant="body2"
                          component="span"
                          className="text-muted"
                        >
                          Expires on {card.card_details.exp_month}/
                          {getSubstring(`${card.card_details.exp_year as string}`, 2, 4)}
                        </Typography>
                      </Box>
                      <Box theme={theme} className="card-cta">
                        <Button
                          onClick={() => { checkForDefaultCard(card.id) }}
                          theme={theme}
                          defaultBtn
                          iconText={
                            <>
                              <Icon name="DeleteIcon" />
                            </>
                          }
                        />
                      </Box>
                    </Box>
                  ))}
                </>
                )
          }
        </div>
        <Typography
          theme={theme}
          variant="body2"
          component="span"
          mb={3}
          display={'block'}
          className='text-muted'
        >
          {gettingCards
            ? <Skeleton theme={theme} width={'100%'} height={23} />
            : 'You can save up to 3 cards in your profile. If you want to add a new card, delete a saved card to proceed.'}
        </Typography>
      </PaymentCardWrap>
    </>
  )
}
