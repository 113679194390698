import { type FC } from 'react'
import { useTheme } from '@mui/material'
import { Typography, Dialog, Box, Button, Icon } from '@ntpkunity/controls'
import { DialogActionArea, UnsubPopupWrap } from './decisionPromptStyle'

interface IDecisionPromptProps {
  hide: () => void
  title: string
  message: string
  saveBtnName: string
  onSave: () => void
}

export const DecisionPrompt: FC<
IDecisionPromptProps
> = ({ hide, title, message, saveBtnName, onSave }) => {
  const theme = useTheme()
  return (
    <>
      <UnsubPopupWrap theme={theme} className="unsub-popup-wrap">
        <Dialog
          theme={theme}
          variant="confirmation"
          size="sm"
          open={true}
          disablePortal={true}
          customFooter={
            <>
              <DialogActionArea theme={theme} className="dialog-action-area">
                <form className="buttons">
                  <Button
                    data-testid="confirmation-popup-cancel-btn"
                    theme={theme}
                    secondary
                    text="Cancel"
                    onClick={hide}
                  />
                  <Button
                    data-testid="confirmation-popup-unsubscribe-btn"
                    theme={theme}
                    danger
                    text={saveBtnName}
                    onClick={onSave}
                  />
                </form>
              </DialogActionArea>
            </>
          }
        >
          <Box theme={theme} className="dialog-icon">
            <Icon name="DialogAlertIcon" />
          </Box>
          <Box theme={theme}>
            <Typography
              theme={theme}
              mb={2}
              className="content-title"
              variant="h2"
              component="h2"
            >
              {title}
            </Typography>
            <Typography
              theme={theme}
              className="text-muted"
              variant="body2"
              component="span"
            >
              {message}
            </Typography>
          </Box>
        </Dialog>
      </UnsubPopupWrap>
    </>
  )
}
