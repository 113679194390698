import { type ReactNode, type FC } from 'react'
import { useTheme } from '@mui/material'
import { Box, Typography } from '@ntpkunity/controls'
import { RelatedBlogWrap } from './relatedBlogsStyle'

interface BlogItemProps {
  blogBadge?: string
  blogSectionTitle?: string
  blogSectionDescription?: string
}

export const RelatedBlogsComponent: FC<BlogItemProps> = ({
  blogBadge,
  blogSectionTitle,
  blogSectionDescription
}) => {
  const theme = useTheme()
  return (
    <RelatedBlogWrap theme={theme} className='related-blog-wrap'>
      <Box theme={theme} className='page-head' textAlign={'center'}>
        {(blogBadge ?? '').length > 0
          ? (<Typography
          display={'inline-block'}
          mb={2}
          theme={theme}
          className="badge text-primary text-uppercase"
          variant="caption"
          component="small"
        >
          {blogBadge}
        </Typography>)
          : null
        }
        {(blogSectionTitle ?? '').length > 0
          ? (<Typography
          theme={theme}
          className="text-h2"
          variant="h2"
          component="h2"
          mb={2}
        >
          {blogSectionTitle}
        </Typography>)
          : null
        }
        {(blogSectionDescription ?? '').length > 0
          ? (<Typography
          mb={{ xs: 5, md: 8 }}
          theme={theme}
          className="text-body1 text-muted"
          variant="body1"
          component="p"
        >
          {blogSectionDescription}
        </Typography>)
          : null
        }
      </Box>
    </RelatedBlogWrap>
  )
}
