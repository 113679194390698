import { type FC } from 'react'
import { Fade } from 'react-awesome-reveal'
import { type IAnimationProps } from './animation.types'

export const FadeAnimation: FC<IAnimationProps> = ({
  children,
  direction,
  triggerOnce = true,
  cascade = false,
  damping = 0,
  delay = 0,
  duration = 1300
}) => {
  return (
      <Fade
        direction={direction}
        delay={delay}
        triggerOnce={triggerOnce}
        cascade={cascade}
        damping={damping}
        duration={duration}
      >
        {children}
      </Fade>
  )
}
