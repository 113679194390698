import { FreeTrialProducts, SubscribedProducts } from '../../components'
import { useTheme } from '@mui/material'
import { type SyntheticEvent, useEffect, useMemo, useState, type ReactElement } from 'react'
import {
  Box,
  Typography,
  Button,
  Dialog,
  Icon,
  Select,
  DuoTab
} from '@ntpkunity/controls'
import {
  FREE_TRIAL_PRODUCT,
  IS_LOGGED_IN
} from '@shared/constants/localStorage'
import {
  AccountType,
  ProductStatus,
  ProductTabName,
  removeItembyKey,
  scrollOnTop,
  setItem
} from 'shared'
import { MainContent, OtpContainer } from './customerAccountStyle'
import { useGetSubscriptions } from '@modules/product'
import { type IProductResponse } from '@shared/typings'

export const messages = {
  subscribedProducts: 'Subscribed Products',
  freeTrialProducts: 'Free Trial Products',
  noFreeProduct: 'You have not subscribed free trial.',
  noSubscribedProduct: 'You have not subscribed any product yet.',
  button: {
    upgrade: 'Upgrade',
    subscribe: 'Subscribe',
    playground: 'Playground',
    gotoApp: 'Go To App',
    documentation: 'Documentation'
  },
  label: {
    transactions: 'Allowed Transactions:',
    excessTransactions: 'Excess Transactions:',
    expiryDate: 'Expiry Date:',
    dueDate: 'Next Payment Date:',
    unsubscriptionEffectiveDate: 'Expiry Date:',
    status: 'Status:',
    package: 'Package:'
  }
}

export const CustomerAccount = (): ReactElement => {
  const theme = useTheme()
  const { data: products } = useGetSubscriptions()
  const [subscriptions, setSubscriptions] = useState(products)
  const [defaultTabIndex] = useState(
    ProductTabName.SUBSCRIBED_PRODUCTS
  )
  const [activeTab, setActiveTab] = useState(defaultTabIndex)
  const [free, setFreeState] = useState<IProductResponse[]>()

  useEffect(() => {
    if (products != null) setSubscriptions(products)
    setFreeState(
      products?.filter(
        (prod) => prod.package_type === AccountType.FREE && prod.is_active
      )
    )
  }, [products])

  const subscribed = useMemo(
    () =>
      products?.filter(
        (prod) => prod.package_type === AccountType.PAID && prod.is_active
      ),
    [products]
  )

  useEffect(() => {
    setActiveTab(
      (free ?? [])?.filter((freeProd: IProductResponse) => freeProd?.status === ProductStatus.EXPIRE)
        .length > 0 || (subscribed ?? [])?.length > 0
        ? ProductTabName.SUBSCRIBED_PRODUCTS
        : ProductTabName.FREE_TRIAL_PRODUCTS
    )
  }, [free, subscribed])

  useEffect(() => {
    removeItembyKey(FREE_TRIAL_PRODUCT)
    setItem(IS_LOGGED_IN, true)
    scrollOnTop()
  }, [])

  const onTabChange = (event: SyntheticEvent, value: number): void => {
    setActiveTab(value)
  }

  return (
    <>
      <MainContent theme={theme} className="main-content">
        <Typography theme={theme} variant="h3" component="h3" display={'none'}>
          {messages.subscribedProducts}
        </Typography>
        {(subscriptions != null) && (
          <DuoTab
            theme={theme}
            varient="underline"
            items={[
              {
                title: messages.freeTrialProducts,
                content: (
                  <>
                    <FreeTrialProducts products={free} />
                  </>
                )
              },
              {
                title: messages.subscribedProducts,
                content: (
                  <>
                    <SubscribedProducts products={subscribed} />
                  </>
                )
              }
            ]}
            defaultTabIndex={defaultTabIndex}
            value={activeTab}
            onChange={onTabChange}
          />
        )}
      </MainContent>

      {/* Unsubscribed OTP Popup */}
      <Dialog
        variant="confirmation"
        size="md"
        theme={theme}
        customFooter={
          <>
            <Button type="submit" secondary fullWidth text="Cancel"></Button>
            <Button type="submit" primary fullWidth text="Unsubscribe"></Button>
          </>
        }
      >
        <Box theme={theme}>
          <Typography
            theme={theme}
            mb={1}
            className="content-title"
            variant="h2"
            component="h2"
          >
            Enter Your <span className="text-primary">OTP</span>
          </Typography>
          <Typography
            display={'block'}
            theme={theme}
            className="text-muted"
            variant="body2"
            component="span"
          >
            We’ve sent an OTP on your registered email. Please enter the six
            digit code.
          </Typography>
        </Box>
        <OtpContainer
          theme={theme}
          className="otp-container"
          mt={4}
        ></OtpContainer>
        <Typography
          display={'block'}
          mt={3}
          theme={theme}
          variant="body2"
          component="span"
          className="text-muted"
        >
          Didn’t recieve OTP?{' '}
          <a href="#" className="link text-primary">
            {' '}
            Resend
          </a>
        </Typography>
      </Dialog>
    </>
  )
}
