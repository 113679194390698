import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.main-content': {
    paddingTop: 112,
    [theme.breakpoints.up('md')]: {
      paddingTop: 145
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-danger': {
      color: theme.palette.error.main
    },
    '.custom-container': {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 32,
        paddingRight: 32
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: 24,
        paddingRight: 24
      }
    }
  }
}))

export const usertracking = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.user': {
    border: '1px solid' + theme.palette.grey[100],
    padding: 34,
    borderRadius: theme.shape.borderRadius
  }
}))

export const TableWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.table-wrap': {
    '.u-table-wrap': {
      '.has-sorting': {
        td: {
          position: 'relative',
          paddingRight: '38px !important',
          '.sort-action': {
            position: 'absolute',
            top: 9,
            right: 12,
            '.btn': {
              '&.with-icon': {
                '&.icon-only': {
                  padding: 0
                }
              },
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }
          }
        }
      },
      '.u-table': {},
      '.u-table-container': {
        maxHeight: 'calc(100vh - 278px)'
      }
    },
    '.status-indicator': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor: theme.palette.primary.main,
      marginRight: 8,
      '&.ind-danger': {
        backgroundColor: theme.palette.error.main
      },
      '&.ind-success': {
        backgroundColor: theme.palette.success.main
      }
    },
    '.text-right': {
      textAlign: 'right'
    },
    '.loader-cell': {
      '.loader': {
        width: 80,
        height: 80,
        img: {
          maxHeight: 80
        }
      }
    },
    '.MuiTablePagination-root': {
      marginTop: '-4px',
      paddingTop: '4px',
      borderColor: theme.palette.grey[100],
      color: theme.palette.grey[600],
      fontSize: theme.typography.htmlFontSize,
      fontFamily: theme.typography.fontFamily
    },
    '.MuiTablePagination-select:focus': {
      backgroundColor: 'transparent'
    },
    '.MuiTablePagination-selectIcon': {
      top: 2,
      left: 32
    }
  }
}))
