import Http from '@shared/helper/http-api'
import { type ICart } from '@shared/typings'
import {
  type UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query'
import { type AppError } from 'single-spa'

const BASE_URL = process.env.BASE_URL

export const ADD_TO_CART = 'marketplace/order/cart'
export const ADD_CARTS = 'marketplace/order/cart-list'
export const GET_CARTS = 'marketplace/order/get-all-carts'
export const REMOVE_FROM_CART = 'marketplace/order/remove-cart'

interface IAddToCart {
  product_id: number
  price_id: number
}

export const useAddToCart = (): any => {
  const url = ADD_TO_CART
  const queryClient = useQueryClient()
  const { data, isLoading, mutate, error } = useMutation(
    async (dto: IAddToCart) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post(url, dto)
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(GET_CARTS)
      }
    }
  )
  return { data, isLoading, mutate, error }
}

export const useGetAllCarts = (
  isLoggedIn: boolean
): {
  data: ICart[] | undefined
  isLoading: boolean
  error: AppError | unknown
  isFetching: boolean
} => {
  const url = GET_CARTS
  const { data, isLoading, error, isFetching } = useQuery<ICart[]>(
    GET_CARTS,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get(url)
    },
    { refetchOnWindowFocus: false, enabled: Boolean(isLoggedIn) }
  )
  return { data, isLoading, error, isFetching }
}

export const useRemoveFromCart = (): any => {
  const queryClient = useQueryClient()
  const url = REMOVE_FROM_CART
  const { data, isLoading, error, mutate } = useMutation(
    REMOVE_FROM_CART,
    async (cartId: number) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.delete(`${url}/${cartId}`)
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(GET_CARTS)
      }
    }
  )
  return { mutate, data, isLoading, error }
}

export const useAddCarts = (): {
  data: ICart | unknown
  mutate: UseMutateFunction
  isLoading: boolean
  error: AppError | unknown
} => {
  const queryClient = useQueryClient()
  const url = ADD_CARTS
  const { data, isLoading, error, mutate } = useMutation(
    ADD_CARTS,
    async (carts: any) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post(url, carts)
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(GET_CARTS)
      }
    }
  )
  return { mutate, data, isLoading, error }
}
