import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const RelatedBlogWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.related-blog-wrap': {
    '.badge': {
      backgroundColor: 'rgba(0, 51, 253, 0.1)',
      padding: '8px 16px',
      borderRadius: 24,
      fontWeight: theme.customVariables.fontWeightSemiBold,
      letterSpacing: '0.5px'
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.page-head': {
      margin: 'auto',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '55%',
        width: '100%'
      }
    }
  }
}))
