import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const UnsubPopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.unsub-popup-wrap': {
    '.u-dialog': {
      '&.u-dialog-sm': {
        '.MuiDialog-paper': {
          maxWidth: 520
        }
      },
      '.u-dialog-content': {
        overflow: 'hidden',
        '&.has-no-footer': {
          [theme.breakpoints.up('md')]: {
            padding: 60
          }
        },
        '.dialog-body': {
          margin: '0 -24px',
          padding: '0 24px',
          overflowY: 'auto',
          overflowX: 'hidden',
          scrollbarWidth: 'thin',
          maxHeight: 'calc(100vh - 512px)',
          '&::-webkit-scrollbar': {
            width: 7,
            height: 7
          },
          '&::-webkit-scrollbar-track': {
            padding: '0 1px',
            backgroundColor: '#f0f0f0'
          },
          '&::-webkit-scrollbar-thumb': {
            transition: 'all 0.3s',
            backgroundColor: '#cdcdcd'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#a6a6a6'
          },
          [theme.breakpoints.up('md')]: {
            margin: '0 -60px',
            padding: '0 60px'
          },
          '.other-field': {
            '.u-form-group': {
              marginTop: 24
            }
          }
        },
        [theme.breakpoints.up('md')]: {
          padding: '60px 60px 0 60px'
        }
      },
      '.u-dialog-action': {
        display: 'block',
        padding: '24px 60px 60px',
        [theme.breakpoints.down('sm')]: {
          padding: '32px 24px 32px'
        }
      }
    },
    '.feedback-form-wrap': {
      '.custom-radio-wrap': {
        width: '100%',
        '.u-custom-control-label': {
          margin: '0 0 10px',
          textAlign: 'left',
          '&:last-child': {
            margin: 0
          }
        }
      }
    },
    '.text-dark': {
      color: theme.palette.grey[900]
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.fw-medium': {
      fontWeight: theme.typography.fontWeightMedium
    }
  }
}))

export const DialogActionArea = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.dialog-action-area': {
    width: '100%',
    '.btn': {
      '&:first-of-type': {
        width: 'auto !important'
      }
    },
    '.buttons': {
      width: '100%',
      display: 'flex',
      gap: '16px',
      '.MuiButton-root': {
        '&:not(:first-of-type)': {
          [theme.breakpoints.down('md')]: {
            flexGrow: 1
          }
        },
        '&:last-of-type': {
          [theme.breakpoints.down('md')]: {
            width: '100%'
          }
        }
      }
    }
  }
}))
