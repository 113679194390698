import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const FullPageMessageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.full-page-message-wrap': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 580,
    margin: 'auto',
    textAlign: 'center',
    minHeight: 'calc(100vh - 73px)',
    '.msg-img': {
      maxHeight: 320,
      img: {
        maxHeight: 320,
        maxWidth: '100%'
      },
      [theme.breakpoints.down('md')]: {
        maxHeight: 200,
        img: {
          maxHeight: 200
        }
      }
    },
    '.link': {
      '&:hover': {
        textDecoration: 'underline !important'
      }
    }
  }
}))
