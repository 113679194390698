import { type FC } from 'react'
import { Box, Container, Grid, Typography } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { FadeAnimation } from '@shared/components'
import { BannerWrap } from './homePageBannerStyle'

export const HomePageBannerComponent: FC = () => {
  const theme = useTheme()
  return (
    <BannerWrap theme={theme} className='banner-wrap'>
      <Container theme={theme} className='custom-container' maxWidth={'lg'}>
        <Grid theme={theme} item container alignItems={{ md: 'center' }} height={'100%'}>
          <Grid theme={theme} item xs={12} sm={10} md={6} lg={4.5}>
            <FadeAnimation>
              <Box theme={theme} className='banner-content'>
                <Typography theme={theme} className='text-white badge' variant='caption' component='small' display={'inline-block'}>
                  Transforming finance and leasing businesses with Appex Now
                </Typography>
                <Typography theme={theme} className='text-white text-h2' variant='h1' component='h1' mt={{ xs: 2, md: 3 }}>
                  Connect, configure and innovate.
                </Typography>
                <Typography theme={theme} className='text-white text-body2' variant='body1' component='p' mt={{ xs: 2, md: 3 }}>
                  From originations to servicing, experience the most intuitive finance and leasing products in asset finance.
                </Typography>
              </Box>
            </FadeAnimation>
          </Grid>
        </Grid>
      </Container>
    </BannerWrap>
  )
}
