import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const ProductsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.product-wrap': {
    '.icon-element': {
      svg: {
        path: {
          stroke: theme.palette.grey[300]
        }
      }
    },
    '.action-area': {
      '.action-btn': {
        marginLeft: 24,
        paddingLeft: 24,
        borderLeft: '1px solid' + theme.palette.grey[100]
      },
      '.btn': {
        '&.btn-default': {
          color: theme.palette.grey[600],
          '&:hover': {
            color: theme.palette.grey[600]
          },
          '&:focus': {
            color: theme.palette.grey[600],
            '&:hover': {
              color: theme.palette.grey[600]
            }
          },
          '.MuiButton-endIcon': {
            transform: 'rotate(180deg)',
            marginRight: 0
          }
        }
      }
    },
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.link': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '.product-section': {
      '.mobile-slider': {
        [theme.breakpoints.up('md')]: {
          display: 'none'
        },
        [theme.breakpoints.down('md')]: {
          display: 'block'
        }
      },
      '.mobile-slider-grid': {
        overflowX: 'auto',
        overflowY: 'hidden',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          width: 0,
          height: 0
        },
        '&::-webkit-scrollbar-track': {
          padding: '0 0px',
          backgroundColor: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
          transition: 'all 0.3s',
          backgroundColor: 'transparent',
          borderRadius: theme.shape.borderRadius
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: 'transparent'
        }
      }
    }
  }
}))

export const ProductBox = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.product-box': {
    padding: '40px 24px',
    border: '1px solid' + theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.3s ease-in-out 0s',
    height: '100%',
    '.prd-link': {
      textDecoration: 'none',
      color: theme.palette.grey[900],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      cursor: 'pointer',
      textAlign: 'center',
      '&:before': {
        display: 'block',
        content: "' '",
        backgroundColor: 'rgba(0, 51, 253, 0.1)',
        width: '100%',
        height: 96,
        position: 'absolute',
        top: 7,
        borderRadius: theme.shape.borderRadius
      }
    },
    '.prd-img': {
      height: 120,
      marginBottom: 16,
      position: 'relative',
      textAlign: 'center',
      width: '100%',
      img: {
        maxHeight: 120
      }
    },
    '.prd-name': {
      fontWeight: theme.customVariables.fontWeightSemiBold
    },
    '&:hover': {
      boxShadow: '0px 32px 32px -24px rgba(0, 26, 128, 0.25)'
    },
    '&.text-only': {
      padding: 24
    }
  }
}))

// export const ProductSlider = styled(
//   Box,
//   {}
// )<Partial<IBoxProps>>(({ theme }) => ({
//   "&.product-slider": {
//     '.MuiButtonBase-root[aria-label="Previous"], .MuiButtonBase-root[aria-label="Next"]':
//       {
//         backgroundColor: theme.palette.common.white,
//         border: "1px solid" + theme.palette.grey[100],
//         opacity: 0,
//         width: 48,
//         height: 48,
//         marginTop: -15,
//         "&:hover": {
//           opacity: "1 !important",
//         },
//       },
//     '.MuiButtonBase-root[aria-label="Next"]': {
//       transform: "rotate(180deg)",
//     },
//     ".css-hn784z": {
//       "&:hover": {
//         '.MuiButtonBase-root[aria-label="Previous"]': {
//           filter: "none",
//         },
//       },
//     },
//     ".css-1abc02a": {
//       "&:hover": {
//         '.MuiButtonBase-root[aria-label="Next"]': {
//           filter: "none",
//         },
//       },
//     },
//     ".product-carousel": {
//       "&:hover": {
//         '.MuiButtonBase-root[aria-label="Previous"], .MuiButtonBase-root[aria-label="Next"]':
//           {
//             opacity: 1,
//           },
//       },
//     },
//     [theme.breakpoints.up("md")]: {
//       display: "block",
//     },
//     [theme.breakpoints.down("md")]: {
//       display: "none",
//     },
//   },
// }));

export const SearchBox = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.search-box': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 0px 80px rgba(235, 236, 242, 0.75)',
    padding: '24px 40px',
    borderRadius: theme.shape.borderRadius,
    maxWidth: 628,
    margin: 'auto',
    '.icon-element': {
      flexShrink: 0,
      svg: {
        path: {
          stroke: theme.palette.grey[300]
        }
      }
    },
    '.u-form-group': {
      marginBottom: 0,
      marginLeft: 26,
      marginRight: 24,
      flexGrow: 1,
      '.u-form-control': {
        height: 20,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        color: theme.palette.grey[600],
        '.MuiInputBase-input': {
          padding: 0,
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.grey[600]
          }
        },
        '&.Mui-focused': {
          '.MuiInputBase-input': {
            '&::placeholder': {
              opacity: 1
            }
          }
        },
        fieldset: {
          border: 'none'
        }
      }
    },
    '.action-area': {
      flexShrink: 0,
      paddingLeft: 24,
      borderLeft: '1px solid' + theme.palette.grey[100],
      '.btn': {
        '&.btn-default': {
          color: theme.palette.grey[600],
          '&:hover': {
            color: theme.palette.grey[600]
          },
          '&:focus': {
            color: theme.palette.grey[600],
            '&:hover': {
              color: theme.palette.grey[600]
            }
          }
        }
      }
    }
  }
}))

export const SearchArea = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.search-area': {
    maxWidth: 628,
    margin: 'auto',
    '.u-custom-autocomplete': {
      '.MuiAutocomplete-root': {
        '.u-form-group': {
          '.MuiInputBase-formControl': {
            height: 72,
            borderRadius: 48,
            padding: '0 40px 0px 0',
            '.MuiInputBase-input': {
              padding: '24px 40px !important',
              [theme.breakpoints.down('md')]: {
                padding: '16px 24px !important'
              }
            },
            svg: {
              path: {
                stroke: theme.palette.grey[300]
              }
            },
            '&:hover': {
              fieldset: {
                borderColor: theme.palette.primary.main
              }
            },
            [theme.breakpoints.down('md')]: {
              height: 56,
              padding: '0 24px 0px 0'
            }
          }
        }
      },
      '.MuiAutocomplete-popper': {
        zIndex: 1200,
        '.MuiAutocomplete-paper': {
          borderRadius: 16,
          '.MuiAutocomplete-listbox': {
            '.MuiAutocomplete-option': {
              padding: '8px 24px'
            }
          }
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%'
    }
  }
}))

export const FeaturedProducts = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.feature-products': {
    '.prd-link': {
      display: 'block',
      cursor: 'pointer'
    },
    '.prd-img': {
      height: 200,
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      position: 'relative',
      img: {
        maxHeight: 200,
        maxWidth: '100%',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center center'
      },
      '.play-icon': {
        position: 'absolute',
        left: '50%',
        top: '50%',
        zIndex: 1,
        transform: 'translate(-50%, -50%)',
        width: 48,
        height: 48,
        img: {
          maxHeight: 48,
          objectFit: 'contain'
        }
      }
    },
    '.product-slider': {
      '.carousel-container': {
        paddingBottom: 0
      },
      '.carousel-control': {
        top: '33%',
        transform: 'translateY(-33%)',
        marginTop: 0,
        '&.next-btn': {
          transform: 'translatey(-33%) rotate(180deg)'
        }
      }
    },
    '.section-head': {
      [theme.breakpoints.down('md')]: {
        padding: '0 24px'
      }
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: -24,
      marginRight: -24
    }
  }
}))

export const ProductSlider = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.product-slider': {
    margin: '0 -12px',
    position: 'relative',
    '.carousel-container': {
      paddingBottom: 45,
      paddingLeft: 0,
      paddingRight: 0,
      [theme.breakpoints.down('md')]: {
        padding: '0px 18px 45px 18px'
      },
      '.carousel-item': {
        '.feat-prod-item': {
          padding: '0 12px',
          [theme.breakpoints.down('md')]: {
            padding: '0 4px'
          }
        },
        '.product-box': {
          margin: '0 12px',
          [theme.breakpoints.down('md')]: {
            margin: '0 4px'
          }
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      margin: 0,
      padding: '0px 0px 0px 8px'
    }
  }
}))
