import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const ProfileMenu = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.profile-menu': {
    backgroundColor: theme.customVariables.tableStripedBg,
    padding: 40,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    top: 105,
    '.u-nav-wrap': {
      flexGrow: 1,
      '.u-list-item': {
        padding: '20px 24px',
        marginBottom: 8,
        '&:last-child': {
          marginBottom: 0
        },
        '.u-list-item-icon': {
          svg: {
            path: {
              stroke: theme.palette.grey[300]
            }
          },
          [theme.breakpoints.down('md')]: {
            marginRight: 8
          }
        },
        '.u-list-item-text': {
          '.MuiTypography-root': {
            fontSize: theme.typography.body1.fontSize,
            lineHeight: theme.typography.body1.lineHeight
          }
        },
        '&:hover': {
          backgroundColor: '#f2f2f2',
          '.u-list-item-text': {
            '.MuiTypography-root': {
              color: theme.palette.grey[900]
            }
          },
          '.u-list-item-icon': {
            svg: {
              path: {
                stroke: theme.palette.grey[600]
              },
              rect: {
                stroke: theme.palette.grey[600]
              }
            }
          },
          [theme.breakpoints.down('md')]: {
            backgroundColor: 'transparent'
          }
        },
        '&:focus': {
          backgroundColor: '#f2f2f2',
          '.u-list-item-text': {
            '.MuiTypography-root': {
              color: theme.palette.grey[900]
            }
          },
          '.u-list-item-icon': {
            svg: {
              path: {
                stroke: theme.palette.grey[600]
              },
              rect: {
                stroke: theme.palette.grey[600]
              }
            }
          },
          [theme.breakpoints.down('md')]: {
            backgroundColor: 'transparent'
          }
        },
        '&.selected': {
          backgroundColor: theme.palette.common.white,
          boxShadow: '0px 16px 16px -8px rgba(0, 36, 156, 0.1)',
          '.u-list-item-text': {
            '.MuiTypography-root': {
              color: theme.palette.grey[900],
              fontWeight: theme.customVariables.fontWeightSemiBold
            }
          },
          '.u-list-item-icon': {
            svg: {
              path: {
                stroke: theme.palette.primary.main
              },
              rect: {
                stroke: theme.palette.primary.main
              }
            }
          },
          [theme.breakpoints.down('md')]: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            '&::before': {
              width: '100%'
            }
          }
        },
        '&:active': {
          backgroundColor: theme.palette.common.white,
          boxShadow: '0px 16px 16px -8px rgba(0, 36, 156, 0.1)',
          '.u-list-item-text': {
            '.MuiTypography-root': {
              color: theme.palette.grey[900],
              fontWeight: theme.customVariables.fontWeightSemiBold
            }
          },
          '.u-list-item-icon': {
            svg: {
              path: {
                stroke: theme.palette.primary.main
              }
            }
          },
          [theme.breakpoints.down('md')]: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        },
        [theme.breakpoints.down('md')]: {
          padding: '20px 0 20px',
          marginBottom: 0,
          marginLeft: 20,
          marginRight: 20,
          position: 'relative',
          '&::before': {
            display: 'block',
            content: "' '",
            backgroundColor: theme.palette.primary.main,
            position: 'absolute',
            width: 0,
            height: 4,
            bottom: -1,
            borderRadius: 4,
            transition: 'all 0.3s ease-in-out 0s',
            opacity: 1
          }
        }
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexShrink: 0
      }
    },
    [theme.breakpoints.down('md')]: {
      backgroundColor: 'transparent',
      borderBottom: '1px solid' + theme.palette.grey[100],
      padding: 0,
      flexDirection: 'row',
      flexWrap: 'nowrap',
      marginLeft: -24,
      marginRight: -24,
      minHeight: 'auto',
      borderRadius: 0,
      overflowX: 'scroll',
      overflowY: 'hidden',
      scrollbarWidth: 'none',
      transition: 'all 0.3s ease-in-out 0s',
      position: 'relative',
      top: 0,
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0
      },
      '&.menu-fixed': {
        position: 'fixed',
        width: '100%',
        backgroundColor: theme.palette.common.white,
        zIndex: 10,
        top: 65
      },
      '.help-box': {
        display: 'none'
      }
    }
  }
}))
