import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const BlogListItem = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.blog-list-item': {
    height: '100%',
    padding: 24,
    [theme.breakpoints.down('md')]: {
      padding: 16
    },
    '.fw-medium': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '.blog-img': {
      maxHeight: 190,
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      img: {
        maxWidth: '100%',
        maxHeight: 190,
        objectFit: 'cover',
        objectPosition: 'center center',
        width: '100%',
        height: '100%'
      }
    },
    '.blog-meta': {
      '.seperator': {
        display: 'inline-block',
        width: 4,
        height: 4,
        borderRadius: '50%',
        backgroundColor: theme.palette.grey[300]
      }
    },
    '.blog-content': {
      '.blog-title': {
        a: {
          color: theme.palette.grey[900],
          textDecoration: 'none'
        }
      },
      '.blog-tags': {
        display: 'inline-block',
        width: '100%'
      },
      '.badge': {
        padding: '4px 12px',
        backgroundColor: 'rgba(0, 51, 253, 0.1)',
        borderRadius: 24,
        fontWeight: theme.customVariables.fontWeightSemiBold,
        letterSpacing: '0.5px',
        margin: '0 5px 5px 0',
        float: 'left'
      }
    },
    '.text-truncate': {
      display: '-webkit-box',
      '-webkitBoxOrient': 'vertical',
      overflow: 'hidden',
      '&.lines-2': {
        '-webkitLineClamp': '2'
      },
      '&.lines-3': {
        '-webkitLineClamp': '3'
      }
    }
  }
}))
