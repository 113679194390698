import { useState, useEffect, useMemo, type FC } from 'react'
import {
  Checkbox,
  Grid,
  Typography,
  Button,
  Select,
  Input,
  Box,
  Icon,
  ContactInput,
  Autocomplete
} from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { SectionContent } from './myAccountStyle'
import { Controller, useForm } from 'react-hook-form'
import {
  addBillingMessage,
  changePassword,
  HttpStatus,
  PasswordKey,
  updateBillingMessage,
  updateBusinessMessage,
  updateProfileErrorMessage,
  updateProfileMessage,
  UserAction,
  Validation,
  validation,
  getCountryOptions
} from 'shared'
import {
  useGetCountries,
  useGetCountyByStateId,
  useGetIndustryTypes,
  useGetStates
} from '@modules/authentication'
import {
  useGetProfile,
  useS3Storage,
  useUpdateProfile,
  useUpdateTenant
} from '@modules/myAccount/services'
import { useStoreContext } from 'store/storeContext'
import { ChangePasswordComponent } from '@ntpkunity/controls-ums'
import { APP_ROUTES } from '@router/path'
import { useUpdateBillingAddress, useSaveBillingAddress } from '@modules/checkout/services'
import DisableLoader from '@shared/assets/images/loader-disabled-btn.gif'
import {
  type IBillingForm,
  type IProfileForm,
  type IStorageForm,
  type ITenantForm,
  type ICountry
} from '@shared/typings'

const messages = {
  agreeNews: 'I agree to receive marketing material related to product(s).',
  name: {
    addressLine1: 'address_line_1',
    addressLine2: 'address_line_2',
    country: 'country_id',
    county: 'state_county',
    industryTypesId: 'industry_types_id',
    companyName: 'company_name',
    city: 'city',
    state: 'state_id',
    zipCode: 'zip_code',
    sendNewsletter: 'send_newsletter',
    termsAndConditions: 'tnc',
    emailTo: 'email',
    s3_access_key: 's3_access_key',
    s3_secret_access: 's3_secret_access',
    s3_bucket_name: 's3_bucket_name'
  },
  label: {
    industryTypesId: 'Industry (Optional)',
    companyName: 'Company Name',
    city: 'City',
    state: 'State',
    zipCode: 'Zip Code',
    country: 'Country',
    county: 'County (Optional)',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    emailTo: 'Email Invoice/Receipt To',
    stateName: 'State/Province/Region'
  },
  validation: {
    emailTo: 'Email address',
    addressLine1: 'Address',
    addressLine2: 'Address 2',
    termsAndConditions: 'Terms and conditions',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    contactNumber: 'Contact Number'
  },
  button: {
    save: 'Save Changes',
    wait: 'Wait...',
    reset: 'Reset S3 credentials'
  },
  placeholder: {
    typeHere: 'Type here...',
    emailAddress: 'sample@sample.com'
  },
  successMsg: 'Profile has been saved successfully.'
}

const layoutText = {
  title: 'Change Password',
  buttonText: 'Save Changes',
  passwordHelperText: '8 characters minimum, including capital letters, numbers and special characters',
  placeholder: 'Type here...'
}

export const MyAccountPage: FC = () => {
  const theme = useTheme()
  const [response, setResponse] = useState<any>()
  const [isProfileShow, setIsProfileShow] = useState(false)
  const [isTenantShow, setIsTenantShow] = useState(false)
  const [isBillingShow, setIsBillingShow] = useState(false)
  const [isStorageShow, setIsStorageShow] = useState(false)
  const [isPasswordShow, setIsPasswordShow] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [stateId, setStateId] = useState<string>()
  const [countryId, setCountryId] = useState<string>()
  const [countryFlagCode, setCountryFlagCode] = useState('')
  const [businessCountryFlagCode, setBusinessCountryFlagCode] = useState('')
  const [actionType, setActionType] = useState<string>(UserAction.EDIT)
  const [country, setCountry] = useState<ICountry>()

  const { data: industryTypes } = useGetIndustryTypes()
  const { data: countriesData } = useGetCountries()
  const { data: states } = useGetStates()
  const { data: userProfile } = useGetProfile()
  const { mutate: updateUserProfile, isLoading: updateProfileLoading } =
    useUpdateProfile()
  const { mutate: updateTenantProfile, isLoading: updateTenantLoading } =
    useUpdateTenant()
  const { mutate: updateBillingAddress, isLoading: updateBillingLoading } =
    useUpdateBillingAddress()
  const { mutate: saveBillingAddress, isLoading: saveBillingLoading } = useSaveBillingAddress()

  const { mutate: updateStorage, isLoading: updateStorageLoading, error: s3CredentialsError } =
    useS3Storage()
  const { data: counties } = useGetCountyByStateId(stateId)

  const profileform = useForm<IProfileForm>()
  const tenantForm = useForm<ITenantForm>()
  const billingForm = useForm<IBillingForm>()
  const storageForm = useForm<IStorageForm>()

  const {
    actions: { setToaster }
  } = useStoreContext()

  const industryTypesOptions = useMemo(
    () =>
      industryTypes
        // ?.sort((a, b) => a.name?.localeCompare(b.name))
        ?.reduce(
          (agg, type) => [...agg, { text: type.name, value: type.id }],
          []
        ),
    [industryTypes]
  )

  const countyOptions = useMemo(
    () =>
      counties?.reduce(
        (agg, type) => [...agg, { text: type.name, value: type.id }],
        []
      ),
    [counties]
  )

  const countries = useMemo(
    () =>
      countriesData
        ?.sort((a, b) => a.name?.localeCompare(b.name))
        ?.reduce(
          (agg: any, countryId: any) => [
            ...agg,
            {
              name: countryId?.name,
              id: countryId?.id,
              countryCode: countryId.country_code,
              code: countryId?.code
            }
          ],
          []
        ),
    [countriesData]
  )

  const stateOptions = useMemo(
    () =>
      states
        ?.filter((state) => state.country_id === countryId)
        ?.reduce(
          (agg, type) => [...agg, { text: type.name, value: type.id }],
          []
        ),
    [states, userProfile, countryId]
  )

  useEffect(() => {
    profileform.setValue('first_name', (userProfile?.first_name ?? ''))
    profileform.setValue('last_name', (userProfile?.last_name ?? ''))
    profileform.setValue('email', userProfile?.user_name)
    profileform.setValue('contact_number', (userProfile?.contact_number ?? ''))
    tenantForm.setValue('name', (userProfile?.company_name ?? ''))
    tenantForm.setValue('contact_number', (userProfile?.business_contact_number ?? ''))
    tenantForm.setValue('country_id', (userProfile?.country_id ?? ''))
    tenantForm.setValue('industry_id', (userProfile?.industry_id ?? null))
    tenantForm.setValue('is_subscribed', userProfile?.is_subscribed)
    billingForm.setValue('email', (userProfile?.billing_email ?? ''))
    billingForm.setValue('billing_country_id', (userProfile?.billing_country_id ?? ''))
    billingForm.setValue('address_line_1', (userProfile?.address_line_1 ?? ''))
    billingForm.setValue('address_line_2', (userProfile?.address_line_2 ?? ''))
    billingForm.setValue('address_line_2', (userProfile?.address_line_2 ?? ''))
    billingForm.setValue('zip_code', (userProfile?.zip_code ?? ''))
    billingForm.setValue('city', (userProfile?.city ?? ''))
    billingForm.setValue('state_id', (userProfile?.state_id ?? ''))
    billingForm.setValue('county_id', userProfile?.county_id)
    billingForm.setValue('state_name', (userProfile?.state_name ?? ''))
    setIsSubscribed(userProfile?.is_subscribed)
    setCountryId(userProfile?.billing_country_id ?? '')
    setStateId(userProfile?.state_id ?? '')
    if ((userProfile?.address_line_1 ?? '').length > 0 && (userProfile?.billing_email ?? '').length > 0) {
      setActionType(UserAction.EDIT)
    } else {
      setActionType(UserAction.CREATE)
    }
  }, [userProfile])

  const userHasS3Credentials =
    (userProfile?.s3_access_key ?? '').length > 0 ||
    (userProfile?.s3_secret_access ?? '').length > 0 ||
    (userProfile?.s3_bucket_name ?? '').length > 0

  useEffect(() => {
    if ((s3CredentialsError ?? '').length > 0) {
      setToaster({
        isDisplay: true,
        message: s3CredentialsError,
        type: 'error',
        showIcon: true
      })
    }
  }, [s3CredentialsError])

  useEffect(() => {
    if (response?.status === HttpStatus.OK) {
      setToaster({
        isDisplay: true,
        message: changePassword,
        type: ''
      })
      setIsPasswordShow(false)
    } else if (response?.status === HttpStatus.Conflict) {
      setToaster({
        isDisplay: true,
        message: response?.message,
        type: 'error'
      })
    }
  }, [response])

  const onProfileSubmit = (data: any): void => {
    data.country_code = countryFlagCode
    updateUserProfile(data, {
      onSuccess () {
        setToaster({
          isDisplay: true,
          message: updateProfileMessage,
          type: ''
        })
        setIsProfileShow(false)
      },
      onError () {
        setToaster({
          isDisplay: true,
          message: updateProfileErrorMessage,
          type: 'error'
        })
      }
    })
  }

  const onTenantSubmit = (data: any): void => {
    data.country_code = businessCountryFlagCode
    updateTenantProfile(data, {
      onSuccess () {
        setToaster({
          isDisplay: true,
          message: updateBusinessMessage,
          type: ''
        })
        setIsTenantShow(false)
      },
      onError () {
        setToaster({
          isDisplay: true,
          message: updateProfileErrorMessage,
          type: 'error'
        })
      }
    })
  }

  const getCountryById = (id: number): ICountry | undefined => {
    return countries?.find((country) => country.id === id)
  }

  const countryOptions = useMemo(
    () => getCountryOptions(countries),
    [countries]
  )

  const onBillingSubmit = (data: any): void => {
    data.country_id = data.billing_country_id
    delete data.billing_country_id
    if (typeof data.state_id === 'number') {
      delete data.state_name
    } else {
      delete data.state_id
      delete data.county_id
    }
    if (actionType === UserAction.EDIT) {
      updateBillingAddress(data, {
        onSuccess () {
          setToaster({
            isDisplay: true,
            message: updateBillingMessage,
            type: ''
          })
          setIsBillingShow(false)
        },
        onError () {
          setToaster({
            isDisplay: true,
            message: updateProfileErrorMessage,
            type: 'error'
          })
        }
      })
    } else {
      saveBillingAddress(data, {
        onSuccess () {
          setToaster({
            isDisplay: true,
            message: addBillingMessage,
            type: ''
          })
          setIsBillingShow(false)
        },
        onError () {
          setToaster({
            isDisplay: true,
            message: updateProfileErrorMessage,
            type: 'error'
          })
        }
      })
    }
  }

  const onStorageSubmit = (data: any): void => {
    updateStorage(data, {
      onSuccess (data) {
        setToaster({
          isDisplay: true,
          message: data.message,
          type: ''
        })
        setIsStorageShow(false)
        storageForm.reset()
      }
    })
  }

  return (
    <>
      <SectionContent
        theme={theme}
        className="section-content"
        mt={{ xs: 0, md: 5 }}
        pb={4}
        mb={4}
      >
        <Typography
          theme={theme}
          variant="h3"
          component="h3"
          mb={3}
          className="text-h3"
        >
          About Me
        </Typography>
        {// eslint-disable-next-line @typescript-eslint/no-misused-promises
        }<form onSubmit={profileform.handleSubmit(onProfileSubmit)}
          onChange={() => { setIsProfileShow(true) }}
        >
          <Grid theme={theme} container item spacing={3}>
            <Grid theme={theme} item xs={12} md={6}>
              <Controller
                name="first_name"
                control={profileform.control}
                rules={validation(
                  messages.validation.firstName,
                  Validation.REQUIRED,
                  Validation.WHITE_SPACE,
                  Validation.LIMIT_LENGTH,
                  '50',
                  Validation.ONLY_ALPHABETS
                )}
                render={({ field: { onChange, value } }) => (
                  <Input
                    theme={theme}
                    fullWidth
                    label={'First Name'}
                    type={'text'}
                    onChange={onChange}
                    error={profileform?.formState?.errors?.first_name?.message}
                    value={value}
                    placeholder={messages.placeholder.typeHere}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12} md={6}>
              <Controller
                name="last_name"
                rules={validation(
                  messages.validation.lastName,
                  Validation.ONLY_ALPHABETS
                )}
                control={profileform.control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    theme={theme}
                    fullWidth
                    label={'Last Name'}
                    type={'text'}
                    placeholder={messages.placeholder.typeHere}
                    error={profileform?.formState?.errors?.last_name?.message?.toString()}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12} md={6}>
              <Controller
                name="email"
                control={profileform.control}
                rules={validation(
                  messages.validation.email,
                  Validation.REQUIRED,
                  Validation.EMAIL
                )}
                render={({ field: { onChange, value } }) => (
                  <Input
                    theme={theme}
                    fullWidth
                    label={'Email Address'}
                    type={'email'}
                    disabled
                    placeholder={messages.placeholder.emailAddress}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12} md={6}>
              <Controller
                name="contact_number"
                control={profileform.control}
                rules={validation(
                  Validation.PHNONE_NUMBER
                )}
                render={({ field }) => (
                  <ContactInput
                    theme={theme}
                    setCountryFlagCode={setCountryFlagCode}
                    countryFlagCode={countryFlagCode}
                    phoneCountryCode={userProfile?.country_code}
                    type={'text'}
                    fullWidth
                    label="Contact Number"
                    {...field}
                    error={
                      profileform.formState.errors?.contact_number?.message
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          {isProfileShow && (
            <Box theme={theme} className="form-cta">
              <Button
                disabled={updateProfileLoading}
                type="submit"
                theme={theme}
                primary
                text={
                  updateProfileLoading
                    ? messages.button.wait
                    : messages.button.save
                }
                startIcon={
                  updateProfileLoading && (
                    <img src={DisableLoader} alt="Loader" />
                  )
                }
              />
            </Box>
          )}
        </form>
      </SectionContent>

      <SectionContent
        theme={theme}
        className="section-content"
        mt={{ xs: 0, md: 4 }}
        pb={4}
        mb={4}
      >
        <ChangePasswordComponent
          theme={theme}
          setResponse={setResponse}
          next_screen_url={`${APP_ROUTES.MY_ACCOUNT}`}
          layoutText={layoutText}
          isAdminPassword={true}
          setIsOnSaveShow={setIsPasswordShow}
          isOnSaveShow={isPasswordShow}
        />
      </SectionContent>

      <SectionContent
        theme={theme}
        className="section-content"
        mt={{ xs: 0, md: 4 }}
        pb={4}
        mb={4}
      >
        <Typography
          theme={theme}
          variant="h3"
          component="h3"
          mb={3}
          className="text-h3"
        >
          Business Information
        </Typography>
        {// eslint-disable-next-line @typescript-eslint/no-misused-promises
        }<form onSubmit={tenantForm.handleSubmit(onTenantSubmit)}
          onChange={() => { setIsTenantShow(true) }}
        >
          <Grid theme={theme} container item spacing={3}>
            <Grid theme={theme} item xs={12} md={6}>
              <Controller
                name="name"
                control={tenantForm.control}
                rules={validation(
                  'Company name',
                  Validation.REQUIRED,
                  Validation.WHITE_SPACE,
                  Validation.LIMIT_LENGTH,
                  '50'
                )}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    fullWidth
                    disabled
                    label={messages.label.companyName}
                    type={'string'}
                    placeholder={messages.placeholder.typeHere}
                    {...field}
                    error={tenantForm?.formState?.errors?.name?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12} md={6}>
              <Controller
                name="industry_id"
                defaultValue={userProfile?.industry_id ?? ''}
                control={tenantForm.control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    theme={theme}
                    label={messages.label.industryTypesId}
                    items={industryTypesOptions}
                    sxProps={false}
                    placeholder="Select"
                    disablePortal={false}
                    onChange={(e) => {
                      onChange(e)
                      setIsTenantShow(true)
                    }}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12} md={6}>
            <Controller
                name={'country_id'}
                defaultValue={userProfile?.country_id ?? ''}
                control={tenantForm.control}
                render={({ field }) => (
                  <Autocomplete
                    theme={theme}
                    capitalizeLabel={true}
                    placeholder="Country"
                    label={messages.label.country}
                    onChange={(_, newValue: any) => {
                      field?.onChange(newValue?.id ?? '')
                      setCountry(getCountryById(newValue?.id ?? ''))
                    }}
                    value={{
                      id: field.value,
                      label: getCountryById(Number(field.value))?.name ?? ''
                    }}
                    items={countryOptions}
                    searchMatchFrom="start"
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12} md={6}>
              <Controller
                name="contact_number"
                control={tenantForm.control}
                rules={validation(
                  'Contact Number',
                  Validation.REQUIRED,
                  Validation.PHNONE_NUMBER,
                  Validation.WHITE_SPACE
                )}
                render={({ field }) => (
                  <ContactInput
                    phoneCountryCode={userProfile?.business_country_code ?? ''}
                    setCountryFlagCode={setBusinessCountryFlagCode}
                    countryFlagCode={businessCountryFlagCode}
                    country={country}
                    theme={theme}
                    type={'text'}
                    error={tenantForm?.formState?.errors?.contact_number?.message}
                    fullWidth
                    label="Business Contact Number"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12}>
              <Controller
                name="is_subscribed"
                control={tenantForm.control}
                defaultValue={isSubscribed}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    checkBoxChecked={value}
                    theme={theme}
                    label={
                      <>
                        <span>{messages.agreeNews}</span>
                      </>
                    }
                    onChange={onChange}
                    value={value}
                  />
                )
                } />

            </Grid>
          </Grid>
          {isTenantShow && (
            <Box theme={theme} className="form-cta">
              <Button
                disabled={updateTenantLoading}
                type="submit"
                theme={theme}
                primary
                text={
                  updateTenantLoading
                    ? messages.button.wait
                    : messages.button.save
                }
                startIcon={
                  updateTenantLoading && (
                    <img src={DisableLoader} alt="Loader" />
                  )
                }
              />
            </Box>
          )}
        </form>
      </SectionContent>

      <SectionContent
        theme={theme}
        className="section-content"
        mt={{ xs: 0, md: 4 }}
        pb={4}
        mb={4}
      >
        <Typography
          theme={theme}
          variant="h3"
          component="h3"
          className="text-h3"
          mb={3}
        >
          Billing Address
        </Typography>

        {// eslint-disable-next-line @typescript-eslint/no-misused-promises
        }<form onSubmit={billingForm.handleSubmit(onBillingSubmit)}
          onChange={() => { setIsBillingShow(true) }}
        >
          <Grid theme={theme} container item spacing={3}>
            <Grid theme={theme} item xs={12}>
              <Controller
                name="email"
                control={billingForm.control}
                rules={validation(
                  messages.validation.emailTo,
                  Validation.REQUIRED,
                  Validation.EMAIL
                )}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    fullWidth
                    label={messages.label.emailTo}
                    type={'text'}
                    placeholder={messages.placeholder.typeHere}
                    error={billingForm.formState.errors?.[
                      messages.name.emailTo
                    ]?.message?.toString()}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12}>
              <Controller
                name="billing_country_id"
                control={billingForm.control}
                rules={validation(messages.label.country, Validation.REQUIRED)}
                render={({ field: { onChange, value } }: any) => (
                  <Autocomplete
                    theme={theme}
                    capitalizeLabel={true}
                    label={messages.label.country}
                    onChange={(e, value: any) => {
                      onChange(value.id ?? '')
                      setCountryId((value).id)
                      setIsBillingShow(true)
                      billingForm.setValue('state_id', '')
                      billingForm.setValue('county_id', '')
                    }}
                    value={{
                      id: value,
                      label: getCountryById(Number(value))?.name ?? ''
                    }}
                    items={countryOptions}
                    searchMatchFrom="start"
                    error={billingForm?.formState?.errors?.billing_country_id?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12}>
              <Controller
                name="address_line_1"
                control={billingForm.control}
                rules={validation(
                  messages.validation.addressLine1,
                  Validation.REQUIRED
                )}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    fullWidth
                    label={messages.label.addressLine1}
                    type={'text'}
                    placeholder={messages.placeholder.typeHere}
                    {...field}
                    error={billingForm.formState.errors?.[
                      messages.name.addressLine1
                    ]?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12}>
              <Controller
                name="address_line_2"
                control={billingForm.control}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    fullWidth
                    label={messages.label.addressLine2}
                    type={'text'}
                    placeholder={messages.placeholder.typeHere}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12} sm={6} md={3}>
              <Controller
                name="city"
                control={billingForm.control}
                rules={validation(
                  messages.label.city,
                  Validation.REQUIRED,
                  Validation.WHITE_SPACE,
                  Validation.LIMIT_LENGTH,
                  '50'
                )}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    fullWidth
                    label={messages.label.city}
                    type={'string'}
                    placeholder={messages.placeholder.typeHere}
                    {...field}
                    error={billingForm.formState.errors?.city?.message?.toString()}
                  />
                )}
              />
            </Grid>

            {stateOptions?.length !== 0 && (
              <Grid theme={theme} item xs={12} sm={6} md={3}>
                <Controller
                  name="state_id"
                  control={billingForm.control}
                  defaultValue={userProfile?.state_id ?? ''}
                  rules={validation(messages.label.state, Validation.REQUIRED)}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      theme={theme}
                      label={messages.label.state}
                      items={stateOptions}
                      sxProps={false}
                      disablePortal={false}
                      selectError={billingForm.formState.errors?.state_id?.message?.toString()}
                      onChange={(e) => {
                        onChange(e)
                        setStateId(e.target.value as any)
                        billingForm.setValue('county_id', null)
                        setIsBillingShow(true)
                      }}
                      value={value}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid theme={theme} item xs={12} sm={6} md={3}>
              <Controller
                name="zip_code"
                control={billingForm.control}
                rules={validation('Zip code', Validation.REQUIRED)}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    fullWidth
                    maskNumeric
                    masking
                    format="#####"
                    label={messages.label.zipCode}
                    type={'true'}
                    placeholder="00000"
                    {...field}
                    error={billingForm.formState.errors?.zip_code?.message?.toString()}
                  />
                )}
              />
            </Grid>
            {stateOptions?.length !== 0 && (
              <Grid theme={theme} item xs={12} sm={6} md={3}>
                <Controller
                  name="county_id"
                  control={billingForm.control}
                  defaultValue={userProfile?.county_id ?? ''}
                  rules={validation('County', Validation.REQUIRED)}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      theme={theme}
                      label={messages.label.county}
                      items={countyOptions}
                      disablePortal={false}
                      sxProps={false}
                      selectError={billingForm.formState.errors?.county_id?.message?.toString()}
                      onChange={(e) => {
                        onChange(e)
                        setIsBillingShow(true)
                      }}
                      value={value}
                    />
                  )}
                />
              </Grid>
            )}

            {stateOptions?.length === 0 && (
              <Grid theme={theme} item xs={12} sm={6} md={3}>
                <Controller
                  name="state_name"
                  control={billingForm.control}
                  rules={validation(
                    messages.label.stateName,
                    Validation.REQUIRED,
                    Validation.LIMIT_LENGTH,
                    '50'
                  )}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      theme={theme}
                      fullWidth
                      label={messages.label.stateName}
                      type={'text'}
                      placeholder={messages.placeholder.typeHere}
                      error={billingForm.formState.errors?.state_name?.message?.toString()}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
          {isBillingShow && (
            <Box theme={theme} className="form-cta">
              <Button
                type="submit"
                theme={theme}
                primary
                disabled={updateBillingLoading || saveBillingLoading}
                text={
                  updateBillingLoading || saveBillingLoading
                    ? messages.button.wait
                    : messages.button.save
                }
                startIcon={
                  (updateBillingLoading || saveBillingLoading) && (
                    <img src={DisableLoader} alt="Loader" />
                  )
                }
              />
            </Box>
          )}
        </form>
      </SectionContent>

      <SectionContent
        theme={theme}
        className="section-content"
        mt={{ xs: 0, md: 4 }}
      >
        <Typography
          theme={theme}
          variant="h3"
          component="h3"
          className="text-h3"
        >
          Storage Path
        </Typography>
        <Typography
          theme={theme}
          variant="body2"
          component="span"
          mb={3}
          display={'block'}
          className="text-body2 text-muted"
        >
          These are your S3 credentials
        </Typography>
        {
          userHasS3Credentials && !isStorageShow
            ? (
                <Button
                  type="button"
                  theme={theme}
                  primary
                  text={messages.button.reset}
                  onClick={() => { setIsStorageShow(true) }}
                />
              )
            : isStorageShow || !userHasS3Credentials
              ? (
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              <form onSubmit={storageForm.handleSubmit(onStorageSubmit)}>
                <Grid theme={theme} container item spacing={3}>
                  <Grid theme={theme} item xs={12} md={6}>
                    <Controller
                      name="s3_access_key"
                      control={storageForm.control}
                      rules={validation(
                        'Access Key',
                        Validation.REQUIRED
                      )}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          fullWidth
                          type="text"
                          placeholder={messages.placeholder.typeHere}
                          label="Access Key"
                          autoComplete="off"
                          {...field}
                          error={storageForm.formState.errors?.[
                            messages.name.s3_access_key
                          ]?.message?.toString()}
                        />
                      )}
                    />
                  </Grid>
                  <Grid theme={theme} item xs={12} md={6}>
                    <Controller
                      name="s3_secret_access"
                      control={storageForm.control}
                      rules={validation(
                        'Secret Access',
                        Validation.REQUIRED
                      )}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          fullWidth
                          type="text"
                          placeholder={messages.placeholder.typeHere}
                          label="Secret Access"
                          autoComplete="off"
                          {...field}
                          error={storageForm.formState.errors?.[
                            messages.name.s3_secret_access
                          ]?.message?.toString()}
                        />
                      )}
                    />
                  </Grid>
                  <Grid theme={theme} item xs={12} md={6}>
                    <Controller
                      name="s3_bucket_name"
                      control={storageForm.control}
                      rules={validation('Bucket name', Validation.REQUIRED)}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          fullWidth
                          type="text"
                          autoComplete="off"
                          placeholder={messages.placeholder.typeHere}
                          label="Bucket Name"
                          {...field}
                          error={storageForm.formState.errors?.[
                            messages.name.s3_bucket_name
                          ]?.message?.toString()}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Box theme={theme} className="form-cta">
                  <Button
                    disabled={updateStorageLoading}
                    type="submit"
                    theme={theme}
                    primary
                    text={
                      updateStorageLoading
                        ? messages.button.wait
                        : messages.button.save
                    }
                    startIcon={
                      updateStorageLoading && (
                        <img src={DisableLoader} alt="Loader" />
                      )
                    }
                  />
                </Box>
              </form>
                )
              : null
        }
      </SectionContent>
    </>
  )
}
