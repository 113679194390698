import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const DialogContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.dilaog-content': {
    '.custom-checkbox-wrap': {
      '.u-custom-control-label': {
        marginBottom: 0
      }
    },
    '.u-form-group': {
      '.u-form-control': {
        '&.MuiInputBase-adornedEnd': {
          svg: {
            maxHeight: 24
          }
        }
      }
    }
  }
}))

export const AddNewCardButtons = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.new-card-buttons': {
    width: '100%',
    '.buttons': {
      width: '100%',
      display: 'flex',
      gap: '16px',
      '.MuiButton-root': {
        '&:not(:first-of-type)': {
          flexGrow: 1
        },
        '&:last-of-type': {
          width: '100%'
        }
      }
    }
  }
}))
