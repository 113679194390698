import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const ProductBox = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.product-box': {
    padding: '40px 24px',
    border: '1px solid' + theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out 0s',
    height: '100%',
    '.prd-link': {
      textDecoration: 'none',
      color: theme.palette.grey[900],
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      textAlign: 'center',
      '&:before': {
        display: 'block',
        content: "' '",
        backgroundColor: 'rgba(0, 51, 253, 0.1)',
        width: '100%',
        height: 96,
        position: 'absolute',
        top: 7,
        borderRadius: theme.shape.borderRadius
      },
      '.prd-bg': {
        display: 'block',
        backgroundColor: 'rgba(0, 51, 253, 0.1)',
        width: '100%',
        height: 96,
        position: 'absolute',
        top: 7,
        borderRadius: theme.shape.borderRadius,
        '&.bg-seagreen': {
          backgroundColor: '#CCFAF6'
        },
        '&.bg-lightskin': {
          backgroundColor: '#FDEFD9'
        },
        '&.bg-lightblue': {
          backgroundColor: '#D9E0FF'
        },
        '&.bg-lightpurple': {
          backgroundColor: 'rgba(121, 0, 130, 0.06)'
        }
      }
    },
    '.prd-img': {
      height: 120,
      marginBottom: 16,
      position: 'relative',
      img: {
        maxHeight: 120
      }
    },
    '.prd-name': {
      fontWeight: theme.customVariables.fontWeightSemiBold
    },
    '&:hover': {
      boxShadow: '0px 32px 32px -24px rgba(0, 26, 128, 0.25)'
    }
  }
}))
