import { colors } from '@modules/homePage/constants'
import { useTheme } from '@mui/material'

import { Box, Grid, Typography } from '@ntpkunity/controls'
import { type FC } from 'react'
import { type Feature } from '@shared/typings'

interface FeatureProps {
  feature: Feature
  index: number
}
export const FeatureComponent: FC<FeatureProps> = ({ feature, index }) => {
  const theme = useTheme()

  return (
    <Grid theme={theme} item xs={12} sm={6} lg={4} key={feature.name}>
      <Box theme={theme} className="feat-item">
        <Box theme={theme} className={`box-bg ${colors[index] as string}`}></Box>
        <Box theme={theme} className="feat-icon">
          <img src={feature.image_url} alt="feature" />
        </Box>
        <Box theme={theme} className="feat-content" pl={3} pr={3}>
          <Typography
            theme={theme}
            variant="h4"
            component="h4"
            className="text-h4 text-capitalize"
          >
            {feature.name}
          </Typography>
          <Typography
            theme={theme}
            variant="body2"
            component="span"
            className="feat-desc"
            mt={1}
            display={'block'}
          >
            {feature.description}
          </Typography>
        </Box>
      </Box>
    </Grid>
  )
}
