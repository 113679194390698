import { useTheme } from '@mui/material'
import { Box, Grid, Typography, Input, Button } from '@ntpkunity/controls'
import { WaitListWrap } from './joinWaitListStyle'
import JoinWaitListImage from '../../../../shared/assets/images/join-waitlist-img.svg'
import { Controller, type SubmitHandler, useForm } from 'react-hook-form'
import { validation } from '@shared/helper'
import { Validation } from '@shared/constants'
import { useJoinWaitList } from '@modules/homePage/services/waitList/waitListService'
import { useStoreContext } from 'store/storeContext'
import DisableLoader from '@shared/assets/images/loader-disabled-btn.gif'
import queryString from 'query-string'
import { type FC, useEffect } from 'react'
import { type IWaitList } from '@shared/typings'
import { FadeAnimation } from '@shared/components'

const messages = {
  name: {
    email: 'email'
  },
  button: {
    notifyMe: 'Notify Me'
  },
  placeholder: {
    email: 'Enter your email address...'
  },
  successMessage: "You've been added to our waitlist!"
}

interface IJoinWaitList {
  productId: number | undefined
}

export const JoinWaitList: FC<IJoinWaitList> = ({ productId }) => {
  const theme = useTheme()
  const { mutate: joinWaitList, isLoading, error } = useJoinWaitList()

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors }
  } = useForm<IWaitList>()

  useEffect(() => {
    if ((error ?? '').length > 0) {
      reset({ email: '' })
      setToaster({
        isDisplay: true,
        message: error,
        type: ''
      })
    }
  }, [error])

  const {
    actions: { setToaster }
  } = useStoreContext()

  const submit: SubmitHandler<any> = () => {
    const data = watch()
    if (productId) {
      data.product_id = +productId
    }
    joinWaitList(data, {
      onSuccess () {
        reset({ email: '' })
        setToaster({
          isDisplay: true,
          message: messages.successMessage,
          type: ''
        })
      }
    })
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(submit)}>
      <WaitListWrap theme={theme} className="waitlist-wrap">
      <Grid theme={theme} container item spacing={{ md: 5, xs: 5, lg: 15 }} alignItems={'center'} justifyContent={'center'}>
        <Grid theme={theme} item xs={12} md={4} lg={4}>
          <FadeAnimation>
            <Box theme={theme} className="img-area">
              <img src={JoinWaitListImage} alt="Join the waitlist" />
            </Box>
          </FadeAnimation>
        </Grid>
        <Grid theme={theme} item xs={12} md={8} lg={6} overflow={'hidden'}>
          <FadeAnimation>
            <Box theme={theme} className="section-content">
              <Typography
                display={'inline-block'}
                mb={{ xs: 2, md: 4 }}
                theme={theme}
                className="badge text-primary text-uppercase"
                variant="caption"
                component="small"
              >
              Launching Digital Onboarding Soon
              </Typography>
              <Typography
                theme={theme}
                variant="h2"
                component="h2"
                className="text-h2"
              >
              Want product access now?
              </Typography>
              <Typography
                theme={theme}
                className="text-muted text-body2"
                variant="body1"
                component="p"
                mt={1}
              >
              Please drop your email for our sales team to demonstrate the capabilities of the product as well as to onboard you.
              </Typography>
              <Box theme={theme} mt={{ xs: 2, md: 4 }} className="notify-form-wrap">
                <Controller
                  name={messages.name.email as 'email'}
                  control={control}
                  rules={validation(
                    'Email',
                    Validation.REQUIRED,
                    Validation.EMAIL
                  )}
                  defaultValue={''}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      fullWidth
                      type={messages.name.email}
                      placeholder={messages.placeholder.email}
                      {...field}
                    />
                  )}
                />
                  <Button theme={theme} disabled={isLoading} primary text={messages.button.notifyMe} type={'submit'} startIcon={ isLoading && (<img src={DisableLoader} alt="Loader" />)}/>
              </Box>
              <Typography mt={{ md: 1, xs: 2 }} theme={theme} variant="caption" component='small' display={'block'} className="text-error">{errors?.email?.message?.toString()}</Typography>
            </Box>
          </FadeAnimation>
        </Grid>
      </Grid>
    </WaitListWrap>
    </form>
  )
}
