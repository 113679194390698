import { useEffect, type ReactElement } from 'react'
import { useTheme } from '@mui/material'
import { BlogListingPageWrap } from './blogListingStyle'
import { Box, Container, Grid, Typography } from '@ntpkunity/controls'
import { HomePageLayout } from '@layout/index'
import { BlogListItemComponent } from '@modules/blog/components'
import { useGetAllBlogs } from '@modules/blog/services'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'
import { scrollOnTop } from '@shared/helper'

export const BlogListingPage = (): ReactElement => {
  const theme = useTheme()
  const navigate = useNavigate()
  const queryParams = '?is_latest=false'
  const { data: blogs } = useGetAllBlogs(queryParams)

  useEffect(() => {
    scrollOnTop()
  }, [])

  return (
    <HomePageLayout>
      <BlogListingPageWrap theme={theme} className='blog-listing-page-wrap'>
       <Container className='custom-container' theme={theme}>
        <Box theme={theme} className='page-head' textAlign={'center'}>
          <Typography
            display={'inline-block'}
            mb={2}
            theme={theme}
            className="badge text-primary text-uppercase"
            variant="caption"
            component="small"
          >
            OUR BLOG
          </Typography>
          <Typography
            theme={theme}
            className="text-h2"
            variant="h2"
            component="h2"
          >
            Resources and Insights
          </Typography>
          <Typography
            mt={2}
            theme={theme}
            className="text-body1 text-muted"
            variant="body1"
            component="p"
          >
            Learn about the industry news, interviews, new product features, the latest in technology, solutions, updates and resources.
          </Typography>
        </Box>
        <Box theme={theme} className='blog-list-items-wrap'>
          <Grid theme={theme} container item spacing={3} alignItems={'stretch'} justifyContent={'center'}>
            {
              blogs?.map((blog) => (
                <Grid key={blog.id} theme={theme} item xs={12} sm={6} md={4}>
                  <BlogListItemComponent
                    onClick={() => { navigate(`${APP_ROUTES.BLOGS}/${blog.title_url}`) }}
                    blog={blog}
                  />
                </Grid>
              ))
            }
          </Grid>
        </Box>
       </Container>
      </BlogListingPageWrap>
    </HomePageLayout>
  )
}
