import { type ICardDetail } from '@shared/typings'
import Http from '@shared/helper/http-api'
import { useMutation, useQueryClient } from 'react-query'

const BASE_URL = process.env.BASE_URL
export const CARD_PAYMENT = 'marketplace/user-management/create-customer-pay'
export const SUBSCRIBE_PRODUCT = 'marketplace/subscription/subscribe-product'
export const SUBSCRIPTIONS = 'marketplace/subscription'

interface IFreeUserCardPayment {
  data: ICardDetail
  subscriptionId: number
}

export const useFreeUserCardPayment = (): any => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate, error } = useMutation<
  IFreeUserCardPayment,
  any
  >(
    async (body) => {
      const { cardDetails, subscriptionId, productPricingId, add_ons: addOns } =
        body as any
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.put<any>(`${SUBSCRIBE_PRODUCT}/${subscriptionId as string}`, {
        card_details: cardDetails,
        price_id: productPricingId,
        add_ons: addOns
      })
    },
    {
      onSuccess () {
        void queryClient.invalidateQueries(SUBSCRIPTIONS)
      }
    }
  )
  return { data, isLoading, mutate, error: error?.message }
}

export const usePaidUserCardPayment = (): any => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate, error } = useMutation<ICardDetail, any>(
    async (body) => {
      const { cardDetail, productPricingId, add_ons: addOns } = body as any
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(`${CARD_PAYMENT}/${productPricingId as string}`, {
        card_details: cardDetail,
        add_ons: addOns
      })
    },
    {
      onSuccess () {
        void queryClient.invalidateQueries(SUBSCRIPTIONS)
      }
    }
  )
  return { data, isLoading, mutate, error: error?.message }
}
