import { Container, Typography } from '@ntpkunity/controls'
import { Header, Footer } from '../../../../layout'
import { useTheme } from '@mui/material'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { InjectedCheckoutForm } from '../../components/card/checkoutForm'
import { useLocation, useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'
import { type ReactElement, useEffect } from 'react'
import { scrollOnTop } from '@shared/helper/methods'
import { MainContent } from '../index'
import { getItem } from '@shared/helper'
import { PAID } from '@shared/constants'

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY as string)

export const messages = {
  heading: {
    paymentMethod: 'Payment Method',
    updatePaymentMethod: 'Update Payment Details'
  },
  label: {
    cardHolderName: 'Card Holder Name',
    cardNumber: 'Card Number',
    expiryDate: 'Expiry Date',
    cvc: 'CVC'
  },
  name: {
    cardHolderName: 'cardHolderName',
    cardNumber: 'number',
    expiryDate: 'expiry_date',
    cvc: 'cvc'
  },
  button: {
    pay: 'Pay',
    save: 'Save',
    wait: 'Wait...'
  },
  type: 'card'
}

export const PaymentPlan = (): ReactElement => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isPaymentPlan = pathname === APP_ROUTES.PAYMENT_PLAN

  useEffect(() => {
    scrollOnTop()
    if (getItem(PAID) !== undefined) {
      navigate(APP_ROUTES.CUSTOMER_ACCOUNT)
    }
  }, [])

  return (
    <>
      <Header theme={theme} />
      <MainContent theme={theme} className="main-content">
        <Container theme={theme} maxWidth="lg" className="custom-container">
          <Typography mb={4} theme={theme} variant="h2" component="h2">
            {isPaymentPlan
              ? messages.heading.paymentMethod
              : messages.heading.updatePaymentMethod}
          </Typography>
          <Elements stripe={stripePromise}>
            <InjectedCheckoutForm isPaymentPlan={isPaymentPlan} />
          </Elements>
        </Container>
      </MainContent>
      <Footer />
    </>
  )
}

PaymentPlan.propTypes = {}
