import { type ReactElement, useEffect, useState } from 'react'
import { useTheme, TablePagination } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import {
  Box,
  DataTable,
  Input,
  Icon,
  Typography,
  Button,
  Container
} from '@ntpkunity/controls'

import { dateFormat, filterKeys, QueryKeys } from '@shared/constants'
import { useQueryClient } from 'react-query'
import {
  UseQueryGetAllUserTypesByParams
} from './services/trackUserService'

import { type ITrackingUser } from '@shared/typings'
import { formatedDate } from '@shared/helper'
import { MainContent, TableWrap } from './trackUserStyle'
import { Header } from '@layout/components'
import { ChevronDown } from '@shared/assets/images/chevron-down'

export const TrackUmsUsersPage = (): ReactElement => {
  const theme = useTheme()

  const [filteredData, setFilteredData] = useState<ITrackingUser[]>([])
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [country, setCountry] = useState('')
  const [company, setCompanyName] = useState('')
  const [allowedTransactions, setAllowedTransaction] = useState<number>()
  const [transactionsPerformed, setPerformedTransactions] = useState<number>()
  const [status, setStatus] = useState('')
  const [apiType, setApiType] = useState('')
  const [product, setProduct] = useState('')
  const [pageNumber, setPageNumber] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [order, setOrder] = useState('ASC')
  const { mutate: GetFilter } = UseQueryGetAllUserTypesByParams()

  const queryClient = useQueryClient()
  const UserConditionFilter: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_USER_CONDITIONS_FILTER
  )

  useEffect(() => {
    setFilteredData((UserConditionFilter)?.result)
  }, [UserConditionFilter])

  useEffect(() => {
    const params = {
      pageNumber,
      pageSize,
      email,
      country,
      full_name: name,
      company_name: company,
      status,
      product_name: product,
      allowed_transactions: allowedTransactions ?? 0,
      transaction_performed: transactionsPerformed ?? 0,
      api_key_type: apiType
    }

    GetFilter(params)
  }, [pageNumber])

  const getFilteredData = (key: string, value: string): void => {
    let tempUsers = [...UserConditionFilter?.result]
    if (value?.trim() === '') {
      setFilteredData(UserConditionFilter?.result)
    } else {
      if (key === 'Full Name') {
        tempUsers = getByName(value)
      } else if (key === 'Email') {
        tempUsers = getByEmail(value)
      } else if (key === 'Country') {
        tempUsers = getByCountry(value)
      } else if (key === 'Allowed Transactions') {
        tempUsers = getTransactionsAllowed(value)
      } else if (key === 'Performed Transactions') {
        tempUsers = getTransactionPerformed(value)
      } else if (key === 'Company Name') {
        tempUsers = getByCompany(value)
      } else if (key === 'Status') {
        tempUsers = getbyStatus(value)
      } else if (key === 'Account Type') {
        tempUsers = getByAccount(value)
      } else if (key === 'Key Type') {
        tempUsers = getByApiType(value)
      } else if (key === 'Product Name') {
        tempUsers = getByProduct(value)
      }

      Object.keys(tempUsers).length > 0 &&
        setFilteredData(
          tempUsers.length > 0
            ? tempUsers
            : value !== ''
              ? []
              : UserConditionFilter?.result
        )
    }
  }
  const getByApiType = (value: string): ITrackingUser[] =>
    UserConditionFilter?.result.filter((user) =>
      user.api_key_type?.toLowerCase().includes(value?.toLowerCase())
    )

  const getByProduct = (value: string): ITrackingUser[] =>
    UserConditionFilter?.result.filter((user) =>
      user.product_name?.toLowerCase().includes(value?.toLowerCase())
    )

  const getTransactionPerformed = (value: string): ITrackingUser[] =>
    UserConditionFilter?.result.filter((user) =>
      user.transaction_performed?.toString().includes(value)
    )
  const getTransactionsAllowed = (value: string): ITrackingUser[] =>
    UserConditionFilter?.result.filter((user) =>
      user.allowed_transactions?.toString().includes(value)
    )

  const getByAccount = (value: string): ITrackingUser[] =>
    UserConditionFilter?.result.filter((user) =>
      user.account_type?.toLowerCase().includes(value?.toLowerCase())
    )
  const getbyStatus = (value: string): ITrackingUser[] =>
    UserConditionFilter?.result.filter((user) =>
      user.status?.toLowerCase().includes(value?.toLowerCase())
    )
  const getByCompany = (value: string): ITrackingUser[] =>
    UserConditionFilter?.result.filter((user) =>
      user.company_name?.toLowerCase().includes(value?.toLowerCase())
    )
  const getByCountry = (value: string): ITrackingUser[] =>
    UserConditionFilter?.result.filter((user) =>
      user.country?.toLowerCase().includes(value?.toLowerCase())
    )
  const getByName = (value: string): ITrackingUser[] =>
    UserConditionFilter?.result.filter((user) =>
      user.full_name?.toLowerCase().includes(value?.toLowerCase())
    )
  const getByEmail = (value: string): ITrackingUser[] =>
    UserConditionFilter?.result.filter((user) =>
      user.email?.toLowerCase().includes(value?.toLowerCase())
    )

  const handleChangePage = (_event: unknown, newPage: number): void => {
    setPageNumber(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setPageSize(+event.target.value)
    setPageNumber(0)
    const params = {
      pageNumber,
      pageSize: +event.target.value,
      email,
      country,
      full_name: name,
      company_name: company,
      status,
      product_name: product,
      allowed_transactions: allowedTransactions ?? 0,
      transaction_performed: transactionsPerformed ?? 0,
      api_key_type: apiType
    }

    GetFilter(params)
  }

  const sorting = (sortingdata): void => {
    if (order === 'ASC') {
      const data = [...filteredData].sort((a, b) =>
        a[sortingdata] > b[sortingdata] ? 1 : -1
      )
      setFilteredData(data)
      setOrder('DSC')
    }
    if (order === 'DSC') {
      const data = [...filteredData].sort((a, b) =>
        a[sortingdata] < b[sortingdata] ? 1 : -1
      )
      setFilteredData(data)
      setOrder('ASC')
    }
  }

  return (
    <>
      <Header theme={theme} />
      <MainContent theme={theme} className="main-content">
        <Container theme={theme} maxWidth="lg" className="custom-container">
          <Typography
            theme={theme}
            variant="h3"
            component="h3"
            className="text-h3"
          >
            Users Tracking
          </Typography>
          <TableWrap theme={theme} mt={4} className="table-wrap">
            <DataTable
              theme={theme}
              variant="basic"
              theadChildren={
                <>
                  <TableRow className="has-sorting">
                    <TableCell>
                      Full Name
                      <span className="sort-action">
                        <Button
                          theme={theme}
                          onClick={() => { sorting('full_name') }}
                          iconText={<Icon name="ArrowUpDown" />}
                        />
                      </span>
                    </TableCell>
                    <TableCell className="cell-md">
                      Email
                      <span className="sort-action">
                        <Button
                          theme={theme}
                          onClick={() => { sorting('email') }}
                          iconText={<Icon name="ArrowUpDown" />}
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      Product Name
                      <span className="sort-action">
                        <Button
                          theme={theme}
                          onClick={() => { sorting('product_name') }}
                          iconText={<Icon name="ArrowUpDown" />}
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      Country
                      <span className="sort-action">
                        <Button
                          theme={theme}
                          onClick={() => { sorting('country') }}
                          iconText={<Icon name="ArrowUpDown" />}
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      Company Name
                      <span className="sort-action">
                        <Button
                          theme={theme}
                          onClick={() => { sorting('company_name') }}
                          iconText={<Icon name="ArrowUpDown" />}
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      Allowed Transactions
                      <span className="sort-action">
                        <Button
                          theme={theme}
                          onClick={() => { sorting('allowed_transactions') }}
                          iconText={<Icon name="ArrowUpDown" />}
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      Performed Transactions
                      <span className="sort-action">
                        <Button
                          theme={theme}
                          onClick={() => { sorting('transaction_performed') }}
                          iconText={<Icon name="ArrowUpDown" />}
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      Key Type
                      <span className="sort-action">
                        <Button
                          theme={theme}
                          onClick={() => { sorting('api_key_type') }}
                          iconText={<Icon name="ArrowUpDown" />}
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      Status
                      <span className="sort-action">
                        <Button
                          theme={theme}
                          onClick={() => { sorting('status') }}
                          iconText={<Icon name="ArrowUpDown" />}
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      Created At
                      <span className="sort-action">
                        <Button
                          theme={theme}
                          onClick={() => { sorting('created_at') }}
                          iconText={<Icon name="ArrowUpDown" />}
                        />
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow className="filters-row">
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth
                          type="text"
                          placeholder="Search..."
                          value={name}
                          onChange={(e) => {
                            setName(e)
                            getFilteredData(filterKeys.full_name, e)
                          }}
                        ></Input>
                        <Button
                          defaultBtn
                          theme={theme}
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell className="cell-md">
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth
                          type="text"
                          placeholder="Search..."
                          value={email}
                          onChange={(e) => {
                            setEmail(e)
                            getFilteredData(filterKeys.email, e)
                          }}
                        ></Input>
                        <Button
                          defaultBtn
                          theme={theme}
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell className="cell-md">
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth
                          type="text"
                          placeholder="Search..."
                          value={product}
                          onChange={(e) => {
                            setProduct(e)
                            getFilteredData(filterKeys.product_name, e)
                          }}
                        ></Input>
                        <Button
                          defaultBtn
                          theme={theme}
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth
                          type="text"
                          placeholder="Search..."
                          value={country}
                          onChange={(e) => {
                            setCountry(e)
                            getFilteredData(filterKeys.country, e)
                          }}
                        ></Input>
                        <Button
                          defaultBtn
                          theme={theme}
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth
                          type="text"
                          placeholder="Search..."
                          value={company}
                          onChange={(e) => {
                            setCompanyName(e)
                            getFilteredData(filterKeys.company_name, e)
                          }}
                        ></Input>
                        <Button
                          defaultBtn
                          theme={theme}
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth
                          type="text"
                          placeholder="Search..."
                          value={allowedTransactions}
                          onChange={(e) => {
                            setAllowedTransaction(e)
                            getFilteredData(
                              filterKeys.allowed_transactions,
                              e
                            )
                          }}
                        ></Input>
                        <Button
                          defaultBtn
                          theme={theme}
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth
                          type="text"
                          placeholder="Search..."
                          value={transactionsPerformed}
                          onChange={(e) => {
                            setPerformedTransactions(e)
                            getFilteredData(
                              filterKeys.transaction_performed,
                              e
                            )
                          }}
                        ></Input>
                        <Button
                          defaultBtn
                          theme={theme}
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth
                          type="text"
                          placeholder="Search..."
                          value={apiType}
                          onChange={(e) => {
                            setApiType(e)
                            getFilteredData(filterKeys.api_key_type, e)
                          }}
                        ></Input>
                        <Button
                          defaultBtn
                          theme={theme}
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth
                          type="text"
                          placeholder="Search..."
                          value={status}
                          onChange={(e) => {
                            setStatus(e)
                            getFilteredData(filterKeys.status, e)
                          }}
                        ></Input>
                        <Button
                          defaultBtn
                          theme={theme}
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>

                    <TableCell></TableCell>
                  </TableRow>{' '}
                </>
              }
              tbodyChildren={
                <>
                  {
                    filteredData?.map((user: ITrackingUser, index: number) => {
                      const format = formatedDate(user.created_at, dateFormat)

                      return (
                        <TableRow key={index}>
                          <TableCell>{user.full_name}</TableCell>
                          <TableCell className="cell-md">
                            {user.email}
                          </TableCell>
                          <TableCell>{user.product_name}</TableCell>
                          <TableCell>{user.country}</TableCell>
                          <TableCell>{user.company_name}</TableCell>
                          <TableCell>{user.allowed_transactions}</TableCell>
                          <TableCell>{user.transaction_performed}</TableCell>
                          <TableCell>{user.api_key_type}</TableCell>
                          <TableCell>{user.status}</TableCell>
                          <TableCell>{`${format}\u00A0\u00A0\u00A0${user.created_at
                            .split('T')[1]
                            .slice(0, -7)}`}</TableCell>
                        </TableRow>
                      )
                    })}
                </>
              }
            />
          </TableWrap>
          {Object.keys(UserConditionFilter).length > 0 && (
            <TablePagination
              rowsPerPageOptions={[25, 50, 75, 100]}
              component="div"
              count={
                Object.keys(UserConditionFilter).length > 0 ? UserConditionFilter?.total_results : 0
              }
              rowsPerPage={pageSize}
              page={pageNumber}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              SelectProps={{
                IconComponent: ChevronDown
              }}
            />
          )}
        </Container>
      </MainContent>
    </>
  )
}
