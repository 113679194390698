import { type FC } from 'react'
import { Box, type IBoxProps, Icon } from '@ntpkunity/controls'
import Carousel from 'react-multi-carousel'
import { IconButton, styled, useTheme } from '@mui/material'
import '../ResponsiveCarousel/ResponsiveCarousel.css'

export const CarouselButtons = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.carousel-button-group': {
    '.carousel-control': {
      position: 'absolute',
      backgroundColor: theme.palette.common.white,
      border: '1px solid' + theme.palette.grey[100],
      width: 48,
      height: 48,
      marginTop: -35,
      top: '50%',
      borderRadius: '50%',
      transform: 'translateY(-50%)',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      },
      '&.next-btn': {
        right: -12,
        transform: 'translatey(-50%) rotate(180deg)'
      },
      '&.prev-btn': {
        left: -12
      }
    }
  }
}))

interface IResponsiveCarouselProps {
  children: any
  infinite?: boolean
  removeArrowOnDeviceType?: string | string[]
  keyBoardControl?: boolean
  containerClass?: string
  itemClass?: string
  slidesToSlide?: number
  autoPlay?: boolean
  responsive: ResponsiveType
  showDots?: boolean
  customDot?: React.ReactElement<any> | null
  autoPlaySpeed?: number
  centerMode?: boolean
}
export type ResponsiveType = Record<
string,
{
  breakpoint: {
    max: number
    min: number
  }
  items: number
  partialVisibilityGutter?: number
  paritialVisibilityGutter?: number
  slidesToSlide?: number
}
>

interface CarouselButtonGroupProps {
  next: any
  previous: any
}

const CarouselButtonGroup: FC<CarouselButtonGroupProps> = ({
  next,
  previous
}) => {
  const theme = useTheme()
  return (
    <CarouselButtons theme={theme} className="carousel-button-group">
      <IconButton
        className="prev-btn carousel-control"
        onClick={() => previous()}
      >
        <Icon name="IconLeftArrow" />
      </IconButton>
      <IconButton className="next-btn carousel-control" onClick={() => next()}>
        <Icon name="IconLeftArrow" />
      </IconButton>
    </CarouselButtons>
  )
}

export const ResponsiveCarousel: FC<IResponsiveCarouselProps> = ({
  children,
  infinite = true,
  removeArrowOnDeviceType = ['xl', 'lg', 'md', 'sm', 'xs'],
  keyBoardControl = true,
  containerClass = 'carousel-container',
  itemClass = 'carousel-item',
  slidesToSlide = 1,
  autoPlay = true,
  responsive,
  showDots = false,
  customDot,
  autoPlaySpeed = 5000,
  centerMode = false
}) => {
  return (
    <Carousel
      swipeable={true}
      draggable={true}
      showDots={showDots}
      responsive={responsive}
      infinite={infinite}
      autoPlay={autoPlay}
      autoPlaySpeed={autoPlaySpeed}
      keyBoardControl={keyBoardControl}
      containerClass={containerClass}
      removeArrowOnDeviceType={removeArrowOnDeviceType}
      itemClass={itemClass}
      slidesToSlide={slidesToSlide}
      renderButtonGroupOutside={true}
      additionalTransfrom={0}
      customDot={customDot}
      renderDotsOutside={true}
      centerMode={centerMode}
      customButtonGroup={<CarouselButtonGroup next={{}} previous={{}} />}
    >
      {children}
    </Carousel>
  )
}
