import { Typography, Box, Button, Icon } from '@ntpkunity/controls'
import { HelpBoxWrap } from './helpBoxStyle'
import { useTheme } from '@mui/material'
import { useModal } from 'react-modal-hook'
import { CalendlyPopupComponent } from '@layout/dashboard'
import { type FC } from 'react'

export const HelpBox: FC = () => {
  const theme = useTheme()

  const [showCalendlyPopup, hideCalendlyPopup] = useModal(
    () => <CalendlyPopupComponent hide={hideCalendlyPopup} />,
    []
  )

  return (
    <HelpBoxWrap theme={theme} className="help-box">
      <Box theme={theme} className="help-icon">
        <Icon name="GetHelpIcon" />
      </Box>
      <Box className="box-content" theme={theme} textAlign={'center'}>
        <Typography
          theme={theme}
          variant="body1"
          component="p"
          className="fw-sb"
        >
          Need Help?
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="body2"
          component="span"
          display={'block'}
          mt={1}
        >
          Our support team is at your disposal 24/7
        </Typography>
        <Box theme={theme} className="cta-wrap" mt={3}>
          <Button
            secondary
            theme={theme}
            text="Get Help"
            fullWidth
            onClick={() => { showCalendlyPopup() }}
          />
        </Box>
      </Box>
    </HelpBoxWrap>
  )
}
