import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const InvoicePreview = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.invoice-preview': {
    border: '1px solid' + theme.palette.grey[100],
    borderRadius: 16,
    padding: 40,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      top: -68,
      marginLeft: 30
    },
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.invoice-items': {
      '.item': {
        borderBottom: '1px solid' + theme.palette.grey[100],
        paddingBottom: 16,
        marginBottom: 16,
        '.prd-price': {
          float: 'right'
        },
        '.prd-name': {
          display: 'block',
          overflow: 'hidden',
          marginRight: '16px'
        },
        '&.item-total': {
          marginBottom: 24,
          '.prd-price': {
            display: 'flex',
            alignItems: 'center',
            h3: {
              marginLeft: 4
            },
            '.loader-wrap': {
              '.loader': {
                width: 70,
                height: 70,
                left: 'auto',
                transform: 'none',
                right: -18,
                top: -20,
                img: {
                  maxHeight: 70
                }
              }
            }
          },
          '.prd-name': {
            span: {
              lineHeight: theme.typography.h3.lineHeight
            }
          }
        }
      }
    }
  }
}))

export const InvoicePreviewMobile = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.invoice-preview-mobile': {
    borderBottom: '1px solid' + theme.palette.grey[100],
    position: 'relative',
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.invoice-items': {
      '.item': {
        borderBottom: '1px solid' + theme.palette.grey[100],
        paddingBottom: 16,
        marginBottom: 16,
        '.prd-price': {
          float: 'right'
        },
        '.prd-name': {
          display: 'block',
          overflow: 'hidden',
          marginRight: '16px'
        }
      }
    },
    '.u-accordian-wrap': {
      marginBottom: 16,
      '.main-title': {
        fontWeight: theme.typography.fontWeightRegular
      },
      '.u-accordion-header': {
        '.sub-title': {
          '.MuiTypography-h4': {
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }
        }
      },
      '.u-accordian-body': {
        '&.filter-accordion': {
          maxHeight: '100%',
          overflowY: 'visible',
          borderBottom: 'none'
        }
      }
    }
  }
}))
