import { type ICustomerProfile } from '@shared/typings'
import Http from '@shared/helper/http-api'
import { useMutation, useQuery } from 'react-query'

const BASE_URL = process.env.BASE_URL
const COUNTRIES = 'marketplace/countries'
const STATES = 'marketplace/states/'
const STATES_BY_COUNTRY_ID = 'marketplace/states/state_by_country_id'
const INDUSTRY_TYPES = 'marketplace/industry-type'
const AGREE_TO_TERMS_CONDITIONS = 'marketplace/user-management/agree-to-tos'
const REGISTRATION = 'marketplace/user-management/create-profile'
const COUNTY = 'ums/counties'

export const useRegistration = (): any => {
  const url = REGISTRATION
  const { data, isLoading, mutate, error } = useMutation<ICustomerProfile, any>(
    async (body) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(url, body)
    }
  )
  return { data, isLoading, mutate, error: error?.message }
}

export const useGetCountries = (): any => {
  const { data, error } = useQuery(
    COUNTRIES,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(COUNTRIES)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error }
}

export const useGetStates = (): any => {
  const { data, error } = useQuery(
    STATES,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(STATES)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error }
}

export const useGetCounties = (): any => {
  const { data, error } = useQuery(
    COUNTY,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(COUNTY)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error }
}

// TODO integrate in registration
export const useGetStatesByCountryId = (countryId: number | undefined): any => {
  const url = `${STATES_BY_COUNTRY_ID}/${countryId ?? ''}`
  const { data, error, isLoading } = useQuery(
    [STATES_BY_COUNTRY_ID, countryId],
    async () => {
      if (countryId !== undefined) {
        const apiService = Http.createConnection({
          baseUrl: BASE_URL,
          withAuthentication: true
        })
        return await apiService.get<any>(url)
      }
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error, isLoading }
}

export const useGetIndustryTypes = (): any => {
  const { data, error } = useQuery(
    INDUSTRY_TYPES,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(INDUSTRY_TYPES)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error }
}

export const useAgreeToTermsOfServices = (): any => {
  const { data, isLoading, mutate, error } = useMutation<any, any>(async () => {
    const apiService = Http.createConnection({
      baseUrl: BASE_URL,
      withAuthentication: true
    })
    return await apiService.post<any>(AGREE_TO_TERMS_CONDITIONS, {})
  })
  return { data, isLoading, mutate, error: error?.message }
}

export const useGetCountyByStateId = (stateId: string | number | undefined): any => {
  const getCountyByStateId = `${COUNTY}/${stateId ?? ''}`
  const { data, error, isLoading } = useQuery<any>(
    [COUNTY, stateId],
    async () => {
      if (stateId !== undefined) {
        const apiService = Http.createConnection({
          baseUrl: BASE_URL,
          withAuthentication: true
        })
        return await apiService.get<any>(getCountyByStateId)
      }
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error, isLoading }
}
