import { type FC, useState } from 'react'
import { useTheme } from '@mui/material'
import { useModal } from 'react-modal-hook'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'
import ScrollIcon from '@shared/assets/images/scroll-icon.gif'
import { Authentication } from '@modules/authentication/authentication'
import { ScrollableContainer, ScrollIconWrap } from './termsAndConditionStyle'
import {
  ConfirmationDialog,
  getItem,
  TermsAndConditions as Terms,
  AccountType,
  ButtonType,
  DialogHeading,
  IconType,
  setItem
} from 'shared'
import {
  Box,
  Button,
  Typography,
  LinearProgressBar,
  Grid
} from '@ntpkunity/controls'
import {
  freeConfirmationMessage,
  className,
  HAS_AGREED_TO_TERMS,
  ACCOUNT_TYPE,
  PRODUCT_PRICING_ID,
  STRIPE_USER_CREATED,
  IS_LOGGED_IN,
  PRODUCT_ID
} from '@shared/constants'
import { useAgreeToTermsOfServices } from '@modules/authentication'
import { useCreateFreeSubscription } from '@modules/product'
import { useStoreContext } from 'store/storeContext'
import DisableLoader from '@shared/assets/images/loader-disabled-btn.gif'

export const TermsAndConditions: FC = () => {
  const {
    states: { userProfile },
    actions: { setUserProfile }
  } = useStoreContext()

  const hasAggreedToTerms = userProfile?.[HAS_AGREED_TO_TERMS]
  const accountType = userProfile?.[ACCOUNT_TYPE]
  const [isDisable, setIsDisable] = useState<boolean>(true)
  const [isScrollIcon, setIsScrollIcon] = useState<boolean>(true)
  const { mutate: agreeToTermOfServices } = useAgreeToTermsOfServices()
  const { mutate: createFreeSubscription, isLoading } =
    useCreateFreeSubscription()
  const theme = useTheme()
  const navigate = useNavigate()
  const handleScroll = (e): void => {
    setIsScrollIcon(false)
    const bottom =
      e.target.scrollHeight - Math.ceil(e.target.scrollTop) <=
      e.target.clientHeight
    if (bottom) {
      setIsDisable(false)
    }
  }

  const onConfirm = (): void => { navigate(APP_ROUTES.CUSTOMER_ACCOUNT) }

  const [show] = useModal(
    () => (
      <ConfirmationDialog
        btnClassName={className.freeSubscriptionConfirmation}
        buttonText={ButtonType.CONTINUE}
        iconName={IconType.SUCCESS}
        title={DialogHeading.CONGRATULATIONS}
        message={freeConfirmationMessage}
        onConfirm={onConfirm}
      />
    ),
    []
  )

  const onAgree = (): void => {
    agreeToTermOfServices(
      {},
      {
        onSuccess () {
          setUserProfile({
            ...userProfile,
            has_agreed_to_terms: true
          })
          setItem(IS_LOGGED_IN, true)
          if ((accountType ?? '').toLowerCase() === AccountType.FREE) {
            createFreeSubscription(
              {
                productPricingId: +getItem(PRODUCT_PRICING_ID)
              },
              {
                onSuccess () {
                  setItem(STRIPE_USER_CREATED, true)
                  show()
                }
              }
            )
          } else {
            navigate(APP_ROUTES.PAYMENT_PLAN, {
              state: {
                accountType: AccountType.PAID,
                packageId: getItem(PRODUCT_PRICING_ID),
                productId: getItem(PRODUCT_ID)
              }
            })
          }
        }
      }
    )
  }

  const messages = {
    button: {
      termAndCond: 'I Agree & Continue',
      wait: 'Wait...',
      scroll: 'Scroll Down to Agree & Continue'
    },
    steps: '75% Completed'
  }

  return (
    <Authentication>
      <>
        <Typography mb={1} theme={theme} variant="h2" component="h2">
          Terms and Conditions of Use and End User License Agreement
        </Typography>
        <ScrollableContainer theme={theme} className="inner-scrollable-area">
          <Box
            theme={theme}
            className="viewport"
            onScroll={(e) => { handleScroll(e) }}
            touchmove={(e) => { handleScroll(e) }}
          >
            <Terms />
            {isScrollIcon && (
              <ScrollIconWrap theme={theme} className="scroll-icon">
                <img src={ScrollIcon} alt="Scroll" />
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                >
                  Scroll
                </Typography>
              </ScrollIconWrap>
            )}
          </Box>
        </ScrollableContainer>
        <Button
          className={className.agreeAndContinueBtn}
          primary
          theme={theme}
          text={
            isLoading
              ? messages.button.wait
              : !isScrollIcon && !isDisable
                  ? messages.button.termAndCond
                  : messages.button.scroll
          }
          disabled={isDisable || isLoading}
          onClick={onAgree}
          startIcon={
            isLoading && (
              <img src={DisableLoader} alt="Loader" />
            )
          }
        ></Button>
        {!(hasAggreedToTerms ?? false) && (
          <>
            <Box theme={theme} className="progress-steps">
              <Grid
                theme={theme}
                container
                item
                spacing={1}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Grid theme={theme} item xs={4}>
                  <Box theme={theme} className="previous-step progress-step">
                    <LinearProgressBar theme={theme} value={100} />
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={4}>
                  <Box theme={theme} className="previous-step progress-step">
                    <LinearProgressBar theme={theme} value={100} />
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={4}>
                  <Box theme={theme} className="active-step progress-step">
                    <LinearProgressBar theme={theme} value={0} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Typography
              theme={theme}
              variant="caption"
              component="small"
              display={'block'}
              textAlign={'center'}
              mt={2}
            >
              {messages.steps}
            </Typography>
          </>
        )}
      </>
    </Authentication>
  )
}
