import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.main-content': {
    padding: '32px 24px 50px',
    height: '100vh',
    [theme.breakpoints.up('md')]: {
      paddingTop: 75,
      paddingBottom: 0
    },
    '.custom-container': {
      height: '100%',
      [theme.breakpoints.up('md')]: {
        paddingLeft: 32,
        paddingRight: 32
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    '.text-danger': {
      color: theme.palette.error.main
    },
    '.full-page-wrap': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto',
      maxWidth: 480
    }
  }
}))
