export const ACCESS_TOKEN_KEY = 'access_token'
export const REFRESH_TOKEN_KEY = 'refresh_token'
export const PRODUCT_PRICING_ID = 'product_pricing_id'
export const USER_NAME = 'user_name'
export const FIRST_NAME = 'first_name'
export const LAST_NAME = 'last_name'
export const COMPANY_NAME = 'company_name'
export const IS_LOGGED_IN = 'is_logged_in'
export const IS_EXISTING_USER = 'is_existing_user'
export const HAS_AGREED_TO_TERMS = 'has_agreed_to_terms'
export const ACCOUNT_TYPE = 'account_type'
export const PRODUCT_ID = 'product_id'
export const ROLE_ID = 'role_id'
export const ROLE = 'role'
export const EMAIL = 'email'
export const PAID = 'paid'
export const PACKAGE_ID = 'package_id'
export const TENANT_ID = 'tenant_id'
export const STRIPE_USER_CREATED = 'stipe_user_created'
export const FREE_TRIAL_PRODUCT = 'free_trial_product'
export const CART_DATA = 'carts'
export const CURRENT_ROUTE = 'currentRoute'
export const IS_CHECKOUT = 'isCheckout'
export const TERMLY_API_CACHE = 'TERMLY_API_CACHE'
export const SIGNUP_STEPS = 'signup_steps'
