import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const UnsubPopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.unsub-popup-wrap': {
    '.u-dialog': {
      '&.u-dialog-sm': {
        '.MuiDialog-paper': {
          maxWidth: 520
        }
      },
      '.u-dialog-content': {
        '&.has-no-footer': {
          [theme.breakpoints.up('md')]: {
            padding: 60
          }
        },
        [theme.breakpoints.up('md')]: {
          padding: '60px 60px 0 60px'
        }
      }
    }
  }
}))

export const DialogActionArea = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.dialog-action-area': {
    width: '100%',
    '.btn': {
      '&:not(:first-of-type)': {
        width: 'auto !important',
        flexShrink: 0
      }
    },
    '.buttons': {
      width: '100%',
      display: 'flex',
      gap: '16px',
      '.MuiButton-root': {
        '&:not(:first-of-type)': {
          [theme.breakpoints.down('md')]: {
            flexGrow: 1
          }
        },
        '&:last-of-type': {
          [theme.breakpoints.down('md')]: {
            width: '100%'
          }
        }
      }
    }
  }
}))
