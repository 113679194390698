import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const PaymentCardWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.cards-area-wrap': {
    '.card-item': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      border: '1px solid' + theme.palette.grey[100],
      padding: 24,
      width: 360,
      marginRight: 24,
      borderRadius: 8,
      flexShrink: 0,
      '&.item-add-new': {
        border: '2px dashed' + theme.palette.grey[100],
        width: '100%',
        flex: '0 1 auto',
        minWidth: 150,
        '.btn-default': {
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          color: theme.palette.grey[600],
          '.MuiButton-startIcon': {
            marginRight: 0,
            marginBottom: 4
          }
        }
      },
      '&:last-child': {
        marginRight: 0
      },
      '.card-icon': {
        maxHeight: 48,
        marginRight: 24,
        minHeight: 48,
        backgroundColor: '#F8F8F8',
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 75,
        padding: '0 15px',
        img: {
          maxHeight: 48
        },
        svg: {
          maxHeight: 26,
          minHeight: 13
        }
      },
      '.card-info': {
        flexGrow: 1,
        '.hide-text': {
          marginRight: 10,
          lineHeight: 0,
          '.text-dot': {
            width: 4,
            height: 4,
            display: 'inline-block',
            borderRadius: '50%',
            backgroundColor: theme.palette.grey[900],
            marginLeft: 2,
            marginRight: 2
          }
        }
      },
      '.card-cta': {
        flexShrink: 0,
        '.btn': {
          '&.with-icon': {
            '&.icon-only': {
              padding: 0
            }
          },
          '&:hover': {
            svg: {
              path: {
                stroke: theme.palette.error.main
              }
            },
            '&:focus': {
              svg: {
                path: {
                  stroke: theme.palette.error.main
                }
              }
            }
          },
          '&:focus': {
            svg: {
              path: {
                stroke: theme.palette.grey[300]
              }
            }
          }
        }
      },
      '.badge-wrap': {
        display: 'block'
      },
      '.card-badge': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: 16,
        padding: '4px 12px',
        position: 'absolute',
        top: -12,
        right: 24,
        zIndex: 3,
        fontSize: 10,
        textTransform: 'uppercase',
        fontWeight: theme.customVariables.fontWeightSemiBold,
        '&.mark-default': {
          backgroundColor: theme.palette.common.white,
          border: '1px solid' + theme.palette.grey[100],
          color: theme.palette.grey[600]
        }
      }
    },
    '.scrollable-area': {
      paddingTop: 24,
      paddingBottom: 24,
      paddingRight: 1,
      display: 'flex',
      flexWrap: 'nowrap',
      overflowX: 'auto',
      scrollbarWidth: 'none',
      userSelect: 'none',
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0
      },
      '&::-webkit-scrollbar-track': {
        padding: '0',
        backgroundColor: 'transparent'
      },
      '&::-webkit-scrollbar-thumb': {
        transition: 'all 0.3s',
        backgroundColor: 'transparent'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'transparent'
      }
    }
  }
}))

export const InfoCaption = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  width: '100%',
  clear: 'both',
  display: 'flex',
  color: theme.palette.grey[600],
  svg: {
    width: 30,
    marginRight: 8,
    flexShrink: 0,
    path: {
      stroke: theme.palette.primary.main
    }
  }
}))
