import { Box, Typography } from '@ntpkunity/controls'
import {
  ProductSlider,
  FeaturedProducts
} from '../../pages/homePage/homePageStyle'
import {
  ResponsiveCarousel,
  type ResponsiveType
} from '@shared/components/ResponsiveCarousel/ResponsiveCarousel'
import { useTheme } from '@mui/material'
import { type FC, useState } from 'react'
import { useModal } from 'react-modal-hook'
import { ProductDemoVideoComponent } from '../productDemoVideo/productDemoVideo'
import { NoDataFound } from '@modules/homePage/pages'
import { type IProductDetail } from '@shared/typings'
import PlayIcon from '../../../../shared/assets/images/ic-play-circle.svg'
import { FadeAnimation } from '@shared/components'

interface BestOfAppexNowProps {
  productDetail: IProductDetail[]
  responsive: ({ itemXl, itemLg, itemMd, itemSm, itemXs }) => ResponsiveType
}

export const BestOfAppexNow: FC<BestOfAppexNowProps> = ({
  productDetail,
  responsive
}) => {
  const theme = useTheme()
  const [videoUrl, setVideoUrl] = useState<string>('')

  const [showDemoVideo, hideDemoVideo] = useModal(
    () => (
      <ProductDemoVideoComponent hide={hideDemoVideo} videoLink={videoUrl} />
    ),
    [productDetail, videoUrl]
  )

  const getThumbnail = (src: string): string | undefined => {
    const youtubeVideoId = src
      ?.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)
      ?.pop()

    if (youtubeVideoId?.length === 11) {
      const videoThumbnail = `//img.youtube.com/vi/${youtubeVideoId}/0.jpg`
      return videoThumbnail
    }
  }

  return (
    <FeaturedProducts theme={theme} className="feature-products">
      <FadeAnimation>
      <Box theme={theme} className="section-head" mb={2}>
        <Typography
          theme={theme}
          variant="h3"
          component="h3"
          flexGrow={1}
          className="text-h3"
        >
          Get the Best of Appex Now
        </Typography>
      </Box>
      </FadeAnimation>
      <FadeAnimation>
      <ProductSlider theme={theme} className="product-slider">
        {productDetail?.length > 0
          ? (
          <ResponsiveCarousel
            responsive={responsive({
              itemXl: 4,
              itemLg: 4,
              itemMd: 3,
              itemSm: 2.04,
              itemXs: 1.03
            })}
          >
            {productDetail?.map(
              (product) =>
                product?.video_url?.length > 0 && (
                  <Box
                    key={product.id}
                    theme={theme}
                    className="feat-prod-item"
                  >
                    <a className="prd-link">
                      <Box
                        theme={theme}
                        className="prd-img"
                        mb={2}
                        onClick={() => {
                          setVideoUrl(product.video_url)
                          showDemoVideo()
                        }}
                      >
                        <img src={getThumbnail(product.video_url)} />
                        <span className="play-icon">
                          <img src={PlayIcon} alt="Play" />
                        </span>
                      </Box>
                      <Box theme={theme} className="prd-content">
                        <Typography
                          display={'block'}
                          mb={1}
                          className="text-muted text-uppercase fw-sbold"
                          component="small"
                          variant="caption"
                          theme={theme}
                        >
                          {product.product_name}
                        </Typography>
                        <Typography
                          className="text-h4"
                          mb={1}
                          theme={theme}
                          component="h4"
                          variant="h4"
                        >
                          {product.description}
                        </Typography>
                      </Box>
                    </a>
                  </Box>
                )
            )}
          </ResponsiveCarousel>
            )
          : (
          <NoDataFound />
            )}
      </ProductSlider>
      </FadeAnimation>
    </FeaturedProducts>
  )
}
