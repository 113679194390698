import { useEffect, type ReactElement } from 'react'
import { useTheme } from '@mui/material'
import { WebinarListingPageWrap } from './webinarListingStyle'
import { Box, Container, Grid, Typography } from '@ntpkunity/controls'
import { HomePageLayout } from '@layout/index'
import { scrollOnTop } from '@shared/helper'
import { WebinarListItemComponent } from '@modules/webinar/components'
import { useGetAllBlogs } from '@modules/blog/services'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'

export const WebinarListingPage = (): ReactElement => {
  const theme = useTheme()
  const navigate = useNavigate()
  const queryParams = '?is_webinar=true'
  const { data: webinars } = useGetAllBlogs(queryParams)

  useEffect(() => {
    scrollOnTop()
  }, [])

  return (
    <HomePageLayout>
      <WebinarListingPageWrap
        theme={theme}
        className="webinar-listing-page-wrap"
      >
        <Container className="custom-container" theme={theme}>
          <Box theme={theme} className="page-head" textAlign={'center'}>
            <Typography
              display={'inline-block'}
              mb={2}
              theme={theme}
              className="badge text-primary text-uppercase"
              variant="caption"
              component="small"
            >
              OUR RESOURCES
            </Typography>
            <Typography
              theme={theme}
              className="text-h2"
              variant="h2"
              component="h2"
            >
              Webinars
            </Typography>
            <Typography
              mt={2}
              theme={theme}
              className="text-body1 text-muted"
              variant="body1"
              component="p"
            >
              Access to upcoming webinars and replays to all of our past
              webinars
            </Typography>
          </Box>
          <Box theme={theme} className="webinar-list-items-wrap">
            <Grid
              theme={theme}
              container
              item
              spacing={3}
              alignItems={'stretch'}
              justifyContent={'center'}
            >
              {webinars?.map((webinar) => (
                <Grid key={webinar.id} theme={theme} item xs={12} sm={6} md={4}>
                  <WebinarListItemComponent
                    onClick={() => {
                      navigate(`${APP_ROUTES.WEBINAR}/${webinar.title_url}`)
                    }}
                    webinar={webinar}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </WebinarListingPageWrap>
    </HomePageLayout>
  )
}
