import { useGetFAQS } from '@modules/homePage/services'
import { useTheme } from '@mui/material'
import { Box, Grid, Typography, Accordion } from '@ntpkunity/controls'
import { type IAccordianProps, type IFAQ } from '@shared/typings'
import { type ReactElement, type FC } from 'react'
import { FaqsWrap } from './faqsStyle'
import { FadeAnimation } from '@shared/components'

interface IFaqProps {
  productId?: number
  productName?: string
}

export const FaqsComponent: FC<IFaqProps> = ({
  productId,
  productName
}): ReactElement => {
  const theme = useTheme()
  const { data: faqs } = useGetFAQS(productId, productName)

  const options = (): IAccordianProps[] => {
    let _options = []
    if (faqs?.length > 0) {
      _options = faqs.map((faq: IFAQ) => {
        return {
          content: (
            <>
              <Box theme={theme} className="content-area">
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  className="text-muted text-body2"
                >
                  {faq?.answer}
                </Typography>
              </Box>
            </>
          ),
          title: (
            <Typography
              theme={theme}
              variant="subtitle1"
              component={'p'}
              className="fw-sbold text-body1"
            >
              {faq?.question}
            </Typography>
          ),
          key: faq?.id,
          isExpanded: false
        }
      })
    }
    return _options
  }

  return (
    <FaqsWrap theme={theme} className="faqs-wrap">
      {faqs?.length > 0
        ? (
        <>
          <FadeAnimation direction='up'>
            <Typography
              mb={{ md: 5, xs: 3 }}
              theme={theme}
              variant="h2"
              component="h2"
              textAlign="center"
              className="text-h2"
            >
              Frequently asked questions
            </Typography>
          </FadeAnimation>
          <Grid
            theme={theme}
            container
            item
            spacing={3}
            justifyContent={'center'}
          >
            <Grid theme={theme} item lg={10} md={12}>
              <Box theme={theme} className="faqs-accordion">
                <FadeAnimation>
                  <Accordion
                    theme={theme}
                    varient="grouped"
                    shouldCollapse={true}
                    items={options()}
                  />
                </FadeAnimation>
              </Box>
              <Box theme={theme} mt={{ md: 5, xs: 3 }} textAlign="center">
                <FadeAnimation>
                  <Typography
                    theme={theme}
                    component="span"
                    variant="body2"
                    display={'block'}
                    className="text-muted text-body2"
                  >
                    Didn’t find the answer you are looking for?{' '}
                    <a
                      href={`mailto:${process.env.SUPPORT_EMAIL as string}`}
                      className="link text-primary"
                    >
                      Contact our support
                    </a>
                  </Typography>
                </FadeAnimation>
              </Box>
            </Grid>
          </Grid>
        </>
          )
        : null}
    </FaqsWrap>
  )
}
