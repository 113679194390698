import { type AppError, formatedDate, isValidNumber } from '@shared/helper'
import Http from '@shared/helper/http-api'
import { UseMutateFunction, useQuery } from 'react-query'

const BASE_URL = process.env.BASE_URL

const PROJECTED_COST_DASHBOARD = 'marketplace/products/project-cost-dashboard'
const PRODUCT_USAGE_TREND_DASHBOARD = 'marketplace/products/product-usage-trend-dashboard'
export const useProjectedCostDashboard = (productId: number | undefined, startDate: Date, endDate: Date): {
  data: string | undefined
  isLoading: boolean
  isError: boolean
  error: AppError | unknown
} => {
  const STARTDATE = formatedDate(startDate, 'YYYY-MM-DD')
  const ENDDATE = formatedDate(endDate, 'YYYY-MM-DD')
  let url = `${PROJECTED_COST_DASHBOARD}`
  if (STARTDATE.length > 0 && ENDDATE.length > 0 && isValidNumber(productId)) {
    url += `?product_id=${productId ?? ''}&start_date=${STARTDATE}&end_date=${ENDDATE}`
  }

  const { data, error, isLoading, isError } = useQuery(
    [url, productId],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(url)
    },
    {
      refetchOnWindowFocus: false,
      enabled: isValidNumber(productId) && STARTDATE.length > 0 && ENDDATE.length > 0

    }
  )
  return { data, error, isLoading, isError }
}

export const useProductUsageTrendDashboard = (productId: number | undefined, startDate: Date, endDate: Date): {
  data: string | undefined
  isLoading: boolean
  isError: boolean
  error: AppError | unknown
} => {
  const STARTDATE = formatedDate(startDate, 'YYYY-MM-DD')
  const ENDDATE = formatedDate(endDate, 'YYYY-MM-DD')
  let url = `${PRODUCT_USAGE_TREND_DASHBOARD}`
  if (STARTDATE.length > 0 && ENDDATE.length > 0 && isValidNumber(productId)) {
    url += `?product_id=${productId ?? ''}&start_date=${STARTDATE}&end_date=${ENDDATE}`
  }

  const { data, error, isLoading, isError } = useQuery(
    [url, productId],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(url)
    },
    {
      refetchOnWindowFocus: false,
      enabled: isValidNumber(productId) && STARTDATE.length > 0 && ENDDATE.length > 0
    }
  )
  return { data, error, isLoading, isError }
}
