import { Header, Footer } from '@layout/components'
import { PaymentMethodComponent } from '@modules/billingDetails'
import {
  CheckoutBillingAddress,
  SelectedProductsPreviewComponent
} from '@modules/checkout/components'
import { Divider, useTheme } from '@mui/material'
import { Box, Container, Grid, Typography } from '@ntpkunity/controls'
import { IS_CHECKOUT, PaymentMethodType } from '@shared/constants'
import { removeItembyKey, scrollOnTop } from '@shared/helper'
import { type ReactElement, useEffect, useState } from 'react'
import { MainContent, StartCheckoutWrap } from './checkoutStyle'

export const CheckoutPage = (): ReactElement => {
  const theme = useTheme()
  const [selectedTab, setSelectedTab] = useState<number>(PaymentMethodType.BANK_TRANSFER)
  const [paymentFrequency, setPaymentFrequency] = useState<string>('')

  const isSelectedTabBankTransfer = selectedTab === PaymentMethodType.BANK_TRANSFER

  useEffect(() => {
    removeItembyKey(IS_CHECKOUT)
    scrollOnTop()
  }, [])

  return (
    <>
      <Header theme={theme} />
      <MainContent theme={theme} className="main-content">
        <Container theme={theme} maxWidth="lg" className="custom-container">
          <Grid theme={theme} container item spacing={{ xs: 3, md: 10 }}>
            <Grid theme={theme} item xs={12} md={7} lg={7.17}>
              <Typography
                mb={3}
                theme={theme}
                variant="h2"
                component="h2"
                className="text-h2"
              >
                Start Checkout
              </Typography>
              <Divider />
              <StartCheckoutWrap
                theme={theme}
                className="start-checkout-wrap"
                mt={3}
              >
                <PaymentMethodComponent selectedTab={selectedTab} setSelectedTab={setSelectedTab} setPaymentFrequency={setPaymentFrequency} />
                <Divider />
                {!isSelectedTabBankTransfer ? <CheckoutBillingAddress /> : null}
              </StartCheckoutWrap>
            </Grid>
            <Grid theme={theme} item xs={12} md={5} lg={4.83}>
              <Box theme={theme} className="cart-summary-area">
                <SelectedProductsPreviewComponent isSelectedTabBankTransfer={isSelectedTabBankTransfer} paymentFrequency={paymentFrequency} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </MainContent>
      <Footer />
    </>
  )
}
