import supportService from '@shared/assets/images/support-services.png'
import transactionalBundle from '@shared/assets/images/transactional-bundle.png'
import comingSoon from '@shared/assets/images/coming-soon.png'

export const addOns = [
  {
    name: 'Transactional Bundle',
    stripe_product_id: 'prod_Lx8x5ZWwVjIvqr',
    is_active: true,
    id: 14,
    description: 'Flex',
    image_url: transactionalBundle
  },
  {
    name: 'Support Services',
    stripe_product_id: 'prod_Lx8x5ZWwVjIvqr',
    is_active: true,
    id: 13,
    description: 'Flex',
    image_url: supportService
  }
]
export const services = [
  {
    name: 'Service',
    stripe_product_id: 'prod_Lx8x5ZWwVjIvqr',
    is_active: true,
    id: 17,
    description: 'Flex',
    image_url: comingSoon
  },
  {
    name: 'API',
    stripe_product_id: 'prod_Lx8x5ZWwVjIvqr',
    is_active: true,
    id: 18,
    description: 'Flex',
    image_url: comingSoon
  },
  {
    name: 'Testing Service',
    stripe_product_id: 'prod_Lx8x5ZWwVjIvqr',
    is_active: true,
    id: 19,
    description: 'Flex',
    image_url: comingSoon
  },
  {
    name: 'Service',
    stripe_product_id: 'prod_Lx8x5ZWwVjIvqr',
    is_active: true,
    id: 17,
    description: 'Flex',
    image_url: comingSoon
  }
]
export const bundles = [
  {
    name: 'Bundle 1',
    stripe_product_id: 'prod_Lx8x5ZWwVjIvqr',
    is_active: true,
    id: 20,
    description: 'Flex',
    image_url: comingSoon
  },
  {
    name: 'Bundle 2',
    stripe_product_id: 'prod_Lx8x5ZWwVjIvqr',
    is_active: true,
    id: 21,
    description: 'Flex',
    image_url: comingSoon
  }
]

export const colors = {
  0: 'bg-seagreen',
  1: 'bg-lightskin',
  2: 'bg-lightblue',
  3: 'bg-lightpurple',
  4: 'bg-seagreen',
  5: 'bg-lightskin',
  6: 'bg-lightblue',
  7: 'bg-lightpurple',
  8: 'bg-seagreen',
  9: 'bg-lightskin'
}
