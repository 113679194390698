import { Grid, Typography, Box, Button, Icon } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { useGetPaymentPlan, useRetryPayment } from '@modules/billingDetails/services'
import { type FC, useMemo, useRef, useState, useEffect } from 'react'
import {
  convertToAmountWithDecimals,
  dateFormat,
  formatedDate,
  PaymentMethod,
  stripeAmount
} from 'shared'
import DisableLoader from '@shared/assets/images/loader-disabled-btn.gif'
import { useModal } from 'react-modal-hook'
import { ProofOfPaymentDialogComponent } from '@modules/billingDetails'
import { type IPaymentPlan } from '@shared/typings'

export const PaymentPlan: FC = () => {
  const theme = useTheme()
  const { data: PaymentPlan, isLoading: paymentPlanLoading } = useGetPaymentPlan()
  const { mutate: retryPayment, isLoading: retryingPayment } = useRetryPayment()
  const orderIdForRetryingPayment = useRef<number>()

  const totalAmount = useMemo(
    () =>
      PaymentPlan?.map((currentValue) =>
        currentValue.order_details.reduce(
          (accumulator: number, currentValue) =>
            accumulator + currentValue.package_amount,
          0
        )
      ),
    [PaymentPlan]
  )

  const [orderIdForProof, setOrderIdForProof] = useState<number | null>(null)
  const [showProofOfPaymentPopup, hideProofOfPaymentPopup] = useModal(
    () => {
      const order = (PaymentPlan ?? []).find(paymentPlan => paymentPlan.order_id === orderIdForProof)
      return (
        <ProofOfPaymentDialogComponent order={order as IPaymentPlan} hide={handleClose} />
      )
    },
    [orderIdForProof, PaymentPlan]
  )
  useEffect(() => {
    if (orderIdForProof !== null) {
      showProofOfPaymentPopup()
    }
  }, [orderIdForProof, showProofOfPaymentPopup])
  const handleClose = (): void => {
    setOrderIdForProof(null)
    hideProofOfPaymentPopup()
  }

  const handleRetryPayment = (orderId): void => {
    orderIdForRetryingPayment.current = orderId
    retryPayment(orderId)
  }

  return (
    <>
      {(PaymentPlan ?? [])?.length > 0 && <><Typography
        theme={theme}
        variant="h3"
        component="h3"
        mb={3}
        className="text-h3"
      >
        Payment Plan
      </Typography>
        <Box theme={theme} className="list-wrap" mt={3}>
          {PaymentPlan?.map((payment, i) => {
            const _retryingPayment = (retryingPayment || paymentPlanLoading) && orderIdForRetryingPayment.current === payment.order_id
            return (
              <Box
                key={i}
                theme={theme}
                className="list-item"
                display={'flex'}
                alignItems={'center'}
                flexWrap={{ xs: 'wrap', md: 'nowrap' }}
              >
                <Box theme={theme} className="list-info-wrap" flexGrow={1}>
                  {payment.order_details?.map((orderDetails, i) => (
                    <Box theme={theme} className="list-info" key={i}>
                      <Grid theme={theme} container item spacing={{ xs: 2, md: 3 }}>
                        <Grid theme={theme} item xs={6} sm={6} md={6}>
                          <Typography
                            theme={theme}
                            variant="subtitle1"
                            component="p"
                            className="text-subtitle1"
                          >
                            {orderDetails.product_name}
                          </Typography>
                          <Typography
                            theme={theme}
                            variant="body2"
                            component="span"
                            className="text-muted icon-text"
                            display={'flex'}
                            alignItems={'center'}
                          >
                            {orderDetails.package_name}
                          </Typography>
                        </Grid>
                        <Grid
                          theme={theme}
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          textAlign={{ xs: 'right' }}
                        >
                          <Typography
                            theme={theme}
                            variant="subtitle1"
                            component="p"
                            className="text-subtitle1"
                          >
                            {convertToAmountWithDecimals(
                              stripeAmount(orderDetails.package_amount)
                            )}
                            /Month
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </Box>
                <Box
                  theme={theme}
                  className="list-action"
                  flexShrink={0}
                  alignItems={'center'}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  {
                    payment.is_payment_pending && payment.payment_type === PaymentMethod.CARD
                      ? <Box
                        theme={theme}
                        mb={2}
                        width='100%'
                      >
                          <Button
                            theme={theme}
                            danger
                            fullWidth
                            startIcon={
                              _retryingPayment &&
                              (
                                <img src={DisableLoader} alt="Loader" />
                              )
                            }
                            text={_retryingPayment ? 'Wait...' : 'Retry Payment'}
                            disabled={_retryingPayment}
                            onClick={() => { handleRetryPayment(payment.order_id) }}
                          />
                      </Box>
                      : null
                  }
                  {
                    payment.is_payment_pending && payment.payment_type === PaymentMethod.BANK_TRANSFER && (payment.payment_proof ?? []).length === 0
                      ? <Box
                        theme={theme}
                        mb={2}
                        width='100%'
                      >
                        <Button
                          theme={theme}
                          fullWidth
                          primary
                          text='Proof of Payment'
                          onClick={() => { setOrderIdForProof(payment.order_id) }}
                        />
                      </Box>
                      : null
                  }
                  {
                    payment.is_payment_pending && payment.payment_type === PaymentMethod.BANK_TRANSFER && (payment.payment_proof ?? []).length > 0
                      ? <Box
                        theme={theme}
                        mb={2}
                        width='100%'
                      >
                        <Button
                          theme={theme}
                          fullWidth
                          secondary
                          text='View/Edit'
                          onClick={() => { setOrderIdForProof(payment.order_id) }}
                        />
                      </Box>
                      : null
                  }
                  {
                    !payment.is_payment_pending
                      ? (
                        <>
                          <Typography
                            theme={theme}
                            variant="subtitle1"
                            component="p"
                            className="text-subtitle1"
                          >
                            {convertToAmountWithDecimals(stripeAmount(totalAmount?.[i] ?? 0))}
                            /Month
                          </Typography>
                          <Typography
                            theme={theme}
                            variant="body2"
                            component="span"
                            className="text-muted text-body2"
                          >
                            Due Date: {formatedDate(payment.order_date, dateFormat)}
                          </Typography>
                        </>
                        )
                      : null
                  }
                </Box>
            </Box>
            )
          })}
        </Box></>}
    </>
  )
}
