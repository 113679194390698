import { styled } from '@mui/material/styles'
import { borderRadius } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const ConatctPage = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.contact-page': {
    '.contact-img': {
      maxHeight: 400,
      img: {
        maxHeight: 400,
        [theme.breakpoints.down('md')]: {
          maxHeight: 200
        }
      },
      [theme.breakpoints.down('md')]: {
        maxHeight: 200
      }
    },
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.link': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer'
      }
    },
    '.u-form-group': {
      '.u-form-control': {
        textarea: {
          '&.MuiInputBase-input': {
            height: 'auto !important'
          }
        }
      }
    },
    '.schedule-meeting-wrap': {
      backgroundColor: '#F5F5F7',
      borderRadius: 16
    },
    '.contact-info-section': {
      '.grid-col-item': {
        '.contact-info-item': {
          height: '100%',
          position: 'relative',
          [theme.breakpoints.up('md')]: {
            '&:after': {
              display: 'block',
              content: '" "',
              borderStyle: 'solid',
              borderWidth: '0px 1px 0px 0px',
              width: 0,
              height: '100%',
              position: 'absolute',
              top: 0,
              right: -14,
              borderColor: theme.palette.grey[100]
            }
          },
          [theme.breakpoints.down('md')]: {
            borderBottom: '1px solid' + theme.palette.grey[100],
            paddingBottom: 24,
            paddingLeft: 0,
            paddingRight: 0,
            margin: '0 32px'
          }
        },
        '&:last-child': {
          '.contact-info-item': {
            borderBottom: 'none',
            paddingBottom: 0,
            [theme.breakpoints.up('md')]: {
              '&:after': {
                display: 'none'
              }
            }
          }
        }
      },
      '.text-small': {
        fontWeight: theme.customVariables.fontWeightSemiBold,
        letterSpacing: '0.5px'
      }
    },
    '.social': {
      ul: {
        paddingLeft: 0
      },
      li: {
        display: 'inline-block',
        marginBottom: 0,
        marginRight: 8,
        verticalAlign: 'middle',
        '&:last-child': {
          marginRight: 0
        }
      },
      '.social-link': {
        textAlign: 'center',
        backgroundColor: 'transparent',
        border: '1px solid' + theme.palette.grey[100],
        width: 40,
        height: 40,
        borderRadius: '50%',
        lineHeight: '40px',
        transition: 'all .3s ease-in-out',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        svg: {
          width: 16,
          height: 16,
          fill: theme.palette.grey[600],
          path: {
            fill: theme.palette.grey[600]
          }
        },
        '&.link-yt': {
          svg: {
            width: 24,
            height: 24
          }
        },
        '&.link-medium': {
          svg: {
            width: 24,
            height: 24
          }
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
          svg: {
            fill: theme.palette.common.white,
            path: {
              fill: theme.palette.common.white
            }
          }
        }
      }
    },
    '.contact-help-section': {
      '.contact-help-box': {
        padding: 32,
        border: '1px solid' + theme.palette.grey[100],
        borderRadius: 16,
        height: '100%',
        transition: 'all 0.3s ease-in-out 0s',
        '&:hover': {
          boxShadow: '0px 32px 32px -24px rgba(0, 26, 128, 0.25)',
          '.icon': {
            transform: 'translateX(10px) scale(1.1)'
          }
        },
        '.icon': {
          transition: 'all 0.3s ease-in-out 0s',
          transformOrigin: 'center center'
        }
      }
    }
  }
}))
