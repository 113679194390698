import Http from '@shared/helper/http-api'
import { type IAddOns } from '@shared/typings'
import { useQuery } from 'react-query'
import { type AppError } from 'single-spa'

const BASE_URL = process.env.BASE_URL
const ADDONS = 'marketplace/products/add-ons'

export const useGetAddOns = (): {
  data: IAddOns[]
  error: AppError | unknown
} => {
  const { data, error } = useQuery(
    ADDONS,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(ADDONS)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error }
}
