import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const AuthPage = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.auth-page': {
    paddingTop: 97,
    paddingBottom: 24,
    borderTop: 'none',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    transform: 'translateZ(0)',
    '.auth-left-img': {
      position: 'fixed',
      top: 97,
      left: 24,
      width: 348,
      flex: '0 0 auto',
      height: 'calc(100% - 121px)',
      borderRadius: 16,
      overflow: 'hidden',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      },
      img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center center'
      }
    },
    '.auth-right-panel': {
      flexGrow: 1,
      padding: '0 20px',
      marginLeft: 348,
      [theme.breakpoints.down('md')]: {
        padding: '32px 32px 50px',
        marginLeft: 0
      },
      '.right-content': {
        maxWidth: 520,
        margin: 'auto',
        minHeight: 'calc(100vh - 121px)',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
          minHeight: 'auto'
        },
        '.inner-scrollable-area': {
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          scrollbarColor: '#cdcdcd transparent',
          paddingTop: 8,
          [theme.breakpoints.up('md')]: {
            maxHeight: '280px'
          },
          [theme.breakpoints.between(1600, 1920)]: {
            maxHeight: '330px'
          },
          [theme.breakpoints.between(1195, 1500)]: {
            maxHeight: '440px'
          },
          [theme.breakpoints.up('xl')]: {
            maxHeight: '440px'
          },
          [theme.breakpoints.down('md')]: {
            maxHeight: '440px'
          },
          '&::-webkit-scrollbar': {
            width: 7,
            height: 7
          },
          '&::-webkit-scrollbar-track': {
            padding: '0 1px',
            backgroundColor: 'transparent'
          },
          '&::-webkit-scrollbar-thumb': {
            transition: 'all 0.3s',
            backgroundColor: '#cdcdcd',
            borderRadius: theme.shape.borderRadius
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#a6a6a6'
          },
          '.viewport': {
            scrollbarWidth: 'thin',
            scrollbarColor: '#cdcdcd transparent',
            '&::-webkit-scrollbar': {
              width: 7,
              height: 7
            },
            '&::-webkit-scrollbar-track': {
              padding: '0 1px',
              backgroundColor: 'transparent'
            },
            '&::-webkit-scrollbar-thumb': {
              transition: 'all 0.3s',
              backgroundColor: '#cdcdcd',
              borderRadius: theme.shape.borderRadius
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#a6a6a6'
            }
          }
        },
        '.otp-field-wrap': {
          display: 'flex',
          justifyContent: 'center',
          '.u-form-group': {
            marginBottom: 0,
            marginRight: 16,
            '&:last-child': {
              marginRight: 0
            },
            '.u-form-control': {
              height: 72,
              fontSize: theme.typography.h2.fontSize,
              lineHeight: theme.typography.h2.lineHeight,
              fontWeight: theme.typography.fontWeightBold,
              '.MuiInputBase-input': {
                height: 72,
                textAlign: 'center',
                '&::placeholder': {
                  opacity: 1
                }
              }
            }
          }
        }
      }
    },
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.link': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer'
      }
    },
    '.back-btn': {
      '.btn': {
        '&.btn-default': {
          color: theme.palette.grey[600],
          '&:hover': {
            color: theme.palette.grey[600],
            '&:focus': {
              color: theme.palette.grey[600]
            }
          },
          '&:focus': {
            color: theme.palette.grey[600]
          }
        }
      }
    },
    '.progress-steps': {
      marginTop: 48,
      '.progress-step': {
        '&.active-step': {
          '.u-progress-bar': {
            '.MuiLinearProgress-bar': {
              backgroundColor: theme.palette.grey[200]
            }
          }
        },
        '&.previous-step': {
          '.u-progress-bar': {
            '.MuiLinearProgress-bar': {
              backgroundColor: theme.palette.primary.main
            }
          }
        }
      },
      '.u-progress-bar': {
        '&.progress-header': {
          backgroundColor: theme.palette.grey[50],
          height: 8
        }
      }
    },
    '.gt-agree-and-continue-btn': {
      textTransform: 'none'
    },
    '.control-wrap': {
      '.decoration-none': {
        '&:hover': {
          textDecoration: 'underline'
        }
      }
    }
  }
}))
