import { type FC } from 'react'
import { useTheme } from '@mui/material'
import { Box, Icon, Typography } from '@ntpkunity/controls'
import { BlogDetailItem } from './blogDetailItemStyle'
import { type IBlog } from '@shared/typings'
import { formatedDate } from '@shared/helper'
import parse from 'html-react-parser'

interface BlogDetailsProps {
  blog: IBlog | undefined
  socialShare?: boolean
}

export const BlogDetailItemComponent: FC<BlogDetailsProps> = ({
  blog,
  socialShare
}) => {
  const theme = useTheme()
  return (
    <BlogDetailItem theme={theme} className="blog-detail-item">
      <Box theme={theme} className='blog-detail-page-head' textAlign={'center'}>
          <Typography theme={theme} variant='h1' component='h1' mt={2} className='blog-title text-h2'>
            {blog?.title}
          </Typography>
          <Box theme={theme} className='blog-meta' display={'flex'} alignItems={'center'} gap={1} mt={2} justifyContent={'center'}>
            <Typography theme={theme} variant='body2' component='p' className='text-muted fw-medium text-body2'>{formatedDate(blog?.created_at ?? '', 'DD MMM, YYYY')}</Typography>
            <span className='seperator'></span>
          <Typography theme={theme} variant='body2' component='p' className='text-muted fw-medium text-body2'>{blog?.author}</Typography>
        </Box>
      </Box>
      <Box theme={theme} className='blog-content'>
        <Box theme={theme} className='blog-img' mb={{ xs: 5, md: 8 }}>
          <img src={blog?.image} alt={blog?.title} />
        </Box>
        <Box theme={theme} className='blog-details-wrap'>
        {/* {(socialShare ?? false)
          ? (<Box theme={theme} className='blog-social-share'>
              <Typography theme={theme} variant="caption" component='small' className='text-muted'>Share this read</Typography>
              <Box theme={theme} className='share-icons'>
                <a href="" className='ic-16'>
                  <Icon name="LinkedInIcon" />
                </a>
                <a href="" className='ic-16'>
                  <Icon name="TwitterIcon" />
                </a>
                <a href="">
                  <Icon name="MediumIcon" />
                </a>
              </Box>
            </Box>)
          : null
          } */}
          <Box theme={theme} className='blog-details'>
            {parse(blog?.content ?? '')}
          </Box>
        </Box>
      </Box>
    </BlogDetailItem>
  )
}
