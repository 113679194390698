import { useTheme } from '@mui/material'
import {
  Box,
  Button,
  Icon,
  Typography,
  CircleLoader
} from '@ntpkunity/controls'
import { type FC, useEffect, useMemo, useState } from 'react'
import { useModal } from 'react-modal-hook'
import { className, UserAction } from '@shared/constants'
import { type IBillingAddress, type ICountry } from '@shared/typings'
import { BillingAddressWrap } from './checkoutBillingAddressStyle'
import { useGetBillingAddress } from '@modules/checkout/services'
import { CheckoutBillingAddressPopup } from '../popup/checkoutBillingAddressPopup'
import {
  useGetCounties,
  useGetCountries,
  useGetCountyByStateId,
  useGetStates,
  useGetStatesByCountryId
} from '@modules/authentication'

const messages = {
  button: {
    addAddress: 'Add Billing Address',
    save: 'Save Address',
    cancel: 'Cancel',
    wait: 'Wait...'
  },
  saveBillingAddress: 'You have added your billing details successfully!',
  updateBillingAddress: 'You have updated your billing details successfully!'
}

export const CheckoutBillingAddress: FC = () => {
  const theme = useTheme()
  const { data: billingData } = useGetBillingAddress()
  const [billingAddress, setBillingAddress] = useState<IBillingAddress>()
  const [actionType, setActionType] = useState<string>(UserAction.CREATE)
  const [selectedCountry, setSelectedCountry] = useState<ICountry>()
  const [, setSelectedCounty] = useState<ICountry>()
  const [selectedState, setSelectedState] = useState<ICountry>()
  const { data: counties } = useGetCountyByStateId(selectedState?.id)
  const { data: states } = useGetStatesByCountryId(selectedCountry?.id)
  const { data: countries } = useGetCountries()
  const { data: allStates } = useGetStates()
  const { data: allCounties } = useGetCounties()

  const countryName = useMemo(
    () =>
      countries?.find((country) => country.id === billingAddress?.country_id)
        ?.name,
    [countries, billingAddress]
  )

  const stateName = useMemo(
    () =>
      allStates?.find((state) => state.id === billingAddress?.state_id)?.name,
    [allStates, billingAddress, selectedCountry]
  )

  const countyName = useMemo(
    () =>
      allCounties?.find((county) => county.id === billingAddress?.county_id)
        ?.name,
    [allCounties, billingAddress, selectedState]
  )

  const [show, hide] = useModal(
    () => (
      <CheckoutBillingAddressPopup
        actionType={actionType}
        hide={hide}
        setSelectedCountry={setSelectedCountry}
        setSelectedCounty={setSelectedCounty}
        setSelectedState={setSelectedState}
        billingData={billingData}
        countries={countries}
        states={states}
        counties={counties}
        selectedCountry={selectedCountry}
      />
    ),
    [actionType, countries, states, counties, billingData, selectedCountry]
  )

  useEffect(() => {
    if (Object?.keys(billingData ?? {}).length > 0) {
      setActionType(UserAction.EDIT)
      setBillingAddress(billingData)
    }
  }, [billingData, countries, states, counties])

  return (
    <>
      <BillingAddressWrap theme={theme} className="billing-address" mt={3}>
        <Typography
          mb={0.5}
          theme={theme}
          variant="h4"
          component="h4"
          className="text-h4"
        >
          Billing Address
        </Typography>
        <Typography
          className="text-muted"
          theme={theme}
          variant="caption"
          component="small"
        >
          This address will be used on invoice and receipts
        </Typography>
        <Box theme={theme} className="billing-address-area" mt={3}>
          {(billingAddress == null)
            ? (
            <Box theme={theme} className="add-new-billing">
              <Box theme={theme} className="card-item item-add-new">
                <Button
                  fullWidth
                  className={className.checkoutAddBillingAddress}
                  defaultBtn
                  theme={theme}
                  startIcon={
                    <>
                      <Icon name="AddIcon" />
                    </>
                  }
                  text={messages.button.addAddress}
                  onClick={() => { show() }}
                />
              </Box>
            </Box>
              )
            : (
            <Box theme={theme} className="billing-address-item">
              <Box
                theme={theme}
                className="card-item"
                flexWrap={{ xs: 'wrap', md: 'nowrap' }}
              >
                {Object.keys(billingAddress ?? {}).length > 0 && (countries ?? []).length > 0
                  ? (
                  <>
                    <Typography
                      theme={theme}
                      className="text-muted card-info"
                      variant="body2"
                      component="span"
                      display={'block'}
                      width={{ xs: '100%', md: 'auto' }}
                    >
                      {`${billingData?.address_line_1 ?? ''}, ${
                        (billingData?.address_line_2 ?? '').length > 0
                          ? billingAddress.address_line_2 + ','
                          : ''
                      }`}
                      <Typography
                        theme={theme}
                        className="text-muted"
                        variant="body2"
                        component="span"
                        display={'block'}
                      >
                        {`${billingData?.city ?? ''}, ${
                          countryName === 'United States'
                            ? stateName as string + ','
                            : `${billingData?.state_name ?? ''},`
                        } ${countryName as string},   ${
                          countryName === 'United States' && countyName?.length > 0
                            ? `${countyName as string},`
                            : ''
                        }
                        ${billingData?.zip_code ?? ''}`}
                      </Typography>
                    </Typography>
                  </>
                    )
                  : (
                  <CircleLoader theme={theme} size="md" />
                    )}
                <Box
                  theme={theme}
                  className="card-cta"
                  mt={{ xs: 2, md: 0 }}
                  width={{ xs: '100%', md: 'auto' }}
                >
                  <Button
                    secondary
                    theme={theme}
                    text={'Change'}
                    type="submit"
                    onClick={() => { show() }}
                  />
                </Box>
              </Box>
            </Box>
              )}
        </Box>
      </BillingAddressWrap>
    </>
  )
}
