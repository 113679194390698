import { type FC } from 'react'
import { useTheme } from '@mui/material'
import { Box } from '@ntpkunity/controls'
import { FullPageMessageWrap } from './fullPageMessageStyle'

interface FullPageMessageProps {
  children: any
  messageImage?: string
  imageAltText?: string
}

export const FullPageMessage: FC<FullPageMessageProps> = ({
  children,
  messageImage,
  imageAltText
}) => {
  const theme = useTheme()
  return (
    <FullPageMessageWrap theme={theme} className="full-page-message-wrap">
      <Box theme={theme} className="msg-img">
        <img src={messageImage} alt={imageAltText} />
      </Box>
      <Box theme={theme} className="message-content" mt={5}>
        {children}
      </Box>
    </FullPageMessageWrap>
  )
}
