import { useTheme } from '@mui/material'
import { type FC, useEffect, useState } from 'react'
import { Authentication } from '@modules/authentication'
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'
import { checkAdminFromRoles, clearLocalStorage, getItemByKey, isLoggedIn, removeItembyKey, setItem, setItembyKey } from '@shared/helper'
import {
  ACCESS_TOKEN_KEY,
  HttpStatus,
  IS_LOGGED_IN,
  USER_NAME,
  className,
  PRODUCT_ID,
  ROLE,
  CURRENT_ROUTE,
  CART_DATA,
  ButtonType,
  IconType,
  DialogHeading,
  freeConfirmationMessage,
  cartsErrorResponse,
  FREE_TRIAL_PRODUCT,
  SIGNUP_STEPS,
  ROLE_ID,
  TENANT_ID,
  COMPANY_NAME,
  REFRESH_TOKEN_KEY,
  STRIPE_USER_CREATED,
  Steps,
  IS_CHECKOUT,
  invalidOtp
} from '@shared/constants'
import { UserWorkspaceComponent, type IResponse } from '@ntpkunity/controls-ums'
import { useStoreContext } from 'store/storeContext'
import { type IRole } from '@shared/typings'
import { useAddCarts } from '@modules/product/services/cart/cartService'
import { useModal } from 'react-modal-hook'
import { ConfirmationDialog } from '@shared/components'
import { useCreateFreeTrialSubscription } from '@modules/product'
import { useQueryClient } from 'react-query'
import { GET_USER_PROFILE } from '@modules/myAccount'

export const UserProfile: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const location = useLocation() as any
  const email = location?.state?.email
  const tenantName = location?.state?.tenantName
  const _isTenant = location?.state?.isTenant as boolean
  const otp = location?.state?.otp as boolean

  const { tab: token } = queryString.parse(location?.search) as any

  if (!email) {
    navigate(APP_ROUTES.SIGNUP, {
      replace: true
    })
  }

  const [response, setResponse] = useState<IResponse>()
  const [urlValidateResponse, setUrlValidateResponse] = useState()

  const {
    states: { userProfile, cart },
    actions: { setUserProfile, setToaster, notificationDispatch }
  } = useStoreContext()

  const { mutate: addAllCarts, isLoading: addCartsLoading } = useAddCarts()
  const {
    mutate: createFreeSubscription,
    isLoading,
    error
  } = useCreateFreeTrialSubscription()

  const [show] = useModal(
    () => (
      <ConfirmationDialog
        btnClassName={className.freeSubscriptionConfirmation}
        buttonText={ButtonType.CONTINUE}
        iconName={IconType.SUCCESS}
        title={DialogHeading.CONGRATULATIONS}
        message={freeConfirmationMessage}
        onConfirm={() => { navigate(APP_ROUTES.CUSTOMER_ACCOUNT) }}
      />
    ),
    []
  )

  useEffect(() => {
    if ((isLoggedIn() ?? '').length > 0) {
      navigate(getItemByKey(CURRENT_ROUTE), {
        replace: true
      })
    }
  }, [])

  useEffect(() => {
    if ((isLoggedIn() ?? '').length > 0 && userProfile?.signup_steps?.toLowerCase() === Steps.TENANT) {
      const freeTrialProduct = getItemByKey(FREE_TRIAL_PRODUCT)
      if ((cart as { cart: [] })?.cart?.length > 0) {
        navigate(APP_ROUTES.CHECKOUT)
      } else if (Object?.keys(freeTrialProduct ?? {})?.length > 0) {
        navigate(APP_ROUTES.CUSTOMER_ACCOUNT)
      } else {
        navigate(getItemByKey(CURRENT_ROUTE))
      }
    }
  }, [userProfile])

  const dumpAllCartsToDB = (navigateTo: string, isShowPopup: boolean): void => {
    const data = getItemByKey(CART_DATA)
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (data) {
      addAllCarts(data, {
        onSuccess () {
          if (isShowPopup) {
            show()
          } else {
            navigate(navigateTo)
          }
        },
        onError () {
          setToaster({
            isDisplay: true,
            message: cartsErrorResponse,
            type: 'error'
          })
          navigate(APP_ROUTES.SINGLE_SIGN_ON)
        }
      })
      removeItembyKey(FREE_TRIAL_PRODUCT)
    }
  }

  useEffect(() => {
    if ((error ?? '').length > 0) {
      setToaster({
        isDisplay: true,
        message: error,
        type: 'error'
      })
    }
  }, [error])

  useEffect(() => {
    if (response !== undefined) {
      const { status, data, message } = response

      if (status === HttpStatus.OK) {
        const {
          tenant_id: tenantId,
          is_tenant: isTenant,
          signup_steps: signupSteps,
          access_token: accessToken,
          refresh_token: refreshToken,
          roles,
          profile,
          allowed_products: allowedProducts,
          tenant_profile: tanantProfile
        } = data ?? {}

        // in case sub-user tries to access main flow
        if (!isTenant) {
          clearLocalStorage()
          navigate(APP_ROUTES.SIGNUP, {
            replace: true
          })
        } else {
          const { name } = tanantProfile ?? { name: '' }
          const role: IRole = roles?.reduce((agg, role: IRole) => {
            if (role?.is_admin) {
              return {
                name: role.name,
                is_admin: role.is_admin
              }
            }
            return {
              name: role.name,
              is_admin: role.is_admin
            }
          }, {})
          const isAdmin = checkAdminFromRoles(roles)
          const productId: number = roles?.[0]?.product_id ?? allowedProducts?.[0]?.id
          const roleId: number = roles?.[0]?.role_id;
          (!Number.isNaN(productId)) && setItem(PRODUCT_ID, productId);
          (!Number.isNaN(roleId)) && setItem(ROLE_ID, roleId)
          role !== undefined && setItem(ROLE, role)
          setItem(
            USER_NAME,
            `${profile?.first_name as string ?? ''} ${profile?.last_name as string ?? ''}`
          )

          setUserProfile({
            ...userProfile,
            ...profile,
            ...tanantProfile,
            ...roles,
            company_name: name,
            is_admin: isAdmin
          })
          queryClient.setQueryData([GET_USER_PROFILE], {
            ...userProfile,
            ...profile,
            ...tanantProfile,
            ...roles,
            company_name: name,
            signup_steps: signupSteps,
            is_admin: isAdmin
          })

          setItem(TENANT_ID, tenantId)
          setItem(COMPANY_NAME, name)
          setItem(IS_LOGGED_IN, true)
          setItem(REFRESH_TOKEN_KEY, refreshToken)
          setItem(ACCESS_TOKEN_KEY, accessToken)
          setItembyKey(SIGNUP_STEPS, signupSteps?.toLowerCase())
          const atTenantStep =
            signupSteps?.toLowerCase() === Steps.TENANT ||
            signupSteps?.toLowerCase() === Steps.TERMS
          if (atTenantStep) {
            setItem(STRIPE_USER_CREATED, true)
          }

          const freeTrialProduct = getItemByKey(FREE_TRIAL_PRODUCT)
          const carts = getItemByKey(CART_DATA)
          const currentRoute = getItemByKey(CURRENT_ROUTE)
          const isCheckout = getItemByKey(IS_CHECKOUT)
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (!freeTrialProduct && !carts) {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (currentRoute && !isCheckout) {
              navigate(currentRoute)
            } else {
              navigate(APP_ROUTES.CUSTOMER_ACCOUNT)
            }
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          } else if (!freeTrialProduct && carts) {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (isCheckout) {
              dumpAllCartsToDB(APP_ROUTES.CHECKOUT, false)
            } else {
              dumpAllCartsToDB(
                currentRoute ?? APP_ROUTES.CHECKOUT,
                false
              )
            }
          } else {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            freeTrialProduct &&
              createFreeSubscription(freeTrialProduct, {
                onSuccess () {
                  setItem(STRIPE_USER_CREATED, true)
                  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                  if (!carts) {
                    show()
                    return
                  }
                  dumpAllCartsToDB(APP_ROUTES.SINGLE_SIGN_ON, true)
                }
              })
          }
        }
      } else if (status === HttpStatus.Conflict) {
        setToaster({
          isDisplay: true,
          message,
          type: 'error'
        })
      } else if (status === HttpStatus.Bad_Request) {
        setToaster({
          isDisplay: true,
          message: invalidOtp,
          type: 'error'
        })
      }
    }
  }, [response])

  return (
    <Authentication>
      <UserWorkspaceComponent
        theme={theme}
        email={email}
        userKey={otp}
        isTenant={_isTenant}
        tenant_name={tenantName}
        validateUrl={token}
        setResponse={setResponse}
        setUrlValidateResponse={setUrlValidateResponse}
        isLoading={addCartsLoading || isLoading}
        layoutText={{
          seoButtonText: 'gt-create-workspace-btn'
        }}
      />
    </Authentication>
  )
}
