import { Box, type IBoxProps } from '@ntpkunity/controls'
import { styled } from '@mui/material/styles'

export const ContentBox = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.content-box': {
    position: 'relative',
    border: '1px solid' + theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    padding: 24,
    marginBottom: 24,
    '&:last-child': {
      marginBottom: 0
    },
    '.dashboard-tabs-wrap': {
      '.tab-list-default': {
        position: 'absolute',
        right: 24,
        top: 24,
        zIndex: 1,
        '.default-varient-btn': {
          padding: '6px 12px'
        },
        [theme.breakpoints.down('md')]: {
          position: 'relative',
          right: 0,
          top: 0
        }
      }
    },
    '.chart-area': {
      '&.dummy-placeholder': {
        backgroundColor: theme.palette.grey[50],
        minHeight: 320,
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    '.ledger': {
      '.ledger-indicator': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        '&.ind-info': {
          backgroundColor: theme.palette.primary.main
        },
        '&.ind-warning': {
          backgroundColor: theme.palette.warning.main
        }
      }
    },
    '.prd-features-wrap': {
      maxHeight: 336,
      overflowY: 'auto',
      overflowX: 'hidden',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0
      },
      '&::-webkit-scrollbar-track': {
        padding: '0 0px',
        backgroundColor: 'trasnparent'
      },
      '&::-webkit-scrollbar-thumb': {
        transition: 'all 0.3s',
        backgroundColor: 'trasnparent'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'trasnparent'
      }
    },
    '.feat-item': {
      display: 'flex',
      borderBottom: '1px solid' + theme.palette.grey[100],
      paddingBottom: 16,
      marginBottom: 16,
      '&:last-child': {
        paddingBottom: 0,
        marginBottom: 0,
        border: 'none'
      },
      '.feat-img': {
        width: 48,
        height: 48,
        flexShrink: 0,
        backgroundColor: theme.palette.grey[50],
        borderRadius: theme.shape.borderRadius,
        marginRight: 16,
        img: {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center center'
        }
      }
    },
    '.feat-content': {
      flexGrow: 1,
      '.btn': {
        '&.btn-default': {
          color: theme.palette.primary.main,
          '.MuiButton-endIcon': {
            transform: 'rotate(180deg)'
          },
          '&:hover': {
            color: theme.palette.primary.main
          },
          '&:focus': {
            color: theme.palette.primary.main
          }
        }
      }
    },
    '.progress-wrap': {
      '.u-progress-bar': {
        '&.progress-header': {
          backgroundColor: theme.palette.grey[100],
          height: 8
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      padding: 16
    }
  },
  '&.metabase-chart': {
    '.loader-wrap': {
      minHeight: 480
    }
  }
}))

export const SelectUnderline = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.underline-select': {
    borderRight: '1px solid' + theme.palette.grey[100],
    paddingRight: 16,
    marginRight: 16,
    '.u-form-group': {
      '.u-form-control': {
        height: 32,
        '&.u-select': {
          '.MuiInputBase-input': {
            padding: '6px 24px 6px 0'
          },
          svg: {
            right: 0
          },
          fieldset: {
            borderColor: 'transparent transparent rgba(0, 0, 0, 0.23)',
            borderRadius: 0
          },
          '&.Mui-focused': {
            fieldset: {
              borderColor:
                'transparent transparent' + theme.palette.primary.main
            }
          }
        }
      },
      '.u-input-label': {
        transform: 'translate(0px, 6px) scale(1)',
        '&.Mui-focused': {
          transform: 'translate(0px, 6px) scale(1)',
          color: 'rgba(0,0,0,0.87)'
        },
        '&.MuiFormLabel-filled': {
          display: 'none'
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      borderRight: 'none',
      paddingRight: 0,
      marginRight: 0
    }
  }
}))

export const SectionHead = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.section-head': {
    position: 'relative',
    zIndex: 2,
    '.head-control-wrap': {
      '.date-range ': {
        '.rmdp-container ': {
          input: {
            height: 40
          }
        },
        '.calendar-icon': {
          top: 9,
          right: 12
        }

      },
      '.date-range-picker': {
        marginLeft: 16,
        '.react-datepicker__input-container': {
          input: {
            height: 40,
            padding: '8px 36px 8px 16px',
            width: '100%'

          }
        },
        '.calendar-icon': {
          right: 8,
          top: 8
        },
        '.u-input-label': {
          transform: 'translate(12px, -40px) scale(1)'
        },
        '&.focused': {
          '.u-input-label': {
            transform: 'translate(12px, -58px) scale(0.85)'
          }
        },
        '&.filled': {
          '.u-input-label': {
            transform: 'translate(12px, -58px) scale(0.85)'
          }
        }
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      },
      '.u-form-group': {
        '.u-form-control': {
          '&.u-select': {
            height: 40
          }
        },
        '.u-input-label': {
          transform: 'translate(12px, 8px) scale(1)',
          '&.MuiFormLabel-filled': {
            transform: 'translate(14px, -9px) scale(0.85)'
          },
          '&.Mui-focused': {
            transform: 'translate(14px, -9px) scale(0.85)'
          }
        }
      }
    }
  }
}))
