import { useTheme } from '@mui/material'
import { type FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Authentication } from '@modules/authentication'
import { APP_ROUTES } from '@router/path'
import { SignupComponent, type IResponse } from '@ntpkunity/controls-ums'

import { useStoreContext } from 'store/storeContext'
import {
  getItemByKey,
  setItembyKey,
  setItem,
  isLoggedIn
} from 'shared'
import {
  className,
  EMAIL,
  HttpStatus,
  CURRENT_ROUTE,
  SIGNUP_STEPS,
  CART_DATA
} from '@shared/constants'

export const Registration: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const {
    actions: { setUserProfile, setToaster },
    states: { userProfile, cart }
  }: any = useStoreContext()

  const [response, setResponse] = useState<IResponse>()

  useEffect(() => {
    if (response !== undefined) {
      const { status, data } = response
      const {
        email,
        signup_steps: signupSteps,
        is_tenant: isTenant
      } = data ?? {}
      setItembyKey(SIGNUP_STEPS, signupSteps?.toLowerCase())
      setItem(EMAIL, email)

      if (status === HttpStatus.OK) {
        cart.cart.length > 0 &&
          setItembyKey(CART_DATA, { cartlist: cart.cart })

        setUserProfile({
          ...userProfile,
          user_name: email,
          signup_steps: signupSteps
        })

        navigate(APP_ROUTES.OTP, {
          replace: true,
          state: {
            email: data?.email,
            isTenant,
            signupSteps
          }
        })
      } else if (status === HttpStatus.Unauthorized) {
        setToaster({
          isDisplay: true,
          message: 'Password Expired: reset password email sent',
          duration: 3000,
          type: ''
        })
      }
    }
  }, [response])

  useEffect(() => {
    if ((isLoggedIn() ?? '')?.length !== 0) {
      navigate(getItemByKey(CURRENT_ROUTE))
    }
  }, [])

  return (
    <Authentication>
      <SignupComponent
        theme={theme}
        layoutText={{
          title: 'Get Started ',
          subTitle: 'Now!',
          seoButtonText: `${pathname.includes(APP_ROUTES.LOGIN) ? className.loginContinueBtn : className.signinContinueBtn}`
        }}
        setResponse={setResponse}
      />
      {/* <Box theme={theme} className='terms-text-area' mt={3}>
          <Typography theme={theme} variant='caption' component='span' display={'block'} className='text-muted'>
            Already a member? <a href="#" className='fw-medium text-primary link' onClick={() => { navigate(APP_ROUTES.SIGNUP) }}>Login</a>
          </Typography>
        </Box> */}
    </Authentication>
  )
}
