import { useTheme } from '@mui/material'
import { Typography, Container } from '@ntpkunity/controls'
import { MainContent } from '../../pages/unsubscription/unsubscriptionStyle'
import { FullPageMessage } from '@shared/components'
import { HomePageLayout } from '@layout/index'
import UnSubErrorImage from '../../../../shared/assets/images/unsub-error-img.svg'
import { type FC } from 'react'

export const UnsubscriptionErrorHandling: FC = () => {
  const theme = useTheme()

  return (
    <>
      <MainContent className="main-content" theme={theme}>
        <HomePageLayout>
          <Container theme={theme} maxWidth="lg" className="custom-container">
            <FullPageMessage
              messageImage={UnSubErrorImage}
              imageAltText={'Successfully Unsubscribed'}
            >
              <Typography
                theme={theme}
                variant="h2"
                component="h2"
                className="text-h2"
              >
                Error Occurred!
              </Typography>
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                display={'block'}
                mt={{ xs: 2, md: 3 }}
                className="text-body2"
              >
                We have encountered a problem. Please try again from the email
                you got earlier. If the issue still persists, log a
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={process.env.SUPPORT_SERVICE}
                  className="text-primary link"
                >
                  {' '}
                  support ticket{' '}
                </a>
                or{' '}
                <a
                  href={`mailto:${process.env.SUPPORT_EMAIL as string}`}
                  className="text-primary link"
                >
                  email{' '}
                </a>
                us for assistance.
              </Typography>
            </FullPageMessage>
          </Container>
        </HomePageLayout>
      </MainContent>
    </>
  )
}
