import { useTheme } from '@mui/material'
import { SectionContent } from './billingDetailsStyle'
import {
  PaymentMethodComponent,
  PaymentPlan
} from '@modules/billingDetails/components'
import { type FC } from 'react'

export const BillingDetails: FC = () => {
  const theme = useTheme()

  return (
    <>
      <SectionContent
        theme={theme}
        className="section-content"
        mt={{ xs: 0, md: 5 }}
        pb={4}
        mb={4}
      >
        <PaymentMethodComponent />
      </SectionContent>
      <SectionContent
        theme={theme}
        className="section-content"
        mt={{ xs: 0, md: 5 }}
        pb={{ xs: 0, md: 4 }}
        mb={{ xs: 0, md: 4 }}
      >
        <PaymentPlan />
      </SectionContent>
    </>
  )
}
