import { Footer, Header } from '@layout/components'
import { useTheme } from '@mui/material'
import { Box, Container, Grid, Typography } from '@ntpkunity/controls'
import { MainContent, ProductBox } from './singleSignOnStyle'
import DeviceImage from '../../../../shared/assets/images/device-img.svg'
import MarketplaceImage from '../../../../shared/assets/images/support-services.png'
import { useGetUserProducts } from '@modules/authentication'
import { useGetUserRoles } from '@modules/myAccount'
import { type FC, useEffect, useState } from 'react'
import { type IAllowedProducts, type ILocation } from '@shared/typings'
import { redirectTo, removeItembyKey } from '@shared/helper'
import { useLocation } from 'react-router-dom'
import { FREE_TRIAL_PRODUCT } from '@shared/constants'

const marketPlaceProduct = {
  id: 99,
  name: 'Marketplace',
  image_url: MarketplaceImage,
  product_path: 'market-place/customer-account',
  documentation_url: ''
}
export const SignleSignOnPage: FC = () => {
  const theme = useTheme()
  const { state } = useLocation()
  const { data: userRoles } = useGetUserRoles()
  const { data: userProducts } = useGetUserProducts()

  const [userAllowedProducts, setUserAllowedProducts] = useState<
  IAllowedProducts[]>((state as ILocation)?.allowedProducts ?? [])

  useEffect(() => {
    removeItembyKey(FREE_TRIAL_PRODUCT)
    if ((state as ILocation)?.is_admin) {
      setUserAllowedProducts([
        ...(state as ILocation).allowedProducts,
        marketPlaceProduct
      ])
    }
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!(state as ILocation)?.allowedProducts) {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (userProducts) {
        setUserAllowedProducts(userProducts)
      }
    }
  }, [userProducts])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!(state as ILocation)?.allowedProducts) {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (userRoles) {
        const _isAdmin: boolean = userRoles?.some((role) => role.is_admin)

        if (_isAdmin) {
          setUserAllowedProducts([...userProducts, marketPlaceProduct])
        } else {
          if (userProducts.length === 1) {
            redirectTo(
              `${process.env.NEXT_SCREEN_BASE_URL as string}/${userProducts[0].product_path as string}`
            )
          } else {
            setUserAllowedProducts(userProducts)
          }
        }
      }
    }
  }, [userRoles, userProducts])

  return (
    <>
      <Header theme={theme} />
      <MainContent theme={theme} className="main-content">
        <Container theme={theme} maxWidth="lg" className="custom-container">
          <Box theme={theme} className="full-page-wrap">
            <Typography
              theme={theme}
              variant="h2"
              component="h2"
              className="text-h2"
              mb={3}
            >
              Choose what you want to use!
            </Typography>
            <Box
              theme={theme}
              className="product-wrap"
              width={'100%'}
              maxWidth={{ lg: '75%' }}
            >
              <Grid
                theme={theme}
                container
                item
                spacing={3}
                justifyContent="center"
              >
                {userAllowedProducts?.map((product) => {
                  const navigationLink = (product.product_path ?? '').length > 0
                    ? `${process.env.NEXT_SCREEN_BASE_URL as string}/${product.product_path}`
                    : `${process.env.DOCUMENTATION as string}${product.documentation_url}`
                  return (
                    <Grid
                      key={product.id}
                      theme={theme}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                    >
                      <ProductBox theme={theme} className="product-box">
                        <a
                          href={navigationLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="prd-link"
                        >
                          <Box theme={theme} className="prd-img">
                            <img
                              src={product.image_url ?? DeviceImage}
                              alt="Product Image"
                            />
                          </Box>
                          <Typography
                            className="prd-name"
                            theme={theme}
                            variant="body1"
                            component="p"
                          >
                            {product.name}
                          </Typography>
                        </a>
                      </ProductBox>
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
          </Box>
        </Container>
      </MainContent>
      <Footer />
    </>
  )
}
