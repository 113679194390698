import Http from '@shared/helper/http-api'
import { useQuery } from 'react-query'

const BASE_URL = process.env.BASE_URL

export const CARD_INFO = 'marketplace/user-management/retrieve-source'

export const useGetCardInfo = (): any => {
  const { data, error } = useQuery<any>(
    CARD_INFO,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(CARD_INFO)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error }
}
