import { networkError } from '@shared/constants/messages'
import axios, { type AxiosInstance } from 'axios'
import { ACCESS_TOKEN_KEY } from '@shared/constants'
import { LocalStorage } from 'shared/helper/browserStorage'
import { AppError } from './errors'
import { getItem } from './localstorage'
import { logoutMarketplace } from './methods'
import { handleRefreshToken } from '@ntpkunity/controls-ums'
interface Setting {
  baseUrl?: string
  withAuthentication?: boolean
}

class Http {
  _localStorageService: LocalStorage
  _baseUrl: string
  _token: string | null
  _axiosInstance: AxiosInstance

  constructor (settings?: Setting) {
    this._localStorageService = new LocalStorage()
    this._baseUrl = settings?.baseUrl ?? process.env.API_GATEWAY ?? ''
    this._token = settings?.withAuthentication === true
      ? `Bearer ${this._localStorageService.getItemByKey(ACCESS_TOKEN_KEY) as string}`
      : null

    this._axiosInstance = axios.create({
      baseURL: this._baseUrl,
      headers: {
        Authorization: `Bearer ${this._localStorageService.getItemByKey(ACCESS_TOKEN_KEY) as string}` ?? ''
      }
    })

    this._axiosInstance.interceptors.response.use(
      (response) => {
        return response
      },
      async (error) => {
        await handleRefreshToken(error, this._axiosInstance, logoutMarketplace)
      })
  }

  static createConnection (settings?: Setting): Http {
    return new Http(settings)
  }

  public async get<T>(url: string, params?: any, options?: any): Promise<T> {
    const response = this._axiosInstance.get<T>(`${this._baseUrl}/${url}`, {
      headers: {
        Authorization: `Bearer ${getItem(ACCESS_TOKEN_KEY)}` ?? ''
      }
    })
    return await response
      .then((resp) => {
        return resp?.data
      })
      .catch((error) => {
        const {
          data: {
            data: { message }
          },
          status
        } = error.response
        throw new AppError(
          `Http.post(${this._baseUrl}/${url})`,
          message ?? networkError,
          error.code,
          error.response.status,
          { params, options, error, status }
        )
      })
  }

  public getPaginated (url: string, params?: any, options?: any): any {
    try {
      throw new AppError(
        `Http.getPaginated(${this._baseUrl}/${url})`,
        'NOT_IMPLEMENTED'
      )
    } catch (error) {
      throw new AppError(
        `Http.getPaginated(${this._baseUrl}/${url})`,
        error,
        undefined,
        undefined,
        { params, options }
      )
    }
  }

  public async post<T>(url: string, body?: any, options?: any): Promise<T> {
    const response = this._axiosInstance.post<T>(`${this._baseUrl}/${url}`, body, {
      headers: {
        Authorization: `Bearer ${getItem(ACCESS_TOKEN_KEY)}` ?? ''
      },
      ...options
    })
    return await response
      .then((resp) => {
        return resp?.data
      })
      .catch((error) => {
        const { data, status } = error.response
        throw new AppError(
          `Http.post(${this._baseUrl}/${url})`,
          data?.message ?? data?.error?.message ?? error.message,
          error.code,
          error.response.status,
          { body, options }
        )
      })
  }

  public async put<T>(url: string, body?: any, options?: any): Promise<T> {
    const response = this._axiosInstance.put<T>(`${this._baseUrl}/${url}`, body, {
      headers: {
        Authorization: `Bearer ${getItem(ACCESS_TOKEN_KEY)}` ?? ''
      }
    })
    return await response
      .then((resp) => {
        return resp?.data
      })
      .catch((error) => {
        const {
          data: {
            data: { message }
          },
          status
        } = error.response
        throw new AppError(
          `Http.post(${this._baseUrl}/${url})`,
          message ?? networkError,
          error.code,
          error.response.status,
          { body, options }
        )
      })
  }

  public async patch<T>(url: string, body?: any, options?: any): Promise<T> {
    const response = this._axiosInstance.patch<T>(`${this._baseUrl}/${url}`, body, {
      headers: {
        Authorization: `Bearer ${getItem(ACCESS_TOKEN_KEY)}` ?? ''
      }
    })
    return await response
      .then((resp) => {
        return resp?.data
      })
      .catch((error) => {
        const { data, status } = error.response
        throw new AppError(
          `Http.post(${this._baseUrl}/${url})`,
          data?.message ?? networkError,
          error.code,
          error.response.status,
          { body, options }
        )
      })
  }

  public async delete<T>(url: string, params?: any, options?: any): Promise<T> {
    const response = this._axiosInstance.delete<T>(`${this._baseUrl}/${url}`, {
      headers: {
        Authorization: `Bearer ${getItem(ACCESS_TOKEN_KEY)}` ?? ''
      }
    })
    return await response
      .then((resp) => {
        return resp?.data
      })
      .catch((error) => {
        const {
          data: {
            data: { message }
          },
          status
        } = error.response
        throw new AppError(
          `Http.post(${this._baseUrl}/${url})`,
          message ?? networkError,
          error.code,
          error.response.status,
          { params, options }
        )
      })
  }
}
export default Http
