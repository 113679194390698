
import Http from '@shared/helper/http-api'
import { type IBlog } from '@shared/typings'
import { type UseQueryResult, useQuery } from 'react-query'
import { type AppError } from 'single-spa'

const BASE_URL = process.env.BASE_URL
const BLOGS_DETAIL = 'signup/blog_by_title'
const BLOGS = 'signup/get-all-blogs'
const BLOG_BY_TAG = 'signup/blog_by_tag'

export const useGetBlogDetails = (title: string | undefined): UseQueryResult<IBlog, AppError | undefined> => {
  const url = `${BLOGS_DETAIL}/${title}`
  return useQuery(
    [BLOGS_DETAIL, title],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<IBlog>(url)
    },
    { refetchOnWindowFocus: false, enabled: !!title }
  )
}

export const useGetAllBlogs = (queryParams: string): UseQueryResult<IBlog[], AppError | undefined> => {
  const url = `${BLOGS}${queryParams}`
  return useQuery(
    [BLOGS, queryParams],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<IBlog[]>(url)
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  )
}

export const useGetBlogByTag = (tag: string | undefined): UseQueryResult<IBlog, AppError | undefined> => {
  const url = `${BLOG_BY_TAG}/${tag}`
  return useQuery(
    [BLOG_BY_TAG, tag],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<IBlog>(url)
    },
    { refetchOnWindowFocus: false, enabled: !!tag }
  )
}
