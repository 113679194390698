import { useTheme } from '@mui/material'
import {
  Box,
  DataTable,
  Grid,
  Input,
  Icon,
  Menu,
  Typography,
  Checkbox,
  Button,
  Tooltip
} from '@ntpkunity/controls'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { InvoiceCard, MainContent, TableWrap } from './transactionHistoryStyle'
import {
  useTransactionHistory,
  usePaymentIntents
} from '@modules/product/services'
import {
  convertToAmountWithDecimals,
  openInNextWindow,
  stripeAmount,
  unixFormatedDate
} from '@shared/helper'
import { dateFormat, MenuOptions, ReceiptStatus } from '@shared/constants'
import { useEffect, useState, useMemo, type FC } from 'react'
import { useStoreContext } from 'store/storeContext'
import { type ITransaction } from '@shared/typings'
import { TableSkeleton } from '@shared/TableSkelton/TableSkelton'

export const TransactionHistory: FC = () => {
  const {
    states: { userProfile }
  } = useStoreContext()

  const theme = useTheme()
  const [date, setDate] = useState('')
  const [productDiscription, setProductDiscription] = useState('')
  const [paymentStatus, setPaymentStatus] = useState('')
  const [invoiceTotal, setInvoiceTotal] = useState('')
  const [filteredData, setFilteredData] = useState<any[]>([])
  const [selected, setSelected] = useState<any[]>([])

  const { data: paymentIntentHistory, isLoading: paymentIntentHistoryLoading } =
    usePaymentIntents(userProfile?.user_id)

  const { data: transactionHistory, isLoading: transactionHistoryLoading } =
    useTransactionHistory(userProfile?.user_id)

  const transactionHistoryMap = useMemo(
    () =>
      new Map(
        (transactionHistory ?? []).map((transaction) => [
          transaction.id,
          transaction
        ])
      ),
    [transactionHistory]
  )

  const paymentIntentHistoryMap = useMemo(
    () =>
      new Map(
        (paymentIntentHistory ?? []).map((paymentIntent) => [
          paymentIntent.invoice,
          paymentIntent
        ])
      ),
    [paymentIntentHistory]
  )

  useEffect(() => {
    setFilteredData(Array.from(transactionHistoryMap.values()))
  }, [transactionHistoryMap])

  // const numSelected = selected.length
  // const rowCount = filteredData?.length

  // const enableBulkActions = numSelected > 0
  // const disableBulkActions = numSelected <= 0
  // const selectedSomeTransactions = numSelected > 0 && numSelected < rowCount
  // const selectedAllTransactions = rowCount > 0 && numSelected === rowCount

  const filterTransactionsByProductDescription = (transactions): any => {
    return transactions?.filter((transaction) => {
      const prodDiscToMatch = transaction?.lines?.data
        .map((product) => product.price.nickname)
        .toString()
      return prodDiscToMatch
        ?.toLowerCase()
        .includes(productDiscription.toLowerCase().trim())
    })
  }

  const filterTransactionByInvoiceTotal = (transactions): any => {
    return transactions?.filter((transaction) => {
      const totalToMatch = transaction?.amount_due?.toString()
      return totalToMatch?.includes(invoiceTotal.trim())
    })
  }

  const filterTransactionByPaymentStatus = (transactions): any => {
    return transactions?.filter((transaction) => {
      const paymentIntentForTransaction: any = paymentIntentHistoryMap.get(
        transaction.id
      )
      const paid = paymentIntentForTransaction?.last_payment_error === null

      const statusToMatch = paid
        ? transaction?.status
        : paymentIntentForTransaction?.charges?.data[0]?.status

      const status = paymentStatus.toLowerCase().trim().includes('de')
        ? ReceiptStatus.FAILED
        : paymentStatus.toLowerCase().trim().includes('p')
          ? ReceiptStatus.PAID
          : ''
      return statusToMatch?.includes(status)
    })
  }

  const filterTransactionByDate = (transactions): any => {
    return transactions?.filter((transaction) => {
      const paymentIntentForTransaction: any = paymentIntentHistoryMap.get(
        transaction.id
      )
      const paid = paymentIntentForTransaction?.last_payment_error === null

      const dateToMatch = paid
        ? unixFormatedDate(transaction?.status_transitions?.paid_at, dateFormat)
        : unixFormatedDate(
          paymentIntentForTransaction?.charges?.data[0]?.created,
          dateFormat
        )

      return dateToMatch.toLowerCase().includes(date.toLowerCase().trim())
    })
  }

  useEffect(() => {
    let filteredTransactions = Array.from(transactionHistoryMap.values())
    filteredTransactions = filterTransactionByDate(filteredTransactions)
    filteredTransactions =
      filterTransactionsByProductDescription(filteredTransactions)
    filteredTransactions =
      filterTransactionByPaymentStatus(filteredTransactions)
    filteredTransactions =
      filterTransactionByInvoiceTotal(filteredTransactions)

    setFilteredData(filteredTransactions)
  }, [productDiscription, invoiceTotal, paymentStatus, date])

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelected = filteredData.map((n: ITransaction) => n.id)
  //     setSelected(newSelected)
  //     return
  //   }
  //   setSelected([])
  // }

  const isSelected = (id: string): boolean => selected.includes(id)

  const handleClick = (event, transactionId): void => {
    if (!selected.includes(transactionId)) {
      setSelected((prevSelected) => [...prevSelected, transactionId])
    } else {
      setSelected((prevSelected) =>
        prevSelected.filter((id) => id !== transactionId)
      )
    }
  }

  const handleOptionClick = (_event, key, value): void => {
    switch (key) {
      case MenuOptions.DOWNLOAD_RECEIPT: {
        const receiptUrl = value?.receipt_url?.split('?')[0] as string + '/pdf'
        openInNextWindow(receiptUrl)
        break
      }
      case MenuOptions.DOWNLOAD_INVOICE: {
        const invoiceUrl = value?.invoice_pdf
        openInNextWindow(invoiceUrl)
        break
      }
    }
  }

  // const handleBulkOptionClick = (_event, key, value) => {
  //   const selectedTransactions = Array.from(
  //     transactionHistoryMap.values()
  //   ).filter(
  //     (transaction: any) =>
  //       selected.includes(transaction.id) &&
  //       transaction.status === ReceiptStatus.PAID
  //   )

  //   switch (key) {
  //     case MenuOptions.DOWNLOAD_RECEIPT:
  //       break
  //     case MenuOptions.VIEW_RECEIPT:
  //       break
  //     default:
  //   }
  // }

  return (
    <MainContent theme={theme} className="main-content" mt={{ xs: 0, md: 5 }}>
      <Typography theme={theme} variant="h3" component="h3" className="text-h3">
        Transaction History
      </Typography>
      <Grid theme={theme} container item spacing={3} display={'none'}>
        <Grid theme={theme} item xs={12} sm={6}>
          <InvoiceCard
            theme={theme}
            className="invoice-card"
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent="center"
          >
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
            >
              Next invoice issue date
            </Typography>
            <Typography theme={theme} variant="h3" component="h3">
              Dec 25, 2022
            </Typography>
          </InvoiceCard>
        </Grid>
        <Grid theme={theme} item xs={12} sm={6}>
          <InvoiceCard
            theme={theme}
            className="invoice-card"
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent="center"
          >
            <Typography
              className="text-muted"
              theme={theme}
              variant="body2"
              component="span"
            >
              Invoice Total
            </Typography>
            <Typography theme={theme} variant="h3" component="h3">
              $ 000.00
            </Typography>
          </InvoiceCard>
        </Grid>
      </Grid>
      <TableWrap theme={theme} className="table-wrap" mt={4}>
        <DataTable
          theme={theme}
          variant="basic"
          theadChildren={
            <>
              <TableRow>
                <TableCell className="checkbox-cell">
                  {/* <Checkbox
                    theme={theme}
                    label=""
                    onChange={handleSelectAllClick}
                    checkBoxChecked={selectedAllTransactions}
                    checkBoxIndeterminate={selectedSomeTransactions}
                  ></Checkbox> */}
                </TableCell>
                <TableCell>Date</TableCell>
                <TableCell className="cell-md">Product Description</TableCell>
                <TableCell>Payment Status</TableCell>
                <TableCell>Invoice Total</TableCell>
                <TableCell className="action-cell fixed-cell">
                  {/* <Menu
                    theme={theme}
                    options={[
                      {
                        optionText: "View Receipt",
                        optionkey: MenuOptions.VIEW_RECEIPT,
                        disabled: disableBulkActions,
                      },
                      {
                        optionText: "Download Receipt",
                        optionkey: MenuOptions.DOWNLOAD_RECEIPT,
                        disabled: disableBulkActions,
                      },
                    ]}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    handleOptionClick={handleBulkOptionClick}
                    render={(cb) => (
                      <Button
                        onClick={cb}
                        defaultBtn
                        theme={theme}
                        iconText={<Icon name="MoreIcon" />}
                      ></Button>
                    )}
                  /> */}
                </TableCell>
              </TableRow>
              <TableRow className="filters-row">
                <TableCell className="checkbox-cell"></TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      placeholder="Search..."
                      value={date}
                      onChange={(e) => { setDate(e) }}
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="cell-md">
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      placeholder="Search..."
                      value={productDiscription}
                      onChange={(e) => { setProductDiscription(e) }}
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      placeholder="Search..."
                      value={paymentStatus}
                      onChange={(e) => { setPaymentStatus(e) }}
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      placeholder="Search..."
                      value={invoiceTotal}
                      onChange={(e) => { setInvoiceTotal(e) }}
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell"></TableCell>
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {transactionHistoryLoading || paymentIntentHistoryLoading
                ? (
                <TableSkeleton theme={theme} columns={6} rows={4} />
                  )
                : (
                    filteredData
                      ?.filter(
                        (transaction: ITransaction) =>
                          !(
                            transaction?.status === ReceiptStatus.DRAFT ||
                        transaction?.status === ReceiptStatus.VOID
                          )
                      )
                      .map((transaction: ITransaction, index: number) => {
                        const isItemSelected = isSelected(transaction.id)
                        const disableIndiviualRecordSelection =
                      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                      !transaction.charge ||
                      transaction.status !== ReceiptStatus.PAID
                        const paymentIntentForTransaction: any =
                      paymentIntentHistoryMap.get(transaction.id)
                        // last_payment_error is null when paid and contains error object on payment failure
                        const paid = transaction?.status === ReceiptStatus.PAID ?? paymentIntentForTransaction?.last_payment_error === null
                        // incase of payment failure we need to pick date from charges created
                        const date = paid
                          ? unixFormatedDate(
                            transaction?.status_transitions?.paid_at,
                            dateFormat
                          )
                          : unixFormatedDate(
                            paymentIntentForTransaction?.charges?.data[0]
                              ?.created ?? paymentIntentForTransaction?.created,
                            dateFormat
                          )
                        return (
                      <TableRow key={index}>
                        <TableCell className="checkbox-cell">
                          <Checkbox
                            theme={theme}
                            label=""
                            checkBoxChecked={isItemSelected}
                            onChange={(event) => { handleClick(event, transaction.id) }
                            }
                          ></Checkbox>
                        </TableCell>
                        <TableCell>{date}</TableCell>
                        <TableCell className="cell-md">
                          <Tooltip
                            title={
                              <>
                                <Box theme={theme}>
                                  {transaction?.lines?.data.map(
                                    (product, index) => (
                                      <Box
                                        theme={theme}
                                        display={'flex'}
                                        key={index}
                                      >
                                        <Box
                                          theme={theme}
                                          flexGrow={1}
                                          whiteSpace="normal"
                                        >
                                          {product.price.nickname ??
                                            product.description}
                                        </Box>
                                        <Box
                                          theme={theme}
                                          flexShrink={0}
                                          ml={3}
                                        >
                                          {convertToAmountWithDecimals(
                                            stripeAmount(product.amount),
                                            transaction.currency
                                          )}
                                        </Box>
                                      </Box>
                                    )
                                  )}
                                </Box>
                              </>
                            }
                            theme={theme}
                          >
                            <>
                              {transaction?.lines?.data
                                .map(
                                  (product) =>
                                    product.price.nickname ??
                                    product.description
                                )
                                .join(', ')}
                            </>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          {paid
                            ? (
                            <>
                              <span className="status-indicator ind-success"></span>
                              Paid
                            </>
                              )
                            : (
                            <>
                              <span className="status-indicator ind-danger"></span>
                              Declined
                            </>
                              )}
                        </TableCell>
                        <TableCell>
                          {convertToAmountWithDecimals(
                            stripeAmount(transaction.amount_due),
                            transaction.currency
                          )}
                        </TableCell>
                        <TableCell className="action-cell fixed-cell">
                          <Menu
                            theme={theme}
                            options={[
                              // {
                              //   optionText: "View Receipt",
                              //   optionkey: MenuOptions.VIEW_RECEIPT,
                              //   optionValue: transaction,
                              //   disabled: disableIndiviualRecordSelection,
                              // },
                              {
                                optionText: 'Download Invoice',
                                optionkey: MenuOptions.DOWNLOAD_INVOICE,
                                optionValue: transaction,
                                disabled: disableIndiviualRecordSelection
                              },
                              {
                                optionText: 'Download Receipt',
                                optionkey: MenuOptions.DOWNLOAD_RECEIPT,
                                optionValue: transaction,
                                disabled: disableIndiviualRecordSelection
                              }
                            ]}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right'
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right'
                            }}
                            handleOptionClick={handleOptionClick}
                            render={(cb) => (
                              <Button
                                onClick={cb}
                                defaultBtn
                                theme={theme}
                                iconText={<Icon name="MoreIcon" />}
                              ></Button>
                            )}
                          />
                        </TableCell>
                      </TableRow>
                        )
                      })
                  )}
            </>
          }
        />
      </TableWrap>
    </MainContent>
  )
}
