import { type IContactUs } from '@shared/typings'
import Http from '@shared/helper/http-api'
import { type UseMutateFunction, useMutation } from 'react-query'

const BASE_URL = process.env.BASE_URL
const CONTACT_US = 'signup/contact-us'

export const useContactUs = (): {
  data: IContactUs | undefined
  isLoading: boolean
  mutate: UseMutateFunction<IContactUs, any, IContactUs, unknown>
  error: string
} => {
  const { data, isLoading, mutate, error } = useMutation<IContactUs, any, IContactUs>(
    async (body) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(CONTACT_US, body)
    }
  )
  return { data, isLoading, mutate, error: error?.message }
}
