import { useTheme } from '@mui/material'
import { Box, Grid, Input, Checkbox } from '@ntpkunity/controls'
import { MASTER_CARD_REGEX, VISA_CARD_REGEX } from '@shared/constants'
import { CardType, Validation } from '@shared/constants/enums'
import { validation } from '@shared/helper/methods'
import { type FC, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import masterCard from '@shared/assets/images/payment-mastercard.svg'
import visaCard from '@shared/assets/images/payment-visa.svg'
import { messages } from '../../pages'

interface ICardSectionProps {
  formMethod: any
}

export const CardSection: FC<ICardSectionProps> = ({ formMethod }) => {
  const theme = useTheme()
  const {
    control,
    formState: { errors },
    watch
  } = formMethod
  const formValues = watch()
  const [cardType, setCardType] = useState<string>('')

  useEffect(() => {
    if (MASTER_CARD_REGEX.exec(formValues.number) != null) {
      setCardType(CardType.MASTERCARD)
    } else if (VISA_CARD_REGEX.exec(formValues.number) != null) {
      setCardType(CardType.VISA)
    }
  }, [formValues.number])

  return (
    <Grid theme={theme} container item spacing={3}>
      <Grid item theme={theme} xs={12}>
        <Grid theme={theme} container item spacing={3}>
          <Grid theme={theme} item xs={12}>
            <Box theme={theme} className="payment-btns">
              <Checkbox
                theme={theme}
                checkBoxDisabled={true}
                label={
                  <>
                    <img src={masterCard} alt="" />
                  </>
                }
                checkBoxChecked={cardType === CardType.MASTERCARD}
              ></Checkbox>
              <Checkbox
                checkBoxDisabled={true}
                label={
                  <>
                    <img src={visaCard} alt="" />
                  </>
                }
                checkBoxChecked={cardType === CardType.VISA}
              ></Checkbox>
            </Box>
          </Grid>
          <Grid
            theme={theme}
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            className="relative-grid"
          >
            <Controller
              name={messages.name.cardNumber}
              control={control}
              rules={validation('Card number', Validation.REQUIRED)}
              render={({ field }) => (
                <Input
                  maskNumeric
                  masking
                  format="#### #### #### ####"
                  theme={theme}
                  fullWidth
                  placeholder="0000 0000 0000 0000"
                  label={messages.label.cardNumber}
                  type={'text'}
                  {...field}
                  error={errors?.number?.message}
                  onChange={(e) => {
                    field.onChange(e)
                    if (e.length === 2) {
                      if (MASTER_CARD_REGEX.exec(e) != null) {
                        setCardType(CardType.MASTERCARD)
                      } else {
                        setCardType(CardType.VISA)
                      }
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid theme={theme} item xs={12} sm={6} md={6} lg={6}>
            <Controller
              name={messages.name.cardHolderName}
              control={control}
              rules={validation(
                'Card holder name',
                Validation.REQUIRED,
                Validation.WHITE_SPACE,
                Validation.LIMIT_LENGTH,
                '50',
                Validation.ONLY_ALPHABETS
              )}
              render={({ field }) => (
                <Input
                  theme={theme}
                  fullWidth
                  placeholder="Sample Name"
                  label={messages.label.cardHolderName}
                  type={'text'}
                  {...field}
                  error={errors?.cardHolderName?.message}
                  onChange={(e) => {
                    field.onChange(e)
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item theme={theme} xs={12}>
        <Grid theme={theme} container item spacing={3}>
          <Grid
            theme={theme}
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            className="relative-grid"
          >
            <Controller
              name={messages.name.expiryDate}
              control={control}
              rules={validation('Expiry date', Validation.REQUIRED)}
              render={({ field }) => (
                <Input
                  maskNumeric
                  masking
                  format="##/##"
                  theme={theme}
                  fullWidth
                  placeholder="MM/YY"
                  label={messages.label.expiryDate}
                  type={'text'}
                  {...field}
                  error={errors?.expiry_date?.message}
                  onChange={(e) => {
                    field.onChange(e)
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            theme={theme}
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            className="relative-grid"
          >
            <Controller
              name={messages.name.cvc}
              control={control}
              rules={validation(messages.label.cvc, Validation.REQUIRED)}
              render={({ field }) => (
                <Input
                  maskNumeric
                  masking
                  format="###"
                  placeholder="000"
                  theme={theme}
                  fullWidth
                  label={messages.label.cvc}
                  type={'text'}
                  {...field}
                  error={errors?.cvc?.message}
                  onChange={(e) => {
                    field.onChange(e)
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
