import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const HowToConnectWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.how-to-connect-wrap': {
    backgroundColor: theme.palette.grey[900],
    position: 'relative',
    margin: 40,
    borderRadius: 40,
    '.inner-content': {
      padding: 120,
      overflow: 'hidden',
      [theme.breakpoints.down('lg')]: {
        padding: '120px 64px'
      },
      [theme.breakpoints.down('md')]: {
        padding: '64px 32px'
      }
    },
    '.text-white': {
      color: theme.palette.common.white
    },
    '.text-muted': {
      color: theme.palette.grey[200]
    },
    '.fw-light': {
      fontWeight: theme.typography.fontWeightLight
    },
    '.fw-normal': {
      fontWeight: theme.typography.fontWeightRegular
    },
    '.text-h1': {
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight
      }
    },
    '.badge': {
      backgroundColor: theme.palette.primary.main,
      padding: '8px 16px',
      borderRadius: 24,
      fontWeight: theme.customVariables.fontWeightSemiBold,
      letterSpacing: '0.5px'
    },
    '.prd-img': {
      height: 260,
      borderRadius: 16,
      overflow: 'hidden',
      position: 'relative',
      img: {
        maxHeight: 260,
        maxWidth: '100%',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center center',
        [theme.breakpoints.down('md')]: {
          maxHeight: 150,
          borderRadius: theme.shape.borderRadius
        }
      },
      '.play-icon': {
        cursor: 'pointer',
        position: 'absolute',
        left: '50%',
        top: '50%',
        zIndex: 1,
        transform: 'translate(-50%, -50%)',
        width: 80,
        height: 80,
        img: {
          maxHeight: 80,
          objectFit: 'contain',
          [theme.breakpoints.down('md')]: {
            maxHeight: 46
          }
        },
        [theme.breakpoints.down('md')]: {
          width: 46,
          height: 46
        }
      },
      [theme.breakpoints.down('md')]: {
        height: 150
      }
    },
    '.progress-steps': {
      display: 'flex',
      gap: 24,
      transition: 'all 0.3s ease-in-out 0s',
      '.step-item': {
        width: '100%',
        transition: 'all 0.8s ease-in-out 0s',
        position: 'relative',
        cursor: 'pointer',
        '&.active': {
          maxWidth: '38.6%',
          flexShrink: 0,
          '.desc-text': {
            opacity: 1,
            height: 'auto',
            [theme.breakpoints.up('md')]: {
              fontSize: theme.typography.body2.fontSize,
              lineHeight: theme.typography.body2.lineHeight
            },
            [theme.breakpoints.down('md')]: {
              height: '100%'
            }
          },
          '.step-count': {
            color: theme.palette.common.white
          },
          h4: {
            color: theme.palette.common.white,
            fontWeight: theme.customVariables.fontWeightSemiBold
          },
          [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
            '&:before': {
              minHeight: '100%'
            },
            '&:after': {
              minHeight: '100%',
              visibility: 'visible',
              transition: 'all 11s ease-in-out 0s'
            }
          },
          '.u-progress-bar': {
            '.MuiLinearProgress-bar': {
              opacity: 1,
              visibility: 'visible'
            }
          }
        },
        '.desc-text': {
          transition: 'all 0.8s ease-in-out 0s',
          height: 0,
          opacity: 0,
          [theme.breakpoints.up('md')]: {
            fontSize: 0,
            lineHeight: 0
          }
        },
        '.u-progress-bar': {
          borderRadius: 2,
          '&.progress-header': {
            backgroundColor: theme.palette.grey[800],
            [theme.breakpoints.down('md')]: {
              display: 'none'
            }
          },
          '.MuiLinearProgress-bar': {
            background: 'linear-gradient(90deg, #0033FD 0%, #00E4D0 100%)',
            opacity: 0,
            visibility: 'hidden'
          }
        },
        [theme.breakpoints.down('md')]: {
          maxWidth: '100%',
          '&:before': {
            display: 'block',
            content: '" "',
            backgroundColor: theme.palette.grey[800],
            width: 4,
            position: 'absolute',
            left: 0,
            top: 0,
            borderRadius: 2,
            transition: 'all 0.8s ease-in-out 0s',
            minHeight: 40
          },
          '&:after': {
            display: 'block',
            content: '" "',
            background: 'linear-gradient(180deg, #0033FD 0%, #00E4D0 100%)',
            width: 4,
            position: 'absolute',
            left: 0,
            top: 0,
            borderRadius: 2,
            transition: 'all 0s ease-in-out 0s',
            minHeight: 0,
            visibility: 'hidden'
          }
        }
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
      }
    },
    [theme.breakpoints.down('md')]: {
      margin: '80px 24px',
      overflow: 'hidden',
      borderRadius: 24
    }
  }
}))
