import { type ICart } from '@shared/typings'
import { type Dispatch, type ReducerAction } from 'react'
import type IActions from 'store/configs'

export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const UPDATE_CART = 'UPDATE_CART'
export const ADD_MULTIPLE_ITEMS_TO_CART = 'ADD_MULTIPLE_ITEMS_TO_CART'

export const initialState = {
  cart: [] as ICart[]
}

const reducer = (
  state: typeof initialState,
  action: IActions
): typeof initialState => {
  switch (action.type) {
    case ADD_TO_CART: {
      return {
        ...state,
        cart: [...state.cart, { ...action.payload }]
      }
    }
    case ADD_MULTIPLE_ITEMS_TO_CART:{
      return {
        ...state,
        cart: action.payload
      } }
    case REMOVE_FROM_CART:{
      return {
        ...state,
        cart: state.cart.filter((c) => c.product_id !== action.payload)
      } }
    case UPDATE_CART:{
      const updateCart = state.cart
      const res = updateCart.find(
        (item) => item.product_id === action.payload.product_id
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ) ?? {} as ICart
      res.account_type = action.payload.account_type
      res.product_name = action.payload.product_name
      res.product_id = action.payload.product_id
      res.price_id = action.payload.price_id
      res.package_name = action.payload.package_name
      res.amount = action.payload.amount
      return {
        ...state,
        cart: [...updateCart]
      } }
    default:
      return state
  }
}

export const addToCart = (
  data: any,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  dispatch({ type: ADD_TO_CART, payload: data })
}

export const addMultipleItemsToCart = (
  data: any,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  dispatch({ type: ADD_MULTIPLE_ITEMS_TO_CART, payload: data })
}

export const removeFromCart = (
  data: any,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  dispatch({ type: REMOVE_FROM_CART, payload: data })
}

export const updateCart = (
  data: any,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  dispatch({ type: UPDATE_CART, payload: data })
}

export default reducer
