import { type Dispatch, type ReducerAction } from 'react'
import { type IUserProfile } from '../configs'
import type IActions from '../configs'

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'

export const initialState: IUserProfile = {
  signup_steps: null,
  user_name: null,
  created_at: null,
  state: null,
  state_id: null,
  updated_at: null,
  zip_code: null,
  state_county: null,
  id: null,
  send_newsletter: null,
  address_line_1: null,
  user_id: null,
  industry_types_id: null,
  address_line_2: null,
  company_name: null,
  phone: null,
  first_name: null,
  last_name: null,
  other_industry_type: null,
  designation: null,
  country_id: null,
  city: null,
  is_deleted: null,
  is_tenant: null,
  is_admin: null,
  is_active: null,
  email: null,
  password: null,
  stripe_customer_id: null,
  master_user_id: null,
  account_type: null,
  is_signup_complete: null,
  has_agreed_to_terms: null,
  county_id: null,
  business_contact_number: null,
  industry_id: null,
  contact_number: null
}

const reducer = (
  state: typeof initialState,
  action: IActions
): typeof initialState => {
  switch (action.type) {
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        ...(action.payload)
      }
  }
  return state
}

export const setUserProfile = (
  data: IUserProfile,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  dispatch({ type: UPDATE_USER_PROFILE, payload: data })
}

export default reducer
