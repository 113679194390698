import { type IProduct, type IProductDetail } from '@shared/typings'
import Http from '@shared/helper/http-api'
import { useMutation, useQuery } from 'react-query'
import { Category } from '@shared/constants'
import { type AppError } from 'single-spa'

const BASE_URL = process.env.BASE_URL

export const PRODUCTS = 'signup/products/available'
export const ADD_ONS = ''
export const SERVICES = ''
export const BUNDLES = ''
export const NEWS_LETTER_SUBSCRIPTION = 'signup/newsletter-subscriber'
export const PRODUCTS_DETAIL = 'signup/product-banners'

export const useGetProducts = (category: string = Category.PRODUCTS): {
  data: IProduct[] | undefined
  error: AppError | unknown
  isLoading: boolean
} => {
  let url = ''
  switch (category) {
    case Category.PRODUCTS:
      url = PRODUCTS
      break
    case Category.ADD_ONS:
      url = PRODUCTS
      break
    case Category.SERVICES:
      url = PRODUCTS
      break
    case Category.BUNDLES:
      url = PRODUCTS
      break
  }
  const { data, error, isLoading } = useQuery<IProduct[]>(
    url,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(url)
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  )
  return { data, error, isLoading }
}

export const useSubscribeNewsLetter = (): any => {
  const { data, isLoading, mutate, error } = useMutation<any, any>(
    async (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(NEWS_LETTER_SUBSCRIPTION, body)
    }
  )
  return { data, isLoading, mutate, error: error?.message }
}

export const useGetProductsDetail = (): {
  data: IProductDetail[] | undefined
  error: AppError | unknown
  isLoading: boolean
} => {
  const { data, error, isLoading } = useQuery<IProductDetail[]>(
    PRODUCTS_DETAIL,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(PRODUCTS_DETAIL)
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  )
  return { data, error, isLoading }
}
