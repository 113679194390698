import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const ProductsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.product-wrap': {
    '.icon-element': {
      '.btn': {
        '&.with-icon': {
          '&.btn-default': {
            padding: 0,
            svg: {
              width: 21,
              path: {
                stroke: theme.palette.grey[300]
              }
            },
            '&:hover': {
              '&:focus': {
                svg: {
                  path: {
                    stroke: theme.palette.grey[300]
                  }
                }
              }
            }
          }
        }
      }
    },
    '.action-area': {
      '.btn': {
        '.btn-sub-text': {
          marginLeft: 4,
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        },
        '&.btn-default': {
          color: theme.palette.grey[600],
          [theme.breakpoints.down('md')]: {
            color: theme.palette.primary.main
          },
          '&:hover': {
            color: theme.palette.grey[600],
            [theme.breakpoints.down('md')]: {
              color: theme.palette.primary.main
            }
          },
          '&:focus': {
            color: theme.palette.grey[600],
            [theme.breakpoints.down('md')]: {
              color: theme.palette.primary.main
            },
            '&:hover': {
              color: theme.palette.grey[600],
              [theme.breakpoints.down('md')]: {
                color: theme.palette.primary.main
              }
            }
          },
          '.MuiButton-endIcon': {
            transform: 'rotate(180deg)',
            marginRight: 0,
            [theme.breakpoints.down('md')]: {
              display: 'none'
            }
          }
        }
      },
      '.u-form-group': {
        marginBottom: 0,
        '.u-form-control': {
          height: 20,
          fontSize: theme.typography.body2.fontSize,
          lineHeight: theme.typography.body2.lineHeight,
          color: theme.palette.grey[900],
          paddingRight: 0,
          '.MuiInputBase-input': {
            padding: 0,
            '&::placeholder': {
              opacity: 0.3,
              color: theme.palette.grey[900]
            }
          },
          '&.Mui-focused': {
            '.MuiInputBase-input': {
              '&::placeholder': {
                opacity: 0.3
              }
            }
          },
          fieldset: {
            border: 'none'
          }
        }
      }
    },
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.link': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '.section-head': {
      [theme.breakpoints.down('md')]: {
        padding: '0 24px',
        '&.with-quiz-text': {
          '.quiz-action': {
            padding: 0
          }
        }
      }
    },
    '.mobile-slider-grid': {
      overflowX: 'auto',
      overflowY: 'hidden',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0
      },
      '&::-webkit-scrollbar-track': {
        padding: '0 0px',
        backgroundColor: 'transparent'
      },
      '&::-webkit-scrollbar-thumb': {
        transition: 'all 0.3s',
        backgroundColor: 'transparent',
        borderRadius: theme.shape.borderRadius
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'transparent'
      },
      '.MuiGrid-item': {
        [theme.breakpoints.down('md')]: {
          flexShrink: 0.028,
          '&:first-child': {
            marginLeft: 8
          },
          '&:last-child': {
            marginRight: 8
          }
        }
      }
    },
    '.product-section': {
      '.mobile-slider': {
        [theme.breakpoints.up('md')]: {
          display: 'none'
        },
        [theme.breakpoints.down('md')]: {
          display: 'block'
        }
      },
      '.quiz-action': {
        position: 'relative',
        zIndex: 10,
        userSelect: 'none',
        '.icon': {
          display: 'inline-block',
          verticalAlign: 'middle',
          transform: 'rotate(180deg)',
          height: 21,
          marginLeft: 8
        },
        [theme.breakpoints.down('md')]: {
          padding: '0 24px'
        }
      }
    },
    '.no-data': {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      marginLeft: 'auto',
      marginRight: 'auto',
      minHeight: 241,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: 45
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: -24,
      marginRight: -24,
      '.in-grid': {
        padding: '0 24px'
      }
    },
    '.product-box': {
      '.prd-link': {
        '&:before': {
          display: 'none'
        }
      }
    }
  }
}))

export const ProductSlider = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.product-slider': {
    margin: '0 -12px',
    position: 'relative',
    '.carousel-container': {
      paddingBottom: 45,
      paddingLeft: 0,
      paddingRight: 0,
      [theme.breakpoints.down('md')]: {
        padding: '0px 18px 45px 18px'
      },
      '.carousel-item': {
        '.feat-prod-item': {
          padding: '0 12px',
          [theme.breakpoints.down('md')]: {
            padding: '0 4px'
          }
        },
        '.product-box': {
          margin: '0 12px',
          [theme.breakpoints.down('md')]: {
            margin: '0 4px'
          }
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      margin: 0,
      padding: '0px 0px 0px 8px'
    }
  }
}))

export const SearchBox = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.search-box': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 0px 80px rgba(235, 236, 242, 0.75)',
    padding: '24px 40px',
    borderRadius: theme.shape.borderRadius,
    maxWidth: 628,
    margin: 'auto',
    '.icon-element': {
      flexShrink: 0,
      svg: {
        path: {
          stroke: theme.palette.grey[300]
        }
      }
    },
    '.u-form-group': {
      marginBottom: 0,
      marginLeft: 26,
      marginRight: 24,
      flexGrow: 1,
      '.u-form-control': {
        height: 20,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        color: theme.palette.grey[600],
        '.MuiInputBase-input': {
          padding: 0,
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.grey[600]
          }
        },
        '&.Mui-focused': {
          '.MuiInputBase-input': {
            '&::placeholder': {
              opacity: 1
            }
          }
        },
        fieldset: {
          border: 'none'
        }
      }
    },
    '.action-area': {
      flexShrink: 0,
      paddingLeft: 24,
      borderLeft: '1px solid' + theme.palette.grey[100],
      '.btn': {
        '&.btn-default': {
          color: theme.palette.grey[600],
          '&:hover': {
            color: theme.palette.grey[600]
          },
          '&:focus': {
            color: theme.palette.grey[600],
            '&:hover': {
              color: theme.palette.grey[600]
            }
          }
        }
      }
    }
  }
}))

export const FeaturedProducts = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.feature-products': {
    '.prd-link': {
      display: 'block',
      cursor: 'pointer'
    },
    '.prd-img': {
      height: 200,
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      position: 'relative',
      img: {
        maxHeight: 200,
        maxWidth: '100%',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center center'
      },
      '.play-icon': {
        position: 'absolute',
        left: '50%',
        top: '50%',
        zIndex: 1,
        transform: 'translate(-50%, -50%)',
        width: 48,
        height: 48,
        img: {
          maxHeight: 48,
          objectFit: 'contain'
        }
      }
    },
    '.product-slider': {
      '.carousel-container': {
        paddingBottom: 0
      },
      '.carousel-control': {
        top: '33%',
        transform: 'translateY(-33%)',
        marginTop: 0,
        '&.next-btn': {
          transform: 'translatey(-33%) rotate(180deg)'
        }
      }
    }
  }
}))

export const NewsLetterArea = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.newsletter-area': {
    marginBottom: -82
  }
}))

export const HomePageBlogsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.home-page-blogs-wrap': {
    '.blog-list-items-wrap': {
      [theme.breakpoints.down('md')]: {
        marginLeft: -24,
        marginRight: -24,
        '.MuiGrid-container': {
          flexWrap: 'nowrap',
          overflowY: 'hidden',
          overflowX: 'auto',
          paddingLeft: 24,
          paddingRight: 24,
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            width: 0,
            height: 0
          },
          '.MuiGrid-item': {
            paddingLeft: 16,
            maxWidth: 'calc(100% - 4px)',
            flexShrink: 0,
            '.blog-list-item': {
              padding: 0
            }
          }
        }
      }
    }
  }
}))
